import { IntlFormatters } from 'react-intl';

import { ErrorShape } from '_api/types';

import { MESSAGES } from '../../../constants';
import { FormErrors } from '../types';

import { getPickupAtErrorFromServerResponse } from './getPickupAtErrorFromServerResponse';

export const getFormError = (
  errors: FormErrors,
  serverError: null | ErrorShape,
  messageFormatter: IntlFormatters['formatMessage'],
): string | undefined => {
  if (serverError?.data) {
    const serverErrorMessages = serverError.data?.errors || serverError.data;

    if (serverErrorMessages.reference) {
      return;
    }

    if (serverErrorMessages.user_blocked && errors.employeeUserId) {
      return messageFormatter(MESSAGES.formEmployeeBlocked);
    }

    if (serverErrorMessages.payment_type && errors.employeeUserId) {
      return serverErrorMessages.payment_type.join('\n');
    }

    if (serverErrorMessages.wrong_pickup_at && errors.pickupAt) {
      return getPickupAtErrorFromServerResponse(serverError, messageFormatter);
    }

    if (serverErrorMessages.no_coverage_without_city) {
      return messageFormatter(MESSAGES.noCoverageWithoutCity);
    }

    // Handle all other unknown server errors
    if (serverErrorMessages) {
      return Object.values(serverErrorMessages).flat().join('. ');
    }
  }

  if (errors.employeeUserId && errors.pickup) {
    return messageFormatter(MESSAGES.formEmployeeAndPickupEmpty);
  }

  if (errors.employeeUserId) {
    return messageFormatter(MESSAGES.formEmployeeEmpty);
  }

  if (errors.pickup) {
    return messageFormatter(MESSAGES.formPickupEmpty);
  }

  if (errors.flightNumber) {
    return errors.flightNumber;
  }

  if (errors.welcomeBoardText) {
    return errors.welcomeBoardText;
  }

  if (errors.passengerName) {
    return errors.passengerName;
  }

  if (errors.passengerPhoneNumber) {
    return errors.passengerPhoneNumber;
  }
};
