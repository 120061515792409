import { Dispatch } from 'redux';

import { authAPI } from '_api/auth';
import { resetAuthState as resetAuthStateAction, setIsLoggedIn } from '_store/auth';
import { resetGlobalError as resetGlobalErrorAction, setAllowUserLoad } from '_store/ui';
import { resetRegistrationState as resetRegistrationStateAction } from '_store/registration';
import { queryClient } from '_queries/client';

export const logout = async (dispatch: Dispatch, isUserInitiated?: boolean) => {
  if (isUserInitiated) {
    await authAPI.signOut();
    dispatch(setIsLoggedIn({ isLoggedIn: false }));
    dispatch(setAllowUserLoad(false));
  }

  queryClient.clear();
  dispatch(resetAuthStateAction());
  dispatch(resetGlobalErrorAction());
  dispatch(resetRegistrationStateAction());
};
