import React from 'react';
import cn from 'clsx';
import { OverlayView, OverlayViewProps } from '@react-google-maps/api';

import { WithChildren } from '_types/helpers';

import s from './styles.scss';

type CustomMarkerProps = {
  position?: OverlayViewProps['position'];
  onDoubleClick?: () => void;
};

const CustomMarker: React.FC<WithChildren<CustomMarkerProps>> = ({
  children,
  position,
  onDoubleClick,
}) => {
  const handleDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onDoubleClick) {
      e.stopPropagation();
      onDoubleClick();
    }
  };

  return (
    <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <div
        className={cn({ [s.container]: Boolean(onDoubleClick) })}
        onDoubleClick={handleDoubleClick}
      >
        {children}
      </div>
    </OverlayView>
  );
};

export type { CustomMarkerProps };
export { CustomMarker };
