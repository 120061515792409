import React from 'react';
import cn from 'clsx';
import { NavLinkProps, NavLink } from 'react-router-dom';
import { MenuItem, MenuItemProps, Divider } from '@wheely/ui-kit';

import s from './styles.scss';

export type NavMenuItemProps = MenuItemProps & {
  children?: React.ReactNode;
  to?: string;
  dark?: boolean;
  exact?: NavLinkProps['exact'];
};

export const NavMenuItem: React.FC<NavMenuItemProps> = ({
  className,
  to,
  href,
  children,
  dark = true,
  exact = false,
  text,
  ...restProps
}) => {
  const link = to || href;

  const textElement = link ? (
    <NavLink className={s.navLink} exact={exact} to={link}>
      {children || text}
      <Divider className={s.divider} dark={dark} noMargin={true} />
    </NavLink>
  ) : (
    <div className={s.navLink}>
      {children || text}
      <Divider className={s.divider} dark={dark} noMargin={true} />
    </div>
  );

  return (
    <MenuItem
      {...restProps}
      className={cn(className, s.menuItem)}
      tagName="div"
      text={textElement}
    />
  );
};
