import { CreateOrderPayload } from '_api/types';
import { createOrderRequest } from '_store/newJourney';
import { RequireSome } from '_types/helpers';

const getCreateOrderRequestPayload = (
  createOrderPayload: RequireSome<
    CreateOrderPayload,
    'passenger' | 'pickup' | 'service_id' | 'external_id'
  >,
): ReturnType<typeof createOrderRequest>['payload'] => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { passenger, user_id, payment_method_id, app, ...restCreateOrderPayload } =
    createOrderPayload;

  return {
    passenger,
    order: {
      ...restCreateOrderPayload,
    },
  };
};

export { getCreateOrderRequestPayload };
