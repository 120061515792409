import keyBy from 'lodash/fp/keyBy';

import { OrderRequestStatus, OrderStatus } from '_api/types';
import { ValueOf } from '_types/helpers';
import { CardType, CountryCodeId } from '_types/common';

const isDev = process.env.NODE_ENV === 'development';

type Role = 'admin' | 'user' | 'travel_coordinator' | 'finance';

const ROLES = {
  admin: 'admin',
  user: 'user',
  travel_coordinator: 'travel_coordinator',
  finance: 'finance',
} as const;

type PhoneCountryCodeItem = {
  id: CountryCodeId;
  value: string;
};
type PhoneCountryCodeItems = Array<PhoneCountryCodeItem>;

const PHONE_COUNTRY_CODES: PhoneCountryCodeItems = [
  {
    id: 'ru',
    value: '+7',
  },
  {
    id: 'fr',
    value: '+33',
  },
  {
    id: 'uk',
    value: '+44',
  },
  {
    id: 'ae',
    value: '+971',
  },
  {
    id: 'other',
    value: '+',
  },
];

const PHONE_COUNTRY_CODES_MAP = keyBy('id', PHONE_COUNTRY_CODES);

const DEFAULT_PHONE_COUNTRY_CODE = PHONE_COUNTRY_CODES[2].value;

const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const THIN_SPACE = '\u2009';
const NON_BREAKING_SPACE = '\u00A0';

if (!window.b2bAppConfig.GOOGLE_MAPS_API_KEY) {
  throw new Error('`GOOGLE_MAPS_API_KEY` is not set');
}

const GOOGLE_MAPS = {
  googleMapsApiKey: window.b2bAppConfig.GOOGLE_MAPS_API_KEY,
  DEFAULT_ZOOM: 17,
  ROUTE_OPACITY: 0.24,
  ROUTE_WEIGHT: 6,
};

const API_APP_VERSION = 'w4b';

if (!window.b2bAppConfig.ALPHA_MODULUS) {
  throw new Error('`ALPHA_MODULUS` is not set');
}

const ALPHA_MODULUS = window.b2bAppConfig.ALPHA_MODULUS;

if (!window.b2bAppConfig.ALPHA_EXP) {
  throw new Error('`ALPHA_EXP` is not set');
}

const ALPHA_EXP = window.b2bAppConfig.ALPHA_EXP;

const ORDER_STATUSES: OrderStatus[] = [
  'new',
  'accepted',
  'arrived',
  'cancelled',
  'car_not_found',
  'done',
  'failed',
  'looking_for_drivers',
  'no_show',
  'on_the_way',
  'serving',
  'scheduled',
];

const ORDER_REQUEST_STATUSES: OrderRequestStatus[] = [
  'not_confirmed',
  'waiting_placement',
  'waiting_confirmation',
  ...ORDER_STATUSES,
];

const EMPTY_VALUE = '—';

const RU_COMPANY_TYPES = {
  individualEntrepreneur: 'ИП',
  legalEntity: 'Юридическое лицо',
} as const;

type RUCompanyType = ValueOf<typeof RU_COMPANY_TYPES>;

const RU_ACTING_AS_VALUES = {
  exOfficio: 'Устав',
  authorisedSignatory: 'Доверенность',
} as const;

type RUActingAs = ValueOf<typeof RU_ACTING_AS_VALUES>;

const VALID_CARD_EXPIRATION_DATE_REGEXP = /^(\d{2})\/(\d{2})$/;

const PAYER_TYPES = {
  company: 'company',
  user: 'user',
  company_passenger: 'company_passenger',
} as const;

const HCAPTCHA_SITEKEY = window.b2bAppConfig.HCAPTCHA_SITEKEY;

const RU_ACCEPTED_CARD_TYPES: CardType[] = ['visa', 'mastercard', 'mir'];
const NON_RU_ACCEPTED_CARD_TYPES: CardType[] = ['visa', 'mastercard', 'amex'];

const FAVORITE_ORDERS_LIMIT = 5;
const FAVORITE_ORDERS_OVER_LIMIT_STATUS = 'FAVORITE_ORDERS_OVER_LIMIT_STATUS';

const STORAGE_KEY = 'b2b-app-session';
const SYNCRONIZER_STORAGE_KEY = 'b2b-api-channel-refresh-access-token';

export type { CountryCodeId, Role, RUCompanyType, RUActingAs };

export const SNOWPLOW_PLATFORM_TYPES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

export {
  isDev,
  ROLES,
  PHONE_COUNTRY_CODES,
  PHONE_COUNTRY_CODES_MAP,
  DEFAULT_PHONE_COUNTRY_CODE,
  EMAIL_REGEXP,
  THIN_SPACE,
  NON_BREAKING_SPACE,
  GOOGLE_MAPS,
  API_APP_VERSION,
  ORDER_STATUSES,
  ORDER_REQUEST_STATUSES,
  EMPTY_VALUE,
  RU_COMPANY_TYPES,
  RU_ACTING_AS_VALUES,
  VALID_CARD_EXPIRATION_DATE_REGEXP,
  PAYER_TYPES,
  HCAPTCHA_SITEKEY,
  RU_ACCEPTED_CARD_TYPES,
  NON_RU_ACCEPTED_CARD_TYPES,
  ALPHA_MODULUS,
  ALPHA_EXP,
  FAVORITE_ORDERS_LIMIT,
  FAVORITE_ORDERS_OVER_LIMIT_STATUS,
  STORAGE_KEY,
  SYNCRONIZER_STORAGE_KEY,
};
