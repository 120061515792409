export const roundDateUpTo = (minutes: number, date: Date): Date => {
  const ms = 1000 * 60 * minutes;

  return new Date(Math.ceil(date.getTime() / ms) * ms);
};

export const roundTimestampUpTo = (minutes: number, milliseconds: number) => {
  const ms = 1000 * 60 * minutes;

  return Math.ceil(milliseconds / ms) * ms;
};
