import { useCallback, useRef, RefObject } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { captureException } from '@sentry/react';

import { HCAPTCHA_SITEKEY } from '../constants';

let resolveLoadPromise: () => void;
const loadPromise = new Promise<void>(promiseResolve => {
  resolveLoadPromise = promiseResolve;
});

export type HCaptchaProps = {
  sitekey: string;
  ref: RefObject<HCaptcha>;
  size: 'invisible';
  onLoad: () => void;
  // stub
  onVerify: () => void;
  onError: (error: any) => void;
};

type GetCaptchaMethod = () => Promise<string | null>;

const captureCaptchaError = (extra: string) => (error: any) =>
  captureException(error, { extra: { url: extra } });

// we need to execute captcha programmatically, so we need to promisify "onLoad" and "onVerify" callback for pleasant API like "await getCaptcha()";
const useCaptcha = (): [HCaptchaProps, GetCaptchaMethod] => {
  // const [loadPromise, loadResolve] = usePromise();
  const ref = useRef<HCaptcha>(null);

  const onLoad = useCallback(() => {
    if (resolveLoadPromise) {
      resolveLoadPromise();
    }
  }, []);

  const getCaptcha = useCallback(async (): Promise<string | null> => {
    await loadPromise;

    try {
      const result = await ref.current?.execute({ async: true });

      if (!result) {
        return null;
      }

      return result?.response;
    } catch (error) {
      captureCaptchaError('captcha')(error);

      return null;
    }
  }, []);

  return [
    {
      sitekey: HCAPTCHA_SITEKEY || '',
      onLoad,
      ref,
      onError: captureCaptchaError('captcha-lib'),
      size: 'invisible',
      // we don't need onVerify because we have async: true
      // stub for ts
      onVerify: () => undefined,
    },
    getCaptcha,
  ];
};

export { useCaptcha };
