import { DateTime } from 'luxon';
import { setHours, setMinutes } from 'date-fns';

import { isValidDate } from '_utils/isValidDate';

export const getUTCDateISO = (
  date: Date | null | undefined,
  timezone: string | undefined | null,
) => {
  if (!isValidDate(date)) {
    return undefined;
  }

  const timezoneDateTime = DateTime.fromObject(
    {
      year: date.getFullYear(),
      month: date.getMonth() + 1, // js date month starts with 0
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
    },
    { zone: timezone || 'UTC' },
  );

  return timezoneDateTime.setZone('UTC').toISO() as string;
};

export const getLocalDateISO = (date: Date | null | undefined) => {
  if (!isValidDate(date)) {
    return undefined;
  }

  return (DateTime.fromJSDate(date).toISO() as string).slice(0, 23); // remove timezone offset from ISO
};

export const isDateNotExist = (
  date: Date | null | undefined,
  timezone: string | undefined | null,
) => {
  if (!isValidDate(date)) {
    return true;
  }

  const timezoneDateTime = DateTime.fromObject(
    {
      year: date.getFullYear(),
      month: date.getMonth() + 1, // js date month starts with 0
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
    },
    { zone: timezone || 'UTC' },
  );
  const oneHourLaterTimezoneDateTime = DateTime.fromObject(
    {
      year: date.getFullYear(),
      month: date.getMonth() + 1, // js date month starts with 0
      day: date.getDate(),
      hour: date.getHours() + 1,
      minute: date.getMinutes(),
    },
    { zone: timezone || 'UTC' },
  );

  return timezoneDateTime.toMillis() === oneHourLaterTimezoneDateTime.toMillis();
};

export const isTimeNotExist = (
  time: string,
  date: Date | null | undefined,
  timezone: string | undefined | null,
) => {
  if (!isValidDate(date)) {
    return true;
  }

  const [hours, minutes] = time.split(':');
  const timeDate = setMinutes(setHours(date, Number(hours)), Number(minutes));

  return isDateNotExist(timeDate, timezone);
};
