import { Company, User } from '_api/types';
import { queryClient } from '_queries/client';
import { COMPANY, USER } from '_queries/keys';

export const companySelector = () => {
  const companyId = queryClient.getQueryData<User>([USER.LOAD])?.company?.id;

  return queryClient.getQueryData<Company>([COMPANY.LOAD, companyId]);
};

export const currencySelector = () => {
  const company = companySelector();

  return company?.currency || null;
};

export const companyStatusSelector = () => {
  const company = companySelector();

  return company?.status || null;
};

export const isCompanyHasPaymentInfoSelector = () => {
  const company = companySelector();

  return Object.values(company?.payment_info || {}).filter(value => Boolean(value)).length > 0;
};

export const companyTypeSelector = () => {
  const company = companySelector();

  return company?.type || null;
};

export const isCompanyHasPaymentMethodSelector = () => {
  const company = companySelector();

  const paymentMethod = company?.payment_method || null;

  if (!paymentMethod || !('card' in paymentMethod)) {
    return false;
  }

  return Boolean(paymentMethod.card);
};

export const companyCityCenterGoogleFormatSelector = () => {
  // TODO: add city center calculation based on country_code https://wheely.atlassian.net/browse/BUS-3021
  const lodonCenter = { lat: 51.49079, lng: -0.12291 };

  return lodonCenter;
};
