import React from 'react';
import { Route, Switch } from 'react-router';

import { NotFound } from '_pages/NotFound';
import { commonRoutes, remindersRoutes } from '_routes';

import { ReminderPage } from './pages/reminder';

export const RemindersRouter = () => (
  <Switch>
    <Route path={remindersRoutes.reminderRoute} component={ReminderPage} exact={true} />
    <Route component={NotFound} path={[commonRoutes.notFound, '*']} />
  </Switch>
);
