import { useQueryClient } from '@tanstack/react-query';

import { User } from '_api/types';
import { useGetCompany } from '_queries/company/useGetCompany';
import { USER } from '_queries/keys';
import { getIsCorporateType } from '_utils/getCompanyType';

import { useUserLinkedCompany } from './user';

export const useCompany = () => {
  const queryClient = useQueryClient();
  const companyId = queryClient.getQueryData<User>([USER.LOAD])?.company?.id;

  const { data } = useGetCompany(companyId, false);

  return data;
};

export const useCompanyState = () => {
  const companyId = useUserLinkedCompany()?.id;

  return useGetCompany(companyId, false);
};

export const useIsCompanyLoading = () => {
  const queryClient = useQueryClient();
  const companyId = queryClient.getQueryData<User>([USER.LOAD])?.company?.id;

  const { isPending } = useGetCompany(companyId, false);

  return isPending;
};

export const useCompanyDefaultPayer = () => {
  const company = useCompany();

  if (!company || getIsCorporateType(company.type)) {
    return null;
  }

  return company.default_payer || null;
};

export const useCompanyCountryCode = () => {
  const company = useCompany();

  return company?.country_code ?? null;
};

export const useHospitalityFavouriteAddress = () => {
  const company = useCompany();

  return (company?.type === 'hospitality' && company?.favourite_location) || null;
};

export const useIsCreditEnabled = () => {
  const company = useCompany();

  return Boolean(company?.credit_amount) && company?.payment_method?.type === 'card';
};
