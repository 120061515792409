import React from 'react';
import { Body, Heading, LinkButton } from '@wheely/web-ui';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';

import { useLocale } from '_i18n/useLocale';
import { AppIcon } from '_common/icons/AppIcon/AppIcon';

import styles from './styles.scss';

export type ReminderProps = {
  maskedPhone: string;
  companyName: string;
  appUrl: string;
};

export const Reminder = ({ maskedPhone, companyName, appUrl }: ReminderProps) => {
  const { langCode } = useLocale();

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.titleBlock}>
          <Heading size="L" className={styles.title}>
            <FormattedMessage
              defaultMessage="Use Your Corporate Account"
              id="TNE+4C"
              description="Reminder title"
            />
          </Heading>
          <Body className={styles.lead}>
            <FormattedMessage
              defaultMessage="{companyName} kindly reminds that you can use your corporate payment method for Wheely business journeys."
              id="UTUxL5"
              description="Reminder lead"
              values={{
                companyName: companyName.replaceAll(' ', ' '),
              }}
            />
          </Body>
          <div className={styles.appLink}>
            <LinkButton as="a" href={appUrl}>
              <FormattedMessage
                defaultMessage="Continue in the app"
                id="lQ/s4D"
                description="App link"
              />
              <AppIcon className={styles.appIcon} />
            </LinkButton>
          </div>
        </div>
        <Heading size="M" className={styles.heading}>
          <FormattedMessage
            defaultMessage="Wheely App"
            id="bQEdKA"
            description="Wheely app description heading"
          />
        </Heading>
        <Body className={styles.text}>
          <FormattedMessage
            defaultMessage="To begin using your corporate payment method, sign in to the {appLink} with your phone number {maskedPhone}. Select {companyName} as a payment method when requesting a journey."
            id="3rhkX5"
            description="Wheely app description"
            values={{
              appLink: <a href={appUrl}>Wheely app</a>,
              maskedPhone: maskedPhone.replaceAll(' ', ' '),
              companyName: companyName.replaceAll(' ', ' '),
            }}
          />
        </Body>
        <div className={cx(styles.image, styles[langCode])} data-test-id="reminder_gif" />
      </div>
    </div>
  );
};
