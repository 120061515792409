import { createSlice, createAction } from '@reduxjs/toolkit';
import omit from 'lodash/fp/omit';

import { RootState } from '_store';
import { Employee, ErrorShape } from '_api/types';

export type EmployeesOrder = string[];
export type EmployeeEntities = Record<string, Employee>;
export type EmployeeSelection = Record<string, boolean>;

export type EmployeesState = {
  query: string;
  name: string;
  selection: EmployeeSelection;
  offset: number;
  total: number;
  order: EmployeesOrder;
  entities: EmployeeEntities;
  isLoading: boolean;
  error: null | ErrorShape;
  userAsEmployee: null | Employee;
  canLoadMore: boolean;
  saveEmployeeStatus: 'INITIAL' | 'SUCCESS' | 'FAILED';
};

export const loadEmployees = createAction<{
  skip?: number;
  limit?: number;
  query?: string;
  departmentId?: string;
  excludeDepartmentId?: string;
  name?: string;
  appendMode?: boolean;
}>('employees/loadEmployees');
export const loadEmployeesFailed = createAction<{ error: ErrorShape }>(
  'employees/loadEmployeesFailed',
);
export const loadEmployeesSucceed = createAction<{
  appendMode?: boolean;
  skip: number;
  limit: number;
  order: EmployeesOrder;
  entities: EmployeeEntities;
  total: number;
}>('employees/loadEmployeesSucceed');

export const selectEmployees = createAction<{ employeeIds: string[] }>('employees/selectEmployees');
export const deselectEmployees = createAction<{ employeeIds: string[] }>(
  'employees/deselectEmployees',
);
export const clearEmployeeSelection = createAction('employees/clearEmployeeSelection');

export const deleteSelectedEmployees = createAction('employees/deleteSelectedEmployees');
export const deleteSelectedEmployeesSucceed = createAction<{ employeeIds: string[] }>(
  'employees/deleteSelectedEmployeesSucceed',
);

export const removeSelectedEmployeesFromDepartment = createAction(
  'employees/removeSelectedEmployeesFromDepartment',
);

export const removeSelectedEmployeesFromDepartmentSucceed = createAction<{ employeeIds: string[] }>(
  'employees/removeSelectedEmployeesFromDepartmentSucceed',
);

export const loadEmployee = createAction<{ id: string }>('employees/loadEmployee');
export const loadEmployeeFailed = createAction<{ error: ErrorShape }>(
  'employees/loadEmployeeFailed',
);
export const loadEmployeeSucceed = createAction<{ entities: EmployeeEntities }>(
  'employees/loadEmployeeSucceed',
);

export const saveEmployee = createAction<{ id: string; employee: Employee }>(
  'employees/saveEmployee',
);
export const saveEmployeeFailed = createAction<{ error: ErrorShape }>(
  'employees/saveEmployeeFailed',
);
export const saveEmployeeSucceed = createAction<{ entities: EmployeeEntities }>(
  'employees/saveEmployeeSucceed',
);

export const deleteEmployee = createAction<{ id: string; redirectPath?: string }>(
  'employees/deleteEmployee',
);
export const deleteEmployeeFailed = createAction<{ error: ErrorShape }>(
  'employees/deleteEmployeeFailed',
);
export const deleteEmployeeSucceed = createAction<{ id: string }>(
  'employees/deleteEmployeeSucceed',
);

export const addEmployees = createAction<{ entities: EmployeeEntities; order: EmployeesOrder }>(
  'employees/addEmployees',
);

export const resetQueryState = createAction('employees/resetQueryState');

export const resetNameState = createAction('employees/resetNameState');

export const loadUserAsEmployeeSucceed = createAction<{ employee: Employee }>(
  'employees/loadUserAsEmployeeSucceed',
);

export const resetSaveEmployeeStatus = createAction('employees/resetSaveEmployeeStatus');

const initialState: EmployeesState = {
  query: '',
  name: '',
  selection: {},
  order: [],
  entities: {},
  offset: 0,
  total: 0,
  error: null,
  isLoading: false,
  userAsEmployee: null,
  canLoadMore: true,
  saveEmployeeStatus: 'INITIAL',
};

export const employeesOrderSelector = (state: RootState) => state.employees.order;
export const employeesEntitiesSelector = (state: RootState) => state.employees.entities;
export const employeesSelectionSelector = (state: RootState) => state.employees.selection;
export const areEmployeesLoadingSelector = (state: RootState) => state.employees.isLoading;
export const employeesErrorSelector = (state: RootState) => state.employees.error;
export const canLoadMoreSelector = (state: RootState) => state.employees.canLoadMore;

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadEmployees, (state, { payload: { skip, query, name, appendMode } }) => ({
      ...state,
      query: query ?? state.query,
      name: name ?? state.name,
      selection: skip ? state.selection : {},
      order: skip || appendMode ? state.order : [],
      entities: skip || appendMode ? state.entities : {},
      error: null,
      isLoading: true,
    }));
    builder.addCase(
      loadEmployeesSucceed,
      (state, { payload: { skip, appendMode, order, entities, total } }) => ({
        ...state,
        isLoading: false,
        error: null,
        order: skip || appendMode ? Array.from(new Set([...state.order, ...order])) : order,
        entities: skip || appendMode ? { ...state.entities, ...entities } : entities,
        total,
        canLoadMore: order.length < total,
      }),
    );
    builder.addCase(loadEmployeesFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }));
    builder.addCase(selectEmployees, (state, { payload }) => ({
      ...state,
      selection: payload.employeeIds.reduce(
        (selection, employeeId) => {
          selection[employeeId] = true;

          return selection;
        },
        { ...state.selection },
      ),
    }));
    builder.addCase(deselectEmployees, (state, { payload }) => ({
      ...state,
      selection: omit(payload.employeeIds, state.selection),
    }));
    builder.addCase(clearEmployeeSelection, state => ({
      ...state,
      selection: {},
    }));

    builder.addCase(deleteSelectedEmployeesSucceed, (state, { payload }) => {
      const deletedIds = new Set(payload.employeeIds);

      return {
        ...state,
        order: state.order.filter(employeeId => !deletedIds.has(employeeId)),
        entities: omit(payload.employeeIds, state.entities),
        selection: {},
      };
    });
    builder.addCase(loadEmployee, state => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(loadEmployeeSucceed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: null,
      entities: {
        ...state.entities,
        ...payload.entities,
      },
    }));
    builder.addCase(loadEmployeeFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }));
    builder.addCase(saveEmployee, state => ({
      ...state,
      isLoading: true,
      error: null,
      saveEmployeeStatus: 'INITIAL',
    }));
    builder.addCase(saveEmployeeSucceed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: null,
      saveEmployeeStatus: 'SUCCESS',
      entities: {
        ...state.entities,
        ...payload.entities,
      },
    }));
    builder.addCase(saveEmployeeFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
      saveEmployeeStatus: 'FAILED',
    }));
    builder.addCase(deleteEmployee, state => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(deleteEmployeeFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }));
    builder.addCase(deleteEmployeeSucceed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: null,
      order: state.order.filter(employeeId => employeeId !== payload.id),
      entities: omit(payload.id, state.entities),
    }));

    builder.addCase(addEmployees, (state, { payload }) => ({
      ...state,
      order: [...state.order, ...payload.order],
      entities: { ...state.entities, ...payload.entities },
    }));

    builder.addCase(removeSelectedEmployeesFromDepartmentSucceed, (state, { payload }) => {
      const deletedIds = new Set(payload.employeeIds);

      return {
        ...state,
        order: state.order.filter(employeeId => !deletedIds.has(employeeId)),
        entities: omit(payload.employeeIds, state.entities),
        selection: {},
      };
    });

    builder.addCase(resetQueryState, state => ({
      ...state,
      query: initialState.query,
    }));

    builder.addCase(resetNameState, state => ({
      ...state,
      name: initialState.name,
    }));

    builder.addCase(loadUserAsEmployeeSucceed, (state, { payload }) => ({
      ...state,
      userAsEmployee: payload.employee,
    }));
    builder.addCase(resetSaveEmployeeStatus, state => ({
      ...state,
      saveEmployeeStatus: 'INITIAL',
    }));
  },
});

export { employeesSlice };
