const IOS_REGEXP = new RegExp('\\biPhone.*Mobile|\\biPod|\\biPad|AppleCoreMedia', 'i');
const ANDROID_REGEXP = new RegExp('Android', 'i');

export interface Device {
  isPhone: boolean;
  isAndroid: boolean;
  isIOS: boolean;
}

export const useDevice = (): Device => {
  if (typeof window === 'undefined') {
    return {
      isPhone: false,
      isAndroid: false,
      isIOS: false,
    };
  }

  const userAgent = window.navigator.userAgent;
  const isIOS = IOS_REGEXP.test(userAgent);
  const isAndroid = ANDROID_REGEXP.test(userAgent);
  const isPhone = isIOS || isAndroid;

  return {
    isPhone,
    isAndroid,
    isIOS,
  };
};
