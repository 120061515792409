import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FootnoteArrowChevronRightIcon, FootnoteButton, LinkButton } from '@wheely/web-ui';

import { useLocale } from '_i18n/useLocale';

import { LanguageSelectNative } from '../../LanguageSelectNative';

import styles from './styles.scss';

export const Footer = () => {
  const { langCode } = useLocale();
  const currentYear = new Date().getFullYear();
  const wheelyYearText = `© ${currentYear} Wheely`;

  return (
    <div className={styles.root} data-test-id="footer_root">
      <div className={styles.content}>
        <LanguageSelectNative />
        <div className={styles.citiesContainer}>
          <div className={styles.marketingInfo}>
            <FootnoteButton>{wheelyYearText}</FootnoteButton>
            <LinkButton
              className={styles.mobileManifestoLink}
              size="small"
              icon={<FootnoteArrowChevronRightIcon />}
              as="a"
              href="https://wheely.com/manifesto"
            >
              <FormattedMessage
                defaultMessage="Time Redefined"
                id="qYWzX1"
                description="Manifesto link"
              />
            </LinkButton>
          </div>
          <div className={styles.cities}>
            <FootnoteButton>
              <FormattedMessage defaultMessage="London" id="o1Ay9A" description="London" />
            </FootnoteButton>
            <FootnoteButton>
              <FormattedMessage defaultMessage="Paris" id="56lZNj" description="Paris" />
            </FootnoteButton>
            <FootnoteButton>
              <FormattedMessage defaultMessage="Dubai" id="53X5Bn" description="Dubai" />
            </FootnoteButton>
            {langCode === 'ru' && (
              <FootnoteButton>
                <FormattedMessage defaultMessage="Moscow" id="7lhrdm" description="Moscow" />
              </FootnoteButton>
            )}
          </div>
          <LinkButton
            className={styles.desktopManifestoLink}
            size="small"
            icon={<FootnoteArrowChevronRightIcon />}
            as="a"
            href="https://wheely.com/manifesto"
          >
            <FormattedMessage
              defaultMessage="Time Redefined"
              id="qYWzX1"
              description="Manifesto link"
            />
          </LinkButton>
        </div>
        <FootnoteButton>
          <FormattedMessage
            defaultMessage="Wheely Ltd. Registered in England & Wales with company number 11473477."
            id="v9ofbY"
            description="Legal Info"
          />
          <br />
          <FormattedMessage
            defaultMessage="Registered office: The Monastery Barn, Syon Park, London Road, Brentford, TW8 8JF, United Kingdom."
            id="5BWStr"
            description="Legal address"
          />
        </FootnoteButton>
      </div>
    </div>
  );
};
