import React from 'react';

import { WheelyLogoIcon } from '_common/icons/IconWheelyLogo';

import styles from './styles.scss';

type Props = {
  rightContent?: React.ReactNode;
  leftContent?: React.ReactNode;
};

export const Header = ({ rightContent, leftContent }: Props) => (
  <div className={styles.root}>
    <div className={styles.content}>
      <div className={styles.leftContent}>{leftContent}</div>
      <WheelyLogoIcon data-test-id="header_logo" className={styles.icon} />
      <div className={styles.rightContent}>{rightContent}</div>
    </div>
  </div>
);
