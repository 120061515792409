import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MenuDivider, useNotification } from '@wheely/ui-kit';
import { FormattedMessage, useIntl } from 'react-intl';

import { useClickOutside } from '_hooks/useClickOutside';
import { useMenuItems } from '_layouts/Basic/components/Footer/hooks/useMenuItems';
import { useDimmer } from '_layouts/Basic/components/Menu/DimmerContext';

import { CustomerServiceButton } from '../CustomerServiceButton/CustomerServiceButton';

import styles from './styles.scss';

export const CustomerServiceMenu = () => {
  const menuItems = useMenuItems();
  const [isOpened, setIsOpened] = useState(false);
  const { setIsShowDimmer } = useDimmer();
  const intl = useIntl();
  const { showNotification } = useNotification();

  const handleClose = useCallback(() => {
    setIsOpened(false);
  }, []);

  const handleToggle = useCallback(() => {
    setIsOpened(state => !state);
  }, []);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsShowDimmer(isOpened);
  }, [isOpened, setIsShowDimmer]);

  useClickOutside(dropdownRef, handleClose);

  const handleEmailClick = useCallback(() => {
    window.navigator.clipboard
      .writeText(menuItems.supportEmail)
      .then(() => {
        showNotification({
          message: intl.formatMessage({
            description: 'Support email copied notification in menu text',
            defaultMessage: 'Email address copied',
            id: 'global_copied',
          }),
        });
      })
      .catch(() => {});
  }, [intl, menuItems.supportEmail, showNotification]);

  return (
    <div className={styles.root} ref={dropdownRef}>
      {isOpened && (
        <div className={styles.menu}>
          <a
            className={styles.menuButton}
            target="_blank"
            href={menuItems.faqLink}
            rel="noreferrer"
          >
            <FormattedMessage description="FAQ menu item text" defaultMessage="FAQ" id="KuUQkk" />
          </a>
          <a
            className={styles.menuButton}
            target="_blank"
            href={menuItems.citiesAndFaresLink}
            rel="noreferrer"
          >
            <FormattedMessage
              description="Cities & Fares menu item text"
              defaultMessage="Cities & Fares"
              id="4/Ti5t"
            />
          </a>
          <MenuDivider className={styles.divider} />
          <a
            className={styles.menuButton}
            target="_blank"
            href={`tel:${menuItems.supportPhone.replace(/[\s\-()]/g, '')}`}
            rel="noreferrer"
          >
            {menuItems.supportPhone}
          </a>
          <button className={styles.menuButton} onClick={handleEmailClick}>
            {menuItems.supportEmail}
          </button>
        </div>
      )}
      <CustomerServiceButton onClick={handleToggle} />
    </div>
  );
};
