import React, { useCallback, useMemo, useState } from 'react';
import cn from 'clsx';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';

import {
  companyCityOrUserLocationSelector,
  selectedServiceOfferDurationSelector,
} from '_store/common/selectors';
import { useTypedSelector } from '_store';
import { Position, Location } from '_api/types';
import { Map as BaseMap } from '_common/Map';
import {
  dropoffPositionSelector,
  stopsSelector,
  pickupPositionSelector,
  isPrebookModeSelector,
} from '_store/newJourney';
import { ExtraStopMarker } from '_common/ExtraStopMarker';
import { Track } from '_common/Track';
import { convertPositionToCoordsShape } from '_utils/convertPositionToCoordsShape';
import {
  mapModeSelector,
  setEditMode,
  FieldToEdit,
  fieldToEditSelector,
  restoreViewMode,
  currentLocationSelector,
  etaSelector,
  geocodingStateSelector,
} from '_store/mapInteraction';
import { PinOnMapMarker } from '_common/PinOnMapMarker';

import { PickupMarker } from '../PickupMarker';
import { TripDetails } from '../TripDetails';

import s from './styles.scss';

const getMiddlePosition = (pickup?: Position, dropoff?: Position) => {
  if (!pickup) {
    return null;
  }

  if (!dropoff) {
    return { lat: pickup[0], lng: pickup[1] };
  }

  return { lat: (pickup[0] + dropoff[0]) / 2, lng: (pickup[1] + dropoff[1]) / 2 };
};

type Props = {
  className?: string;
  handleSetPickupLocation?: (location: Location) => void;
  isViewOnly?: boolean;
  showEditControls?: boolean;
};

export const Map = ({
  className,
  handleSetPickupLocation,
  isViewOnly,
  showEditControls = true,
}: Props) => {
  const dispatch = useDispatch();
  const [isMapReady, setIsMapReady] = useState(false);
  const defaultCenter = useTypedSelector(companyCityOrUserLocationSelector);
  const pickupPosition = useTypedSelector(pickupPositionSelector);
  const dropOffPosition = useTypedSelector(dropoffPositionSelector);
  const duration = useTypedSelector(selectedServiceOfferDurationSelector);
  const route = useTypedSelector(state => state.newJourney.route.polyline);
  const isLoading = useTypedSelector(state => state.serviceOffers.isLoading);
  const stops = useTypedSelector(stopsSelector);
  const fieldToEdit = useTypedSelector(fieldToEditSelector);
  const etaForEditMode = useTypedSelector(etaSelector);
  const geocodingState = useTypedSelector(geocodingStateSelector);
  const isPrebookModeSelected = useTypedSelector(isPrebookModeSelector);

  const currentLocation = useTypedSelector(currentLocationSelector);

  const { setFieldValue } = useFormikContext();

  const mapMode = useTypedSelector(mapModeSelector);

  const isMapInEditingMode = isViewOnly ? false : mapMode === 'edit' && fieldToEdit;

  const handleDoubleClick = useCallback(
    (field: FieldToEdit) => {
      dispatch(setEditMode({ field }));
    },
    [dispatch],
  );

  const path = useMemo(() => {
    if (!route || !isMapReady) {
      return null;
    }

    return google?.maps?.geometry?.encoding?.decodePath(route);
  }, [route, isMapReady]);

  const tripDetailsCoords = useMemo(
    () => getMiddlePosition(pickupPosition, dropOffPosition),
    [pickupPosition, dropOffPosition],
  );

  const handleMapLoad = useCallback(() => {
    setIsMapReady(true);
  }, []);

  const handleCancelEditingPin = useCallback(() => {
    dispatch(restoreViewMode());
  }, [dispatch]);

  const handleConfirmEditingPin = useCallback(() => {
    if (!currentLocation) {
      return;
    }

    if (fieldToEdit?.type === 'stop') {
      setFieldValue(`stops.${fieldToEdit.index}.data`, currentLocation);
    }

    if (fieldToEdit?.type === 'pickup' && handleSetPickupLocation) {
      handleSetPickupLocation(currentLocation);
    }

    dispatch(restoreViewMode());
  }, [dispatch, setFieldValue, fieldToEdit, handleSetPickupLocation, currentLocation]);

  if (!defaultCenter) {
    return null;
  }

  const editModeProps = isMapInEditingMode
    ? {
        onCancel: handleCancelEditingPin,
        onConfirm: handleConfirmEditingPin,
        showEditControls,
      }
    : null;

  const pinOnMapMarkerEta =
    isPrebookModeSelected || geocodingState === 'loading' ? null : etaForEditMode;

  return (
    <BaseMap
      center={tripDetailsCoords ?? defaultCenter}
      className={cn(s.mapWrapper, className)}
      onLoad={handleMapLoad}
      editModeProps={editModeProps}
    >
      {isMapInEditingMode && <PinOnMapMarker eta={pinOnMapMarkerEta} />}

      {!isMapInEditingMode && pickupPosition && (
        <PickupMarker
          className={s.pickupMarker}
          position={convertPositionToCoordsShape(pickupPosition)}
          onDoubleClick={() =>
            handleDoubleClick({
              type: 'pickup',
              position: convertPositionToCoordsShape(pickupPosition),
            })
          }
        />
      )}

      {!isMapInEditingMode
        ? stops.reduce<React.ReactNode[]>((fulfilledStops, stop, index) => {
            // i.e. empty field
            if (!stop?.position) {
              return fulfilledStops;
            }

            const marker = (
              <ExtraStopMarker
                key={index}
                position={convertPositionToCoordsShape(stop.position)}
                onDoubleClick={() =>
                  handleDoubleClick({
                    type: 'stop',
                    index,
                    position: convertPositionToCoordsShape(stop.position),
                  })
                }
              />
            );

            // i.e. render all, except the empty ones
            return [...fulfilledStops, marker];
          }, [])
        : null}

      {!isMapInEditingMode && tripDetailsCoords && duration && (
        <TripDetails duration={duration} position={tripDetailsCoords} isLoading={isLoading} />
      )}
      {!isMapInEditingMode && <Track path={path} />}
    </BaseMap>
  );
};
