import React from 'react';
import { Button, ButtonGroup, IconArrowLeft } from '@wheely/ui-kit';
import { useIntl } from 'react-intl';

import dialogStyles from '../../styles.scss';

import s from './styles.scss';

type Props = {
  onChangeFlight: () => void;
  onBookWithoutFlightNumber: () => void;
  onBack: () => void;
};

export const FlightNotFound = ({ onChangeFlight, onBookWithoutFlightNumber, onBack }: Props) => {
  const intl = useIntl();

  return (
    <>
      <div className={dialogStyles.titleWithBackButton}>
        <Button
          className={dialogStyles.backButton}
          minimal={true}
          onClick={onBack}
          icon={<IconArrowLeft />}
        />
        <div>
          {intl.formatMessage({
            defaultMessage: 'Flight not found',
            id: 'yot17P',
            description: 'Flight not found modal title',
          })}
        </div>
      </div>
      <div className={s.text}>
        {intl.formatMessage({
          defaultMessage:
            'Please make sure you have entered the correct flight number and departure date. Your flight may not be listed in the tracking system. In this case, please select the airport and enter your landing time.',
          id: '5rPHTw',
          description: 'Flight not description',
        })}
      </div>
      <ButtonGroup className={dialogStyles.footer}>
        <Button
          className={dialogStyles.footerButton}
          onClick={onChangeFlight}
          text={intl.formatMessage({
            defaultMessage: 'Edit flight',
            id: 'r5IOZy',
            description: 'Edit flight button text',
          })}
        />
        <Button
          className={dialogStyles.footerButton}
          minimal={true}
          onClick={onBookWithoutFlightNumber}
          text={intl.formatMessage({
            defaultMessage: 'Book without flight number',
            id: '9WL0nx',
            description: 'Book without flight number button text',
          })}
        />
      </ButtonGroup>
    </>
  );
};
