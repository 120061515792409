import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, Intent } from '@wheely/ui-kit';

import { useTypedSelector } from '_store';
import { Location } from '_api/types';
import {
  restoreViewMode,
  currentLocationSelector,
  fieldToEditSelector,
} from '_store/mapInteraction';

import s from './styles.scss';

type Props = React.PropsWithChildren<{
  handleSetPickupLocation?: (location: Location) => void;
}>;

export const MobileModalControls = ({ children, handleSetPickupLocation }: Props) => {
  const dispatch = useDispatch();
  const currentLocation = useTypedSelector(currentLocationSelector);
  const fieldToEdit = useTypedSelector(fieldToEditSelector);

  const { setFieldValue } = useFormikContext();

  const handleCancelMapEditing = useCallback(() => {
    dispatch(restoreViewMode());
  }, [dispatch]);

  const handleConfirmEditingPin = useCallback(() => {
    if (!currentLocation) {
      return;
    }

    if (fieldToEdit?.type === 'pickup' && handleSetPickupLocation) {
      handleSetPickupLocation(currentLocation);
    }

    if (fieldToEdit?.type === 'stop') {
      setFieldValue(`stops.${fieldToEdit.index}.data`, currentLocation);
    }

    dispatch(restoreViewMode());
  }, [dispatch, setFieldValue, fieldToEdit, currentLocation, handleSetPickupLocation]);

  return (
    <div className={s.container}>
      {children}
      <div className={s.buttons}>
        <Button disabled={!currentLocation} onClick={handleConfirmEditingPin} className={s.button}>
          <FormattedMessage
            description="Confirm button text"
            defaultMessage="Confirm"
            id="yrTAdQ"
          />
        </Button>
        <Button onClick={handleCancelMapEditing} intent={Intent.NONE} className={s.button}>
          <FormattedMessage description="Cancel button title" defaultMessage="Cancel" id="D4IuIb" />
        </Button>
      </div>
    </div>
  );
};
