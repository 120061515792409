import { AddCompanyStep } from './context';

const eventNamesMap: Record<AddCompanyStep, string> = {
  WORK_EMAIL: 'Signup - Signup - Open',
  COMPANY_DETAILS: 'Signup - Company Details - Open',
  PAYMENT_METHOD: 'Signup - Payment Method - Open',
  ACCOUNT_SETTINGS: 'Signup - Account Settings - Open',
};

export const getEventName = (step: keyof typeof eventNamesMap) => eventNamesMap[step];

export const ADD_COMPANY_SOURCE = 'dashboard';
export const NEW_REGISTRATION_VERSION = '2.0';
