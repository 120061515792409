import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';

import { RootState } from '_store';
import { ErrorShape, ServiceOffer, GetServiceFaresPayload } from '_api/types';
import { selectedServiceIdSelector } from '_store/newJourney';

export type ServiceOffersOrder = string[];
export type ServiceOffersEntities = Record<string, ServiceOffer>;

export type ServiceOffersState = {
  isLoading: boolean;
  error: null | ErrorShape;
  order: ServiceOffersOrder;
  entities: ServiceOffersEntities;
};

export const setServiceOffersLoadingState = createAction<{ isLoading: boolean }>(
  'serviceOffers/setServiceOffersLoadingState',
);
export const loadServiceOffers = createAction<GetServiceFaresPayload & { isSilent?: boolean }>(
  'serviceOffers/loadServiceOffers',
);
export const loadServiceOffersSucceed = createAction<{
  order: ServiceOffersOrder;
  entities: ServiceOffersEntities;
}>('serviceOffers/loadServiceOffersSucceed');
export const loadServiceOffersFailed = createAction<{
  error: ErrorShape;
}>('serviceOffers/loadServiceOffersFailed');

const initialState: ServiceOffersState = {
  isLoading: false,
  error: null,
  order: [],
  entities: {},
};

export const serviceOffersOrderSelector = (state: RootState): ServiceOffersOrder =>
  state.serviceOffers.order;
export const serviceOffersEntitiesSelector = (state: RootState): ServiceOffersEntities =>
  state.serviceOffers.entities;
export const serviceOffersSelector = createSelector(
  serviceOffersOrderSelector,
  serviceOffersEntitiesSelector,
  (order, entities) => order.map(id => entities[id]),
);
export const selectedServiceOfferSelector = createSelector(
  serviceOffersEntitiesSelector,
  selectedServiceIdSelector,
  (serviceOffersEntities, selectedId) => {
    if (!selectedId || !(selectedId in serviceOffersEntities)) {
      return null;
    }

    return serviceOffersEntities[selectedId];
  },
);

export const isChauffeurForADayAvailableSelector = createSelector(
  serviceOffersEntitiesSelector,
  serviceOffersEntities =>
    Object.values(serviceOffersEntities).some(serviceOffer =>
      Boolean(serviceOffer?.service?.long_journey_options?.length),
    ),
);

export const isChauffeurForADayAvailableForSelectedServiceSelector = createSelector(
  selectedServiceOfferSelector,
  selectedServiceOffer => {
    if (!selectedServiceOffer?.service?.long_journey_options) {
      return false;
    }

    return selectedServiceOffer?.service?.long_journey_options.length > 0;
  },
);

export const isLoadingSelector = (state: RootState) => state.serviceOffers.isLoading;

export const serverErrorSelector = (state: RootState) => state.serviceOffers.error;

export const serviceByIdSelector = createSelector(
  serviceOffersEntitiesSelector,
  (state: RootState, { id }: { id?: string }) => id,
  (entities, id) => (id && id in entities ? entities[id] : null),
);

const serviceOffersSlice = createSlice({
  name: 'serviceOffers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setServiceOffersLoadingState, (state, { payload: { isLoading } }) => ({
      ...state,
      isLoading,
    }));

    builder.addCase(loadServiceOffers, (state, { payload: { isSilent } }) => ({
      ...state,
      isLoading: isSilent ? state.isLoading : true,
      error: null,
    }));

    builder.addCase(loadServiceOffersSucceed, (state, { payload: { order, entities } }) => ({
      ...state,
      isLoading: false,
      order,
      entities,
      error: null,
    }));

    builder.addCase(loadServiceOffersFailed, (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      order: [],
      error,
    }));
  },
});

export { serviceOffersSlice };
