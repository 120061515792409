import { Button, IconArrowLeft, ButtonGroup } from '@wheely/ui-kit';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Flight } from '_api/types';
import { FlightCard } from '_common/FlightCard';

import dialogStyles from '../../styles.scss';

import s from './styles.scss';

type Props = {
  selectedFlight: Flight | null;
  flights: Flight[];
  isLoading: boolean;
  isServerErrorOccurred: boolean;
  onSelect: (flight: Flight) => void;
  onConfirm: () => void;
  onBack: () => void;
};

export const FlightList = ({
  selectedFlight,
  flights,
  isLoading,
  isServerErrorOccurred,
  onSelect,
  onConfirm,
  onBack,
}: Props) => {
  const intl = useIntl();

  return (
    <>
      <div className={dialogStyles.titleWithBackButton}>
        <Button
          className={dialogStyles.backButton}
          minimal={true}
          onClick={onBack}
          icon={<IconArrowLeft />}
        />
        <div>
          {intl.formatMessage({
            defaultMessage: 'Choose your flight',
            id: 'AY9Y88',
            description: 'Choose your flight modal title',
          })}
        </div>
      </div>
      <div className={s.list}>
        {flights.map(flight => (
          <FlightCard
            key={flight.id}
            flight={flight}
            isSelectable={true}
            className={s.flightCard}
            isSelected={flight === selectedFlight}
            onSelect={onSelect}
          />
        ))}
      </div>
      <div className={dialogStyles.footer}>
        <ButtonGroup>
          <Button
            onClick={onConfirm}
            disabled={isLoading}
            loading={isLoading}
            text={intl.formatMessage({
              defaultMessage: 'Confirm',
              id: 'yrTAdQ',
              description: 'Confirm button text',
            })}
          />
          <Button
            minimal={true}
            onClick={onBack}
            text={intl.formatMessage({
              defaultMessage: 'Change flight details',
              id: '2zDStg',
              description: 'Change flight details button text',
            })}
          />
        </ButtonGroup>
        {isServerErrorOccurred && (
          <div className={s.serverErrorMessageContainer}>
            <FormattedMessage
              description="Server error message"
              defaultMessage="Server error, please try again later."
              id="PigG+0"
            />
          </div>
        )}
      </div>
    </>
  );
};
