import React from 'react';
import cx from 'clsx';
import { H7, Text } from '@wheely/ui-kit';
import { FormattedMessage } from 'react-intl';

import { IconPassengerFilled } from '_common/icons/IconPassengerFilled';

import styles from './styles.scss';

type UserButtonProps = {
  userName?: JSX.Element | string;
  onClick: VoidFunction;
};

export const UserButton = ({ userName, onClick }: UserButtonProps) => {
  return (
    <button className={cx(styles.root)} onClick={onClick}>
      <IconPassengerFilled height={18} />
      <div className={styles.userInfo}>
        <Text withoutPadding={true}>{userName}</Text>
        <H7 className={styles.title}>
          <FormattedMessage
            defaultMessage="My Info"
            id="4yaz9L"
            description="Sidebar menu my info title"
          />
        </H7>
      </div>
    </button>
  );
};
