import { createAction, createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/fp/omit';
import uniq from 'lodash/fp/uniq';

import { RootState } from '_store';
import { Department, ErrorShape } from '_api/types';

export type DepartmentsOrder = string[];
export type DepartmentEntities = Record<string, Department>;
export type DepartmentsSelection = Record<string, boolean>;

export type DepartmentsState = {
  query: string;
  total: number;
  selection: DepartmentsSelection;
  offset: number;
  order: DepartmentsOrder;
  entities: DepartmentEntities;
  isLoading: boolean;
  error: null | ErrorShape;
};

export const loadDepartments = createAction<{ skip?: number; limit?: number; query?: string }>(
  'departments/loadDepartments',
);
export const loadDepartmentsFailed = createAction<{ error: ErrorShape }>(
  'departments/loadDepartmentsFailed',
);
export const loadDepartmentsSucceed = createAction<{
  skip: number;
  limit: number;
  total: number;
  order: DepartmentsOrder;
  entities: DepartmentEntities;
}>('departments/loadDepartmentsSucceed');

export const selectDepartments = createAction<{ departmentIds: string[] }>(
  'departments/selectDepartments',
);
export const deselectDepartments = createAction<{ departmentIds: string[] }>(
  'departments/deselectDepartments',
);

export const deleteSelectedDepartments = createAction('departments/deleteSelectedDepartments');
export const deleteSelectedDepartmentsSucceed = createAction<{ departmentIds: string[] }>(
  'departments/deleteSelectedDepartmentsSucceed',
);

export const createDepartment = createAction<{ title: string }>('departments/createDepartment');
export const createDepartmentFailed = createAction<{ error: ErrorShape }>(
  'departments/createDepartmentFailed',
);
export const createDepartmentSucceed = createAction<{ department: Department }>(
  'departments/createDepartmentSucceed',
);

export const editDepartment = createAction<{ id: string; title: string }>(
  'departments/editDepartment',
);
export const editDepartmentFailed = createAction<{ error: ErrorShape }>(
  'departments/editDepartmentFailed',
);
export const editDepartmentSucceed = createAction<{ department: Department }>(
  'departments/editDepartmentSucceed',
);

export const loadDepartment = createAction<{ id: string }>('departments/loadDepartment');
export const loadDepartmentFailed = createAction<{ error: ErrorShape }>(
  'departments/loadDepartmentFailed',
);
export const loadDepartmentSucceed = createAction<{ department: Department }>(
  'departments/loadDepartmentSucceed',
);

export const deleteDepartment = createAction<{ id: string }>('departments/deleteDepartment');
export const deleteDepartmentFailed = createAction<{ error: ErrorShape }>(
  'departments/deleteDepartmentFailed',
);
export const deleteDepartmentSucceed = createAction<{ id: string }>(
  'departments/deleteDepartmentSucceed',
);

export const resetQueryState = createAction('departments/resetQueryState');

export const setIsLoading = createAction<{ isLoading: boolean }>('departments/setIsLoading');

const initialState: DepartmentsState = {
  query: '',
  selection: {},
  order: [],
  entities: {},
  total: 0,
  offset: 0,
  error: null,
  isLoading: false,
};

export const departmentsOrderSelector = (state: RootState) => state.departments.order;
export const departmentsEntitiesSelector = (state: RootState) => state.departments.entities;

export const isLoadingSelector = (state: RootState) => state.departments.isLoading;

const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDepartments, (state, { payload: { skip, query } }) => ({
      ...state,
      query: query ?? state.query,
      selection: skip ? state.selection : {},
      order: skip ? state.order : [],
      entities: skip ? state.entities : {},
      error: null,
      isLoading: true,
    }));
    builder.addCase(loadDepartmentsSucceed, (state, { payload: { skip, ...payload } }) => ({
      ...state,
      isLoading: false,
      error: null,
      total: payload.total,
      order: skip ? uniq([...state.order, ...payload.order]) : payload.order,
      entities: skip ? { ...state.entities, ...payload.entities } : payload.entities,
    }));
    builder.addCase(loadDepartmentsFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }));
    builder.addCase(selectDepartments, (state, { payload }) => ({
      ...state,
      selection: payload.departmentIds.reduce(
        (selection, departmentId) => {
          selection[departmentId] = true;

          return selection;
        },
        { ...state.selection },
      ),
    }));
    builder.addCase(deselectDepartments, (state, { payload }) => ({
      ...state,
      selection: omit(payload.departmentIds, state.selection),
    }));
    builder.addCase(deleteSelectedDepartmentsSucceed, (state, { payload }) => {
      const deletedIds = new Set(payload.departmentIds);

      return {
        ...state,
        order: state.order.filter(employeeId => !deletedIds.has(employeeId)),
        entities: omit(payload.departmentIds, state.entities),
        selection: {},
      };
    });

    builder.addCase(createDepartment, state => ({ ...state, isLoading: true, error: null }));
    builder.addCase(createDepartmentFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }));
    builder.addCase(createDepartmentSucceed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: null,
      entities: { [payload.department.id]: payload.department },
    }));

    builder.addCase(loadDepartment, state => ({ ...state, isLoading: true, error: null }));
    builder.addCase(loadDepartmentFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }));
    builder.addCase(loadDepartmentSucceed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: null,
      entities: { [payload.department.id]: payload.department },
    }));

    builder.addCase(editDepartment, state => ({ ...state, isLoading: true, error: null }));
    builder.addCase(editDepartmentFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }));
    builder.addCase(editDepartmentSucceed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: null,
      entities: { ...state.entities, [payload.department.id]: payload.department },
    }));

    builder.addCase(resetQueryState, state => ({
      ...state,
      query: initialState.query,
    }));

    builder.addCase(setIsLoading, (state, { payload: { isLoading } }) => ({
      ...state,
      isLoading,
    }));
  },
});

export { departmentsSlice };
