import React from 'react';
import { Body, Heading } from '@wheely/web-ui';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';

import { useLocale } from '_i18n/useLocale';

import styles from './styles.scss';

export type InviteGrantedAccessProps = {
  maskedPhone: string;
  companyName: string;
};

export const GrantedAccess = ({ maskedPhone, companyName }: InviteGrantedAccessProps) => {
  const { langCode } = useLocale();

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.titleBlock}>
          <Heading size="L" className={styles.title}>
            <FormattedMessage
              defaultMessage="Access To The Corporate Dashboard"
              id="YkgAWT"
              description="Granted access title"
            />
          </Heading>
          <Body className={styles.lead}>
            <FormattedMessage
              defaultMessage="{companyName} granted you access to manage the Corporate Dashboard and invited you to use your corporate payment method for Wheely business journeys."
              id="GaZLUt"
              description="Granted access lead"
              values={{
                companyName: companyName.replaceAll(' ', ' '),
              }}
            />
          </Body>
        </div>
        <Heading size="M" className={styles.heading}>
          <FormattedMessage
            defaultMessage="Corporate Dashboard"
            id="a1onq6"
            description="Corporate dashboard description heading"
          />
        </Heading>
        <Body className={styles.text}>
          <FormattedMessage
            defaultMessage="Manage your organisation's personnel, control travel policies and billing options. To access Corporate Dashboard, sign in to {portalLink} with your phone number {maskedPhone}."
            id="vjqY+X"
            description="Corporate dashboard description"
            values={{
              portalLink: <a href="https://business.wheely.com">business.wheely.com</a>,
              maskedPhone: maskedPhone.replaceAll(' ', ' '),
            }}
          />
        </Body>
        <div
          className={cx(styles.image, styles[langCode])}
          data-test-id="invite_granted_access_image"
        />
      </div>
    </div>
  );
};
