import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FocusStyleManager } from '@wheely/ui-kit';

import { useTypedSelector } from '_store';
import { sendKeepalive as sendKeepaliveAction } from '_store/common/actions';
import { phoneSelector, updatePhoneCode as updatePhoneCodeAction } from '_store/auth';
import { usePhoneCodeUpdateFn } from '_hooks/usePhoneCodeUpdateFn';
import { useSnowplow } from '_hooks/useSnowplow';
import { WithChildren } from '_types/helpers';

FocusStyleManager.onlyShowFocusOnTabs();

export const AppInitializer = ({ children }: WithChildren) => {
  useSnowplow();

  const dispatch = useDispatch();
  const phone = useTypedSelector(phoneSelector);

  usePhoneCodeUpdateFn((code: string) => {
    if (!phone?.number) {
      dispatch(updatePhoneCodeAction({ code }));
    }
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        return;
      }

      dispatch(sendKeepaliveAction());
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [dispatch]);

  return <>{children}</>;
};
