import React from 'react';
import { Icon, IconProps } from '@wheely/ui-kit';

export const IconSupportFilled: Icon = ({
  width,
  height,
  className,
  fill = 'currentColor',
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5001 8.625C16.5001 12.1458 13.1422 15 9.00006 15C7.71034 15 6.49666 14.7233 5.4367 14.2359L1.50006 15.75L2.8094 12.2249C1.98335 11.2005 1.50006 9.96072 1.50006 8.625C1.50006 5.10418 4.85793 2.25 9.00006 2.25C13.1422 2.25 16.5001 5.10418 16.5001 8.625ZM9.02511 4.76564C10.3454 4.76564 11.3001 5.49689 11.3001 6.77658C11.3001 7.55895 10.859 7.99638 10.4379 8.41407C10.0608 8.78804 9.69966 9.14618 9.68527 9.72189L9.67511 10.125H8.37511V9.64064C8.37511 8.81401 8.84224 8.31564 9.25568 7.87456C9.58388 7.52442 9.87823 7.21038 9.87823 6.79689C9.87823 6.3297 9.57355 6.06564 9.02511 6.06564C8.5173 6.06564 8.11105 6.33986 8.11105 7.10158V7.41642H6.67902V6.9797C6.67902 5.49689 7.78605 4.76564 9.02511 4.76564ZM9.8173 11.7938C9.8173 12.2203 9.47199 12.586 9.02511 12.586C8.58839 12.586 8.23292 12.2203 8.23292 11.7938C8.23292 11.3672 8.58839 11.0117 9.02511 11.0117C9.47199 11.0117 9.8173 11.3672 9.8173 11.7938Z"
      fill={fill}
    />
  </svg>
);
