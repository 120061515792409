import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SelectField, SelectFieldProps } from '@wheely/ui-kit';

import {
  CORPORATE_ITEMS_MESSAGES,
  CORPORATE_PAYER_TYPES,
  HOSPITALITY_ITEMS_MESSAGES,
  HOSPITALITY_PAYER_TYPES,
} from '_common/PayerSelect/constants';
import { Payer } from '_api/types';

type PayerFieldItem = {
  value: Payer;
  title: string;
};

export type PayerSelectProps = Omit<SelectFieldProps<PayerFieldItem>, 'items'> & {
  isHospitalityType?: boolean;
};

const PayerSelect: React.VFC<PayerSelectProps> = ({
  isHospitalityType = false,
  ...selectFieldProps
}) => {
  const intl = useIntl();

  const items = useMemo(() => {
    if (isHospitalityType) {
      return HOSPITALITY_PAYER_TYPES.map(payerType => ({
        value: payerType,
        title: intl.formatMessage(HOSPITALITY_ITEMS_MESSAGES[payerType]),
      }));
    }

    return CORPORATE_PAYER_TYPES.map(payerType => ({
      value: payerType,
      title: intl.formatMessage(CORPORATE_ITEMS_MESSAGES[payerType]),
    }));
  }, [intl, isHospitalityType]);

  return <SelectField<PayerFieldItem> items={items} {...selectFieldProps} />;
};

export { PayerSelect };
