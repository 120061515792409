import React from 'react';
import cx from 'clsx';

import { IconBag } from '_common/icons/IconBag';
import { ArrowIcon } from '_common/icons/ArrowIcon';

import styles from './styles.scss';

type InfoButtonProps = {
  handleToggle(): void;
  companyName?: string;
  isOpened: boolean;
  profileName: string | JSX.Element;
};

export const InfoButton = ({
  handleToggle,
  companyName,
  profileName,
  isOpened,
}: InfoButtonProps) => (
  <button className={styles.content} onClick={handleToggle}>
    <div className={styles.userGroup}>
      <IconBag width={18} className={styles.companyIcon} />
      <div className={styles.userInfo}>
        <div className={styles.companyName}>{companyName}</div>
        <div className={styles.profileName}>{profileName}</div>
      </div>
    </div>
    <ArrowIcon
      className={cx(styles.icon, {
        [styles.iconOpened]: isOpened,
      })}
      width={18}
    />
  </button>
);
