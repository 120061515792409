import React, { MutableRefObject, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Divider,
  FormGroupProps,
  highlightText,
  ItemListRenderer,
  ItemRenderer,
  SelectMenu,
  SelectMenuItem,
  SuggestField,
  SuggestFieldProps,
  Text,
  UserPic,
  defaultInputValueRenderer,
} from '@wheely/ui-kit';
import cn from 'clsx';
import { FormattedMessage } from 'react-intl';

import { useTypedSelector } from '_store';
import { employeesSelectItemsSelector } from '_store/common/selectors';
import {
  areEmployeesLoadingSelector,
  canLoadMoreSelector,
  employeesOrderSelector,
  loadEmployees,
} from '_store/employees';
import { DefaultSelectLoadingItem } from '_common/DefaultSelectLoadingItem';
import { DefaultSelectNoResults } from '_common/DefaultSelectNoResults';
import { AddFieldButton } from '_common/AddFieldButton';
import { BatchedWaypoint } from '_common/BatchedWaypoint';

import s from './styles.scss';

type EmployeeSuggestItem = {
  value: string;
  image?: null | string;
  title: string;
  phone?: string;
  isCurrentUser?: boolean;
};

type EmployeeSuggestField = SuggestFieldProps<EmployeeSuggestItem>;
type EmployeeSuggestProps = FormGroupProps & {
  onChange: EmployeeSuggestField['onItemSelect'];
  value?: string | null;
  placeholder?: string;
  disabled?: boolean;
  onBookForAnother?: (query: string) => void;
  onClear?: () => void;
};

const employeeItemRenderer: ItemRenderer<EmployeeSuggestItem> = (
  item,
  { handleClick, modifiers, index, query },
  selectedItem,
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  const selected = selectedItem?.value === item.value;

  return (
    <SelectMenuItem
      data-test-id="employee-select-menu-item"
      className={cn({ selected }, s.employeeItem)}
      key={item.value ?? `item-${index}`}
      text={
        <div className={s.employeeCell}>
          <UserPic className={s.employeeItemUserPic} size={'small'} img={item.image} />
          <div className={s.employeeInfo}>
            <span className={s.title}>
              {highlightText(item.title, query)}{' '}
              {item.isCurrentUser && (
                <Text muted={true} withoutPadding={true} tagName={'span'}>
                  (
                  <FormattedMessage
                    description="Is current user label"
                    defaultMessage="You"
                    id="bTZEAj"
                  />
                  )
                </Text>
              )}
            </span>
            <span className={s.phone}>{item.phone}</span>
          </div>
        </div>
      }
      onClick={handleClick}
    />
  );
};

const itemListRenderer =
  (
    inputRef: MutableRefObject<HTMLInputElement | null>,
    onBookForAnother?: (query: string) => void,
  ): ItemListRenderer<EmployeeSuggestItem> =>
  (listProps, initialContent, noResults, infiniteScrollProps, loadingElement, menuProps) => {
    const { itemsParentRef, renderItem, filteredItems, query } = listProps;
    const { isLoading } = infiniteScrollProps ?? {};
    const isEmpty = !isLoading && !filteredItems.length;
    const isNoResultsShown = !onBookForAnother && isEmpty;
    const isDividerShown = !onBookForAnother || !isEmpty;
    const isInitialContentShown = !query.length && initialContent;
    const width = inputRef.current?.offsetWidth;

    return (
      <div className={s.menu}>
        <SelectMenu
          className={cn(s.list, { [s.empty]: !isDividerShown })}
          style={width ? { width, maxWidth: width, minWidth: width } : undefined}
          {...menuProps}
          itemsParentRef={itemsParentRef}
        >
          {isInitialContentShown ? (
            initialContent
          ) : (
            <>
              {isNoResultsShown && noResults}
              {filteredItems.map(renderItem).filter(Boolean)}
              {infiniteScrollProps && (
                <BatchedWaypoint
                  isLoading={infiniteScrollProps.isLoading}
                  loadMore={infiniteScrollProps.loadMore}
                  canLoadMore={infiniteScrollProps.canLoadMore}
                />
              )}
              {(isLoading || infiniteScrollProps?.canLoadMore) && loadingElement}
            </>
          )}
        </SelectMenu>
        {isDividerShown && <Divider className={s.divider} />}
        {onBookForAnother && (
          <div className={s.bookForOtherCell}>
            <div className={s.bookForOther} onClick={() => onBookForAnother(query)}>
              <AddFieldButton
                data-test-id="add-book-for-another-button"
                className={s.bookForOtherButton}
                text={
                  <FormattedMessage
                    description="Book for another person button"
                    defaultMessage="Book For Another Person"
                    id="zBVZKV"
                  />
                }
              />
            </div>
          </div>
        )}
      </div>
    );
  };

const EmployeeSuggest = ({
  value,
  onChange,
  placeholder,
  disabled,
  onBookForAnother,
  ...restProps
}: EmployeeSuggestProps) => {
  const dispatch = useDispatch();
  const [isOpened, setIsOpened] = useState(false);
  const { name } = useTypedSelector(state => state.employees);
  const isLoading = useTypedSelector(areEmployeesLoadingSelector);
  const order = useTypedSelector(employeesOrderSelector);
  const canLoadMore = useTypedSelector(canLoadMoreSelector);
  const items = useTypedSelector(employeesSelectItemsSelector);
  const handleQueryChange = useCallback<
    (query: string, event?: React.ChangeEvent<HTMLInputElement>) => void
  >(
    _name => {
      if (_name !== name) {
        dispatch(loadEmployees({ name: _name }));
      }
    },
    [dispatch, name],
  );

  const handleLoadMore = useCallback(() => {
    dispatch(loadEmployees({ name, skip: order.length }));
  }, [dispatch, name, order]);

  const userpic = useMemo(
    () => items.find(item => item.value === value)?.image ?? null,
    [items, value],
  );
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <SuggestField<EmployeeSuggestItem>
      noResults={<DefaultSelectNoResults className={s.loadingItem} />}
      {...restProps}
      placeholder={placeholder}
      itemRenderer={employeeItemRenderer}
      itemListRenderer={itemListRenderer(
        inputRef,
        onBookForAnother &&
          (query => {
            handleQueryChange('');
            onBookForAnother(query);
          }),
      )}
      resetOnClose={true}
      onItemSelect={onChange}
      inputProps={{
        inputRef,
        leftElement:
          value && !isOpened ? (
            <UserPic className={s.employeeInputUserPic} size={'small'} img={userpic} />
          ) : undefined,
        'data-test-id': 'employee-suggest-input',
      }}
      infiniteScrollProps={{
        loadMore: handleLoadMore,
        canLoadMore,
        isLoading,
      }}
      popoverProps={{
        onOpening: () => {
          setIsOpened(true);
        },
        onClosing: () => {
          setIsOpened(false);
        },
      }}
      focusInputOnClear={true}
      loadingElement={<DefaultSelectLoadingItem className={s.loadingItem} />}
      value={value}
      items={items}
      query={name}
      scrollToActiveItem={false}
      onQueryChange={handleQueryChange}
      disabled={disabled}
      errorProps={{
        'data-test-id': 'employee-suggest-error',
      }}
      inputValueRenderer={item => (isOpened && placeholder) || defaultInputValueRenderer(item)}
    />
  );
};

export { EmployeeSuggest, employeeItemRenderer };
export type { EmployeeSuggestItem, EmployeeSuggestField, EmployeeSuggestProps };
