import { defineMessages } from 'react-intl';

const LOCATION_LOCATIONS_KEY = 'locations';
const ITEM_TYPE_BACK = 'back' as const;

const META_ITEM_MESSAGES = defineMessages({
  airport: {
    description: 'Airports menu item title',
    defaultMessage: 'Airports',
    id: 'C8oKR1',
  },
  railway_hub: {
    description: 'Railway hubs menu item description',
    defaultMessage: 'Railway Hubs',
    id: 'gRUKOj',
  },
  [ITEM_TYPE_BACK]: {
    description: 'Back menu item',
    defaultMessage: 'Back',
    id: 'EsLypW',
  },
});

export { LOCATION_LOCATIONS_KEY, ITEM_TYPE_BACK, META_ITEM_MESSAGES };
