import React, { useCallback, useState, ReactNode, useLayoutEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { I18nProvider } from '@wheely/web-ui';

import { apiClient } from '_api';
import { getLocaleData, getUserLanguageCode, updateUserLanguageCode } from '_i18n';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE_CODE } from '_i18n/constants';
import { LangCode } from '_types/common';

const IntlContext = React.createContext<{
  switchLocale: (locale: LangCode) => void;
  availableLanguages: LangCode[];
  currentLanguage: LangCode;
}>({
  switchLocale: () => {
    // eslint-disable-next-line no-console
    console.warn(
      "Something went wrong with locale context. Looks like you're trying to switch locale before app has completely loaded",
    );
  },
  availableLanguages: AVAILABLE_LANGUAGES,
  currentLanguage: DEFAULT_LANGUAGE_CODE,
});

type Props = { children: ReactNode };

const IntlProviderWrapper: React.FC<Props> = ({ children }: Props) => {
  const [intlState, setIntlState] = useState<{
    locale: null | LangCode;
    messages: any;
  }>({
    locale: null,
    messages: {},
  });

  const switchLocale = useCallback(
    async (locale: LangCode) => {
      if (intlState.locale === locale) {
        return;
      }

      if (!AVAILABLE_LANGUAGES.includes(locale)) {
        // eslint-disable-next-line no-console
        console.warn(
          `Language ${locale} is not supported. Only ${AVAILABLE_LANGUAGES.join(
            ', ',
          )} are available`,
        );

        return;
      }

      const messages = await getLocaleData(locale);

      updateUserLanguageCode(locale);
      setIntlState({ locale, messages });
      apiClient.langCode = locale;
    },
    [intlState.locale],
  );

  useLayoutEffect(() => {
    switchLocale(getUserLanguageCode());
  });

  return (
    <IntlContext.Provider
      value={{
        switchLocale,
        availableLanguages: AVAILABLE_LANGUAGES,
        currentLanguage: intlState.locale || DEFAULT_LANGUAGE_CODE,
      }}
    >
      {intlState.locale ? (
        <IntlProvider
          key={intlState.locale}
          locale={intlState.locale}
          messages={intlState.messages}
          defaultLocale={DEFAULT_LANGUAGE_CODE}
        >
          <I18nProvider locale={intlState.locale}>{children}</I18nProvider>
        </IntlProvider>
      ) : null}
    </IntlContext.Provider>
  );
};

export { IntlProviderWrapper as IntlProvider, IntlContext };
