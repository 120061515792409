import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconAdd } from '@wheely/ui-kit';
import cx from 'clsx';

import { useCompanyType } from '_hooks';
import { NavMenu, NavMenuItem, NavMenuItemProps } from '_common/NavMenu';
import { useBreakpoints } from '_hooks/useBreakpoints';
import { OnMenuItemClick } from '_layouts/Basic/types';
import { featureFlags } from '_utils/featureFlags';
import { usePermissions } from '_app/PermissionsProvider';
import { useCompany } from '_hooks/company';
import { settingsBase } from '_routes/settings';
import { employeesRoutes } from '_routes';

import s from './styles.scss';
import { useDimmer } from './DimmerContext';

type Props = {
  onMenuItemClick?: OnMenuItemClick;
  disabled?: boolean;
};

const renderNavMenuItem = (disabled?: boolean) => (props: NavMenuItemProps, index: number) => (
  <NavMenuItem key={props.to || `nav-menu-item-${index}`} {...props} disabled={disabled} />
);

const Menu = ({ onMenuItemClick, disabled }: Props) => {
  const { isHospitalityType } = useCompanyType();
  const { isTablet } = useBreakpoints();
  const company = useCompany();
  const { hasPermission } = usePermissions();
  const { isShowDimmer } = useDimmer();

  const menuItems = useMemo<Array<NavMenuItemProps>>(() => {
    const items = [];

    if (hasPermission('newJourney:page:read')) {
      items.push({
        to: '/new-journey',
        labelElement: <IconAdd className={s.icon} />,
        text: (
          <FormattedMessage description="New Journey" defaultMessage="New Journey" id="GQf+bO" />
        ),
        onClick: onMenuItemClick,
      });
    }

    if (hasPermission('journeys:page:read')) {
      items.push({
        to: '/journeys',
        text: <FormattedMessage description="Journeys" defaultMessage="Journeys" id="lUYvAG" />,
        onClick: onMenuItemClick,
      });
    }

    // Temporary hide for Employees (Concierges) and Billing sections
    // from menu for the tablet and mobile views
    if (hasPermission('people:page:read') && !isTablet) {
      items.push({
        to: employeesRoutes.employeesBase,
        text: isHospitalityType ? (
          <FormattedMessage description="Concierges" defaultMessage="Concierges" id="Dl7Px+" />
        ) : (
          <FormattedMessage description="People" defaultMessage="People" id="b1Qpxf" />
        ),
        onClick: onMenuItemClick,
      });
    }

    if (hasPermission('billing:page:read') && !isTablet) {
      items.push({
        to: '/billing',
        text: <FormattedMessage description="Billing" defaultMessage="Billing" id="bELDhE" />,
        onClick: onMenuItemClick,
      });
    }

    if (hasPermission('policies:page:read') && featureFlags.isPoliciesEnabled(company)) {
      items.push({
        to: '/policies',
        text: <FormattedMessage description="Policies" defaultMessage="Policies" id="ZdmRY1" />,
        onClick: onMenuItemClick,
      });
    }

    if (hasPermission('reports:page:read')) {
      items.push({
        to: '/reports',
        text: (
          <FormattedMessage
            description="Menu link to Reports"
            defaultMessage="Reports"
            id="El9Ju+"
          />
        ),
        onClick: onMenuItemClick,
      });
    }

    items.push({
      to: settingsBase,
      text: (
        <FormattedMessage
          description="Menu link to Settings"
          defaultMessage="Settings"
          id="jdD1cL"
        />
      ),
      onClick: onMenuItemClick,
    });

    return items;
  }, [onMenuItemClick, hasPermission, isTablet, company, isHospitalityType]);

  return (
    <NavMenu className={cx({ [s.dimmer]: isShowDimmer })} data-test-id={'menu-section'}>
      {menuItems.map(renderNavMenuItem(disabled))}
    </NavMenu>
  );
};

export { Menu };
