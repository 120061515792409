import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient } from '_api';
import { UserToCreate } from '_api/types';
import { normalizeUser } from '_api/normalize';

import { USER } from '../keys';

export const usePostUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [USER.CREATE],
    mutationFn: (userToCreate: UserToCreate) =>
      apiClient
        .createUser({ user: userToCreate })
        .then(({ user, token }) => ({ user: normalizeUser(user), token })),
    onSuccess: ({ user }) => {
      queryClient.setQueryData([USER.LOAD], user);
    },
  });
};
