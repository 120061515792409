import { isDev } from '_constants';
import { Company } from '_api/types';

const POLICIES_AVAILABLE = [
  '5c98c103946f500005503e5f', // +79431055351 Cardprofit, staging
];

const INCREASED_ESTIMATED_PRICE_RATIOS: Record<string, number> = {
  '58ef4485fa14b90016005aaa': 1.1, // prod
  '64d09df30aa7fd0011f43d97': 1.1, // staging
};

const featureFlags = {
  isB2CPaymentEnabled(company?: Company | null) {
    return isDev || Boolean(company);
  },
  isPoliciesEnabled(company?: Company | null) {
    return isDev || Boolean(company) || (company?.id && POLICIES_AVAILABLE.includes(company.id));
  },
  isEstimatedPriceChanged(companyId: string) {
    return Object.keys(INCREASED_ESTIMATED_PRICE_RATIOS).includes(companyId);
  },
  isMetabaseEnabled(companyId?: string | null) {
    return isDev || Boolean(companyId);
  },
};

export { featureFlags, INCREASED_ESTIMATED_PRICE_RATIOS };
