import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Body, Heading } from '@wheely/web-ui';
import { useDispatch } from 'react-redux';
import { ISession, transformRawSession } from '@wheely/web-auth-lib';

import { authAPI } from '_api/auth';
import { extractErrorShape } from '_utils/extractErrorShape';
import { useCaptcha } from '_hooks/useCaptcha';
import { setAllowUserLoad } from '_store/ui';
import { setIsLoggedIn } from '_store/auth';
import { usePostUser } from '_queries/user/usePostUser';
import { useIsRegisterPage } from '_modules/Auth/hooks';
import { useGetCompanyConfigQuery } from '_queries/company/useGetCompanyConfig';

import { FormValues, PhoneForm } from '../PhoneForm/PhoneForm';
import { PinForm } from '../PinForm/PinForm';

import styles from './styles.scss';

const STEPS = {
  LOGIN_INPUT: 'LOGIN_INPUT',
  CODE_INPUT: 'CODE_INPUT',
};

const resetSessionIfNotActivated = async () => {
  const session = await authAPI.getCurrentSession();

  if (session && !session?.activated) {
    authAPI.signOut();
  }
};

export const AuthForm = ({ onLogin }: Partial<Record<'onLogin', () => void>>) => {
  const [pinSentAt, setPinSentAt] = useState<Date>();
  const [callRequestedAt, setCallRequestedAt] = useState<Date>();
  const [enteredPhone, setEnteredPhone] = useState<string>(
    localStorage.getItem('login_phone') || '',
  );

  const { mutateAsync } = usePostUser();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(STEPS.LOGIN_INPUT);
  const [serverErrorMessage, setServerErrorMessage] = useState<string | null>(null);
  const intl = useIntl();
  const [hCaptchaProps, getCaptcha] = useCaptcha();
  const isRegisterPage = useIsRegisterPage();
  const { data, isLoading: isPhoneInputDisabled } = useGetCompanyConfigQuery();

  const phoneCodeOptions = useMemo(
    () =>
      data
        ? data.countries_list.map(country => ({
            value: country.phone_code,
            localizedName: country.localized_name,
          }))
        : [
            {
              value: '+',
              localizedName: {
                en: 'Other country',
                ru: 'Другая страна',
                fr: 'Autre pays',
              },
            },
          ],
    [data],
  );

  const handleSendPin = useCallback(
    async (phone: string, savedCaptchaToken?: string) => {
      try {
        setIsLoading(true);
        setPinSentAt(new Date());

        let captchaToken: string | null | undefined = savedCaptchaToken;

        if (!savedCaptchaToken) {
          captchaToken = await getCaptcha();
        }

        if (!captchaToken) {
          setIsLoading(false);
          setPinSentAt(new Date(0));

          return;
        }

        const signInMeta = {
          ...(isRegisterPage && { b2b_registration: true }),
        };

        const { error } = await authAPI.signInWithPhone(
          phone,
          captchaToken,
          intl.locale,
          signInMeta,
        );

        if (error) {
          throw error;
        }

        setEnteredPhone(phone);
        localStorage.setItem('login_phone', phone);
        setStep(STEPS.CODE_INPUT);
      } catch (error) {
        setPinSentAt(new Date(0));
        setServerErrorMessage(extractErrorShape(error)?.message);
      } finally {
        setIsLoading(false);
      }
    },
    [getCaptcha, intl.locale, isRegisterPage],
  );

  const handleCreateUser = useCallback(
    async (phone: string) => {
      const { token } = await mutateAsync({ phone });

      const session = transformRawSession(token) as ISession;

      await authAPI.setCurrentSession(session);

      setEnteredPhone(phone);
      localStorage.setItem('login_phone', phone);
      setStep(STEPS.CODE_INPUT);
    },
    [mutateAsync],
  );

  const handlePhoneSubmit = useCallback(
    async ({ phone }: FormValues) => {
      let captchaToken;

      setServerErrorMessage(null);

      try {
        setIsLoading(true);
        setPinSentAt(new Date());

        captchaToken = await getCaptcha();

        if (!captchaToken) {
          setIsLoading(false);
          setPinSentAt(new Date(0));

          return;
        }

        await handleCreateUser(phone);
      } catch (error) {
        const serializedError = extractErrorShape(error);

        if (serializedError.data?.errors?.user_exists) {
          await handleSendPin(phone, captchaToken as string);

          return;
        }

        setPinSentAt(new Date(0));
        setServerErrorMessage(serializedError?.message);
      } finally {
        setIsLoading(false);
      }
    },
    [getCaptcha, handleCreateUser, handleSendPin],
  );

  const handleActivatePin = useCallback(
    async ({ pin }: Record<'pin', string>) => {
      try {
        const { error } = await authAPI.activateToken(pin, intl.locale);

        if (error) {
          throw error;
        }

        dispatch(setIsLoggedIn({ isLoggedIn: true }));
        dispatch(setAllowUserLoad(true));
        onLogin?.();
      } catch (error) {
        setServerErrorMessage(extractErrorShape(error)?.message);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, intl.locale, onLogin],
  );

  const handleCallMe = useCallback(async () => {
    try {
      setIsLoading(true);
      setCallRequestedAt(new Date());
      setServerErrorMessage(null);

      const phoneCaptcha = await getCaptcha();

      if (!phoneCaptcha) {
        setIsLoading(false);
        setCallRequestedAt(new Date(0));

        return;
      }

      const { error } = await authAPI.requestPinCall(enteredPhone, phoneCaptcha, intl.locale);

      if (error) {
        throw error;
      }
    } catch (error) {
      setCallRequestedAt(new Date(0));
      setServerErrorMessage(extractErrorShape(error)?.message);
    } finally {
      setIsLoading(false);
    }
  }, [enteredPhone, getCaptcha, intl.locale]);

  const isLoginInputStep = step === STEPS.LOGIN_INPUT;

  const handleChangeLogin = useCallback(async () => {
    authAPI.signOut();
    setCallRequestedAt(new Date(0));
    setStep(STEPS.LOGIN_INPUT);
  }, []);

  const handlePinResend = useCallback(() => {
    handleSendPin(enteredPhone);
  }, [enteredPhone, handleSendPin]);

  useEffect(() => {
    resetSessionIfNotActivated();
  }, []);

  return (
    <div className={styles.root}>
      <Heading size="M" className={styles.heading}>
        <FormattedMessage
          defaultMessage="Corporate Account"
          id="sign_in_title"
          description="Login form title"
        />
      </Heading>
      <Body className={styles.description}>
        <FormattedMessage
          defaultMessage="Sign in or create account to access Wheely products and{space}services scaled-up for your business needs."
          id="sign_in_lead_text"
          description="Login form description"
          values={{
            space: ' ',
          }}
        />
      </Body>
      {isLoginInputStep ? (
        <PhoneForm
          isPhoneInputDisabled={isPhoneInputDisabled}
          phoneCodeOptions={phoneCodeOptions}
          hCaptchaProps={hCaptchaProps}
          enteredPhone={enteredPhone}
          onSubmit={handlePhoneSubmit}
          isLoading={isLoading}
          serverErrorMessage={serverErrorMessage as string}
        />
      ) : (
        <PinForm
          onSubmit={handleActivatePin}
          pinSentAt={pinSentAt}
          phoneCodeOptions={phoneCodeOptions}
          callRequestedAt={callRequestedAt}
          onPinResend={handlePinResend}
          onCallMe={handleCallMe}
          onChangeLogin={handleChangeLogin}
          enteredPhone={enteredPhone}
          hCaptchaProps={hCaptchaProps}
          isLoading={isLoading}
          serverErrorMessage={serverErrorMessage as string}
        />
      )}
    </div>
  );
};
