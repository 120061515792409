import { createSlice, createAction } from '@reduxjs/toolkit';

import { ErrorShape } from '_api/types';
import { RootState } from '_store';

export type AreEmployeesHidden = boolean;
type GlobalError = ErrorShape | null;

export type UiState = {
  allowUserLoad: boolean;
  areEmployeesHidden: AreEmployeesHidden;
  isAppReadyToBeShown: boolean;
  globalError: GlobalError;
  isSkipHistory: boolean;
  isPageLoading: boolean;
};

const initialState: UiState = {
  allowUserLoad: false,
  areEmployeesHidden: false,
  isAppReadyToBeShown: false,
  globalError: null,
  isSkipHistory: false,
  isPageLoading: false,
};

export const setAreEmployeesHidden = createAction<{
  areEmployeesHidden: AreEmployeesHidden;
}>('ui/areEmployeesHidden');

export const setIsAppReadyToBeShown = createAction<{
  isAppReadyToBeShown: boolean;
}>('ui/setIsAppReadyToBeShown');

export const isAppReadyToBeShownSelector = (state: RootState) => state.ui.isAppReadyToBeShown;

export const setIsPageLoading = createAction<boolean>('ui/setIsPageLoading');
export const setAllowUserLoad = createAction<boolean>('ui/setAllowUserLoad');

export const setGlobalError = createAction<{ error: GlobalError }>('ui/setGlobalError');
export const resetGlobalError = createAction('ui/resetGlobalError');

export const setSkipHistory = createAction<boolean>('ui/setSkipHistory');

export const areEmployeesHiddenSelector = (state: RootState) => state.ui.areEmployeesHidden;
export const globalErrorSelector = (state: RootState) => state.ui.globalError;
export const isPageLoadingSelector = (state: RootState) => state.ui.isPageLoading;
export const allowUserLoadSelector = (state: RootState) => state.ui.allowUserLoad;
export const isSkipHistorySelector = (state: RootState) => state.ui.isSkipHistory;

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setAreEmployeesHidden, (state: UiState, { payload }) => ({
      ...state,
      areEmployeesHidden: payload.areEmployeesHidden,
    }));
    builder.addCase(setIsPageLoading, (state: UiState, { payload }) => ({
      ...state,
      isPageLoading: payload,
    }));
    builder.addCase(setAllowUserLoad, (state: UiState, { payload }) => ({
      ...state,
      allowUserLoad: payload,
    }));
    builder.addCase(setIsAppReadyToBeShown, (state: UiState, { payload }) => ({
      ...state,
      isAppReadyToBeShown: payload.isAppReadyToBeShown,
    }));
    builder.addCase(setGlobalError, (state: UiState, { payload }) => ({
      ...state,
      globalError: payload.error,
    }));

    builder.addCase(resetGlobalError, (state: UiState) => ({
      ...state,
      globalError: null,
    }));

    builder.addCase(setSkipHistory, (state: UiState, { payload }) => ({
      ...state,
      isSkipHistory: payload,
    }));
  },
});

export { uiSlice };
