import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import compose from 'lodash/fp/compose';
import isNil from 'lodash/fp/isNil';
import pick from 'lodash/fp/pick';
import pickBy from 'lodash/fp/pickBy';
import uniq from 'lodash/fp/uniq';

import { ErrorShape, GetOrderPayload, Order, OrderStatus } from '_api/types';
import { RootState } from '_store';
import { setOrdersCommonFilters } from '_store/common/actions';
import { DateRange, OrdersEntities, OrdersOrder } from '_store/types';
import { companyTypeSelector } from '_queries/company/selectors';

import { createConvertOrderListToTableData } from './common/selectors';

export const FILTERS = {
  DATE_RANGE: 'dateRange',
  DEPARTMENT: 'departmentId',
  USER: 'userId',
  STATUS: 'statuses',
  QUERY: 'query',
} as const;

type OrderFilter = (typeof FILTERS)[keyof typeof FILTERS];

export type FilterType = Extract<OrderFilter, 'userId' | 'departmentId' | 'statuses'>;

export const filterTypes: FilterType[] = ['departmentId', 'userId', 'statuses'];

const orderFilters: OrderFilter[] = ['dateRange', 'query', ...filterTypes];

const pickFilters = compose(
  pickBy(value => !isNil(value)),
  pick(orderFilters),
);

export type OrdersState = {
  dateRange: null | DateRange;
  userId: null | string;
  departmentId: null | string;
  number: null | number;
  statuses: OrderStatus[];
  passenger: null | string;
  error: null | ErrorShape;
  isLoading: boolean;
  order: OrdersOrder;
  entities: OrdersEntities;
  canLoadMore: boolean;
  isPollingAllowed: boolean;
  filterType: null | FilterType;
};

export type Filters = Partial<{
  dateRange: null | DateRange;
  departmentId: string;
  userId: string;
  statuses: OrderStatus[];
  query: string;
}>;

export const loadOrders = createAction<
  Filters & {
    limit?: number;
    skip?: number;
    favorite?: boolean;
  }
>('orders/loadOrders');
export const loadOrdersFailed = createAction<{ error: ErrorShape }>('orders/loadOrdersFailed');
export const loadOrdersSucceed = createAction<{
  skip: number;
  limit: number;
  order: OrdersOrder;
  entities: OrdersEntities;
  canLoadMore: boolean;
}>('orders/loadOrdersSucceed');

export const loadOrder = createAction<GetOrderPayload>('orders/loadOrder');
export const loadOrderFailed = createAction<{ error: ErrorShape }>('orders/loadOrderFailed');
export const loadOrderSucceed = createAction<{
  order: Order;
}>('orders/loadOrderSucceed');

export const setOrder = createAction<{ order: Order }>('orders/setOrder');

export const pausePolling = createAction('orders/pausePolling');
export const resumePolling = createAction('orders/resumePolling');

const ordersOrderSelector = (state: RootState) => state.orders.order;
const ordersEntitiesSelector = (state: RootState) => state.orders.entities;
const ordersSelector = createSelector(
  ordersOrderSelector,
  ordersEntitiesSelector,
  (order, entities) => order.map(id => entities[id]),
);

export const ordersLoadingErrorSelector = (state: RootState) => state.orders.error;
export const isOrdersLoadingSelector = (state: RootState) => state.orders.isLoading;
export const ordersErrorSelector = (state: RootState) => state.orders.error;
export const canLoadMoreOrdersSelector = (state: RootState) => state.orders.canLoadMore;
export const activeFiltersSelector = (state: RootState): Filters => pickFilters(state.orders);
export const dateRangeSelector = (state: RootState) => state.orders.dateRange;
export const ordersCountSelector = (state: RootState) => state.orders.order.length;
export const filterTypeSelector = (state: RootState) => state.orders.filterType;

export const orderByIdSelector = createSelector(
  ordersEntitiesSelector,
  (state: RootState, { id }: { id: string }) => id,
  (ordersEntities, id): Order | null => ordersEntities[id] || null,
);

export const getPickupTimeFromOrderSelector = createSelector(
  (state: RootState, { order }: { order: Order | null }) => order?.events || [],
  events => events?.find(event => event.name === 'serving')?.ts ?? null,
);

export const ordersTableDataSelector = createSelector(
  ordersSelector,
  companyTypeSelector,
  createConvertOrderListToTableData(),
);

export const isPollingAllowedSelector = (state: RootState) => state.orders.isPollingAllowed;

const initialState: OrdersState = {
  dateRange: null,
  userId: null,
  departmentId: null,
  number: null,
  statuses: [],
  passenger: null,
  error: null,
  isLoading: false,
  order: [],
  entities: {},
  canLoadMore: true,
  isPollingAllowed: true,
  filterType: null,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setFilterType(state, { payload: { type } }: PayloadAction<{ type: FilterType }>) {
      state.filterType = type;
    },
    excludeOrderById(state, { payload: { orderId } }: PayloadAction<{ orderId: string }>) {
      if (!state.entities[orderId]) {
        return state;
      }

      const { [orderId]: _, ...entities } = state.entities;

      return {
        ...state,
        entities,
        order: state.order.filter(orderItem => orderItem !== orderId),
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      loadOrders,
      (state, { payload: { skip, departmentId, dateRange, userId, statuses = [], query } }) => ({
        ...state,
        departmentId: departmentId || null,
        dateRange: dateRange || null,
        userId: userId || null,
        order: skip ? state.order : [],
        entities: skip ? state.entities : {},
        statuses,
        query,
        isLoading: true,
        error: null,
      }),
    );
    builder.addCase(loadOrdersSucceed, (state, { payload: { order, entities, canLoadMore } }) => ({
      ...state,
      order: uniq([...state.order, ...order]),
      entities: { ...state.entities, ...entities },
      isLoading: false,
      error: null,
      canLoadMore,
    }));
    builder.addCase(loadOrdersFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }));

    builder.addCase(loadOrder, state => ({ ...state, isLoading: true, error: null }));
    builder.addCase(loadOrderFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }));
    builder.addCase(loadOrderSucceed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: null,
      entities: { [payload.order.id]: payload.order },
    }));

    builder.addCase(setOrder, (state, { payload }) => ({
      ...state,
      entities: { ...state.entities, [payload.order.id]: payload.order },
    }));

    builder.addCase(setOrdersCommonFilters, (state, { payload }) => ({
      ...state,
      dateRange: payload.dateRange || initialState.dateRange,
    }));

    builder.addCase(pausePolling, state => ({
      ...state,
      isPollingAllowed: false,
    }));

    builder.addCase(resumePolling, state => ({
      ...state,
      isPollingAllowed: true,
    }));
  },
});
