import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient } from '_api';

import { INVITE_ACCEPT } from '../keys';

export const useEnrichInviteMutation = (inviteId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [...INVITE_ACCEPT, inviteId, 'update'],
    mutationFn: (phone: string) =>
      apiClient
        .enrichInvite({ invite_id: inviteId, phone })
        .then(({ company_name, masked_phone }) => ({
          companyName: company_name,
          maskedPhone: masked_phone,
        })),
    onSuccess(data) {
      queryClient.setQueriesData({ queryKey: [...INVITE_ACCEPT, inviteId] }, () => data);
    },
  });
};
