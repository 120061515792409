import React from 'react';
import { NumberFormatOptions } from '@formatjs/ecma402-abstract';
import { CustomFormatConfig, FormattedNumber } from 'react-intl';

import { CurrencyCode } from '_api/types';

type Props = { currencyCode?: null | CurrencyCode; value: number } & NumberFormatOptions &
  CustomFormatConfig;

export const FormattedCurrency = ({ currencyCode, value, ...restProps }: Props) => (
  <FormattedNumber
    value={value}
    style={currencyCode ? 'currency' : 'decimal'}
    currency={currencyCode ?? undefined}
    currencyDisplay="narrowSymbol"
    maximumFractionDigits={0}
    minimumFractionDigits={0}
    {...restProps}
  />
);
