import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { LinkButton, FootnoteArrowChevronRightIcon } from '@wheely/web-ui';
import { FormattedMessage } from 'react-intl';

import { useLocale } from '_i18n/useLocale';
import { LangCode } from '_types/common';

import { LANGUAGE_SELECT_ITEMS } from './constants';
import styles from './styles.scss';

export const LanguageSelectNative: React.FC = () => {
  const { switchLocale, langCode: currentLanguage } = useLocale();

  const handleItemSelect = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      switchLocale(event.target.value as LangCode);
    },
    [switchLocale],
  );

  const selectedItem = useMemo(
    () => LANGUAGE_SELECT_ITEMS.find(({ value }) => value === currentLanguage),
    [currentLanguage],
  );

  return (
    <>
      <LinkButton
        size="small"
        className={styles.button}
        icon={<FootnoteArrowChevronRightIcon />}
        data-test-id="public_layout_language_button"
      >
        <FormattedMessage defaultMessage="Language" id="/fw99n" description="Language select" /> (
        {selectedItem?.title})
        <select value={selectedItem?.value} className={styles.select} onChange={handleItemSelect}>
          {LANGUAGE_SELECT_ITEMS.map(({ title, value }) => (
            <option key={value} value={value}>
              {title}
            </option>
          ))}
        </select>
      </LinkButton>
    </>
  );
};
