import { LangCode } from '_types/common';

type LanguageSelectItem = {
  title: string;
  value: LangCode;
};

export const LANGUAGE_SELECT_ITEMS: LanguageSelectItem[] = [
  {
    title: 'English',
    value: 'en',
  },
  {
    title: 'Русский',
    value: 'ru',
  },
  {
    title: 'Français',
    value: 'fr',
  },
];
