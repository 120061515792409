import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  KeenSliderInstance,
  KeenSliderOptions,
  TrackDetails,
  useKeenSlider,
} from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import cn from 'clsx';

import { isFunction } from '_utils/isFunction';

import s from './styles.scss';

export type Slide<V = any> = {
  absoluteIndex: number;
  relativeIndex: number;
  value: V;
  className: string;
  contentClassName: string;
  isActive: boolean;
  isDisabled: boolean;
};
export type IsSlideDisabledPredicate<V = any> = (
  options: {
    trackDetails: TrackDetails;
  } & Pick<Slide<V>, 'absoluteIndex' | 'relativeIndex' | 'value'>,
) => boolean;
export type SlideGetter<V = any> = (
  relativeIndex: number,
  setValue: SlideValueSetter,
  trackDetails: TrackDetails,
  isSlideDisabled: IsSlideDisabledPredicate | boolean,
) => Slide<V>;

// It is important to use `slide.relativeIndex` as a `key` of slide;
// otherwise, keen-slider can't modify DOM properly
export type SlideRenderer<V = any> = (slide: Slide<V>) => JSX.Element;

export type SlideValueSetter<V = any> = (
  absoluteIndex: number,
  relativeIndex: number,
  trackDetails: TrackDetails | null,
) => V;
export type OnSlideChange<V = any> = (slide: Slide<V>, instance: KeenSliderInstance | null) => void;
export type Props = {
  length: number;
  onSlideChange: OnSlideChange;
  setValue?: SlideValueSetter;
  getSlide?: SlideGetter;
  renderSlide?: SlideRenderer;
  isSlideDisabled?: IsSlideDisabledPredicate | boolean;
  activeIndex?: number;
  className?: string;
  wrapperClassName?: string;
} & Partial<Omit<KeenSliderOptions, 'initial'>>;

const getSliderIndex = (instance: KeenSliderInstance, loop = false) =>
  loop ? instance.track.details.rel : instance.track.details.abs;

const getSlideIndex = (slide: Slide, loop = false) =>
  loop ? slide.relativeIndex : slide.absoluteIndex;

const slideValueSetter: SlideValueSetter = (absoluteIndex, relativeIndex) =>
  relativeIndex.toString();

const slideGetter: SlideGetter = (relativeIndex, setValue, trackDetails, isSlideDisabled) => {
  const absoluteIndex = trackDetails.slides[relativeIndex].abs;
  const value = setValue(absoluteIndex, relativeIndex, trackDetails);

  const isActive = trackDetails.rel === relativeIndex;
  const isDisabled = isFunction(isSlideDisabled)
    ? isSlideDisabled({
        value,
        absoluteIndex,
        relativeIndex,
        trackDetails,
      })
    : false;

  const className = cn(s.slide, {
    [s.isActive]: isActive,
    [s.isDisabled]: isDisabled,
  });

  return {
    absoluteIndex,
    relativeIndex,
    value,
    isActive,
    isDisabled,
    className,
    contentClassName: s.slideContent,
  };
};

const slideRenderer: SlideRenderer = slide => {
  const { relativeIndex, value, className, contentClassName } = slide;

  // Note the `relativeIndex` in the `key`
  return (
    <div className={className} key={`wheel-cell-${relativeIndex}`}>
      <span className={contentClassName}>{String(value)}</span>
    </div>
  );
};

const createSlides = ({
  trackDetails,
  setValue,
  getSlide,
  slidesCount,
  isSlideDisabled,
}: {
  setValue: SlideValueSetter;
  getSlide: SlideGetter;
  trackDetails: TrackDetails | null;
  slidesCount: number;
  isSlideDisabled: IsSlideDisabledPredicate | boolean;
}) =>
  Array.from({ length: slidesCount })
    .map((_, relativeIndex) => {
      if (!trackDetails) {
        return null;
      }

      return getSlide(relativeIndex, setValue, trackDetails, isSlideDisabled);
    })
    .filter((slide): slide is Slide => Boolean(slide));

export const Wheel = forwardRef<KeenSliderInstance, Props>(
  (
    {
      length: slidesCount,
      setValue = slideValueSetter,
      getSlide = slideGetter,
      renderSlide = slideRenderer,
      isSlideDisabled = false,
      onSlideChange,
      className,
      wrapperClassName,
      activeIndex, // indexes syncing for controlled mode
      ...restSliderOptions
    },
    instanceRef,
  ) => {
    const [trackDetails, setTrackDetails] = useState<TrackDetails | null>(null);
    const prevTrackDetails = useRef<TrackDetails | null>(trackDetails);
    const isMounted = useRef(false);
    const isSyncing = useRef(false);
    const lastActiveIndex = useRef(activeIndex);
    const isControlled = typeof activeIndex === 'number';

    const options = useRef<KeenSliderOptions>({
      slides: {
        origin: 'center',
        number: slidesCount,
        perView: 3,
      },
      vertical: true,
      initial: activeIndex,
      rubberband: true,
      selector: `.${s.slide}`,
      mode: 'free-snap',
      detailsChanged: instance => {
        setTrackDetails(instance.track.details);
      },
      created: instance => {
        setTrackDetails(instance.track.details);
      },
      dragChecked: () => {
        isSyncing.current = false;
      },
      ...restSliderOptions,
    });

    useEffect(() => {
      isMounted.current = true;

      return () => {
        isMounted.current = false;
      };
    }, []);

    const [rootNodeRef, slider] = useKeenSlider<HTMLDivElement>(options.current);
    const isLoopMode = options.current.loop === true;

    useEffect(() => {
      if (isFunction(instanceRef)) {
        instanceRef(slider.current);
      } else if (instanceRef) {
        instanceRef.current = slider.current;
      }
    }, [instanceRef, slider]);

    useEffect(() => {
      if (trackDetails && !prevTrackDetails.current && isMounted.current) {
        prevTrackDetails.current = trackDetails;
        slider.current?.update(options.current);
      }
    }, [slider, trackDetails]);

    useEffect(() => {
      const slideChangeHandler = (instance: KeenSliderInstance) => {
        if (!isMounted.current || !instance || !onSlideChange) {
          return;
        }

        const { details } = instance.track;
        const nextSlide = getSlide(details.rel, setValue, details, isSlideDisabled);

        // Uncontrolled mode: just emit change event
        if (!isControlled) {
          onSlideChange(nextSlide, instance);

          return;
        }

        // Controlled mode: calculations and corrections for disabled slides

        // Ignore syncing if it's in progress already
        if (isSyncing.current) {
          isSyncing.current = false;

          return;
        }

        // Find nearest enabled slide's index

        lastActiveIndex.current = activeIndex;

        // The next slide is not disabled and can be active, only emit change event
        if (!nextSlide.isDisabled) {
          onSlideChange(nextSlide, instance);

          return;
        }

        const slides = createSlides({
          trackDetails: instance.track.details,
          setValue,
          getSlide,
          slidesCount,
          isSlideDisabled,
        });

        const nextSlideIndex = getSlideIndex(nextSlide, isLoopMode);

        let availableSlide: Slide | undefined;

        if (lastActiveIndex.current >= nextSlideIndex) {
          availableSlide = slides.find(slide => !slide.isDisabled);
        } else {
          availableSlide = [...slides].reverse().find(slide => !slide.isDisabled);
        }

        const correctedIndex = availableSlide
          ? getSlideIndex(availableSlide, isLoopMode)
          : nextSlideIndex;

        instance.animator.stop();
        instance.moveToIdx(correctedIndex, !isLoopMode);
      };

      const { current: instance } = slider;

      instance?.on('animationEnded', slideChangeHandler);

      return () => instance?.on('animationEnded', slideChangeHandler, true);
    }, [onSlideChange]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!slider.current || !isControlled) {
        return;
      }

      const instance = slider.current;

      const sliderIndex = getSliderIndex(instance, isLoopMode);

      if (sliderIndex === activeIndex) {
        isSyncing.current = false;

        return;
      }

      isSyncing.current = true;
      instance.animator.stop();
      instance.moveToIdx(activeIndex, !isLoopMode);
      lastActiveIndex.current = activeIndex;
    }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <div ref={rootNodeRef} className={cn(s.root, className)}>
        <div className={cn(s.wrapper, wrapperClassName)}>
          {createSlides({
            trackDetails,
            setValue,
            getSlide,
            slidesCount,
            isSlideDisabled,
          }).map(renderSlide)}
        </div>
      </div>
    );
  },
);
