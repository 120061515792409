import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Intent, ButtonProps, FormGroupProps } from '@wheely/ui-kit';
import cn from 'clsx';

import { WrappedIconExtraStop } from '_common/LocationField/common';
import { CompletionZone, Location } from '_api/types';
import { LocationField, LocationFieldProps } from '_common/LocationField';
import { getLocationName } from '_utils/getLocationName';
import { useLocationAutoComplete } from '_hooks/useLocationAutoComplete';
import { useTypedSelector } from '_store';
import { mapModeSelector } from '_store/mapInteraction';
import { usePrevious } from '_hooks/usePrevious';
import { useHospitalityFavouriteAddress } from '_hooks/company';

import { MESSAGES } from './constants';
import s from './styles.scss';

type DropoffAddressFieldProps = {
  location?: Location | null;
  error?: string;
  onChange: ({ location }: { location: Location | null }) => void;
  setTouched: (touched: boolean) => void;
  touched: boolean;
  disabled: boolean;
  hasDeleteButton?: boolean;
  deleteButtonProps?: ButtonProps;
  formGroupProps?: FormGroupProps & { className?: string };
};

export const DropoffAddressField = ({
  location,
  error,
  onChange,
  setTouched,
  touched,
  disabled,
  hasDeleteButton,
  deleteButtonProps,
  formGroupProps,
}: DropoffAddressFieldProps) => {
  const intl = useIntl();
  const lastUsedLocation = useRef(location);
  const mapMode = useTypedSelector(mapModeSelector);
  const previousMapMode = usePrevious(mapMode);

  const [isFocused, setFocused] = useState<boolean>(false);

  const [query, setQuery] = useState(location ? getLocationName(location) : '');
  const [completions, setCompletions] = useState<Record<string, any>[]>([]);
  const [zones, setZones] = useState<CompletionZone[]>();

  const onSetSelectedItem = useCallback(
    (itemLocation: Location | null) => {
      onChange({ location: itemLocation });
    },
    [onChange],
  );

  const { handleItemSelect, suggestRef, handleQueryChange } = useLocationAutoComplete({
    type: 'dropoff',
    mode: 'dropoff',
    setSelectedItem: onSetSelectedItem,
    setQuery,
    setCompletions,
    setZones,
  });

  // update suggestions when map pin updated
  useEffect(() => {
    if (
      mapMode === 'view' &&
      previousMapMode === 'edit' &&
      lastUsedLocation.current !== location &&
      location
    ) {
      lastUsedLocation.current = location;

      if (location.line1) {
        handleQueryChange(location.line1);
      }
    }
  }, [location, mapMode, previousMapMode, handleQueryChange]);

  const handleClosing = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  useEffect(() => {
    if (lastUsedLocation.current !== location) {
      if (!location) {
        // Trigger validation on render tick when selected item gets removed
        setTouched(true);
      }

      lastUsedLocation.current = location;
    }
  }, [location, lastUsedLocation.current]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFocusField = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlurField = useCallback(() => {
    setFocused(false);
  }, []);

  const inputProps: LocationFieldProps['inputProps'] = {
    leftIcon: WrappedIconExtraStop,
    placeholder: intl.formatMessage({
      description: 'Dropoff address field suggest placeholder',
      defaultMessage: 'Dropoff',
      id: 'x3Puf+',
    }),
    onFocus: handleFocusField,
    onBlur: handleBlurField,
    'data-test-id': 'dropoff-select-address-input',
  };

  const errorText = [!isFocused, !location, query].every(value => Boolean(value))
    ? intl.formatMessage(MESSAGES.unfinishedError)
    : error;

  const fieldError = touched ? errorText : undefined;
  const intent = fieldError ? Intent.DANGER : Intent.NONE;

  const hospitalityFavouriteAddress = useHospitalityFavouriteAddress();

  return (
    <LocationField
      query={query}
      completions={completions}
      zones={zones}
      onQueryChange={handleQueryChange}
      selectedItem={location}
      onItemSelect={handleItemSelect}
      inputProps={inputProps}
      error={fieldError}
      intent={intent}
      disabled={disabled}
      popoverProps={{ onClosing: handleClosing }}
      suggestRef={suggestRef}
      extraInitialLocations={hospitalityFavouriteAddress ? [hospitalityFavouriteAddress] : null}
      hasDeleteButton={hasDeleteButton}
      deleteButtonProps={deleteButtonProps}
      formGroupProps={{
        ...formGroupProps,
        className: cn(s.formGroup, formGroupProps?.className),
      }}
      menuProps={{
        'data-test-id': 'dropoff-location-select-menu',
      }}
      errorProps={{
        'data-test-id': 'dropoff-error',
      }}
    />
  );
};
