import { FieldMetaProps } from 'formik';

import { ValueOf } from '_types/helpers';

import { FormValues } from '../types';

const getFieldErrorMessage = <M extends ValueOf<FormValues>>(
  fieldMeta: FieldMetaProps<M>,
): string | undefined => (fieldMeta.touched ? fieldMeta.error : undefined);

export { getFieldErrorMessage };
