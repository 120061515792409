import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import dialogStyles from '../../styles.scss';

import { FormValues, FormErrors } from './types';
import { FormContent } from './FormContent';
import s from './styles.scss';

const FLIGHT_NUMBER_REGEXP = /^[A-Z]/i;

const MIN_FLIGHT_NUMBER_LENGTH = 3;

const validateFlightNumber = (flightNumber: string): boolean =>
  FLIGHT_NUMBER_REGEXP.test(flightNumber);

type Props = {
  serverValidationErrors: FormErrors;
  isServerErrorOccurred: boolean;
  isLoading: boolean;
  onSubmit: (values: FormValues) => void;
  onBookWithoutFlightNumber: () => void;
  onResetServerValidationError: (fieldName: keyof FormErrors) => void;
};

// beware: initial values used by formik should not change if you use validateOnMount
// otherwise formik validation will not work
// https://github.com/jaredpalmer/formik/issues/2046
const initialValues: FormValues = {
  flightNumber: '',
  departureDate: new Date(),
};

export const FlightSearchForm = ({
  isServerErrorOccurred,
  serverValidationErrors,
  isLoading,
  onSubmit,
  onBookWithoutFlightNumber,
  onResetServerValidationError,
}: Props) => {
  const intl = useIntl();

  const validateForm = useCallback(
    (values: FormValues) => {
      const errors: FormErrors = {};

      if (!values.flightNumber) {
        errors.flightNumber = intl.formatMessage({
          description: 'Empty flight number error message',
          defaultMessage: 'Flight number is required',
          id: 'VqvPyq',
        });
      } else if (values.flightNumber.length < MIN_FLIGHT_NUMBER_LENGTH) {
        errors.flightNumber = intl.formatMessage({
          description: 'Flight number should be at least 3 characters long error message',
          defaultMessage: 'Flight number should be at least 3 characters long',
          id: 'qXhfd9',
        });
      } else if (!validateFlightNumber(values.flightNumber)) {
        errors.flightNumber = intl.formatMessage({
          description: 'Invalid flight number error message',
          defaultMessage: 'The flight number should begin with a letter. For example: “AA1234”.',
          id: 'pzm3bV',
        });
      }

      return errors;
    },
    [intl],
  );

  return (
    <>
      <div className={dialogStyles.title}>
        {intl.formatMessage({
          defaultMessage: 'Flight details',
          id: 'qeaa4N',
          description: 'Flight details dialog title',
        })}
      </div>
      <Formik
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={onSubmit}
        validateOnMount={true}
      >
        {formikProps => (
          <FormContent
            {...formikProps}
            className={s.form}
            isServerErrorOccurred={isServerErrorOccurred}
            serverValidationErrors={serverValidationErrors}
            onBookWithoutFlightNumber={onBookWithoutFlightNumber}
            onResetServerValidationError={onResetServerValidationError}
            isLoading={isLoading || formikProps.isSubmitting}
          />
        )}
      </Formik>
    </>
  );
};
