import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'clsx';

import { setSkipHistory } from '_store/ui';
import { withSagaFlow } from '_hocs/withSagaFlow';
import { switchCompanyFlowSaga } from '_sagas/switchCompany';
import { useUserName } from '_hooks/user';
import { logout } from '_utils/logout';

import { useMenuItems } from './hooks/useMenuItems';
import s from './styles.scss';
import { DesktopMenu } from './DesktopMenu';

type Props = {
  className?: string;
  onMenuItemClick?: VoidFunction;
};

const Footer: React.VFC<Props> = withSagaFlow<Props>({ onLoad: switchCompanyFlowSaga })(({
  className,
  onMenuItemClick,
}) => {
  const dispatch = useDispatch();
  const menuItems = useMenuItems();

  const handleSignOutClick = useCallback(() => {
    dispatch(setSkipHistory(true));
    logout(dispatch, true);
  }, [dispatch]);

  const userName = useUserName();

  const profileName = userName ?? (
    <FormattedMessage description="Profile menu item text" defaultMessage="Profile" id="em35Lx" />
  );

  return (
    <div className={cn(s.footer, className)}>
      <DesktopMenu
        profileName={profileName}
        menuItems={menuItems}
        onMenuItemClick={onMenuItemClick}
        handleSignOutClick={handleSignOutClick}
      />
    </div>
  );
});

export { Footer };
