import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { apiClient } from '_api';
import { normalizeServiceOffers } from '_api/normalize';
import { Location, GetServiceFaresResponse } from '_api/types';
import {
  fieldToEditSelector,
  setCurrentLocation,
  setGeocodingState,
  setEta,
} from '_store/mapInteraction';
import { selectedServiceOfferSelector } from '_store/serviceOffers';
import { getEtaForChosenService } from '_utils/getEtaForChosenService';
import { transformToGoToPinLocation } from '_utils/goToPinLocationFormatters';

type Position = { lat: number; lng: number };

export function* loadReversedGeocodeAndEtaSaga(
  targetPosition: Position,
  { goToPinTitle }: { goToPinTitle: string },
): SagaIterator {
  try {
    const fieldToEdit = fieldToEditSelector(yield select());
    const selectedServiceOffer = selectedServiceOfferSelector(yield select());

    yield put(setGeocodingState({ geocodingState: 'loading' }));

    const { location } = yield call(apiClient.getReverseGeocode, targetPosition);

    if (location) {
      // api returns not exact location of the pin, we have to fix it this way
      const position: Location['position'] = [targetPosition.lat, targetPosition.lng];
      const updatedLocation: Location = { ...location, position: position };

      if (fieldToEdit?.type === 'pickup') {
        const servicesFares: GetServiceFaresResponse = yield call(apiClient.getServiceFares, {
          pickup: updatedLocation,
          selected_service_id: selectedServiceOffer?.service?.id,
          stops: [],
        });

        const {
          entities: { serviceOffers = {} },
        } = normalizeServiceOffers(servicesFares);

        yield put(
          setEta({ eta: getEtaForChosenService(serviceOffers, selectedServiceOffer) || null }),
        );
      }

      yield put(setCurrentLocation({ location: updatedLocation }));
    } else {
      // server doesn't know any location for such targetPosition, but request was successful
      yield put(
        setCurrentLocation({ location: transformToGoToPinLocation(targetPosition, goToPinTitle) }),
      );
      yield put(setEta({ eta: null }));
    }
  } catch (error) {
    yield put(setCurrentLocation({ location: null }));
    yield put(setEta({ eta: null }));
  } finally {
    yield put(setGeocodingState({ geocodingState: 'done' }));
  }
}
