import { QueryClient, QueryClientConfig } from '@tanstack/react-query';

const config: QueryClientConfig = {
  defaultOptions: {
    queries: {
      networkMode: 'always',
      staleTime: 5 * 60 * 1000, // 5 min
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
};

export const queryClient = new QueryClient(config);
