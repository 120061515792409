import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@wheely/ui-kit';

import { ErrorShape } from '_api/types';

import { FormHelpers } from '../Form/types';

import s from './styles.scss';

type Props = {
  validateForm: FormHelpers['validateForm'];
  serverError: ErrorShape | null;
  submitCount: number;
  formError?: string;
  disabled?: boolean;
  loading?: boolean;
};

export const FormFooter = ({
  loading,
  disabled,
  formError,
  serverError,
  validateForm,
  submitCount,
}: Props) => {
  const intl = useIntl();
  const lastServerError = useRef(serverError);

  // Trigger form validation on server error change (e.g. after request fail result)
  useEffect(() => {
    if (lastServerError.current !== serverError) {
      validateForm();
      lastServerError.current = serverError;
    }
  }, [serverError, validateForm]);

  return (
    <div className={s.footer}>
      <Button
        loading={loading}
        disabled={disabled}
        type="submit"
        fill={true}
        text={intl.formatMessage({
          defaultMessage: 'Request a Chauffeur',
          id: 'RB7SsA',
          description: 'Request a Chauffeur form button text',
        })}
        data-test-id="new-journey-form-submit-button"
      />

      {submitCount > 0 && formError && (
        <span className={s.footerError} data-test-id="common-validation-error">
          {formError}
        </span>
      )}
    </div>
  );
};
