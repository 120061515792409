import React, { useEffect } from 'react';
import cn from 'clsx';
import { Button, ButtonProps, H3, IconArrowLeft, IconMediumCross } from '@wheely/ui-kit';
import { FormattedMessage } from 'react-intl';

import { useBodyScrollBlocking } from '_hooks/useBodyScrollBlocking';
import { Breakpoint, BREAKPOINTS, useBreakpoints } from '_hooks/useBreakpoints';

import s from './styles.scss';

export type MobileModalProps = Omit<React.HTMLProps<HTMLDivElement>, 'title'> & {
  children: React.ReactElement;
  onClose: () => void;
  className?: string;
  showCloseIcon?: boolean;
  onBackButtonClick?: () => void;
  title?: React.ReactNode;
  closeButtonProps?: Partial<Omit<ButtonProps, 'onClick'>>;
  backButtonProps?: Partial<Omit<ButtonProps, 'onClick'>>;
  breakpoint?: Breakpoint;
};

const MobileModal = ({
  children,
  showCloseIcon = true,
  className,
  closeButtonProps,
  onClose,
  backButtonProps,
  onBackButtonClick,
  breakpoint: breakpointProp = BREAKPOINTS.isTablet,
  title = null,
  ...HTMLProps
}: MobileModalProps) => {
  const { [breakpointProp]: breakpoint } = useBreakpoints();

  useBodyScrollBlocking();
  useEffect(() => {
    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        onClose();
      }
    };

    document.addEventListener('keydown', keyDownEventHandler, { capture: true });

    return () => {
      document.removeEventListener('keydown', keyDownEventHandler, { capture: true });
    };
  }, [onClose]);

  if (!breakpoint) {
    onClose();

    return null;
  }

  return (
    <div {...HTMLProps} className={cn(s.container, className)}>
      <div className={cn(s.content, { [s.topSpace]: Boolean(title) })}>
        {title && (
          <H3 capitalize={true} className={s.title}>
            {title}
          </H3>
        )}
        {showCloseIcon && (
          <Button
            minimal={true}
            {...closeButtonProps}
            className={cn(s.close, closeButtonProps?.className)}
            onClick={onClose}
          >
            <IconMediumCross className={s.cross} />
          </Button>
        )}
        {onBackButtonClick && (
          <div className={s.backButtonContainer}>
            <Button
              minimal={true}
              icon={<IconArrowLeft />}
              {...backButtonProps}
              className={cn(s.back, backButtonProps?.className)}
              onClick={onBackButtonClick}
            >
              <FormattedMessage
                description="Mobile modal back button title"
                defaultMessage="Back"
                id="T/u2SE"
              />
            </Button>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export { MobileModal };
