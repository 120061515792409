import { FormikErrors, FormikTouched } from 'formik';

export type FormValues = {
  flightNumber: string;
  departureDate: Date;
};

export const FORM_FIELDS: Record<keyof FormValues, keyof FormValues> = {
  flightNumber: 'flightNumber',
  departureDate: 'departureDate',
};

export type FormErrors = FormikErrors<FormValues>;

export type FormTouched = FormikTouched<FormValues>;
