import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';

import { roundTimestampUpTo } from '_utils/roundDateUpTo';

import s from './styles.scss';

export const PrebookOnlyNote = ({
  eta,
  timezone,
}: {
  eta: number | null;
  timezone: string | null | undefined;
}) => (
  <div className={s.dateTimeNote} data-test-id={'nearest-available-time-row'}>
    <FormattedMessage
      description="Prebook only mode note"
      defaultMessage="Request for now is not available.{br}Nearest available request time is {time}"
      id="UN88pB"
      values={{
        br: <br />,
        time: DateTime.fromMillis(roundTimestampUpTo(5, Date.now() + (eta || 0) * 1000), {
          zone: timezone || 'UTC',
        }).toFormat('HH:mm'),
      }}
    />
  </div>
);
