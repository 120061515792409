import React from 'react';
import { Body, Heading, Footnote, LinkButton } from '@wheely/web-ui';
import { FormattedMessage } from 'react-intl';

import { AppIcon } from '_common/icons/AppIcon/AppIcon';

import { QRCode } from '../../components/QRCode';

import styles from './styles.scss';

export type InviteAcceptedProps = {
  maskedPhone: string;
  companyName: string;
};

export const InviteAccepted = ({ maskedPhone, companyName }: InviteAcceptedProps) => (
  <div className={styles.root}>
    <Heading size="M" className={styles.heading}>
      <FormattedMessage
        defaultMessage="Phone Number Linked"
        id="RPxV33"
        description="Accepted invite title"
      />
    </Heading>
    <Body className={styles.dashboardDescription}>
      <FormattedMessage
        defaultMessage="To access the Corporate Dashboard, sign in to"
        id="Zo9Q2M"
        description="Corporate dashboard description before link"
      />{' '}
      <LinkButton as="a" href="https://business.wheely.com" className={styles.link}>
        business.wheely.com
      </LinkButton>{' '}
      <FormattedMessage
        defaultMessage="with your phone number {maskedPhone}."
        id="MW7tUl"
        description="Corporate dashboard description after link"
        values={{
          maskedPhone: maskedPhone.replaceAll(' ', ' '),
        }}
      />
    </Body>
    <Body className={styles.description}>
      <FormattedMessage
        defaultMessage="To begin using your corporate payment method, sign in to the"
        id="z5Dpdt"
        description="Accepted invite description before wheely app name"
      />{' '}
      <LinkButton as="a" href="https://whee.ly/invite" className={styles.link}>
        <FormattedMessage
          defaultMessage="Wheely app"
          id="LOSfX8"
          description="Accepted invite description wheely app name"
        />
      </LinkButton>{' '}
      <FormattedMessage
        defaultMessage="with your phone number {maskedPhone}. Select {companyName} as a payment method when booking a journey."
        id="BhR9YT"
        description="Accepted invite description after wheely app name"
        values={{
          companyName: companyName.replaceAll(' ', ' '),
          maskedPhone: maskedPhone.replaceAll(' ', ' '),
        }}
      />
      <span className={styles.qrCodeDescription}>
        <FormattedMessage
          defaultMessage=" Scan this QR code to install the Wheely app."
          id="Y3l8Ka"
          description="QR code description"
        />
      </span>
    </Body>
    <Footnote className={styles.subDescription}>
      <FormattedMessage
        defaultMessage="Need another phone number? Contact the admin of{companyName} to resend invitation."
        id="oM34lw"
        description="Accepted invite change phone text"
        values={{ companyName: ` ${companyName}`.replaceAll(' ', ' ') }}
      />
    </Footnote>
    <div className={styles.qrCode}>
      <QRCode data-test-id="invite_accepted_app_qr" className={styles.qrCodeIcon} />
    </div>
    <div className={styles.appLink}>
      <LinkButton as="a" href="https://whee.ly/invite">
        <FormattedMessage defaultMessage="Continue in the app" id="lQ/s4D" description="App link" />
        <AppIcon className={styles.appIcon} />
      </LinkButton>
    </div>
  </div>
);
