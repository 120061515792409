import { useDispatch } from 'react-redux';
import { useLayoutEffect } from 'react';

import { setIsAppReadyToBeShown } from '_store/ui';
import { Company, User } from '_api/types';

export const useRegistrationFlow = (allowUserLoad: boolean, user?: User, company?: Company) => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const userLoadedAndHasCompanyThatShouldBeLoaded =
      !(user?.company && company) && !(user?.id && !user?.company);

    if (!allowUserLoad || userLoadedAndHasCompanyThatShouldBeLoaded) {
      return;
    }

    dispatch(setIsAppReadyToBeShown({ isAppReadyToBeShown: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.company, allowUserLoad, company, dispatch]);
};
