import React from 'react';

import { IconCarPlaceholder } from '_common/IconCarPlaceholder';

import s from './styles.scss';

const ItemImage: React.FC<{
  url?: string;
  title?: string;
}> = ({ url, title }) => {
  if (url) {
    return <img className={s.itemImage} src={url} alt={title} />;
  }

  return <IconCarPlaceholder className={s.itemImage} />;
};

export { ItemImage };
