import React from 'react';
import { useIntl } from 'react-intl';
import { Text, Loader } from '@wheely/ui-kit';

import { Location } from '_api/types';
import { GeocodingState } from '_store/mapInteraction';

import s from './styles.scss';

type Props = {
  location?: Location | null;
  geocodingState: GeocodingState;
};

export const Address = ({ location, geocodingState }: Props) => {
  const intl = useIntl();
  const value =
    location?.line1 ||
    location?.name ||
    intl.formatMessage({
      defaultMessage: 'Go to pin',
      id: '7MMBrY',
      description: 'User selects pin on map which has no address',
    });

  if (geocodingState === 'initial') {
    return null;
  }

  return (
    <div className={s.container}>
      {geocodingState === 'loading' ? (
        <Loader />
      ) : (
        <Text tagName={'span'} capitalize={true}>
          {value}
        </Text>
      )}
    </div>
  );
};
