import { authRoutes } from '_routes';

const ID_MACHER = /\/([0-9a-fA-F]{24}|\d+)(\/|$)/;

export const reloadAfterCompanySwitch = () => {
  if (
    window.location.pathname.startsWith(authRoutes.registerRoute) ||
    window.location.pathname.startsWith(authRoutes.addCompanyRoute) ||
    window.location.pathname.match(ID_MACHER)
  ) {
    return (window.location.href = window.location.origin);
  }

  return (window.location.href = `${window.location.origin}${window.location.pathname}`);
};
