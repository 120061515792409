import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { endOfDay, getHours, getMinutes, isAfter, isToday, max, set, startOfDay } from 'date-fns';
import {
  InputValueRenderer,
  isTimeStringValid,
  parseTimeString,
  Time,
  TimeItemsPredicate,
  TimePicker,
  TimePickerItem,
  TimePickerProps,
} from '@wheely/ui-kit';

import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from '_common/constants';
import { DatePicker, DatePickerProps } from '_common/DatePicker';
import { MobileDatePicker } from '_common/DatePicker/MobileDatePicker';
import { MobileModal } from '_common/MobileModal';
import { MobileTapContainer } from '_common/MobileTapContainer';
import { BREAKPOINTS, useBreakpoints } from '_hooks/useBreakpoints';
import { useLocale } from '_i18n/useLocale';
import { useLocaleDate } from '_i18n/useLocaleDate';
import { formatDateToTimeValue } from '_utils/formatDateToTimeValue';
import { isValidDate } from '_utils/isValidDate';
import { roundDateUpTo } from '_utils/roundDateUpTo';
import { shiftDateToUtcOffset } from '_utils/shiftDateToUtcOffset';
import { unshiftDateToUtcOffset } from '_utils/unshiftDateToUtcOffset';
import { isTimeNotExist } from '_utils/timezone/timezone';

import s from './styles.scss';
import { timePickerItemRenderer } from './timePickerItemRenderer';
import { getSubtitleFormatPattern, timeItemListPredicate } from './utils';

type Props = {
  onChange: (date: null | Date) => void;
  value: null | Date;
  earliestReservationDate: Date;
  utcOffset: number | null;
  nowTitle?: string;
  todayTitle?: string;
  error?: string | null;
  disabled?: boolean;
  datePickerProps?: Omit<
    DatePickerProps,
    | 'value'
    | 'todayTitle'
    | 'onChange'
    | 'isToday'
    | 'disabled'
    | 'months'
    | 'weekdaysLong'
    | 'weekdaysShort'
    | 'firstDayOfWeek'
  >;
  timePickerProps?: Omit<
    TimePickerProps,
    'nowTitle' | 'value' | 'onChange' | 'isToday' | 'canSetNow' | 'error' | 'disabled'
  >;
  timezone?: string | null;
  city?: string | null;
};

export const JourneyDateTimePicker = ({
  onChange,
  value,
  todayTitle,
  error,
  disabled,
  datePickerProps = {},
  timePickerProps,
  earliestReservationDate,
  utcOffset,
  timezone,
  city,
}: Props) => {
  const intl = useIntl();
  const { langCode } = useLocale();
  const { format } = useLocaleDate();
  const { isMobile, isDesktop } = useBreakpoints();

  const [localizedMonths, localizedWeekdaysLong, localizedWeekdaysShort] = useMemo(
    () => [
      MONTHS.map(msg => intl.formatMessage(msg)),
      WEEKDAYS_LONG.map(msg => intl.formatMessage(msg)),
      WEEKDAYS_SHORT.map(msg => intl.formatMessage(msg)),
    ],
    [intl],
  );

  let derivedDateValue: undefined | Date;
  let derivedTimeValue: undefined | string;
  let derivedIsToday = false;

  // Type guard for `value`
  if (value) {
    derivedDateValue = value;
    derivedTimeValue = formatDateToTimeValue(derivedDateValue);
    derivedIsToday = isToday(unshiftDateToUtcOffset(derivedDateValue));
  }

  useEffect(() => {
    if (!derivedDateValue) {
      onChange(roundDateUpTo(5, earliestReservationDate));
    }
  }, [derivedDateValue, earliestReservationDate, onChange]);

  const handleDateChange = useCallback(
    (date: Date) => {
      let nextValue = value;

      if (value) {
        const hours = getHours(value);
        const minutes = getMinutes(value);

        nextValue = max([
          set(date, { hours, minutes, seconds: 0 }),
          shiftDateToUtcOffset(new Date(), utcOffset),
          roundDateUpTo(5, earliestReservationDate),
        ]);
      }

      onChange(nextValue);
    },
    [earliestReservationDate, onChange, utcOffset, value],
  );
  const handleTimeChange = useCallback(
    (time: Time) => {
      let nextValue = value || roundDateUpTo(5, earliestReservationDate);

      if (isTimeStringValid(time)) {
        const [hours, minutes] = parseTimeString(time);

        nextValue = set(nextValue, { hours, minutes, seconds: 0 });
      }

      onChange(nextValue);
    },
    [earliestReservationDate, onChange, value],
  );

  const timeItemsPredicate = useCallback<TimeItemsPredicate>(
    ({ disabled: isTimeItemDisabled, timeItems, isToday: isTimeItemToday }) => {
      if (isTimeItemDisabled) {
        return [];
      }

      if (!isTimeItemToday) {
        return timeItems.filter(
          ({ value: itemValue }) => !isTimeNotExist(itemValue, value, timezone),
        );
      }

      const minTodayTime = formatDateToTimeValue(
        max([shiftDateToUtcOffset(new Date(), utcOffset), earliestReservationDate]),
      );

      return timeItems.filter(
        ({ value: itemValue }) =>
          itemValue >= minTodayTime && !isTimeNotExist(itemValue, value, timezone),
      );
    },
    [earliestReservationDate, timezone, utcOffset, value],
  );

  const derivedTodayTitle = useMemo(
    () =>
      todayTitle ||
      intl.formatMessage({
        description: 'DateTime picker today title',
        defaultMessage: 'Today',
        id: 'w6hiBp',
      }),
    [intl, todayTitle],
  );

  const timePickerInputValueRenderer = useCallback<InputValueRenderer<TimePickerItem>>(
    item => {
      const formattedTime = `${item.title ?? item.value}`;

      if (isDesktop || !isValidDate(derivedDateValue)) {
        return formattedTime;
      }

      const formattedDate = derivedIsToday
        ? derivedTodayTitle
        : format(derivedDateValue, 'd MMM yyyy');

      return `${formattedDate}, ${formattedTime}`;
    },
    [derivedDateValue, derivedIsToday, derivedTodayTitle, format, isDesktop],
  );

  const [isMobileDatePickerOpened, setIsMobileDatePickerOpened] = useState(false);
  const handleOpenMobileDatePicker = useCallback(() => setIsMobileDatePickerOpened(true), []);
  const handleCloseMobileDatePicker = useCallback(() => setIsMobileDatePickerOpened(false), []);
  const handleMobileDatePickerSubmit = useCallback(
    (date: Date) => {
      setIsMobileDatePickerOpened(false);
      onChange(date);
    },
    [onChange],
  );

  const renderMobileDatePickerSubtitle = useCallback(
    (localValue: Date) =>
      intl.formatMessage(
        {
          description: 'Pickup time picker, modal subtitle',
          defaultMessage: 'Schedule for {date}, {time}',
          id: 'pGj+rO',
        },
        {
          date: format(localValue, getSubtitleFormatPattern(localValue)),
          time: formatDateToTimeValue(localValue),
        },
      ),
    [format, intl],
  );

  const isDateValid = useMemo(() => {
    if (error || !isValidDate(derivedDateValue)) {
      return false;
    }

    return (
      derivedIsToday || isAfter(endOfDay(derivedDateValue), startOfDay(earliestReservationDate))
    );
  }, [derivedDateValue, derivedIsToday, earliestReservationDate, error]);
  const timezoneLabel = useMemo(() => {
    if (isValidDate(value) && city) {
      return intl.formatMessage(
        {
          description: 'UTC label',
          defaultMessage: 'Local time: {city}',
          id: 'Z+VofN',
        },
        { city },
      );
    }

    return '';
  }, [intl, city, value]);

  return (
    <div className={s.dateTimePicker}>
      <>
        <MobileTapContainer
          disabled={disabled}
          onClick={handleOpenMobileDatePicker}
          breakpoint={BREAKPOINTS.isMobile}
        >
          <div className={s.dateTimePickerWrapper}>
            {!isMobile && (
              <DatePicker
                {...datePickerProps}
                value={derivedDateValue}
                onChange={handleDateChange}
                isToday={derivedIsToday}
                todayTitle={derivedTodayTitle}
                isValid={isDateValid}
                disabled={disabled}
                months={localizedMonths}
                weekdaysLong={localizedWeekdaysLong}
                weekdaysShort={localizedWeekdaysShort}
                locale={langCode}
                popoverClassName={s.datePicker}
              />
            )}
            <TimePicker
              {...timePickerProps}
              value={derivedTimeValue}
              onChange={handleTimeChange}
              isToday={derivedIsToday}
              error={error}
              disabled={!error && disabled}
              className={s.timePicker}
              itemRenderer={timePickerItemRenderer}
              inputValueRenderer={timePickerInputValueRenderer}
              itemsPredicate={timeItemsPredicate}
              itemListPredicate={timeItemListPredicate}
            />
          </div>
        </MobileTapContainer>
        {isMobileDatePickerOpened && (
          <MobileModal onClose={handleCloseMobileDatePicker} breakpoint={BREAKPOINTS.isMobile}>
            <MobileDatePicker
              value={derivedDateValue}
              onSubmit={handleMobileDatePickerSubmit}
              todayTitle={derivedTodayTitle}
              disabledDays={datePickerProps.disabledDays}
              fromMonth={datePickerProps.fromMonth}
              title={intl.formatMessage({
                description: 'Pickup time picker, modal title',
                defaultMessage: 'Pickup time',
                id: 'DQU8Wl',
              })}
              subTitle={renderMobileDatePickerSubtitle}
              timezone={timezone}
            />
          </MobileModal>
        )}
      </>
      {timezoneLabel && <div className={s.timezoneLabel}>{timezoneLabel}</div>}
    </div>
  );
};
