import React from 'react';
import { Redirect } from 'react-router';

import { ErrorShape } from '_api/types';

type Props = {
  isError: boolean;
  error: unknown;
  children: React.ReactElement;
};

export const NotFoundWrapper = ({ isError, error, children }: Props) => {
  if (isError && (error as ErrorShape).status === 404) {
    return <Redirect to="/not_found" />;
  }

  return children;
};
