import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createReduxEnhancer } from '@sentry/react';

// disable phantom errors
// sagas
// eslint-disable-next-line import/order
import { rootSaga } from '../sagas/root';
// eslint-disable-next-line import/order
import { persistConfig } from './persist';

// slices
// TODO: DI for slices
import { authSlice } from './auth';
import { employeesSlice } from './employees';
import { departmentsSlice } from './departments';
import { uiSlice } from './ui';
import { ordersSlice } from './orders';
import { orderRequestsSlice } from './orderRequests';
import { newJourneySlice } from './newJourney';
import { serviceOffersSlice as servicesSlice } from './serviceOffers';
import { registrationSlice } from './registration';
import { favoriteOrdersSlice } from './favoriteOrders';
import { mapInteractionSlice } from './mapInteraction';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history),
  [authSlice.name]: authSlice.reducer,
  [employeesSlice.name]: employeesSlice.reducer,
  [departmentsSlice.name]: departmentsSlice.reducer,
  [uiSlice.name]: uiSlice.reducer,
  [ordersSlice.name]: ordersSlice.reducer,
  [orderRequestsSlice.name]: orderRequestsSlice.reducer,
  [newJourneySlice.name]: newJourneySlice.reducer,
  [servicesSlice.name]: servicesSlice.reducer,
  [registrationSlice.name]: registrationSlice.reducer,
  [favoriteOrdersSlice.name]: favoriteOrdersSlice.reducer,
  [mapInteractionSlice.name]: mapInteractionSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
    }),
    routerMiddleware(history),
    sagaMiddleware,
  ],
  reducer: persistedReducer,
  enhancers: [
    createReduxEnhancer({
      stateTransformer: state => ({
        [authSlice.name]: {
          token: state[authSlice.name].token ? {} : null,
        },
      }),
    }),
  ],
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type GetReduxState = () => RootState;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const runSaga = sagaMiddleware.run;
