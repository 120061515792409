import React from 'react';

import { AppInitializer } from '_app/AppInitializer';
import { AppReadyGate } from '_app/AppReadyGate';
import { GlobalErrorGate } from '_app/GlobalErrorGate';
import { PermissionsProvider } from '_app/PermissionsProvider';
import { WithSession } from '_app/WithSession/WithSession';
import { WithChildren } from '_types/helpers';
import { CompanyPendingWrapper } from '_app/CompanyPendingWrapper';

export const PrivateRouterWrapper = ({ children }: WithChildren) => {
  return (
    <WithSession>
      <AppReadyGate>
        <PermissionsProvider>
          <CompanyPendingWrapper>
            <GlobalErrorGate>
              <AppInitializer>{children}</AppInitializer>
            </GlobalErrorGate>
          </CompanyPendingWrapper>
        </PermissionsProvider>
      </AppReadyGate>
    </WithSession>
  );
};
