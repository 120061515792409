import React from 'react';
import { useIntl } from 'react-intl';
import { Text } from '@wheely/ui-kit';

import styles from '../styles.scss';

export const MeetAndGreetRailwayHubWithoutBoard = () => {
  const intl = useIntl();

  return (
    <>
      <Text className={styles.text} tagName="p" muted={true} withoutPadding={true}>
        {intl.formatMessage({
          defaultMessage: 'The chauffeur will be waiting outside the train carriage.',
          id: 'RUKosW',
        })}
      </Text>

      <Text className={styles.text} tagName="p" muted={true} withoutPadding={true}>
        {intl.formatMessage({
          defaultMessage: 'Please note that parking charges will be added to the final fare.',
          id: 'P+ojeO',
        })}
      </Text>
    </>
  );
};
