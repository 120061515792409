import { createSelector } from '@reduxjs/toolkit';
import { Selector } from 'react-redux';

import { getLongJourneyOptionsKey } from '_store/common/utils';
import { CompanyType, Employee, Order } from '_api/types';
import { EMPTY_VALUE } from '_constants';
import { RootState } from '_store';
import { departmentsEntitiesSelector, departmentsOrderSelector } from '_store/departments';
import { employeesEntitiesSelector, employeesOrderSelector } from '_store/employees';
import { userLocationSelector } from '_store/newJourney';
import { selectedServiceOfferSelector } from '_store/serviceOffers';
import { formatAddress } from '_utils/formatAddress';
import { formatAndJoinNames } from '_utils/formatAndJoinNames';
import { companyCityCenterGoogleFormatSelector } from '_queries/company/selectors';
import { getIsHospitalityType } from '_utils/getCompanyType';
import { authRoutes } from '_routes';

const userAsEmployeeSelector = (state: RootState) => state.employees.userAsEmployee;

const transformEmployee = (employee: Employee, isCurrentUser?: boolean) => ({
  title: [employee.first_name, employee.last_name].join(' '),
  value: employee.user_id,
  image: employee.photo_url,
  phone: employee.phone,
  isCurrentUser: Boolean(isCurrentUser),
});

export const employeesItemsSelector = createSelector(
  employeesOrderSelector,
  employeesEntitiesSelector,
  (order, entities) => order.map(employeeId => transformEmployee(entities[employeeId])),
);

export const employeesSelectItemsSelector = createSelector(
  employeesOrderSelector,
  employeesEntitiesSelector,
  userAsEmployeeSelector,
  (order, entities, userAsEmployee) => {
    const userAsEmployeeHeading = userAsEmployee ? [transformEmployee(userAsEmployee, true)] : [];

    const restEmployees = order
      .filter(id => id !== userAsEmployee?.id)
      .map(employeeId => {
        const employee = entities[employeeId];

        return transformEmployee(employee);
      });

    return userAsEmployeeHeading.concat(restEmployees);
  },
);

export const departmentsSelectItemsSelector = createSelector(
  departmentsOrderSelector,
  departmentsEntitiesSelector,
  (order, entities) =>
    order.map(departmentId => {
      const department = entities[departmentId];

      return {
        title: department.title,
        value: department.id,
      };
    }),
);

export const companyCityOrUserLocationSelector = createSelector(
  userLocationSelector,
  companyCityCenterGoogleFormatSelector,
  (userLocation, cityCenter) => userLocation || cityCenter,
);

export const selectedServiceOfferDurationSelector = createSelector(
  selectedServiceOfferSelector,
  serviceOffer => serviceOffer?.service?.approximate_duration ?? null,
);

export const selectedServiceOfferUtcOffsetSelector = createSelector(
  selectedServiceOfferSelector,
  serviceOffer => {
    if (!serviceOffer) {
      return null;
    }

    return serviceOffer.service.utc_offset;
  },
);

export const selectedServiceOfferTimezoneSelector = createSelector(
  selectedServiceOfferSelector,
  serviceOffer => {
    if (!serviceOffer) {
      return null;
    }

    return serviceOffer.service.timezone;
  },
);

export const selectedServiceOfferCitySelector = createSelector(
  selectedServiceOfferSelector,
  serviceOffer => {
    if (!serviceOffer) {
      return null;
    }

    return serviceOffer.service.city_name;
  },
);

export const isOnlyPrebookAvailableForSelectedServiceOfferSelector = createSelector(
  selectedServiceOfferSelector,
  serviceOffer => {
    if (!serviceOffer) {
      return false;
    }

    // no eta -> prebook only
    return typeof serviceOffer.service_availability.eta !== 'number';
  },
);

export const createIsLoadingSelector = (isLoadingSelectors: Selector<RootState, boolean>[]) =>
  createSelector(isLoadingSelectors, (...loadingStates) => loadingStates.some(state => state));

export const isRegistrationFlowSelector = (state: RootState) =>
  state.router.location.pathname.startsWith(authRoutes.registerRoute);

export const createConvertOrderListToTableData =
  ({ isFavorite = false }: { isFavorite?: boolean } = {}) =>
  (orders: Order[], companyType: CompanyType | null) =>
    orders.map(order => {
      const {
        id,
        city,
        department,
        employee_name,
        number,
        passenger,
        payer,
        payment,
        pickup,
        dropoff,
        service,
        sorted_at,
        status,
        expected_duration,
        reference,
      } = order;
      const isHospitalityType = getIsHospitalityType(companyType);

      return {
        id,
        data: {
          number: { value: number ?? null },
          status: { value: status },
          location: { value: city?.name ?? EMPTY_VALUE },
          class: { value: service.title },
          start: { value: { date: sorted_at, utcOffset: service.utc_offset } },
          pickup: { value: formatAddress(pickup) },
          dropoff: { value: dropoff ? formatAddress(dropoff) : EMPTY_VALUE },
          passenger: {
            value: isHospitalityType
              ? formatAndJoinNames([passenger?.name])
              : formatAndJoinNames([employee_name, passenger?.name]),
          },
          passengerPhone: { value: isHospitalityType && passenger?.phone ? passenger.phone : null },
          employee: { value: formatAndJoinNames([employee_name]) },
          department: { value: department?.title || EMPTY_VALUE },
          payer: {
            value: payer,
          },
          cost: {
            value: {
              amount: payment?.total ?? null,
              currencyCode: payment?.currency_code ?? null,
            },
          },
          reference: { value: reference ?? EMPTY_VALUE },
          actions: { value: { id, isFavorite } },
          meta: {
            isGoToPinPickup: Boolean(pickup.go_to_pin),
            isGoToPinDropoff: Boolean(dropoff?.go_to_pin),
            longJourneyOptionsKey: getLongJourneyOptionsKey(expected_duration),
          },
        },
      };
    });
