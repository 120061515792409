import React from 'react';

export const QRCode = ({ className, ...rest }: { className: string }) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    shapeRendering="crispEdges"
    className={className}
  >
    <path
      stroke="#000000"
      d="M13 2.5h1m1 0h1m1 0h1m1 0h4M11 3.5h1m1 0h2m2 0h1m3 0h2m1 0h1M5 5.5h7m1 0h4m2 0h1m3 0h7M5 6.5h1m5 0h1m4 0h2m3 0h1m1 0h1m5 0h1M5 7.5h1m1 0h3m1 0h1m2 0h1m1 0h1m1 0h1m1 0h2m1 0h1m1 0h3m1 0h1M5 8.5h1m1 0h3m1 0h1m3 0h2m1 0h1m1 0h2m1 0h1m1 0h3m1 0h1M5 9.5h1m1 0h3m1 0h1m3 0h3m3 0h1m1 0h1m1 0h3m1 0h1m1 0h2M5 10.5h1m5 0h1m2 0h1m1 0h1m1 0h2m3 0h1m5 0h1m1 0h2M3 11.5h1m1 0h7m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h7m1 0h1m1 0h1M3 12.5h1m9 0h2m2 0h5m9 0h1m1 0h1M3 13.5h1m1 0h2m1 0h2m1 0h1m2 0h1m2 0h1m1 0h1m1 0h1m1 0h1m5 0h1m2 0h3M2 14.5h1m4 0h1m2 0h1m1 0h2m1 0h1m2 0h4m2 0h5m2 0h1M2 15.5h2m3 0h1m2 0h3m1 0h1m2 0h1m1 0h4m1 0h1m1 0h1m2 0h1m2 0h1M2 16.5h2m2 0h1m1 0h1m1 0h1m1 0h1m6 0h1m1 0h4m1 0h4m1 0h1m2 0h1M3 17.5h1m1 0h2m1 0h1m1 0h3m1 0h2m7 0h1m5 0h1m3 0h1M3 18.5h1m1 0h1m1 0h1m5 0h1m1 0h1m1 0h2m1 0h2m3 0h1m2 0h1m2 0h1m1 0h2M5 19.5h2m2 0h1m1 0h2m2 0h1m2 0h6m1 0h5m1 0h1m1 0h2M2 20.5h1m2 0h1m1 0h1m1 0h1m4 0h1m2 0h1m1 0h1m2 0h3m1 0h2m1 0h1m1 0h2m1 0h1M2 21.5h2m1 0h1m2 0h1m1 0h3m3 0h3m2 0h5m1 0h2m2 0h1M2 22.5h1m10 0h1m2 0h1m4 0h1m3 0h1m1 0h2m2 0h1M5 23.5h7m5 0h2m2 0h1m1 0h1m1 0h1m3 0h1m3 0h2M5 24.5h1m5 0h1m3 0h2m1 0h1m1 0h2m3 0h1m5 0h3M5 25.5h1m1 0h3m1 0h1m1 0h1m1 0h2m1 0h1m1 0h6m2 0h2M5 26.5h1m1 0h3m1 0h1m1 0h1m1 0h1m1 0h3m2 0h2m4 0h2M5 27.5h1m1 0h3m1 0h1m4 0h1m1 0h1m2 0h2m2 0h5M5 28.5h1m5 0h1m1 0h6m4 0h3m1 0h3m1 0h1M5 29.5h7m1 0h1m1 0h4m1 0h2m1 0h1m2 0h1m2 0h1M8 31.5h1m2 0h1m1 0h1m2 0h2m1 0h1m3 0h1m1 0h1m2 0h1M9 32.5h1m1 0h1m1 0h1m1 0h2m1 0h3m2 0h1m2 0h1M11 33.5h6m1 0h2m2 0h1m2 0h1M13 34.5h2m2 0h2m3 0h1"
    ></path>
  </svg>
);
