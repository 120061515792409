import React from 'react';
import { useField } from 'formik';
import { FormGroup, InputGroup, Text } from '@wheely/ui-kit';
import { useIntl } from 'react-intl';

import { getFieldErrorMessage } from '_pages/NewJourney/components/Form/utils/getFieldErrorMessage';

import styles from '../styles.scss';

interface Props {
  disabled?: boolean;
}

export const MeetAndGreetAirportWithBoard = ({ disabled }: Props) => {
  const intl = useIntl();
  const [welcomeBoardTextField, welcomeBoardTextMeta] = useField('welcomeBoardText');

  return (
    <>
      <FormGroup
        error={getFieldErrorMessage(welcomeBoardTextMeta)}
        errorProps={{
          'data-test-id': 'welcome-board-error',
        }}
      >
        <InputGroup
          {...welcomeBoardTextField}
          disabled={disabled}
          placeholder={intl.formatMessage({
            description: 'Board text placeholder',
            defaultMessage: 'Name board text',
            id: 'ayREHY',
          })}
          data-test-id="welcome-board-field"
        />
      </FormGroup>
      <Text className={styles.text} tagName="p" muted={true} withoutPadding={true}>
        {intl.formatMessage({
          defaultMessage:
            'Your driver will meet you at Arrivals with a name board. Parking fees will be added to the final cost.',
          id: 'fAR8Td',
        })}
      </Text>
    </>
  );
};
