import React, { useCallback } from 'react';
import { Body, PhoneNumberInput, PhoneNumberInputProps, SelectOption } from '@wheely/web-ui';
import { FieldAttributes, useField } from 'formik';

type FormikPhoneInputProps<T> = {
  inputProps?: PhoneNumberInputProps;
} & FieldAttributes<T>;

const renderSelectedPhoneCode = ({ value: phoneCodeValue }: SelectOption) => (
  <Body style={{ textAlign: 'start', width: '100%', display: 'block' }}>{phoneCodeValue}</Body>
);

export const FormikPhoneInput = <T extends Record<string, unknown>>({
  inputProps = {},
  name,
}: FormikPhoneInputProps<T>) => {
  const [phoneField, phoneFieldMeta, helpers] = useField(name);

  const handleChange = useCallback(
    (value: string) => {
      helpers.setValue(value);
    },
    [helpers],
  );

  return (
    <PhoneNumberInput
      renderSelectedValue={renderSelectedPhoneCode}
      {...phoneField}
      {...inputProps}
      {...(phoneFieldMeta.touched && {
        errorMessage: phoneFieldMeta.error || inputProps?.errorMessage,
      })}
      onChange={handleChange}
    />
  );
};
