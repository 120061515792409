import { useQuery } from '@tanstack/react-query';

import { apiClient } from '_api';

import { CITIES } from './keys';

export const useGetCitiesQuery = () =>
  useQuery({
    refetchOnWindowFocus: false,
    retry: false,
    queryKey: [CITIES],
    queryFn: () => apiClient.getCities(),
  });
