import React from 'react';

import { ViewportLoader } from '_common/ViewportLoader';

type Props = {
  isLoading: boolean;
  children: React.ReactElement;
};

export const LoadingWrapper = ({ isLoading, children }: Props) => {
  if (isLoading) {
    return <ViewportLoader isLoading={true} />;
  }

  return children;
};
