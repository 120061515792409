import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import cn from 'clsx';

import { useTypedSelector } from '_store';
import { isLoggedInSelector } from '_store/auth';
import { LogoLink } from '_common/LogoLink';
import { CompanySwitcherHeader } from '_common/CompanySwitcher';
import { withSagaFlow } from '_hocs/withSagaFlow';
import { switchCompanyFlowSaga } from '_sagas/switchCompany';
import { logout } from '_utils/logout';

import s from './styles.scss';

type Props = {
  className?: string;
  withoutLogoutButton: boolean;
};

export const Header = withSagaFlow<Props>({ onLoad: switchCompanyFlowSaga })(({
  className,
  withoutLogoutButton,
}) => {
  const dispatch = useDispatch();
  const isLoggedIn = useTypedSelector(isLoggedInSelector);

  const handleLogoutClick = useCallback(() => {
    logout(dispatch, true);
  }, [dispatch]);

  return (
    <div className={cn(s.header, className)}>
      <div className={s.logoContainer}>
        <LogoLink className={s.logo} />
      </div>
      <div className={s.rightContentContainer}>
        <CompanySwitcherHeader />
        {!withoutLogoutButton && isLoggedIn && (
          <button className={s.logoutButton} onClick={handleLogoutClick}>
            <FormattedMessage
              defaultMessage="Logout"
              id="NWBdkA"
              description="Guest header logout button label"
            />
          </button>
        )}
      </div>
    </div>
  );
});
