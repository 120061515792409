import { debounce } from 'redux-saga/effects';

import { loadEmployees as loadEmployeesAction } from '_store/employees';

import { loadEmployees as loadEmployeesSaga } from './loadEmployees';

function* watchEmployeesLoad() {
  yield debounce(600, loadEmployeesAction.type, loadEmployeesSaga);
}

export { watchEmployeesLoad };
