import React, { useMemo } from 'react';
import { SelectField, SelectFieldProps } from '@wheely/ui-kit';

import { useLocale } from '_i18n/useLocale';
import { alphabeticalOrder } from '_utils/alphabeticalOrder';
import { useGetCitiesQuery } from '_queries/citites';

export type CitySelectItem = {
  value: string;
  title: string;
};

export type CitySelectProps = Omit<SelectFieldProps<CitySelectItem>, 'items'>;

export const CitySelect = ({ disabled, ...rest }: CitySelectProps) => {
  const { langCode } = useLocale();

  const { data, isLoading } = useGetCitiesQuery();

  const cityItems = useMemo<CitySelectItem[]>(
    () =>
      Object.values(data?.cities || {})
        .map(city => ({
          value: city.id,
          title: city.localized_name[langCode] || city.localized_name.en,
        }))
        .sort(alphabeticalOrder),
    [data, langCode],
  );

  return (
    <SelectField<CitySelectItem> items={cityItems} disabled={isLoading || disabled} {...rest} />
  );
};
