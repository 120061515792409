export const employeesBase = '/employees';

export const employeesDetails = `${employeesBase}/:id`;

export const employeesDepartments = `${employeesBase}/departments`;

export const employeesDepartmentsDetails = `${employeesDepartments}/:id`;

export const employeesDepartmentsNew = `${employeesDepartments}/new`;

export const oldRoutes = {
  employeesBase: ['/employees/employees', '/concierges/concierges'],
  employeesDetails: ['/employees/employees/:id', '/concierges/concierges/:id'],
  employeesDepartments: ['/concierges/departments'],
  employeesDepartmentsDetails: ['/concierges/departments/:id'],
  employeesDepartmentsNew: ['/concierges/departments/new'],
};

export const redirects = [
  {
    from: oldRoutes.employeesBase,
    to: employeesBase,
  },
  {
    from: oldRoutes.employeesDetails,
    to: employeesDetails,
  },
  {
    from: oldRoutes.employeesDepartments,
    to: employeesDepartments,
  },
  {
    from: oldRoutes.employeesDepartmentsDetails,
    to: employeesDepartmentsDetails,
  },
  {
    from: oldRoutes.employeesDepartmentsNew,
    to: employeesDepartmentsNew,
  },
];
