import { defineMessages } from 'react-intl';

const MESSAGES = defineMessages({
  description: {
    description: 'Company attach card notifier description text',
    defaultMessage:
      'Please re-add your corporate payment card by 30 March to continue using Wheely services.',
    id: 'Sa/kMq',
  },
  linkLabel: {
    description: 'Company attach card notifier Go to settings link label',
    defaultMessage: 'Go to settings',
    id: '8qkrmX',
  },
});

export { MESSAGES };
