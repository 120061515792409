import React from 'react';
import cn from 'clsx';

import { ChauffeurForADayType } from '_api/types';

import s from './styles.scss';

type Props = {
  type: ChauffeurForADayType;
  className?: string;
};

export const ChauffeurForADaySign = ({ type, className }: Props) => (
  <div className={cn(s.container, { [s.halfDay]: type === 'half_day' }, className)} />
);
