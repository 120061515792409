import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';

import { companyCityOrUserLocationSelector } from '_store/common/selectors';
import { apiClient } from '_api';
import { CompletionsResponse, CompletionsPayload } from '_api/types';
import { getGeoPointQueryFromCoords } from '_utils/getGeoPointQueryFromCoords';

function* loadCompletions({
  query,
  mode,
}: {
  query: string;
  mode?: CompletionsPayload['mode'];
}): SagaIterator<{ completions: null | CompletionsResponse }> {
  if (!query.length) {
    return { completions: null };
  }

  const coords = companyCityOrUserLocationSelector(yield select());

  try {
    const completions: CompletionsResponse = yield call(apiClient.getCompletions, {
      input: query,
      position: coords ? getGeoPointQueryFromCoords(coords) : undefined,
      mode,
    });

    return { completions };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Cannot get location by reference:', error);

    throw error;
  }
}

export { loadCompletions };
