import React from 'react';
import cn from 'clsx';
import { Link } from 'react-router-dom';
import {
  AnchorButton,
  AnchorButtonProps,
  ButtonProps,
  IconArrowRightCircled,
  Text,
} from '@wheely/ui-kit';

import { WithChildren } from '_types/helpers';

import s from './styles.scss';

type PageNotifierProps = WithChildren<{
  className?: string;
  contentClassName?: string;
  descriptionClassname?: string;
  anchorButtonProps: Pick<AnchorButtonProps, 'component' | 'href'> & ButtonProps;
}>;

const PageNotifier: React.FC<PageNotifierProps> = ({
  className,
  contentClassName,
  children,
  descriptionClassname,
  anchorButtonProps,
}) => {
  const { className: anchorButtonClassname, ...restAnchorButtonProps } = anchorButtonProps;

  return (
    <div className={cn(s.root, className)}>
      <div className={cn(s.content, contentClassName)}>
        <Text className={cn(s.description, descriptionClassname)}>{children}</Text>
        <AnchorButton
          component={Link}
          rightIcon={<IconArrowRightCircled />}
          minimal={true}
          className={cn(s.link, anchorButtonClassname)}
          {...restAnchorButtonProps}
        />
      </div>
    </div>
  );
};

export { PageNotifier };
