import React from 'react';
import type { Icon, IconProps } from '@wheely/ui-kit';

export const IconBag: Icon = ({ width, height, className, fill = 'currentColor' }: IconProps) => (
  <svg
    width={width}
    className={className}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 1.5C9.33579 1.5 9 1.83579 9 2.25V3.5H15V2.25C15 1.83579 14.6642 1.5 14.25 1.5H9.75ZM12 21C15.2421 21 18.4169 20.7116 21.5 20.1591V11.7146C18.4763 12.5523 15.2904 13 12 13C8.70958 13 5.52372 12.5523 2.5 11.7146V20.1591C5.58314 20.7116 8.75789 21 12 21ZM12 11.5C15.2904 11.5 18.4763 11.0523 21.5 10.2146V5H2.5V10.2146C5.52372 11.0523 8.70958 11.5 12 11.5Z"
    />
  </svg>
);
