import { useLocale } from '_i18n/useLocale';
import { LangCode } from '_types/common';
import { getRootDomain } from '_utils/getRootDomain';

type Links = {
  termsOfUse: string;
  privacyPolicy: string;
  cookiePolicy: string;
};

const rootDomain = getRootDomain(location.host);

const linksByCountryCode: Record<Extract<LangCode, 'ru' | 'en'>, Links> = {
  en: {
    termsOfUse: `https://${rootDomain}/en/legal/uk/for-business-accounts/terms`,
    cookiePolicy: `https://${rootDomain}/en/legal/uk/for-users/cookie `,
    privacyPolicy: `https://${rootDomain}/en/legal/uk/for-business-accounts/privacy`,
  },
  ru: {
    termsOfUse: `https://wheely.ltd/ru/legal/russia/for-business-accounts/terms`,
    cookiePolicy: `https://wheely.ltd/ru/legal/russia/for-users/cookie`,
    privacyPolicy: `https://wheely.ltd/ru/legal/russia/for-business-accounts/privacy`,
  },
};

const useLegalLinks = (): Links => {
  // NOTE We are using locale information because we don't know the company's country till the login
  const { langCode } = useLocale();

  if (langCode !== 'en' && langCode !== 'ru') {
    return linksByCountryCode.en;
  }

  return linksByCountryCode[langCode];
};

export { useLegalLinks };
