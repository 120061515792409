import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import cn from 'clsx';
import {
  addDays,
  addHours,
  addMinutes,
  differenceInCalendarDays,
  endOfDay,
  endOfHour,
  endOfMinute,
  getDay,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isSameDay,
  isSameHour,
  isSameMinute,
  isToday,
  isWithinInterval,
  set,
  startOfDay,
  startOfHour,
  startOfMinute,
  subDays,
  subHours,
  subMinutes,
} from 'date-fns';
import { KeenSliderInstance } from 'keen-slider';

import { isDev } from '_constants';
import { useLocaleDate } from '_i18n/useLocaleDate';
import { isValidDate } from '_utils/isValidDate';
import { isFunction } from '_utils/isFunction';
import { castAsCompactArray } from '_utils/castAsCompactArray';
import { roundDateUpTo } from '_utils/roundDateUpTo';
import { DatePickerProps } from '_common/DatePicker';
import { ExcludeArray } from '_types/helpers';
import { isDateNotExist } from '_utils/timezone/timezone';

import {
  IsSlideDisabledPredicate,
  OnSlideChange,
  SlideRenderer,
  SlideValueSetter,
  Wheel,
} from './components/Wheel';
import s from './styles.scss';

type DisabledDays = NonNullable<DatePickerProps['disabledDays']>;

export type MobileDateTimePickerProps = {
  value: Date;
  dateFormatPattern?: string;
  onChange?: (date: Date) => void;
  disabled?: boolean;
  disabledDays?: DisabledDays;
  fromMonth?: Date;
  todayTitle?: string;
  dark?: boolean;
  isDateOnly?: boolean;
  timezone?: string | null;
};

type Precision = 'day' | 'hours' | 'minutes';

const areDatesEquals = (dateLeft: Date, dateRight: Date, precision?: Precision) => {
  let comparator: typeof isSameDay;

  switch (precision) {
    case 'day': {
      comparator = isSameDay;

      break;
    }
    case 'hours': {
      comparator = isSameHour;

      break;
    }
    case 'minutes': {
      comparator = isSameMinute;

      break;
    }

    default: {
      comparator = isSameMinute;
    }
  }

  return comparator(dateLeft, dateRight);
};

const getDateBounds = (date: Date, precision?: Precision): { start: Date; end: Date } => {
  switch (precision) {
    case 'day': {
      return { start: startOfDay(date), end: endOfDay(date) };
    }
    case 'hours': {
      return { start: startOfHour(date), end: endOfHour(date) };
    }
    case 'minutes': {
      return { start: startOfMinute(date), end: endOfMinute(date) };
    }
    default: {
      return { start: date, end: date };
    }
  }
};

const isGivenDateDisabled = (
  date: Date,
  disabledDays: ExcludeArray<DisabledDays>,
  precision?: Precision,
  timezone?: string | null,
): boolean => {
  if (isDateNotExist(date, timezone)) {
    return true;
  }

  // Function
  if (isFunction(disabledDays)) {
    return disabledDays(date);
  }

  // Date
  if (isValidDate(disabledDays)) {
    return isSameDay(disabledDays, date);
  }

  // Days of week
  if ('daysOfWeek' in disabledDays) {
    return disabledDays.daysOfWeek.includes(getDay(date));
  }

  const dateBounds = getDateBounds(date, precision);

  // Before and After
  if ('before' in disabledDays && 'after' in disabledDays) {
    return (
      isBefore(dateBounds.end, disabledDays.before) || isAfter(dateBounds.start, disabledDays.after)
    );
  }

  // Before
  if ('before' in disabledDays) {
    return isBefore(dateBounds.end, disabledDays.before);
  }

  // After
  if ('after' in disabledDays) {
    return isAfter(dateBounds.start, disabledDays.after);
  }

  // Range
  if ('from' in disabledDays && 'to' in disabledDays) {
    return isWithinInterval(date, {
      start: getDateBounds(disabledDays.from, precision).start,
      end: getDateBounds(disabledDays.to, precision).end,
    });
  }

  return false;
};

const createIsDateDisabled =
  (isPickerDisabled: boolean, disabledDays?: DisabledDays, timezone?: string | null) =>
  (date: Date, precision?: Precision) => {
    if (isPickerDisabled) {
      return true;
    }

    if (!disabledDays) {
      return false;
    }

    const roundedDate = set(date, { seconds: 0 });

    return castAsCompactArray<DisabledDays>(disabledDays).some(rule =>
      isGivenDateDisabled(roundedDate, rule, precision, timezone),
    );
  };

const findNearestAvailableDate = (
  targetDate: Date,
  referenceDate: Date,
  isDateDisabled: ReturnType<typeof createIsDateDisabled>,
  precision: Precision,
): Date => {
  const isCalculationUnavailable =
    !isDateDisabled(targetDate, precision) ||
    isDateDisabled(referenceDate, precision) ||
    areDatesEquals(targetDate, referenceDate, precision);

  if (isCalculationUnavailable) {
    return targetDate;
  }

  const findNearestBefore = targetDate.getTime() > referenceDate.getTime();
  let result: Date;

  if (findNearestBefore) {
    result = startOfDay(targetDate);

    while (isDateDisabled(result, 'day')) {
      result = subDays(result, 1);
    }

    result = roundDateUpTo(5, result);

    while (isDateDisabled(result, 'hours')) {
      result = subHours(result, 1);
    }

    while (!isDateDisabled(addMinutes(result, 5), 'minutes')) {
      result = addMinutes(result, 5);
    }
  } else {
    result = endOfDay(targetDate);

    while (isDateDisabled(result, 'day')) {
      result = addDays(result, 1);
    }

    result = roundDateUpTo(5, result);

    while (isDateDisabled(result, 'hours')) {
      result = addHours(result, 1);
    }

    while (!isDateDisabled(subMinutes(result, 5), 'minutes')) {
      result = subMinutes(result, 5);
    }
  }

  return set(result, { seconds: 0 });
};

const getIndexByDate = (date: Date) => differenceInCalendarDays(date, new Date());

const getDateByIndex: SlideValueSetter<Date> = absoluteIndex => addDays(new Date(), absoluteIndex);

const hoursValueSetter: SlideValueSetter<string> = (absoluteIndex: number, relativeIndex: number) =>
  relativeIndex.toString().padStart(2, '0');

const minutesValueSetter: SlideValueSetter<string> = (
  absoluteIndex: number,
  relativeIndex: number,
) => (relativeIndex * 5).toString().padStart(2, '0');

const getIndexes = (date: Date) => {
  date = set(date, { seconds: 0 });

  return {
    date: getIndexByDate(date),
    hours: getHours(date),
    minutes: Math.ceil(getMinutes(date) / 5),
  };
};

const getPrecision = (isDateOnly: boolean): Precision => (isDateOnly ? 'day' : 'minutes');

export const MobileDateTimePicker = ({
  value,
  onChange,
  dateFormatPattern = 'iii d LLL',
  disabled = false,
  disabledDays,
  fromMonth,
  todayTitle,
  dark,
  isDateOnly = false,
  timezone,
}: MobileDateTimePickerProps) => {
  const { format } = useLocaleDate();
  const instanceRef = useRef<KeenSliderInstance>(null);

  const derivedFromMonth = isValidDate(fromMonth) && isDateOnly ? startOfDay(fromMonth) : fromMonth;

  const isDateDisabled = useMemo(
    () => createIsDateDisabled(disabled, disabledDays, timezone),
    [disabled, disabledDays, timezone],
  );

  const interceptDateChange = useCallback(
    (nextValue: Date) => {
      const precision = getPrecision(isDateOnly);

      let result = set(nextValue, { seconds: 0 });

      if (!isFunction(onChange) || areDatesEquals(result, value, precision)) {
        return;
      }

      if (isDateDisabled(result, precision)) {
        result = findNearestAvailableDate(result, value, isDateDisabled, precision);
      }

      onChange(result);
    },
    [isDateOnly, isDateDisabled, onChange, value],
  );

  const isDateSlideDisabledPredicate = useCallback<IsSlideDisabledPredicate<Date>>(
    ({ value: date }) => isDateDisabled(date, 'day'),
    [isDateDisabled],
  );

  const isHoursSlideDisabledPredicate = useCallback<IsSlideDisabledPredicate<string>>(
    ({ value: hours }) => {
      const dateToCheck = set(value, { hours: Number(hours) });

      return isDateDisabled(dateToCheck, 'hours');
    },
    [isDateDisabled, value],
  );

  const isMinutesSlideDisabledPredicate = useCallback<IsSlideDisabledPredicate<string>>(
    ({ value: minutes }) => {
      const dateToCheck = set(value, { minutes: Number(minutes) });

      return isDateDisabled(dateToCheck, 'minutes');
    },
    [isDateDisabled, value],
  );

  const dateSlideRenderer = useCallback<SlideRenderer<Date>>(
    ({ value: slideValue, relativeIndex, className, contentClassName }) => {
      const text =
        todayTitle && isToday(slideValue) ? todayTitle : format(slideValue, dateFormatPattern);

      return (
        <div className={className} key={`wheel-cell-${relativeIndex}`}>
          <span className={contentClassName}>{text}</span>
        </div>
      );
    },
    [dateFormatPattern, format, todayTitle],
  );

  const handleDateChange = useCallback<OnSlideChange<Date>>(
    ({ value: date }) => {
      if (!isValidDate(date)) {
        return;
      }

      const hours = getHours(value);
      const minutes = getMinutes(value);

      const nextValue = set(date, { hours, minutes });

      interceptDateChange(nextValue);
    },
    [interceptDateChange, value],
  );

  const handleHoursChange = useCallback<OnSlideChange<string>>(
    ({ value: hours }) => {
      const nextValue = set(value, { hours: Number(hours) });

      interceptDateChange(nextValue);
    },
    [interceptDateChange, value],
  );

  const handleMinutesChange = useCallback<OnSlideChange<string>>(
    ({ value: minutes }) => {
      const nextValue = set(value, { minutes: Number(minutes) });

      interceptDateChange(nextValue);
    },
    [interceptDateChange, value],
  );

  // Calculate `min` for date picker
  const minMonthIndex = useMemo(
    () => (derivedFromMonth ? getIndexByDate(derivedFromMonth) : undefined),
    [derivedFromMonth],
  );

  const indexes = useMemo(() => getIndexes(value), [value]);

  // Emit initial value update if current is unavailable if it is possible to calculate
  useEffect(() => {
    if (isValidDate(value) && isDateDisabled(value) && derivedFromMonth) {
      const precision = getPrecision(isDateOnly);

      const nearestAvailableDate = findNearestAvailableDate(
        value,
        derivedFromMonth,
        isDateDisabled,
        precision,
      );

      if (!areDatesEquals(nearestAvailableDate, value, precision)) {
        interceptDateChange(nearestAvailableDate);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Validate `fromMonth` and `disabledDays`
  useEffect(() => {
    if (!isDev || !disabledDays || !isValidDate(fromMonth)) {
      return;
    }

    const isFromMonthDisabled = isDateDisabled(fromMonth, getPrecision(isDateOnly));

    if (isFromMonthDisabled) {
      // eslint-disable-next-line no-console
      console.error(
        `Invalid properties passed: "fromMonth" is overlapping with "disabledDays". Got\n    fromMonth: '${fromMonth.toISOString()}'\n    disabledDays: '${JSON.stringify(
          disabledDays,
        )}'\n`,
      );
    }
  }, [disabledDays, fromMonth, isDateDisabled, isDateOnly]);

  return (
    <div
      className={cn(s.root, {
        [s.dark]: dark,
      })}
    >
      <div className={s.dateWheelHolder}>
        <Wheel
          ref={instanceRef}
          onSlideChange={handleDateChange}
          renderSlide={dateSlideRenderer}
          setValue={getDateByIndex}
          isSlideDisabled={isDateSlideDisabledPredicate}
          activeIndex={indexes.date}
          length={24}
          range={{
            align: false,
            min: minMonthIndex,
          }}
          loop={{
            min: minMonthIndex,
          }}
          className={s.dateSlider}
          disabled={disabled}
        />
      </div>

      {!isDateOnly && (
        <>
          <div className={s.timeWheelHolder}>
            <Wheel
              onSlideChange={handleHoursChange}
              setValue={hoursValueSetter}
              isSlideDisabled={isHoursSlideDisabledPredicate}
              activeIndex={indexes.hours}
              loop={true}
              length={24}
              disabled={disabled}
            />
          </div>
          <div className={s.timeWheelHolder}>
            <Wheel
              onSlideChange={handleMinutesChange}
              setValue={minutesValueSetter}
              isSlideDisabled={isMinutesSlideDisabledPredicate}
              activeIndex={indexes.minutes}
              loop={true}
              length={12}
              className={s.minutesSlider}
              disabled={disabled}
            />
          </div>
        </>
      )}
    </div>
  );
};
