import { AuthApi, AuthAdapter, LocalStorage, AuthApiSessionManager } from '@wheely/web-auth-lib';
import { captureException } from '@sentry/react';

import { getApiUrl } from '_utils/getApiUrl';

import { STORAGE_KEY, SYNCRONIZER_STORAGE_KEY } from '../constants';

const appId = window.b2bAppConfig.APP_ID;
const apiUrl = getApiUrl();
const baseURL = apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl;

const storage = new LocalStorage();

const adapter = new AuthAdapter({ apiHost: baseURL, appId, captureException });

const sessionManager = new AuthApiSessionManager({
  storage,
  tabSyncronizerConfig: {
    storageKey: SYNCRONIZER_STORAGE_KEY,
  },
  adapter,
  sessionStorageKey: STORAGE_KEY,
});

const authAPI = new AuthApi({ sessionManager });

export { authAPI, sessionManager };
