import { useCallback, useEffect, useRef } from 'react';

import { useMethod } from '_hooks/useMethod';

export const useDebouncedMethod = <K extends any[]>(
  func: (...args: K) => any,
  timeout: number,
): ((...args: K) => void) => {
  const method = useMethod(func);
  const timeoutId = useRef<any>(null);
  const debouncedMethod = useCallback(
    (...args: K) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        method(...args);
      }, timeout);
    },
    [method, timeout],
  );

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, []);

  return debouncedMethod;
};
