import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import '@wheely/ui-kit/dist/ui-kit-global.css';
import '@wheely/ui-kit/dist/ui-kit.css';
import { history } from '_store';

import './styles/index.global.scss';
import { AppContainer } from './app';

if (window.b2bAppConfig.SENTRY_DSN) {
  Sentry.init({
    dsn: window.b2bAppConfig.SENTRY_DSN,
    environment: window.b2bAppConfig.SENTRY_ENV || 'production',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
    tunnel: process.env.NODE_ENV === 'production' ? '/sentry' : undefined,
    release: `wheely-b2b@${window.b2bAppConfig.SENTRY_RELEASE}`,
  });
} else {
  // eslint-disable-next-line no-console
  console.warn('`SENTRY_DSN` is not set');
}

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(<AppContainer />);

/* eslint-disable @typescript-eslint/ban-ts-comment, no-console */
// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept(error => {
    console.error('An error occurred while accepting new version');
    console.error(error);
  });
}
