import { ServiceOffer } from '_api/types';

export const getEtaForChosenService = (
  serviceOffers: Record<string, ServiceOffer>,
  selectedServiceOffer: ServiceOffer | null,
) => {
  const serviceOffersList = Object.values(serviceOffers);

  if (!selectedServiceOffer) {
    // find closest eta
    return serviceOffersList.reduce<number | null>((min, serviceOffer) => {
      const currentEta = serviceOffer?.service_availability?.eta || null;

      if (!min) {
        return currentEta;
      }

      if (currentEta) {
        return Math.min(min, currentEta);
      }

      return min;
    }, null);
  }

  const chosenServiceOffer = serviceOffersList.find(
    serviceOffer => serviceOffer.service.id === selectedServiceOffer.service.id,
  );

  return chosenServiceOffer?.service_availability?.eta;
};
