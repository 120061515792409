import React, { ChangeEventHandler, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { TextField, CountryCodeItem } from '@wheely/ui-kit';

import { resetServerError } from '_store/newJourney';
import { useCompanyType } from '_hooks';
import { PhoneField } from '_common/PhoneField';
import { usePhoneCountryCode } from '_hooks/usePhoneCountryCode';
import { FormGroupTitle } from '_pages/NewJourney/components/FormGroupTitle';

import { FormValues } from '../Form/types';

import s from './styles.scss';

type Props = {
  disabled?: boolean;
};

export const PassengerField = ({ disabled }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isHospitalityType } = useCompanyType();
  const phoneCode = usePhoneCountryCode();
  const { setFieldValue, values, errors, touched } = useFormikContext<FormValues>();

  const handlePassengerNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      setFieldValue('passengerName', event.target.value);
    },
    [setFieldValue],
  );

  const handlePhoneCodeChange = useCallback<(item: CountryCodeItem) => void>(
    item => {
      setFieldValue('passengerCountryCode', item.value);
    },
    [setFieldValue],
  );

  const handlePhoneNumberChange = useCallback<(value: string) => void>(
    value => {
      setFieldValue('passengerPhoneNumber', value);
    },
    [setFieldValue],
  );
  const handleFieldClose = useCallback(() => {
    dispatch(resetServerError());

    setFieldValue('passengerName', null);
    setFieldValue('passengerPhoneNumber', null);
    setFieldValue('passengerCountryCode', null);
  }, [dispatch, setFieldValue]);

  return (
    <>
      <TextField
        title={
          <div className={s.title}>
            <FormGroupTitle>
              <FormattedMessage
                description="Hospitality passenger field title"
                defaultMessage="Passenger"
                id="Gqfhix"
              />
            </FormGroupTitle>
            {!isHospitalityType && (
              <span
                className={s.clear}
                data-test-id="clear-book-for-another-button"
                onClick={handleFieldClose}
              >
                <FormattedMessage
                  description="Passenger field clear button label"
                  defaultMessage="Clear"
                  id="L9B1FZ"
                />
              </span>
            )}
          </div>
        }
        name="passengerName"
        placeholder={intl.formatMessage({
          defaultMessage: 'Enter a name',
          id: 'BPSKwH',
          description: 'New journey passenger name placeholder',
        })}
        value={values?.passengerName || ''}
        error={touched.passengerName && errors?.passengerName}
        disabled={disabled}
        autoFocus={true}
        autoComplete="off"
        maxLength={128}
        onChange={handlePassengerNameChange}
        data-test-id="passenger-name"
        errorProps={{
          'data-test-id': 'passenger-name-error',
        }}
      />

      <PhoneField
        name="passengerPhoneNumber"
        countryCode={values.passengerCountryCode ?? phoneCode}
        value={values.passengerPhoneNumber || ''}
        placeholder={intl.formatMessage({
          description: 'Phone field placeholder',
          defaultMessage: 'Phone number',
          id: 'pTNKg/',
        })}
        error={touched.passengerPhoneNumber && errors?.passengerPhoneNumber}
        disabled={disabled}
        onChange={handlePhoneNumberChange}
        onCodeChange={handlePhoneCodeChange}
        data-test-id="passenger-phone-number"
        errorProps={{
          'data-test-id': 'passenger-phone-number-error',
        }}
      />
    </>
  );
};
