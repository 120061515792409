import { createSlice, createAction } from '@reduxjs/toolkit';

import { RootState } from '_store';
import { ErrorShape, PhoneShape } from '_api/types';

export type AuthState = {
  phone: PhoneShape;
  error: ErrorShape | null;
  pinSentAt: string | null;
  callRequestedAt: string | null;
  isLoggedIn: boolean;
  isLoading: boolean;
};

export const setIsLoggedIn = createAction<{ isLoggedIn: boolean }>('auth/setIsLoggedIn');

export const sendPin = createAction<{ phone: PhoneShape; captchaToken: string; locale: string }>(
  'auth/sendPin',
);
export const sendPinFailed = createAction<{ error: ErrorShape }>('auth/sendPinFailed');
export const sendPinSucceed = createAction<{
  phone: PhoneShape;
}>('auth/sendPinSucceed');
export const resetPinState = createAction('auth/resetPinState');
export const resetAuthState = createAction('auth/resetAuthState');

export const requestPinCall = createAction<{
  phone: PhoneShape;
  captchaToken: string;
  locale: string;
}>('auth/requestPinCall');
export const requestPinCallFailed = createAction<{ error: ErrorShape }>(
  'auth/requestPinCallFailed',
);
export const requestPinCallSucceed = createAction('auth/requestPinCallSucceed');
export const resetPinCallStatus = createAction('auth/resetRequestCallStatus');

export const activatePin = createAction<{ pin: string; locale: string }>('auth/activatePin');
export const activatePinFailed = createAction<{ error: ErrorShape }>('auth/activatePinFailed');
export const activatePinSucceed = createAction('auth/activatePinSucceed');

export const updateUserCompany = createAction<{ companyId: string }>('auth/updateUserCompany');
export const updateUserFailed = createAction<{ error: ErrorShape }>('auth/updateUserFailed');
export const updatePhoneCode = createAction<{ code: string }>('auth/updatePhoneCode');

export const clearAuthError = createAction('auth/clearAuthError');

export const serverValidationMessageSelector = (state: RootState) =>
  state.auth.error?.message ?? null;

export const isLoggedInSelector = (state: RootState) => state.auth.isLoggedIn;
export const pinSentAtSelector = (state: RootState) => state.auth.pinSentAt;

export const phoneSelector = (state: RootState) => state.auth.phone;
export const isLoadingSelector = (state: RootState) => state.auth.isLoading;
export const callRequestedAtSelector = (state: RootState) => state.auth.callRequestedAt;

export const phoneCodeSelector = (state: RootState) => state.auth.phone?.code;

export const setCaptchaToken = createAction<{ captchaToken: string }>('auth/setCaptchaToken');

export const initialState: AuthState = {
  phone: {
    code: '',
    number: '',
  },
  error: null,
  pinSentAt: null,
  callRequestedAt: null,
  isLoggedIn: false,
  isLoading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setIsLoggedIn, (state, { payload }) => ({
      ...state,
      isLoggedIn: payload.isLoggedIn,
    }));
    builder.addCase(sendPin, state => ({
      ...state,
      error: null,
      isLoading: true,
    }));
    builder.addCase(sendPinSucceed, (state, { payload }) => ({
      ...state,
      error: null,
      isLoading: false,
      phone: payload.phone,
      pinSentAt: new Date().toISOString(),
    }));
    builder.addCase(sendPinFailed, (state, { payload }) => ({
      ...state,
      error: payload.error,
      isLoading: false,
    }));
    builder.addCase(resetPinState, state => ({
      ...state,
      error: null,
      isLoading: false,
      pinSentAt: null,
    }));
    builder.addCase(requestPinCall, (state, { payload }) => ({
      ...state,
      captchaToken: payload.captchaToken,
    }));
    builder.addCase(requestPinCallSucceed, state => ({
      ...state,
      isLoading: false,
      callRequestedAt: new Date().toISOString(),
    }));

    builder.addCase(resetPinCallStatus, state => ({
      ...state,
      callRequestedAt: null,
    }));

    builder.addCase(activatePin, state => ({
      ...state,
      error: null,
      isLoading: true,
    }));
    builder.addCase(activatePinSucceed, state => ({
      ...state,
      error: null,
      isLoading: false,
      isLoggedIn: true,
    }));
    builder.addCase(activatePinFailed, (state, { payload }) => ({
      ...state,
      error: payload.error,
      isLoading: false,
    }));
    builder.addCase(resetAuthState, state => ({ ...initialState, phone: state.phone }));
    builder.addCase(updateUserFailed, (state, { payload: { error } }) => ({
      ...state,
      error,
      isLoading: false,
    }));
    builder.addCase(updatePhoneCode, (state, { payload: { code } }) => ({
      ...state,
      phone: {
        ...state.phone,
        code,
      },
    }));
    builder.addCase(setCaptchaToken, (state, { payload: { captchaToken } }) => ({
      ...state,
      captchaToken,
    }));
    builder.addCase(clearAuthError, state => ({
      ...state,
      error: null,
    }));
  },
});

export { authSlice };
