import { defineMessages } from 'react-intl';

const MESSAGES = defineMessages({
  title: {
    description: 'Payment field title',
    defaultMessage: 'Payment',
    id: 'Wzz2JW',
  },
  description: {
    description: 'Payment field description',
    defaultMessage: 'The passenger will receive an SMS with a link to payment.',
    id: 'Pn9X3P',
  },
});

export { MESSAGES };
