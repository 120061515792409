import React, { useCallback } from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';

import { PayerSelect, PayerSelectProps } from '_common/PayerSelect';

import { FormGroupTitle } from '../FormGroupTitle';
import { FormValues } from '../Form/types';

import { MESSAGES } from './constants';
import s from './styles.scss';

type Props = Omit<PayerSelectProps, 'onItemSelect'> & {
  isHidden?: boolean;
  clearServerError?: () => void;
};

export const PayerField = ({
  disabled = false,
  isHidden = false,
  isHospitalityType = false,
  clearServerError,
}: Props) => {
  const intl = useIntl();
  const [payerField, , payerFieldHelper] = useField<FormValues['payer']>('payer');

  const handleItemSelect = useCallback<PayerSelectProps['onItemSelect']>(
    ({ value }) => {
      if (clearServerError) {
        clearServerError();
      }

      payerFieldHelper.setValue(value);
    },
    [clearServerError, payerFieldHelper],
  );

  if (isHidden) {
    return null;
  }

  return (
    <PayerSelect
      formGroupProps={{ className: s.payerField }}
      isHospitalityType={isHospitalityType}
      value={payerField.value}
      onItemSelect={handleItemSelect}
      title={<FormGroupTitle>{intl.formatMessage(MESSAGES.title)}</FormGroupTitle>}
      description={payerField.value === 'user' ? intl.formatMessage(MESSAGES.description) : null}
      disabled={disabled}
      buttonProps={{ 'data-test-id': 'charge-select' }}
      menuProps={{
        'data-test-id': 'charge-select-menu',
      }}
    />
  );
};
