import React from 'react';
import { useIntl } from 'react-intl';

import { PageNotifier } from '_common/PageNotifier';
import { useHasPermission } from '_app/PermissionsProvider';
import { useCompany } from '_hooks/company';

import { MESSAGES } from './constants';

type Props = { className?: string };

export const CompanyAttachCardNotifier = ({ className }: Props) => {
  const intl = useIntl();
  const canReadCompanyAttachCardNotifier = useHasPermission(
    'common:companyAttachCardNotifier:read',
  );
  const paymentMethod = useCompany()?.payment_method || null;

  const shouldAttachCardToCompany = paymentMethod?.type === 'card' && !paymentMethod.card;

  if (!canReadCompanyAttachCardNotifier || !shouldAttachCardToCompany) {
    return null;
  }

  return (
    <PageNotifier
      className={className}
      anchorButtonProps={{
        text: intl.formatMessage(MESSAGES.linkLabel),
        href: '/settings/billing',
      }}
    >
      {intl.formatMessage(MESSAGES.description)}
    </PageNotifier>
  );
};
