import React, { useCallback, useState } from 'react';
import { Button, Dialog, IconMediumCross } from '@wheely/ui-kit';

import { Flight, Location, ServiceOffer } from '_api/types';
import { loadFlights } from '_sagas/common/loadFlights';
import { useLocalSagas } from '_hooks';
import { handleApiError } from '_utils/handleApiError';
import { AirportSelect } from '_pages/NewJourney/components/PickupAddressField/components/AirportPickupDialog/components/AirportSelect';
import { useTypedSelector } from '_store';
import { getFlightArrivalDateTime } from '_utils/getFlightArrivalDateTime';
import { stopsSelector } from '_store/newJourney';
import { loadServiceOffersForPickupLocation } from '_sagas/newJourney';
import { getEarliestReservationDate } from '_utils/getEarliestReservationDate';
import { getServiceOfferEta } from '_utils/getServiceOfferNearestReservationOffset';
import { PickupUnavailable } from '_pages/NewJourney/components/PickupAddressField/components/AirportPickupDialog/components/PickupUnavailable';
import { selectedServiceOfferSelector } from '_store/serviceOffers';
import { useBreakpoints } from '_hooks/useBreakpoints';

import { FlightNotFound } from './components/FlightNotFound';
import { FlightList } from './components/FlightList';
import { FlightLanded } from './components/FlightLanded';
import { FlightSearchForm } from './components/FlightSearchForm';
import {
  FormErrors as FlightSearchFormErrors,
  FormValues as FlightSearchFormValues,
} from './components/FlightSearchForm/types';
import s from './styles.scss';

type Props = {
  isOpen: boolean;
  hideBookWithoutFlightNumber: boolean;
  onClose: () => void;
  onFlightSelect: (flight: Flight) => void;
  onLocationChange: (location: Location) => void;
  onAsapRequest: () => void;
};

export const AirportPickupDialog = ({
  isOpen,
  hideBookWithoutFlightNumber = false,
  onFlightSelect,
  onAsapRequest,
  onLocationChange,
  onClose,
}: Props) => {
  const { runLocalSaga } = useLocalSagas();
  const { isMobile } = useBreakpoints();

  const [isFlightNotFound, setIsFlightNotFound] = useState(false);
  const [serverValidationErrors, setServerValidationErrors] = useState<FlightSearchFormErrors>({});
  const [isServerErrorOccurred, setIsServerErrorOccurred] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState<Flight | null>(null);
  const [flights, setFlights] = useState<Flight[] | null>(null);
  const [isAirportSelectMode, setIsAirportSelectMode] = useState(false);
  const [isCheckingServiceOffers, setIsCheckingServiceOffers] = useState(false);
  const [earliestPickupDateTime, setEarliestPickupDateTime] = useState<Date | null>(null);

  const selectedServiceOffer = useTypedSelector(selectedServiceOfferSelector);
  const stops = useTypedSelector(stopsSelector);

  const closeDialog = useCallback(() => {
    setIsFlightNotFound(false);
    setFlights(null);
    setServerValidationErrors({});
    setIsServerErrorOccurred(false);
    setIsAirportSelectMode(false);
    setEarliestPickupDateTime(null);
    onClose();
  }, [onClose]);

  const selectFlight = useCallback(
    (flight: Flight) => {
      const flightArrivalDateTime = getFlightArrivalDateTime(flight);

      setIsCheckingServiceOffers(true);
      setIsServerErrorOccurred(false);
      runLocalSaga(loadServiceOffersForPickupLocation, {
        pickup: flight.pickup,
        selectedServiceId: selectedServiceOffer ? selectedServiceOffer.service.id : null,
        stops,
      })
        .then(serviceOfferEntities => {
          const newEarliestPickupDateTime = Object.values<ServiceOffer>(serviceOfferEntities)
            .filter(serviceOffer => {
              if (typeof serviceOffer.service_availability.earliest_reservation !== 'number') {
                // prebook unavailable
                return false;
              }

              if (!selectedServiceOffer) {
                return true;
              }

              return serviceOffer.service.id === selectedServiceOffer.service.id;
            })
            .reduce<Date | null>((acc, serviceOffer) => {
              const serviceOfferEta = getServiceOfferEta(serviceOffer, true);

              const serviceOfferEarliestPickupTime = getEarliestReservationDate(
                serviceOfferEta,
                serviceOffer.service.utc_offset,
              );

              if (!acc || acc > serviceOfferEarliestPickupTime) {
                return serviceOfferEarliestPickupTime;
              }

              return acc;
            }, null);

          if (newEarliestPickupDateTime && newEarliestPickupDateTime > flightArrivalDateTime) {
            setSelectedFlight(flight);
            setEarliestPickupDateTime(newEarliestPickupDateTime);

            return;
          }

          onFlightSelect(flight);
          closeDialog();
        })
        .catch(error => {
          handleApiError(error);
          setIsServerErrorOccurred(true);
        })
        .finally(() => setIsCheckingServiceOffers(false));
    },
    [runLocalSaga, stops, onFlightSelect, closeDialog, selectedServiceOffer],
  );

  const handleFlightSearchFormSubmit = useCallback(
    async (values: FlightSearchFormValues) => {
      try {
        setIsServerErrorOccurred(false);

        const loadedFlights: Flight[] = await runLocalSaga(loadFlights, {
          departureDate: values.departureDate,
          flightNumber: values.flightNumber,
        });

        const firstLoadedFlight = loadedFlights[0];

        if (loadedFlights.length === 1) {
          selectFlight(firstLoadedFlight);

          return;
        }

        setSelectedFlight(firstLoadedFlight);
        setFlights(loadedFlights);
        setIsFlightNotFound(false);
      } catch (error: any) {
        handleApiError(error);

        if (error.flight_not_found) {
          setIsFlightNotFound(true);

          return;
        }

        if (error.flight_number) {
          setServerValidationErrors({
            flightNumber: error.flight_number[0],
          });

          return;
        }

        setIsServerErrorOccurred(true);
      }
    },
    [runLocalSaga, selectFlight],
  );

  const handleFlightListConfirm = useCallback(() => {
    if (!selectedFlight) {
      return;
    }

    selectFlight(selectedFlight);
  }, [selectedFlight, selectFlight]);

  const handleRequestOnDemand = useCallback(
    (location: Location) => {
      onLocationChange(location);
      onAsapRequest();
      closeDialog();
    },
    [onLocationChange, onAsapRequest, closeDialog],
  );

  const resetFlightNotFound = useCallback(() => {
    setIsFlightNotFound(false);
  }, []);

  const resetFlights = useCallback(() => {
    setFlights(null);
  }, []);

  const resetIsAirportSelectMode = useCallback(() => {
    setIsAirportSelectMode(false);
  }, []);

  const resetPickupUnavailable = useCallback(() => {
    setEarliestPickupDateTime(null);
    setSelectedFlight(null);
  }, []);

  const handleBookWithoutFlightNumber = useCallback(() => {
    if (hideBookWithoutFlightNumber) {
      closeDialog();
    } else {
      setIsAirportSelectMode(true);
    }
  }, [closeDialog, hideBookWithoutFlightNumber]);

  const handleResetServerValidationError = useCallback(
    (fieldName: keyof FlightSearchFormErrors) => {
      setServerValidationErrors(({ [fieldName]: fieldToReset, ...orderFields }) => orderFields);
    },
    [],
  );

  const handleAirportSelectConfirm = useCallback(
    (location: Location) => {
      onLocationChange(location);
      closeDialog();
    },
    [closeDialog, onLocationChange],
  );

  const renderContent = () => {
    if (isAirportSelectMode) {
      return (
        <AirportSelect onBack={resetIsAirportSelectMode} onConfirm={handleAirportSelectConfirm} />
      );
    }

    if (
      selectedFlight &&
      (earliestPickupDateTime || (flights?.length === 1 && selectedFlight.status === 'landed'))
    ) {
      if (selectedFlight.status === 'landed') {
        return (
          <FlightLanded
            flight={selectedFlight}
            onRequestOnDemand={handleRequestOnDemand}
            onBookWithoutFlightNumber={handleBookWithoutFlightNumber}
            onBack={resetPickupUnavailable}
          />
        );
      }

      return (
        <PickupUnavailable
          earliestPickupDateTime={earliestPickupDateTime as Date}
          onRequestOnDemand={handleRequestOnDemand}
          flight={selectedFlight}
          onBack={resetPickupUnavailable}
          serviceOffer={selectedServiceOffer}
        />
      );
    }

    if (isFlightNotFound) {
      return (
        <FlightNotFound
          onChangeFlight={resetFlightNotFound}
          onBookWithoutFlightNumber={handleBookWithoutFlightNumber}
          onBack={resetFlightNotFound}
        />
      );
    }

    if (flights) {
      return (
        <FlightList
          isLoading={isCheckingServiceOffers}
          flights={flights}
          onSelect={setSelectedFlight}
          selectedFlight={selectedFlight}
          onConfirm={handleFlightListConfirm}
          isServerErrorOccurred={isServerErrorOccurred}
          onBack={resetFlights}
        />
      );
    }

    return (
      <FlightSearchForm
        isLoading={isCheckingServiceOffers}
        onSubmit={handleFlightSearchFormSubmit}
        serverValidationErrors={serverValidationErrors}
        isServerErrorOccurred={isServerErrorOccurred}
        onBookWithoutFlightNumber={handleBookWithoutFlightNumber}
        onResetServerValidationError={handleResetServerValidationError}
      />
    );
  };

  return (
    <Dialog
      portalClassName={s.portal}
      className={s.dialog}
      isOpen={isOpen}
      onClose={closeDialog}
      bodyClassName={s.body}
      isCloseButtonShown={!isMobile}
    >
      {isMobile && (
        <Button
          minimal={true}
          icon={<IconMediumCross height={20} width={20} />}
          className={s.mobileCloseButton}
          onClick={closeDialog}
          type={'button'}
        />
      )}
      {renderContent()}
    </Dialog>
  );
};
