import { IntlFormatters } from 'react-intl';

import { Location } from '_api/types';
import { isPickupLocationInaccurate } from '_utils/isPickupLocationInaccurate';

import { MESSAGES } from '../../../constants';
import { FormErrors } from '../types';

const getPickupError = (
  location: null | Location,
  hasServiceOffersServerError: boolean,
  messageFormatter: IntlFormatters['formatMessage'],
): FormErrors['pickup'] => {
  if (!location) {
    return messageFormatter(MESSAGES.pickupEmpty);
  }

  if (isPickupLocationInaccurate(location)) {
    return messageFormatter(MESSAGES.locationInaccurate);
  }

  if (hasServiceOffersServerError) {
    return messageFormatter(MESSAGES.noCoverageWithoutCity);
  }

  return undefined;
};

export { getPickupError };
