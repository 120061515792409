import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider, Menu } from '@wheely/ui-kit';

import { CompanySwitcherMenu } from '_common/CompanySwitcher';
import { CustomerServiceMenu } from '_common/CompanySwitcher/CustomerServiceMenu/CustomerServiceMenu';

import s from './styles.scss';
import { MenuItems } from './hooks/useMenuItems';

type Props = {
  menuItems: MenuItems;
  profileName: string | JSX.Element;
  onMenuItemClick?: VoidFunction;
  handleSignOutClick: VoidFunction;
};

export const DesktopMenu: React.VFC<Props> = ({
  menuItems,
  profileName,
  onMenuItemClick,
  handleSignOutClick,
}) => (
  <Menu dark={true} className={s.menu} data-test-id={'footer-menu'}>
    <CompanySwitcherMenu
      profileName={profileName}
      handleSignOutClick={handleSignOutClick}
      onMenuItemClick={onMenuItemClick}
    />
    <CustomerServiceMenu />
    <div className={s.legalLinks}>
      <Divider dark={true} className={s.divider} />
      <a className={s.legalLink} href={menuItems.termsAndConditionsLink}>
        <FormattedMessage
          description="Terms & Conditions menu item text"
          defaultMessage="Terms & Conditions"
          id="gHoJxL"
        />
      </a>
      <a className={s.legalLink} href={menuItems.privacyPolicyLink}>
        <FormattedMessage
          description="Privacy Policy menu item text"
          defaultMessage="Privacy Policy"
          id="Jkw/N/"
        />
      </a>
    </div>
  </Menu>
);
