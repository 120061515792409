import React, { useCallback, useEffect, useMemo, useRef, useState, KeyboardEvent } from 'react';
import { useIntl } from 'react-intl';
import { FormGroup, FormGroupProps, Intent, Suggest, SuggestProps } from '@wheely/ui-kit';
import cn from 'clsx';

import { useTypedSelector } from '_store';
import { nearbyLocationsSelector } from '_store/newJourney';
import { DefaultSelectLoadingItem } from '_common/DefaultSelectLoadingItem';
import { DefaultSelectNoResults } from '_common/DefaultSelectNoResults';
import { CompletionZone, Location } from '_api/types';

import {
  inputValueRenderer,
  itemListPredicate,
  itemListRenderer,
  itemRenderer,
  itemsEqual,
  shouldDismissPopoverPredicate,
} from './common';
import {
  createInitialItems,
  getBackItem,
  isAddressFieldItems,
  MetaLocationSuggestItem,
} from './helpers';
import { LOCATION_LOCATIONS_KEY } from './constants';
import s from './styles.scss';

export type LocationFieldProps = Omit<FormGroupProps, 'error'> &
  Omit<
    SuggestProps<Record<string, any>>,
    'onItemSelect' | 'onQueryChange' | 'items' | 'query' | 'ref'
  > & {
    query: string;
    onQueryChange: (query: string) => void;
    completions: Record<string, any>[];
    extraInitialLocations?: (MetaLocationSuggestItem | Location)[] | null;
    onItemSelect: (item: Record<string, any> | null) => void;
    error?: string;
    hideErrorWhileOpened?: boolean;
    suggestRef?: SuggestProps<Record<string, any>>['suggestRef'];
    hideAirports?: boolean;
    zones?: CompletionZone[];
  };

// ??? move to suggest
// https://wheely.atlassian.net/browse/BUS-1204
const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};

export const LocationField = ({
  intent,
  title,
  error,
  description,
  formGroupProps,
  errorProps,
  hideErrorWhileOpened = false,
  onItemSelect,
  query,
  onQueryChange,
  completions,
  extraInitialLocations,
  inputProps,
  popoverProps = {},
  infiniteScrollProps,
  disabled,
  hideAirports = false,
  zones,
  ...restProps
}: LocationFieldProps) => {
  const intl = useIntl();
  const [items, setItems] = useState<Record<string, any>[]>([]);
  // TODO get rid of new journey connection
  const nearbyLocations = useTypedSelector(nearbyLocationsSelector);

  const [isOpen, setIsOpen] = useState(popoverProps.isOpen ?? popoverProps.defaultIsOpen ?? false);

  const initialItems: Record<string, any>[] = useMemo(
    () =>
      createInitialItems(intl.formatMessage, nearbyLocations, hideAirports, extraInitialLocations),
    [extraInitialLocations, intl.formatMessage, nearbyLocations, hideAirports],
  );

  const handleItemSelect = useCallback(
    (item: Record<string, any> | null) => {
      if (!item) {
        onItemSelect(null);
        onQueryChange('');
        setItems(initialItems);

        return;
      }

      let nestedLocations = item[LOCATION_LOCATIONS_KEY];

      const zone = zones?.find(({ title: zoneTitle }) => item.name === zoneTitle);

      const preparedZones = zone?.snapping_points.map(({ place }) => ({
        ...place,
        type: zone.type,
      }));

      if (isAddressFieldItems(preparedZones)) {
        setItems([getBackItem(intl.formatMessage, items), ...preparedZones]);

        return;
      }

      if (isAddressFieldItems(nestedLocations)) {
        const shouldPrependBackItem =
          nestedLocations !== initialItems && !nestedLocations[0].isMeta;

        if (shouldPrependBackItem) {
          nestedLocations = [getBackItem(intl.formatMessage, items), ...nestedLocations];
        }

        setItems(nestedLocations);

        return;
      }

      onItemSelect(item);
    },
    [zones, onItemSelect, onQueryChange, initialItems, intl.formatMessage, items],
  );

  const handleQueryChange = useCallback<(nextQuery: string) => void>(
    nextQuery => {
      if (nextQuery !== query) {
        onQueryChange(nextQuery);
      }
    },
    [onQueryChange, query],
  );

  const handleOpening = useCallback(
    (node: HTMLElement) => {
      if (!infiniteScrollProps?.isLoading) {
        handleQueryChange(query);
      }

      popoverProps.onOpening?.(node);
      setIsOpen(true);
    },
    [handleQueryChange, infiniteScrollProps?.isLoading, popoverProps, query],
  );
  const handleClosing = useCallback(
    (node: HTMLElement) => {
      popoverProps.onClosing?.(node);
      setIsOpen(false);
    },
    [popoverProps],
  );

  const prevInitialItems = useRef(initialItems);
  const prevItems = useRef(items);

  useEffect(() => {
    if (!query?.length) {
      if (initialItems.length && initialItems !== prevItems.current) {
        setItems(initialItems);
        prevInitialItems.current = initialItems;
        prevItems.current = initialItems;
      }
    } else {
      setItems(completions);
      prevItems.current = completions;
    }
  }, [initialItems, completions, query]);

  useEffect(() => {
    if (query) {
      onQueryChange(query);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const computedError = hideErrorWhileOpened && isOpen ? undefined : error;
  const computedIntent =
    hideErrorWhileOpened && isOpen && intent === Intent.DANGER ? Intent.NONE : intent;

  return (
    <FormGroup
      title={title}
      error={computedError}
      description={description}
      formGroupProps={formGroupProps}
      errorProps={errorProps}
    >
      <div className={s.formGroupContentWrapper} onKeyDown={handleKeyDown}>
        <Suggest<Record<string, any>>
          items={items}
          onItemSelect={handleItemSelect}
          itemListPredicate={itemListPredicate}
          itemRenderer={itemRenderer}
          itemListRenderer={itemListRenderer}
          itemsEqual={itemsEqual}
          inputValueRenderer={inputValueRenderer}
          query={query}
          onQueryChange={handleQueryChange}
          resetQueryOnClear={false}
          focusInputOnClear={false}
          selectTextOnFocus={false}
          scrollToActiveItem={false}
          disabled={disabled}
          inputProps={{
            ...inputProps,
            className: cn(s.input, inputProps?.className),
            intent: computedIntent,
          }}
          popoverProps={{
            ...popoverProps,
            modifiers: {
              offset: { enabled: false },
              ...popoverProps?.modifiers,
            },
            openOnTargetFocus: true,
            onClosing: handleClosing,
            onOpening: handleOpening,
          }}
          infiniteScrollProps={infiniteScrollProps}
          shouldDismissPopoverPredicate={shouldDismissPopoverPredicate}
          loadingElement={<DefaultSelectLoadingItem />}
          noResults={<DefaultSelectNoResults />}
          {...restProps}
        />
      </div>
    </FormGroup>
  );
};
