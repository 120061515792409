import React, { useCallback, useRef, useState } from 'react';
import cn from 'clsx';
import { useDispatch } from 'react-redux';
import { IconCheckmark, IconCornerBottom } from '@wheely/ui-kit';

import { updateUserCompany } from '_store/auth';
import { useClickOutside } from '_hooks/useClickOutside';
import { useUserCompanies } from '_hooks/user';
import { useCompany } from '_hooks/company';

import styles from './styles.scss';

export const CompanySwitcherHeader: React.FC = () => {
  const company = useCompany();
  const companiesList = useUserCompanies();
  const [isOpened, setIsOpened] = useState(false);

  const dispatch = useDispatch();

  const onCompanyActivate = useCallback(
    (companyId: string) => () => {
      setIsOpened(false);
      dispatch(updateUserCompany({ companyId }));
    },
    [dispatch],
  );

  const handleToggle = useCallback(() => {
    setIsOpened(state => !state);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpened(false);
  }, []);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, handleClose);

  if (!companiesList?.length) {
    return null;
  }

  if (companiesList?.length === 1) {
    return <span className={cn(styles.singleCompanyText)}>{company?.name}</span>;
  }

  return (
    <div className={cn(styles.root)} ref={dropdownRef}>
      <button className={cn(styles.button, styles.openButton)} onClick={handleToggle}>
        <span className={cn(styles.openButtonText)}>{company?.name}</span>
        <IconCornerBottom width={12} />
      </button>
      {isOpened && (
        <div className={cn(styles.dropdown)}>
          {companiesList?.map(({ id, name }) => (
            <button
              onClick={onCompanyActivate(id)}
              key={id}
              className={cn(styles.button, styles.dropdownButton)}
            >
              <span>{name}</span>
              {id === company?.id && <IconCheckmark width={18} />}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
