import { TimeItemListPredicate } from '@wheely/ui-kit';
import { isThisYear } from 'date-fns';

import { isValidDate } from '_utils/isValidDate';

export const timeItemListPredicate: TimeItemListPredicate = (query, items) => {
  if (!query) {
    return items;
  }

  const slicedQuery = query.slice(0, 4);

  return items.filter(({ title }) => typeof title === 'string' && title.includes(slicedQuery));
};

const DATE_FORMAT_PATTERN = 'd MMMM';
const FULL_DATE_FORMAT_PATTERN = `${DATE_FORMAT_PATTERN}, yyyy`;

export const getSubtitleFormatPattern = (value?: Date) => {
  if (isValidDate(value) && isThisYear(value)) {
    return DATE_FORMAT_PATTERN;
  }

  return FULL_DATE_FORMAT_PATTERN;
};

export const getFormattedUtcOffset = (utcOffsetStringValue: string) => {
  if (!utcOffsetStringValue) {
    return '';
  }

  return `(${utcOffsetStringValue})`;
};
