import React, { useCallback, useEffect, useState } from 'react';
import cn from 'clsx';
import { ButtonProps } from '@wheely/ui-kit';

import { AddFieldButton } from '_common/AddFieldButton';

import { OptionalFieldHeader } from './components/OptionalFieldHeader';

type Props = React.PropsWithChildren<{
  className?: string;
  title: React.ReactNode;
  openedTitle: React.ReactNode;
  buttonProps?: ButtonProps;
  closeButtonProps?: ButtonProps;
  initialIsOpen?: boolean;
  disabled?: boolean;
  canClose?: boolean;
  isOpenControlled?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}>;

const OptionalField = ({
  title,
  openedTitle,
  onOpen,
  onClose,
  children,
  buttonProps,
  closeButtonProps,
  className,
  disabled,
  initialIsOpen = false,
  canClose = true,
  isOpenControlled,
}: Props) => {
  const [isOpened, setIsOpened] = useState(initialIsOpen);

  useEffect(() => {
    if (initialIsOpen && typeof onOpen === 'function') {
      onOpen();
    }
    // если initialIsOpen === true,
    // то вызываем onOpen только после первого маунта,
    // изменения initialIsOpen и onOpen нас не волнуют
    // (initialIsOpen не должен меняться, т.к. это неконтролируемый компонент,
    // onOpen может меняться только если забыли сверху useCallback, прощаем такое)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTitleClick = useCallback<(event: React.MouseEvent<HTMLElement>) => void>(
    event => {
      setIsOpened(true);

      if (typeof onOpen === 'function') {
        onOpen();
      }

      if (typeof buttonProps?.onClick === 'function') {
        buttonProps.onClick(event);
      }
    },
    [onOpen, buttonProps],
  );
  const handleCollapseClick = useCallback<(event: React.MouseEvent<HTMLElement>) => void>(
    event => {
      setIsOpened(false);

      if (typeof onClose === 'function') {
        onClose();
      }

      if (typeof closeButtonProps?.onClick === 'function') {
        closeButtonProps.onClick(event);
      }
    },
    [onClose, closeButtonProps],
  );

  return (
    <div className={cn({ opened: isOpened }, className)}>
      {isOpened || isOpenControlled ? (
        <>
          <OptionalFieldHeader
            title={openedTitle}
            closeButtonProps={{
              ...closeButtonProps,
              disabled,
              onClick: handleCollapseClick,
            }}
            canClose={canClose}
          />
          <div>{children}</div>
        </>
      ) : (
        <AddFieldButton
          disabled={disabled}
          onClick={handleTitleClick}
          text={title}
          {...buttonProps}
        />
      )}
    </div>
  );
};

export { OptionalField };
