const NON_NUMERIC_CHARACTERS_REGEXP = /\D/g;

const getPassenger = (name: string | null, countryCode: string | null, phone: string | null) => {
  if (name !== null && phone !== null) {
    return {
      name: name,
      phone: `${countryCode}${phone.replace(NON_NUMERIC_CHARACTERS_REGEXP, '')}`,
    };
  }

  return undefined;
};

export { getPassenger };
