import React from 'react';
import { useIntl } from 'react-intl';
import { IconInfoCircled } from '@wheely/ui-kit';
import cn from 'clsx';

import { MESSAGES } from '../../constants';

import s from './styles.scss';

// query parameter to show chauffeur for a day info on the site
// this is a temp solution,
// should be removed after CFAD is enabled everywhere
const W4B_URL_MARKER = 'from-w4b=true';

const ItemInfoLink: React.FC<{
  url?: null | string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}> = ({ url, onClick, className }) => {
  const intl = useIntl();

  if (!url) {
    return null;
  }

  const urlWithMarker = `${url}${url.includes('?') ? '&' : '?'}${W4B_URL_MARKER}`;

  return (
    <a
      href={urlWithMarker}
      className={cn(s.itemInfoLink, className)}
      target="_blank"
      rel="noreferrer noopener"
      title={intl.formatMessage(MESSAGES.aboutService)}
      tabIndex={-1}
      onClick={onClick}
      data-test-id="service-level-item-info-link"
    >
      <IconInfoCircled className={s.itemInfoLinkIcon} />
    </a>
  );
};

export { ItemInfoLink };
