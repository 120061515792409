const getLocationName = (location: Record<string, any>, useShortFormat = false): string => {
  const shortFormat =
    location.name ?? location.line1 ?? location.line2 ?? location.line2_without_city;

  if (useShortFormat) {
    return shortFormat;
  }

  const fullAddress = [location.line2, location.line1].filter(Boolean).join(', ');

  if (!fullAddress.length) {
    return shortFormat;
  }

  return fullAddress;
};

const extractLocation = (data: any): any => {
  const [locations] = data;
  const {
    locations: [location],
  } = locations;

  return location;
};

const getLocationNameShortName = (data: any): string => {
  const location = extractLocation(data);

  return getLocationName(location, true);
};

export { getLocationName, getLocationNameShortName, extractLocation };
