import {
  format as _format,
  formatDistanceToNowStrict as _formatDistanceToNowStrict,
  parse as _parse,
  Locale,
} from 'date-fns';
import { useCallback } from 'react';

import { FirstWeekContainsDate, WeekStartsOn } from '_i18n/types';

import { useLocale } from './useLocale';

type FormatOptions = {
  locale?: Locale;
  weekStartsOn?: WeekStartsOn;
  firstWeekContainsDate?: FirstWeekContainsDate;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
};

const useLocaleDate = () => {
  const { locale } = useLocale();

  const format = useCallback<
    (date: Date, formatPattern?: string, options?: FormatOptions) => string
  >(
    (date: Date, formatPattern = 'PP', options?: FormatOptions) =>
      _format(date, formatPattern, {
        locale,
        weekStartsOn: locale?.options?.weekStartsOn || 0,
        ...options,
      }),
    [locale],
  );

  const formatDistanceToNowStrict = useCallback(
    (
      date: Date,
      options?: {
        addSuffix?: boolean;
        unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
        roundingMethod?: 'floor' | 'ceil' | 'round';
        locale?: Locale;
      },
    ) =>
      _formatDistanceToNowStrict(date, {
        locale,
        ...options,
      }),
    [locale],
  );

  const parse = useCallback(
    (
      dateString: string,
      formatPattern: string,
      referenceDate: Date | number = new Date(),
      options?: {
        locale?: Locale;
        weekStartsOn?: WeekStartsOn;
        firstWeekContainsDate?: FirstWeekContainsDate;
        useAdditionalWeekYearTokens?: boolean;
        useAdditionalDayOfYearTokens?: boolean;
      },
    ) =>
      _parse(dateString, formatPattern, referenceDate, {
        locale,
        weekStartsOn: locale?.options?.weekStartsOn || 0,
        ...options,
      }),
    [locale],
  );

  return { format, formatDistanceToNowStrict, parse };
};

export { useLocaleDate };
