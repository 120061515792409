import React from 'react';
import { Separator } from '@wheely/web-ui';

import { WithChildren } from '_types/helpers';

import { Footer } from './Footer';
import { Header } from './Header';
import styles from './styles.scss';

type Props = {
  rightHeaderContent?: React.ReactNode;
  leftHeaderContent?: React.ReactNode;
};

export const PublicLayout = ({
  children,
  rightHeaderContent,
  leftHeaderContent,
}: WithChildren<Props>) => (
  <div className={styles.scrollWrapper}>
    <div className={styles.root}>
      <Header rightContent={rightHeaderContent} leftContent={leftHeaderContent} />
      <Separator className={styles.headingSeparator} />
      <div className={styles.content}>{children}</div>
      <Separator className={styles.headingSeparator} />
      <Footer />
    </div>
  </div>
);
