import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useQuery } from '_hooks/useQuery';
import { ErrorShape } from '_api/types';
import { Update3dsCardParams } from '_modules/Auth/types';

export const use3dsResultHandler = ({
  onUpdate3dsCard,
  update3dsCardError,
  reset3dsQueryParamsAfterUpdate = true,
}: {
  onUpdate3dsCard: (params: Update3dsCardParams) => Promise<void>;
  update3dsCardError: ErrorShape | null;
  reset3dsQueryParamsAfterUpdate?: boolean;
}): {
  isUpdate3dsCardLoading: boolean;
  is3dsFailed: boolean;
} => {
  const { location, replace } = useHistory();

  const [isUpdate3dsCardLoading, setIsUpdate3dsCardLoading] = useState(false);
  const [is3dsFailed, setIs3dsFailed] = useState(false);

  const query = useQuery();

  const query3dsSuccess = query.get('3ds_success');
  const paymentMethodId = query.get('payment_method_id');
  const sessionId = query.get('cko-session-id') || query.get('session_id');

  const is3dsSuccessful = query3dsSuccess === 'true';

  const reset3dsQueryParams = useCallback(() => {
    if (!reset3dsQueryParamsAfterUpdate) {
      return;
    }

    const { search, ...state } = (location.state || {}) as Record<string, unknown>;

    replace(location.pathname, state);
  }, [reset3dsQueryParamsAfterUpdate, location.state, location.pathname, replace]);

  useEffect(() => {
    if (query3dsSuccess === 'false') {
      setIs3dsFailed(true);
      reset3dsQueryParams();
    }
  }, [query3dsSuccess, reset3dsQueryParams]);

  useEffect(() => {
    const shouldUpdate3dsCard =
      !update3dsCardError &&
      !isUpdate3dsCardLoading &&
      is3dsSuccessful &&
      sessionId &&
      paymentMethodId;

    if (shouldUpdate3dsCard) {
      setIsUpdate3dsCardLoading(true);

      onUpdate3dsCard({ paymentMethodId, sessionId })
        .catch(() => {})
        .finally(() => {
          reset3dsQueryParams();
          setIsUpdate3dsCardLoading(false);
        });
    }
  }, [
    sessionId,
    is3dsSuccessful,
    isUpdate3dsCardLoading,
    paymentMethodId,
    reset3dsQueryParams,
    update3dsCardError,
    onUpdate3dsCard,
  ]);

  return { isUpdate3dsCardLoading, is3dsFailed };
};
