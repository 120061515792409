import { CountryCodeId } from '_types/common';

const getCountryCodeId = (source: string = navigator.language.slice(0, 2)): CountryCodeId => {
  switch (source) {
    case 'fr':
      return 'fr';
    case 'en':
      return 'uk';
    case 'ru':
      return 'ru';
    default:
      return 'other';
  }
};

export type { CountryCodeId };
export { getCountryCodeId };
