import { IntlFormatters } from 'react-intl';
import { isBefore } from 'date-fns';

import { formatNearestReservationTime } from '_utils/formatNearestReservationTime';

import { MESSAGES } from '../../../constants';
import { FormErrors } from '../types';

const getPickupAtError = (
  pickupAt: null | Date,
  earliestReservationDate: Date,
  messageFormatter: IntlFormatters['formatMessage'],
): FormErrors['pickup'] => {
  let error;

  if (pickupAt === null) {
    return error;
  }

  if (!pickupAt) {
    error = messageFormatter(MESSAGES.pickupTimeInvalid);
  } else if (isBefore(pickupAt, earliestReservationDate)) {
    error = messageFormatter(MESSAGES.nearestAvailableTime, {
      formattedTime: formatNearestReservationTime(earliestReservationDate),
    });
  }

  return error;
};

export { getPickupAtError };
