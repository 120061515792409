import React from 'react';
import { FieldInputProps } from 'formik';
import { Text } from '@wheely/ui-kit';
import { useIntl } from 'react-intl';

import styles from './styles.scss';
import { MeetAndGreetAirportWithBoard } from './components/MeetAndGreetAirportWithBoard';

interface Props {
  disabled?: boolean;
  welcomeBoardField: FieldInputProps<unknown>;
}

export const MeetAndGreetAirport = ({ welcomeBoardField, disabled }: Props) => {
  const intl = useIntl();

  return welcomeBoardField.value ? (
    <MeetAndGreetAirportWithBoard disabled={disabled} />
  ) : (
    <Text className={styles.text} tagName="p" muted={true} withoutPadding={true}>
      {intl.formatMessage({
        defaultMessage:
          'If you deactivate the meet and greet option the driver will call you after your plane has landed to confirm the pick-up location. Parking fees could be added to the final cost.',
        id: 'CyzcYg',
      })}
    </Text>
  );
};
