import { useEffect } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { reloadAfterCompanySwitch } from '_utils/reloadAfterCompanySwitch';
import { Company, CompanyShort } from '_api/types';

const notifyAboutCompanySwitchAndReload = (intl: IntlShape) => {
  alert(
    intl.formatMessage({
      description: 'Company switching alert when company was switched from another tab/window',
      defaultMessage: 'The active company has been changed. The page will now be reloaded.',
      id: '1h9t/2',
    }),
  );

  reloadAfterCompanySwitch();
};

export const useCompanySwitched = (
  prevCompany?: Company,
  company?: Company,
  userLinkedCompany?: CompanyShort,
) => {
  const intl = useIntl();

  useEffect(() => {
    if (
      !company &&
      userLinkedCompany?.id &&
      prevCompany?.id &&
      userLinkedCompany.id !== prevCompany.id
    ) {
      notifyAboutCompanySwitchAndReload(intl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLinkedCompany?.id, intl]);

  useEffect(() => {
    const handleStore = (event: StorageEvent) => {
      if (event.key === 'companyId') {
        notifyAboutCompanySwitchAndReload(intl);
      }
    };

    window.addEventListener('storage', handleStore);

    return () => {
      window.removeEventListener('storage', handleStore);
    };
  }, [intl]);
};
