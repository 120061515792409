import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormikProps } from 'formik';

import { useTypedSelector } from '_store';
import {
  isLoadingSelector as isNewJourneyLoadingSelector,
  isPrebookModeSelector,
  pickupLocationSelector,
  resetServerError,
  resetState,
  serverErrorSelector as newJourneyServerErrorSelector,
} from '_store/newJourney';
import { useCompanyType } from '_hooks';

import { FormContent } from './FormContent';
import { FormValues } from './types';
import s from './styles.scss';

export const Form = (formikProps: FormikProps<FormValues>) => {
  const dispatch = useDispatch();
  const { isHospitalityType } = useCompanyType();
  const isNewJourneyLoading = useTypedSelector(isNewJourneyLoadingSelector);
  const serverError = useTypedSelector(newJourneyServerErrorSelector);
  const pickupLocation = useTypedSelector(pickupLocationSelector);
  const isPrebookMode = useTypedSelector(isPrebookModeSelector);

  useEffect(
    () => () => {
      dispatch(resetState());
    },
    [dispatch],
  );

  const clearServerError = useCallback(() => {
    dispatch(resetServerError());
  }, [dispatch]);

  return (
    <div className={s.form}>
      <FormContent
        {...formikProps}
        pickupLocation={pickupLocation}
        serverError={serverError}
        isLoading={isNewJourneyLoading}
        clearServerError={clearServerError}
        isPrebookMode={isPrebookMode}
        isHospitalityType={isHospitalityType}
      />
    </div>
  );
};
