import { useContext } from 'react';

import { IntlContext } from '../app/IntlProvider';

import { DATE_LOCALES } from './constants';

const useLocale = () => {
  const { currentLanguage: langCode, switchLocale } = useContext(IntlContext);
  const locale = DATE_LOCALES[langCode];

  return { locale, langCode, switchLocale };
};

export { useLocale };
