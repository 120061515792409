import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string): boolean => {
  const [isMatches, setMatches] = useState(window.matchMedia(query).matches);

  const handleChange = (event: MediaQueryListEvent) => setMatches(event.matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    if (typeof mediaQueryList.addEventListener === 'function') {
      mediaQueryList.addEventListener('change', handleChange);
    } else {
      mediaQueryList.addListener(handleChange);
    }

    return () => {
      if (typeof mediaQueryList.removeEventListener === 'function') {
        mediaQueryList.removeEventListener('change', handleChange);
      } else {
        mediaQueryList.removeListener(handleChange);
      }
    };
  }, [query]);

  return isMatches;
};
