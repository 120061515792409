import { Route } from 'react-router';
import React from 'react';

import { InvitesRouter } from '_modules/Invites/router';
import { invitesRoutes, remindersRoutes } from '_routes';
import { RemindersRouter } from '_modules/Reminders/router';

export const PublicRouter = [
  <Route
    path={invitesRoutes.invitesBaseRoute}
    key={invitesRoutes.invitesBaseRoute}
    component={InvitesRouter}
  />,
  <Route
    path={remindersRoutes.reminderRoute}
    key={remindersRoutes.reminderRoute}
    component={RemindersRouter}
  />,
];
