import React from 'react';
import { Redirect, Switch } from 'react-router';

import { reportsRoutes } from '_routes';
import { lazyRetry } from '_utils/lazyRetry';
import { PrivateRoute } from '_common/PrivateRoute';
import { featureFlags } from '_utils/featureFlags';
import { useCompanyType } from '_hooks';
import { useCompany } from '_hooks/company';

const EmployeesPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "EmployeesPage" */ './EmployeesPage/EmployeesPage'),
    'EmployeesPage',
  ),
);

const DepartmentsPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "DepartmentsPage" */ './DepartmentsPage/DepartmentsPage'),
    'DepartmentsPage',
  ),
);

const AnalyticsPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "AnalyticsPage" */ './AnalyticsPage/AnalyticsPage'),
    'AnalyticsPage',
  ),
);

export const RerportsRouter = () => {
  const { isCorporateType } = useCompanyType();
  const companyId = useCompany()?.id || null;

  return (
    <Switch>
      <PrivateRoute
        permission="reports:employeesTab:read"
        path={reportsRoutes.reportsEmployees}
        component={EmployeesPage}
        exact={true}
      />

      {isCorporateType && (
        <PrivateRoute
          permission="reports:departmentsTab:read"
          path={reportsRoutes.reportsDepartments}
          component={DepartmentsPage}
          exact={true}
        />
      )}

      {featureFlags.isMetabaseEnabled(companyId) && (
        <PrivateRoute
          permission="reports:analyticsTab:read"
          path={reportsRoutes.reportsAnalytics}
          exact={true}
          component={AnalyticsPage}
        />
      )}

      <Redirect to={reportsRoutes.reportsEmployees} />
    </Switch>
  );
};
