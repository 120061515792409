import { isAirport } from '_utils/isAirport';
import { isRailwayHub } from '_utils/isRailwayHub';
import { LocationSubtype, LocationType, PickupDetails } from '_api/types';

import { FormValues } from '../types';

type Params = Partial<
  {
    locationType: LocationType;
    locationSubType: LocationSubtype;
    flightTrackingId: string;
  } & Pick<
    FormValues,
    | 'welcomeBoard'
    | 'welcomeBoardText'
    | 'flightNumber'
    | 'trainNumber'
    | 'carriageNumber'
    | 'origin'
    | 'arrivalTime'
  >
>;

const getPickupDetails = ({
  locationType,
  locationSubType,
  welcomeBoard,
  welcomeBoardText,
  flightNumber,
  flightTrackingId,
  trainNumber,
  carriageNumber,
  origin,
  arrivalTime,
}: Params): PickupDetails | undefined => {
  if (isAirport(locationType)) {
    return {
      welcome_board: welcomeBoard,
      welcome_board_text: welcomeBoard ? welcomeBoardText : undefined,
      flight_number: flightNumber?.trim().toUpperCase(),
      tracking_id: flightTrackingId,
    };
  }

  if (isRailwayHub(locationType)) {
    const isOriginSubType = locationSubType === 'origin';

    return {
      welcome_board: welcomeBoard,
      welcome_board_text: welcomeBoard ? welcomeBoardText : undefined,
      ...(isOriginSubType
        ? {
            origin: welcomeBoard ? origin : undefined,
            arrival_time: welcomeBoard ? arrivalTime : undefined,
          }
        : {
            train_number: welcomeBoard ? trainNumber : undefined,
            carriage_number: welcomeBoard ? carriageNumber : undefined,
          }),
    };
  }

  return undefined;
};

export { getPickupDetails };
