import { call, put, takeLatest } from 'redux-saga/effects';

import { apiClient } from '_api';
import { UpdateUserCompanyResponse } from '_api/types';
import { updateUserCompany, updateUserFailed } from '_store/auth';
import { handleApiError } from '_utils/handleApiError';
import { setIsPageLoading } from '_store/ui';
import { reloadAfterCompanySwitch } from '_utils/reloadAfterCompanySwitch';

export function* switchUserCompany(action: ReturnType<typeof updateUserCompany>) {
  try {
    yield put(setIsPageLoading(true));

    const { user }: UpdateUserCompanyResponse = yield call(apiClient.updateUserCompany, {
      company_id: action.payload.companyId,
    });

    yield window.localStorage.setItem('companyId', user.current_company_id);

    yield reloadAfterCompanySwitch();
  } catch (error) {
    yield put(updateUserFailed({ error: handleApiError(error) }));
  }
}

export function* switchCompanyFlowSaga() {
  yield takeLatest(updateUserCompany, switchUserCompany);
}
