import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '_store';
import { mapModeSelector, restoreViewMode } from '_store/mapInteraction';

export const useCleanupMapState = () => {
  const dispatch = useDispatch();
  const mapMode = useTypedSelector(mapModeSelector);

  useEffect(
    () => () => {
      if (mapMode === 'edit') {
        dispatch(restoreViewMode());
      }
    },
    [dispatch, mapMode],
  );
};
