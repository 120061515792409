export const pickNonEmpty = <TValue>(object: TValue): Partial<TValue> => {
  const collected: Partial<TValue> = {};

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key) && Boolean(object[key])) {
      collected[key] = object[key];
    }
  }

  return collected;
};
