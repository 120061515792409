const lazyRetry = <TComponent>(
  componentImport: () => Promise<TComponent>,
  chunkName: string,
): Promise<TComponent> => {
  const SESSION_STORAGE_KEY = `retry-${chunkName}-refreshed`;

  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_KEY) || 'false');

    componentImport()
      .then(component => {
        window.sessionStorage.setItem(SESSION_STORAGE_KEY, 'false');
        resolve(component);
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');

          return window.location.reload();
        }

        reject(error);
      });
  });
};

export { lazyRetry };
