import {
  newTracker,
  trackPageView,
  setUserId,
  addGlobalContexts,
  trackSelfDescribingEvent,
} from '@snowplow/browser-tracker';
import { TimezonePlugin } from '@snowplow/browser-plugin-timezone';
import { DebuggerPlugin } from '@snowplow/browser-plugin-debugger';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { SNOWPLOW_PLATFORM_TYPES } from '_constants';

import { useUserId } from './user';
import { useCompany } from './company';
import { useDevice } from './useDevice';

const JSON_SCHEMA = 'iglu:com.snowplowanalytics.snowplow/web_page/jsonschema/1-0-0';

const sendSnowplowEvent = (options: {
  event: string;
  event_group?: string;
  properties?: Record<string, any>;
}) => {
  try {
    trackSelfDescribingEvent({
      event: {
        schema: JSON_SCHEMA,
        data: options,
      },
    });
    // eslint-disable-next-line no-empty
  } catch {}
};

const useSnowplow = () => {
  const { pathname } = useLocation();
  const userId = useUserId();
  const companyId = useCompany()?.id ?? null;
  const { isPhone } = useDevice();

  useEffect(() => {
    if (window.b2bAppConfig.SNOWPLOW_COLLECTOR_URL) {
      newTracker('wheely_b2b', window.b2bAppConfig.SNOWPLOW_COLLECTOR_URL, {
        appId: window.b2bAppConfig.APP_ID,
        platform: 'web',
        withCredentials: false,
        discoverRootDomain: true,
        cookieName: 'x_wheely_sp_',
        encodeBase64: false,
        respectDoNotTrack: true,
        eventMethod: 'post',
        postPath: '',
        contexts: {
          webPage: true,
        },
        plugins: [TimezonePlugin(), DebuggerPlugin()],
      });
    }
  }, []);

  useEffect(() => {
    addGlobalContexts([
      {
        schema: JSON_SCHEMA,
        data: {
          company_id: companyId ?? '',
          ua: window.navigator.userAgent,
          platform_type: isPhone ? SNOWPLOW_PLATFORM_TYPES.MOBILE : SNOWPLOW_PLATFORM_TYPES.DESKTOP,
        },
      },
    ]);
  }, [companyId, isPhone]);

  useEffect(() => {
    setUserId(userId);
  }, [userId]);

  useEffect(() => {
    trackPageView();
  }, [pathname]);
};

export { useSnowplow, JSON_SCHEMA, sendSnowplowEvent };
