import { roundDateUpTo } from '_utils/roundDateUpTo';

import { isFiniteNumber } from './isFiniteNumber';
import { isValidDate } from './isValidDate';

export const getEarliestReservationDate = (
  serviceOfferEta: null | number,
  serviceUtcOffset: null | number,
  fallbackDate?: Date,
) => {
  const date = isValidDate(fallbackDate) ? fallbackDate : new Date();

  if (serviceOfferEta) {
    const currentZoneUtcOffset = -date.getTimezoneOffset() * 60 * 1000;
    const utcOffset = isFiniteNumber(serviceUtcOffset)
      ? serviceUtcOffset * 1000
      : currentZoneUtcOffset;

    const earlestReservationDate = new Date(
      Date.now() - currentZoneUtcOffset + utcOffset + serviceOfferEta * 1000,
    );

    return roundDateUpTo(5, earlestReservationDate);
  }

  return date;
};
