import React from 'react';
import { Body, Heading, LinkButton } from '@wheely/web-ui';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';

import { useLocale } from '_i18n/useLocale';
import { AppIcon } from '_common/icons/AppIcon/AppIcon';

import styles from './styles.scss';

export type InviteGrantedAccessProps = {
  maskedPhone: string;
  companyName: string;
};

export const Invited = ({ maskedPhone, companyName }: InviteGrantedAccessProps) => {
  const { langCode } = useLocale();

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.titleBlock}>
          <Heading size="L" className={styles.title}>
            <FormattedMessage
              defaultMessage="Join Your Corporate Account"
              id="C6DvvM"
              description="Invited title"
            />
          </Heading>
          <Body className={styles.lead}>
            <FormattedMessage
              defaultMessage="{companyName} invited you to use your corporate payment method for Wheely business journeys."
              id="h2pHhA"
              description="Invited lead"
              values={{
                companyName: companyName.replaceAll(' ', ' '),
              }}
            />
          </Body>
          <div className={styles.appLink}>
            <LinkButton as="a" href="https://whee.ly/invite">
              <FormattedMessage
                defaultMessage="Continue in the app"
                id="lQ/s4D"
                description="App link"
              />
              <AppIcon className={styles.appIcon} />
            </LinkButton>
          </div>
        </div>
        <Heading size="M" className={styles.heading}>
          <FormattedMessage
            defaultMessage="Wheely App"
            id="bQEdKA"
            description="Wheely app description heading"
          />
        </Heading>
        <Body className={styles.text}>
          <FormattedMessage
            defaultMessage="To begin using your corporate payment method, sign in to the {appLink} with your phone number {maskedPhone}. Select {companyName} as a payment method when booking a journey."
            id="ss31sO"
            description="Wheely app description"
            values={{
              appLink: <a href="https://whee.ly/invite">Wheely app</a>,
              maskedPhone: maskedPhone.replaceAll(' ', ' '),
              companyName: companyName.replaceAll(' ', ' '),
            }}
          />
        </Body>
        <div className={cx(styles.image, styles[langCode])} data-test-id="invite_invited_gif" />
      </div>
    </div>
  );
};
