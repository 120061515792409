import React from 'react';
import { H3, H5 } from '@wheely/ui-kit';
import { FormattedMessage } from 'react-intl';
import { Button } from '@wheely/web-ui';
import { useHistory } from 'react-router';

import { BasicLayout } from '_layouts/Basic';
import { useUser } from '_hooks/user';
import { useGetCompany } from '_queries/company/useGetCompany';
import { employeesRoutes } from '_routes';

import styles from './styles.scss';

export const WelcomeCompanyPage = () => {
  const user = useUser();
  const history = useHistory();

  const { data: company } = useGetCompany(user?.company?.id, true, true);
  const isCompanyActive = company?.status === 'active';

  const onInvitePeopleClick = () => {
    if (isCompanyActive) {
      history.push(employeesRoutes.employeesBase);
    }
  };

  return (
    <BasicLayout>
      <div className={styles.contentWrapper}>
        <div className={styles.contentRoot}>
          <H3 className={styles.title}>
            <FormattedMessage
              defaultMessage="Welcome To Your Corporate Account"
              id="welcome_title"
              description="Welcome company title"
            />
          </H3>
          <H5 className={styles.description}>
            <FormattedMessage
              defaultMessage="Your corporate account is set up and ready to use. You can start by inviting more people to use your corporate payment method for Wheely business journeys."
              id="welcome_text"
              description="Welcome company description"
            />
          </H5>
          <div className={styles.buttonContainer}>
            <Button
              data-test-id="invite_people_button"
              disabled={!isCompanyActive}
              isLoading={!isCompanyActive}
              onClick={onInvitePeopleClick}
              className={styles.inviteButton}
            >
              <FormattedMessage
                defaultMessage="Invite People"
                id="welcome_invite_button"
                description="Invite people button on welcome company page"
              />
            </Button>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};
