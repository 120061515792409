import React from 'react';

import { ErrorState } from '_common/ErrorState';
import { BasicLayout } from '_layouts/Basic';

type Props<P extends Record<string, unknown>> = {
  isError: boolean;
  children: React.ReactElement;
  className?: string;
  layout?: React.FC<P>;
  layoutProps?: Omit<P, 'children'>;
};

export const ErrorWrapper = <P extends Record<string, unknown>>({
  isError,
  layout: Layout = BasicLayout,
  children,
  className,
  layoutProps = {} as Props<P>['layoutProps'],
}: Props<P>) => {
  if (isError) {
    return (
      <Layout {...(layoutProps as P)}>
        <ErrorState className={className} />
      </Layout>
    );
  }

  return children;
};
