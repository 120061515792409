import { useEffect } from 'react';
import { Classes } from '@wheely/ui-kit';

const useBodyScrollBlocking = () => {
  useEffect(() => {
    document.body.classList.add(Classes.OVERLAY_OPEN);

    return () => {
      document.body.classList.remove(Classes.OVERLAY_OPEN);
    };
  }, []);
};

export { useBodyScrollBlocking };
