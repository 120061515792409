import React from 'react';
import { TextField } from '@wheely/ui-kit';
import { useField } from 'formik';
import { useIntl } from 'react-intl';

import { getFieldErrorMessage } from '_pages/NewJourney/components/Form/utils/getFieldErrorMessage';

import { MeetAndGreetByTrainNumber } from './MeetAndGreetByTrainNumber';
import { MeetAndGreetByOrigin } from './MeetAndGreetByOrigin';

interface Props {
  disabled?: boolean;
  isOriginPickupSubType?: boolean;
}

export const MeetAndGreetRailwayHubWithBoardText = ({ disabled, isOriginPickupSubType }: Props) => {
  const intl = useIntl();
  const [welcomeBoardTextField, welcomeBoardTextMeta] = useField('welcomeBoardText');
  const MeetInfo = isOriginPickupSubType ? MeetAndGreetByOrigin : MeetAndGreetByTrainNumber;

  return (
    <>
      <TextField
        {...welcomeBoardTextField}
        disabled={disabled}
        placeholder={intl.formatMessage({
          description: 'Board text placeholder',
          defaultMessage: 'Name board text',
          id: 'ayREHY',
        })}
        error={getFieldErrorMessage(welcomeBoardTextMeta)}
        data-test-id="welcome-board-field"
        errorProps={{
          'data-test-id': 'welcome-board-error',
        }}
      />
      <MeetInfo disabled={disabled} />
    </>
  );
};
