import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MaybeSession } from '@wheely/web-auth-lib/dist/common/session';

import { authAPI } from '_api/auth';
import { logout } from '_utils/logout';
import { useTypedSelector } from '_store';
import { isLoggedInSelector, setIsLoggedIn } from '_store/auth';
import { setAllowUserLoad } from '_store/ui';

export const useSessionUpdate = (enabled: boolean) => {
  const oldIsLoggedIn = useTypedSelector(isLoggedInSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const handleLogout = (newSession: MaybeSession) => {
      const newIsLoggedIn = Boolean(newSession?.activated);

      if (!newIsLoggedIn) {
        logout(dispatch);
      }
    };

    const handleLogin = (newSession: MaybeSession) => {
      const newIsLoggedIn = Boolean(newSession?.activated);

      if (newIsLoggedIn && oldIsLoggedIn !== newIsLoggedIn) {
        dispatch(setIsLoggedIn({ isLoggedIn: true }));
        dispatch(setAllowUserLoad(true));
      }
    };

    const authUpdateCallback = oldIsLoggedIn ? handleLogout : handleLogin;

    const removeHandler = authAPI.onAuthStateUpdated(authUpdateCallback);

    return removeHandler;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldIsLoggedIn, enabled]);
};
