import React, { useCallback, useEffect, useState } from 'react';
import cn from 'clsx';
import { Classes } from '@wheely/ui-kit';

import { useCompany } from '_hooks/company';

import { Navigation } from './components/Navigation';
import s from './styles.scss';

type Props = React.PropsWithChildren<{
  className?: string;
  contentClassName?: string;
}>;

export const BasicLayout = ({ children, className, contentClassName }: Props) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const companyStatus = useCompany()?.status || null;
  const navMenuDisabled = companyStatus === 'pending';

  const handleToggleOpen = useCallback<() => void>(() => {
    setIsMenuOpened(!isMenuOpened);
  }, [isMenuOpened]);

  useEffect(() => {
    if (isMenuOpened) {
      document.body.classList.add(Classes.OVERLAY_OPEN);
    } else {
      document.body.classList.remove(Classes.OVERLAY_OPEN);
    }
  }, [isMenuOpened]);

  return (
    <div className={cn(className, s.basicLayout)} id="root-layout">
      <Navigation
        isMenuOpened={isMenuOpened}
        onToggleOpen={handleToggleOpen}
        disabled={navMenuDisabled}
      />
      <main className={cn(contentClassName, s.content)}>{children}</main>
    </div>
  );
};
