import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'clsx';
import { IconLogo } from '@wheely/ui-kit';

import s from './styles.scss';

type Props = {
  className?: string;
};

const LogoLink: React.VFC<Props> = ({ className }) => (
  <Link to="/" className={cn(s.logoLink, className)}>
    <IconLogo className={s.icon} />
  </Link>
);

export { LogoLink };
