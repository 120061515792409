import React from 'react';
import { highlightText, ItemRenderer, SelectMenuItem, TimePickerItem } from '@wheely/ui-kit';

export const timePickerItemRenderer: ItemRenderer<TimePickerItem> = (
  item,
  { handleClick, modifiers, index, query },
  selectedItem,
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  const { value, title } = item;
  const isSelected = selectedItem?.value === value;

  // Highlight only first 4 symbols of query
  // to keep highlighting of matched items with query like "18:02"
  const slicedQuery = query.slice(0, 4);
  const text = <>{highlightText(title, slicedQuery)}</>;

  return (
    <SelectMenuItem
      key={value || `item-${index}`}
      text={text}
      active={!isSelected ? modifiers.active : false}
      onClick={handleClick}
      selected={isSelected}
    />
  );
};
