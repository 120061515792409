import { FormErrors, FormTouched } from '../types';

const getErrorMessageByFieldName = <T extends FormErrors, K extends keyof FormTouched>(
  name: K,
  errors: T,
  touched: FormTouched,
  submitCount?: number,
): T[K] | undefined => {
  if (touched[name] || submitCount) {
    return errors[name];
  }

  return undefined;
};

export { getErrorMessageByFieldName };
