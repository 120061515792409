import React from 'react';
import { Route, Switch } from 'react-router';

import { NotFound } from '_pages/NotFound';
import { commonRoutes, invitesRoutes } from '_routes';

import { AcceptInvite } from './pages/accept';
import { GrantedAccess } from './pages/granted-access';
import { Invited } from './pages/invited';

export const InvitesRouter = () => (
  <Switch>
    <Route path={invitesRoutes.invitesInviteAcceptRoute} component={AcceptInvite} exact={true} />
    <Route
      path={invitesRoutes.invitesEmployeeGrantedAccessRoute}
      component={GrantedAccess}
      exact={true}
    />
    <Route path={invitesRoutes.invitesEmployeeInvitedRoute} component={Invited} exact={true} />
    <Route component={NotFound} path={[commonRoutes.notFound, '*']} />
  </Switch>
);
