import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ItemListRenderer,
  ItemRenderer,
  SelectMenu,
  SelectMenuItem,
  SuggestField,
} from '@wheely/ui-kit';

import { useTypedSelector } from '_store';
import { DefaultSelectLoadingItem } from '_common/DefaultSelectLoadingItem';
import { DefaultSelectNoResults } from '_common/DefaultSelectNoResults';
import { OptionalField } from '_common/OptionalField';

import s from './styles.scss';

type ReferenceItemType = {
  title: string;
  value: string;
};

const maxCharsCount = 500;

const itemListRenderer: ItemListRenderer<ReferenceItemType> = (
  listProps,
  initialContent,
  noResults,
  infiniteScrollProps,
  loadingElement,
) => {
  const { itemsParentRef, renderItem, query, filteredItems } = listProps;
  const { isLoading } = infiniteScrollProps ?? {};

  let menuContent = null;

  if (isLoading) {
    menuContent = loadingElement;
  } else if (
    filteredItems.length > 1 ||
    (filteredItems.length === 1 &&
      filteredItems[0].title.trim().toLowerCase() !== query.trim().toLowerCase())
  ) {
    menuContent = filteredItems.map(renderItem).filter(Boolean);
  }

  if (menuContent) {
    return (
      <SelectMenu
        className={s.menu}
        itemsParentRef={itemsParentRef}
        infiniteScrollProps={infiniteScrollProps}
      >
        {menuContent}
      </SelectMenu>
    );
  }

  return null;
};

const itemRenderer: ItemRenderer<ReferenceItemType> = (item, { handleClick, modifiers, index }) => (
  <SelectMenuItem
    shouldDismissPopover={true}
    key={`item-${item.title}-${index}`}
    active={modifiers.active}
    text={item.title}
    onClick={handleClick}
    data-test-id="reference-field-menu-item"
  />
);

type Props = {
  value: string;
  disabled?: boolean;
  initialIsOpen: boolean;
  error?: string;
  onClose?: () => void;
  onValueChange: (value: string) => void;
};

export const ReferenceField = ({
  value,
  disabled,
  initialIsOpen,
  error,
  onClose,
  onValueChange,
}: Props) => {
  const intl = useIntl();
  const recentReferences = useTypedSelector(state => state.newJourney.references);

  const items: ReferenceItemType[] = useMemo(
    () =>
      recentReferences.map((item, index) => ({
        title: item.value,
        value: `${item.value}-${index}`,
      })),
    [recentReferences],
  );

  const handleFieldClose = useCallback(() => {
    if (onClose) {
      onClose();
    }

    onValueChange('');
  }, [onValueChange, onClose]);

  const handleItemSelect = useCallback<
    (item: ReferenceItemType | null, event?: React.SyntheticEvent<HTMLElement>) => void
  >(
    item => {
      if (item?.title?.length) {
        onValueChange(item.title);
      }
    },
    [onValueChange],
  );

  const handleQueryChange = useCallback(
    (query: string) => {
      onValueChange(query);
    },
    [onValueChange],
  );

  const currentCharsCount = Math.max(maxCharsCount - value.length, 0);

  const inputProps = {
    placeholder: intl.formatMessage({
      description: 'New journey reference placeholder',
      defaultMessage: 'Enter a reference',
      id: 'nYl3X9',
    }),
    maxLength: maxCharsCount,
    'data-test-id': 'reference-field-input',
  };

  const [isOpenControlled, setIsOpenControlled] = useState(false);

  useEffect(() => {
    if (error) {
      setIsOpenControlled(true);
    }
  }, [error]);

  return (
    <OptionalField
      title={intl.formatMessage({
        description: 'New journey add reference button title',
        defaultMessage: 'Add reference',
        id: '904eLf',
      })}
      openedTitle={intl.formatMessage({
        description: 'New journey reference field title',
        defaultMessage: 'Reference',
        id: 'xujOYQ',
      })}
      onClose={handleFieldClose}
      disabled={disabled}
      initialIsOpen={initialIsOpen}
      buttonProps={{
        'data-test-id': 'add-reference-button',
      }}
      isOpenControlled={isOpenControlled}
      canClose={!error && !isOpenControlled}
    >
      <SuggestField<ReferenceItemType>
        description={intl.formatMessage(
          {
            description: 'New journey reference field description',
            defaultMessage:
              'This reference will be shown in the receipt and invoice{br}Characters left: {currentCharsCount} of {maxCharsCount}',
            id: '8dpx/8',
          },
          {
            br: <br />,
            maxCharsCount,
            currentCharsCount,
          },
        )}
        descriptionProps={{
          'data-test-id': 'reference-field-description',
        }}
        selectedItem={null}
        onQueryChange={handleQueryChange}
        query={value}
        onItemSelect={handleItemSelect}
        items={items}
        inputProps={inputProps}
        itemListRenderer={itemListRenderer}
        itemRenderer={itemRenderer}
        selectTextOnFocus={false}
        loadingElement={<DefaultSelectLoadingItem />}
        disabled={disabled}
        noResults={<DefaultSelectNoResults />}
        error={error}
      />
    </OptionalField>
  );
};
