import React from 'react';
import { IconPickupBig, IconPickupPrebookingBig } from '@wheely/ui-kit';

import { EtaMarkerContent } from '_common/EtaMarkerContent';

import s from './styles.scss';

type Props = {
  eta?: number | null;
};

export const PinOnMapMarker = ({ eta }: Props) => (
  <div className={s.pin}>
    {eta ? (
      <>
        <IconPickupBig />
        <EtaMarkerContent eta={eta} />
      </>
    ) : (
      <IconPickupPrebookingBig />
    )}
  </div>
);
