import { getIsCorporateType, getIsHospitalityType } from '_utils/getCompanyType';

import { useCompany } from './company';

export const useCompanyType = () => {
  const companyType = useCompany()?.type || null;
  const isHospitalityType = getIsHospitalityType(companyType);
  const isCorporateType = getIsCorporateType(companyType);

  return {
    companyType,
    isHospitalityType,
    isCorporateType,
  };
};
