import { useQuery } from '@tanstack/react-query';

import { apiClient } from '_api';

import { COMPANY } from '../keys';

export const useGetCompanyConfigQuery = () =>
  useQuery({
    networkMode: 'always',
    refetchOnWindowFocus: false,
    retry: false,
    queryKey: [COMPANY.LOAD_CONFIG],
    queryFn: () => apiClient.getCompanyConfig(),
  });
