import React from 'react';
import { Redirect, useLocation } from 'react-router';

import { WithChildren } from '_types/helpers';
import { useCompany } from '_hooks/company';
import { authRoutes, commonRoutes, personalSettingsRoutes } from '_routes';
import { card3dsVerificationRoute } from '_routes/common';

const ALLOWED_ROUTES = [
  authRoutes.loginRoute,
  authRoutes.addCompanyRoute,
  authRoutes.pendingRoute,
  authRoutes.welcomeRoute,
  personalSettingsRoutes.personalSettingsBase,
  card3dsVerificationRoute,
];

export const CompanyPendingWrapper: React.FC<WithChildren> = ({ children }) => {
  const company = useCompany();
  const companyStatus = company?.status || null;
  const { pathname } = useLocation();

  if (companyStatus === 'pending' && !ALLOWED_ROUTES.includes(pathname)) {
    return <Redirect to={authRoutes.pendingRoute} />;
  }

  if (companyStatus !== 'pending' && pathname === authRoutes.pendingRoute) {
    return <Redirect to={commonRoutes.root} />;
  }

  return <>{children}</>;
};
