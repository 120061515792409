import React from 'react';
import { Waypoint } from 'react-waypoint';

const BatchedWaypoint = ({
  loadMore,
  canLoadMore,
  isLoading,
}: {
  loadMore: () => void;
  canLoadMore: boolean;
  isLoading: boolean;
}) => {
  if (!canLoadMore) {
    return null;
  }

  return (
    <Waypoint
      onEnter={() => {
        if (!isLoading) {
          loadMore();
        }
      }}
    />
  );
};

export { BatchedWaypoint };
