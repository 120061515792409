import React, { useCallback, useMemo, useState } from 'react';
import cn from 'clsx';
import {
  DatePicker as BaseDatePicker,
  DatePickerProps as BaseDatePickerProps,
  IconCalendar,
  IconCornerBottom,
  IPopoverProps,
  OnDateChange,
  Popover2 as UIPopover,
} from '@wheely/ui-kit';

import { isValidDate } from '_utils/isValidDate';
import { useLocale } from '_i18n/useLocale';
import { useLocaleDate } from '_i18n/useLocaleDate';
import { ExtractProperty } from '_types/helpers';

import s from './styles.scss';

type PopoverProps = React.ComponentProps<typeof UIPopover> & { children?: React.ReactNode };

const Popover = UIPopover as unknown as React.FC<PopoverProps>;

export type DatePickerProps = {
  isToday: boolean;
  todayTitle: string;
  disabled?: boolean;
  closeOnSelect?: boolean;
  usePortalForPopover?: boolean;
  popoverTargetTagName?: keyof JSX.IntrinsicElements;
  popoverClassName?: string;
  mobileDatePickerProps?: any;
  isValid?: boolean;
} & BaseDatePickerProps;

const BUTTON_FORMAT_PATTERN = 'd MMM yyyy';

export const DatePicker = ({
  value,
  onChange,
  isToday,
  todayTitle,
  disabled,
  closeOnSelect = true,
  usePortalForPopover = true,
  popoverTargetTagName,
  popoverClassName,
  isValid = true,
  ...props
}: DatePickerProps) => {
  const { locale } = useLocale();
  const { format } = useLocaleDate();
  const [isOpen, setIsOpen] = useState(false);

  const _onChange = useCallback<OnDateChange>(
    (...args) => {
      if (typeof onChange === 'function') {
        onChange(...args);
      }

      if (closeOnSelect) {
        setIsOpen(false);
      }
    },
    [closeOnSelect, onChange],
  );
  const handlePopoverOpening = useCallback(() => {
    setIsOpen(true);
  }, []);
  const handlePopoverClosed = useCallback(() => {
    setIsOpen(false);
  }, []);
  const handlePopoverInteraction = useCallback<ExtractProperty<IPopoverProps, 'onInteraction'>>(
    nextOpenState => {
      setIsOpen(nextOpenState);
    },
    [],
  );

  const buttonTitle = useMemo(() => {
    if (isToday) {
      return todayTitle;
    }

    if (isValidDate(value)) {
      return format(value, BUTTON_FORMAT_PATTERN);
    }

    return null;
  }, [isToday, todayTitle, value, format]);

  return (
    <Popover
      className={popoverClassName}
      minimal={true}
      placement="bottom-start"
      onOpening={handlePopoverOpening}
      onClosed={handlePopoverClosed}
      onInteraction={handlePopoverInteraction}
      openOnTargetFocus={true}
      isOpen={isOpen}
      disabled={disabled}
      modifiers={{
        offset: {
          enabled: true,
          options: { offset: [0, 8] },
        },
      }}
      content={
        <div data-test-id="date-picker-popover-content">
          <BaseDatePicker
            onChange={_onChange}
            value={value}
            disabled={disabled}
            firstDayOfWeek={locale?.options?.weekStartsOn || 0}
            {...props}
          />
        </div>
      }
      targetTagName={popoverTargetTagName}
      usePortal={usePortalForPopover}
    >
      <button
        className={cn(s.button, { [s.isOpened]: isOpen, [s.invalid]: !isValid })}
        type="button"
        disabled={disabled}
        data-test-id="date-picker-button"
      >
        <IconCalendar className={s.iconCalendar} />
        <span>{buttonTitle}</span>
        <IconCornerBottom className={s.iconCornerBottom} />
      </button>
    </Popover>
  );
};
