import React from 'react';
import cn from 'clsx';
import {
  IconAirport,
  IconExtraStop,
  IconCornerLeft,
  IconPickup,
  IconRailway,
  InputValueRenderer,
  ItemListRenderer,
  ItemRenderer,
  ItemsEqual,
  SelectMenu,
  SelectMenuItem,
  wrapIcon,
} from '@wheely/ui-kit';

import { getLocationName } from '_utils/getLocationName';
import { LocationType } from '_api/types';
import { LocationFieldProps } from '_common/LocationField/index';

import { isReferenceLocation } from './helpers';
import { ITEM_TYPE_BACK } from './constants';
import s from './styles.scss';

const WrappedIconPickup = wrapIcon(<IconPickup />);
const WrappedIconExtraStop = wrapIcon(<IconExtraStop />);
const getMenuIconByLocationType = (type: LocationType) => {
  switch (type) {
    case 'airport': {
      return <IconAirport className={s.menuItemIcon} />;
    }

    case 'railway_hub': {
      return <IconRailway className={s.menuItemIcon} />;
    }

    default: {
      return null;
    }
  }
};

const itemListPredicate = (query: string, items: Record<string, any>[]) => items;

const itemListRenderer: ItemListRenderer<Record<string, any>> = (
  listProps,
  initialContent,
  noResults,
  infiniteScrollProps,
  loadingElement,
  menuProps,
) => {
  const { itemsParentRef, renderItem, query, filteredItems } = listProps;
  const { isLoading } = infiniteScrollProps ?? {};
  let menuContent = isLoading ? loadingElement : noResults;

  if (!query.length && initialContent) {
    menuContent = initialContent;
  } else if (filteredItems.length && !isLoading) {
    menuContent = filteredItems.map(renderItem).filter(Boolean);
  }

  return (
    <SelectMenu
      {...menuProps}
      itemsParentRef={itemsParentRef}
      infiniteScrollProps={infiniteScrollProps}
      className={cn(s.menu, menuProps?.className)}
    >
      {menuContent}
    </SelectMenu>
  );
};

const handleMouseDown: React.MouseEventHandler<HTMLElement> = event => event.preventDefault();

const itemRenderer: ItemRenderer<Record<string, any>> = (
  item,
  { handleClick, modifiers, index },
  selectedItem,
) => {
  if (!modifiers.matchesPredicate || !item) {
    return null;
  }

  const { type, isInitial, isMeta } = item;
  const selected = selectedItem ? getLocationName(selectedItem) === getLocationName(item) : false;

  const text = getLocationName(item, true);
  const addressLine =
    isInitial || isMeta ? null : <span className={s.addressLine}>{item.line2}</span>;
  const IconComponent = getMenuIconByLocationType(type);
  const isBackItem = type === ITEM_TYPE_BACK;
  const backIcon = isBackItem ? <IconCornerLeft stroke={'#9e9e9e'} /> : null;
  const isActive = !selected && modifiers.active;

  return (
    <SelectMenuItem
      className={cn({ selected, back: isBackItem }, s.menuItem)}
      key={`location-${text}-${index}`}
      text={
        <>
          {text}
          {addressLine}
        </>
      }
      active={isActive}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      labelElement={wrapIcon(IconComponent)}
      icon={backIcon}
      multiline={true}
      data-test-id="address-field-menu-item"
    />
  );
};

const inputValueRenderer: InputValueRenderer<Record<string, any>> = item => getLocationName(item);

// HMMMMMMMMMMMM
const itemsEqual: ItemsEqual<Record<string, any>> = (itemA, itemB) => itemA === itemB;

const shouldDismissPopoverPredicate: LocationFieldProps['shouldDismissPopoverPredicate'] = (
  currentShouldDismissPopover,
  item,
) =>
  !(
    item.isMeta ||
    item.type === ITEM_TYPE_BACK ||
    item.locations?.length ||
    item.zone_id ||
    isReferenceLocation(item)
  );

export {
  WrappedIconExtraStop,
  WrappedIconPickup,
  getMenuIconByLocationType,
  itemListPredicate,
  itemListRenderer,
  itemRenderer,
  inputValueRenderer,
  itemsEqual,
  shouldDismissPopoverPredicate,
};
