import React from 'react';
import cn from 'clsx';
import { Button, ButtonProps } from '@wheely/ui-kit';

import { IconMap } from '_common/icons/IconMap';

import s from './styles.scss';

export const EditLocationOnMapButton = ({ onClick, className, ...restProps }: ButtonProps) => (
  <Button
    {...restProps}
    onClick={onClick}
    minimal={true}
    outlined={true}
    className={cn(className, s.button)}
  >
    <IconMap />
  </Button>
);
