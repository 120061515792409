import React, { useCallback, useEffect, useRef } from 'react';
import { Task } from 'redux-saga';

import { runSaga } from '_store';

type RunSaga = typeof runSaga;
type RunLocalSaga = (...args: Parameters<RunSaga>) => Promise<any>;
export type WithLocalSagasProps = { runSaga: RunLocalSaga };

// eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions
function withLocalSagas<P>(
  WrappedComponent: React.ComponentType<P & WithLocalSagasProps>,
): React.FC<P> {
  // eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions
  return function WrappedWithSagas(props) {
    const sagaTasks = useRef<Task[]>([]);

    useEffect(
      () => () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        sagaTasks.current.forEach(task => {
          task.cancel();
        });
      },
      [sagaTasks],
    );

    const runLocalSaga: RunLocalSaga = useCallback((saga, ...args) => {
      const sagaTask = runSaga(saga, ...args);

      sagaTasks.current.push(sagaTask);

      return sagaTask.toPromise();
    }, []);

    // Casting to generic is important because of TS issue
    // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
    return <WrappedComponent {...(props as P)} runSaga={runLocalSaga} />;
  };
}

export { withLocalSagas };
