import { useQuery } from '@tanstack/react-query';

import { apiClient } from '_api';
import { normalizeUser } from '_api/normalize';

import { USER } from '../keys';

export const useGetUser = (enabled: boolean) =>
  useQuery({
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    gcTime: 10 * 1000,
    refetchInterval: 10 * 1000,
    retry: false,
    enabled,
    queryKey: [USER.LOAD],
    networkMode: 'always',
    queryFn: () => apiClient.getUser().then(({ user }) => normalizeUser(user)),
  });
