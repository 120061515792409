import { useMemo, useRef, useCallback } from 'react';
import debounce from 'lodash/fp/debounce';
import omit from 'lodash/fp/omit';
import pick from 'lodash/fp/pick';
import { CustomBaseSuggest } from '@wheely/ui-kit';

import { loadCompletions as loadCompletionsSaga } from '_sagas/common/loadCompletions';
import { getLocationByReference } from '_sagas/common/getLocationByReference';
import { isReferenceLocation } from '_common/LocationField/helpers';
import { useLocalSagas } from '_hooks';
import { CompletionZone, Location } from '_api/types';
import { isPickupLocationInaccurate } from '_utils/isPickupLocationInaccurate';
import { isDropoffLocationInaccurate } from '_utils/isDropoffLocationInaccurate';
import { getLocationName } from '_utils/getLocationName';

type UseHandleQueryChangeParams = {
  setQuery: (query: string) => void;
  setCompletions: (items: Record<string, any>[]) => void;
  setZones?: (zones: CompletionZone[]) => void;
  mode?: 'pickup' | 'dropoff';
};

const useDebouncedLoadCompletions = (
  onUpdate: (completions: Record<string, any>[]) => void,
  setZones: UseHandleItemSelectParams['setZones'],
) => {
  const { runLocalSaga } = useLocalSagas();

  const loadCompletions = useCallback<
    (args: { query: string; mode?: 'pickup' | 'dropoff' }) => void
  >(
    async ({ query, mode }) => {
      try {
        const { completions } = await runLocalSaga(loadCompletionsSaga, { query, mode });

        if (completions?.locations) {
          onUpdate(completions.locations);
        }

        if (completions.zones) {
          setZones?.(completions.zones);
        }
      } catch (error) {
        onUpdate([]);
      }
    },
    [runLocalSaga, onUpdate, setZones],
  );

  const debouncedLoadCompletions = useMemo(() => debounce(200, loadCompletions), [loadCompletions]);

  return debouncedLoadCompletions;
};

const useHandleQueryChange = ({
  setCompletions,
  setQuery,
  setZones,
  mode,
}: UseHandleQueryChangeParams) => {
  const debouncedLoadCompletions = useDebouncedLoadCompletions(setCompletions, setZones);

  const handleQueryChange = useCallback<(query: string) => void>(
    (nextQuery: string) => {
      setQuery(nextQuery);
      debouncedLoadCompletions({ query: nextQuery, mode });
    },
    [setQuery, debouncedLoadCompletions, mode],
  );

  return handleQueryChange;
};

type UseHandleItemSelectParams = {
  type: 'pickup' | 'dropoff';
  setSelectedItem: (location: Location | null) => void;
} & UseHandleQueryChangeParams;

const useHandleItemSelect = ({
  type,
  setSelectedItem,
  setQuery,
  setCompletions,
}: UseHandleItemSelectParams) => {
  const { runLocalSaga } = useLocalSagas();

  const handleQueryChange = useHandleQueryChange({ setQuery, setCompletions });

  const suggestRef = useRef<CustomBaseSuggest<Record<string, any>>>(null);

  const isLocationInnacurateFn = useMemo(
    () => (type === 'pickup' ? isPickupLocationInaccurate : isDropoffLocationInaccurate),
    [type],
  );

  const handleItemSelect = useCallback(
    async (item: Record<string, any> | null) => {
      if (!item) {
        setSelectedItem(item);

        return;
      }

      const location = omit(['isMeta', 'isInitial', 'kind'], item) as Location;

      handleQueryChange(getLocationName(location));

      if (isReferenceLocation<Record<string, any>>(location)) {
        try {
          const formattedLocation = await runLocalSaga(getLocationByReference, {
            locationReference: location,
            type, // getLocationByReference is expecting to `pickup` only, but `type` here may be `dropoff` as well
          });

          const refinedLocation = formattedLocation?.location;

          if (refinedLocation) {
            setQuery(getLocationName(refinedLocation));

            setSelectedItem(refinedLocation);

            handleQueryChange(getLocationName(refinedLocation));

            if (!isLocationInnacurateFn(refinedLocation) && suggestRef.current) {
              suggestRef.current.setOpenState(false);
            }
          }
        } catch (error) {
          if (window.b2bAppConfig.ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.warn(error);
          }
        }
      } else {
        setSelectedItem(location);
      }
    },
    [setSelectedItem, isLocationInnacurateFn, setQuery, handleQueryChange, type, runLocalSaga],
  );

  return { handleItemSelect, suggestRef };
};

type UseLocationAutoCompleteParams = UseHandleItemSelectParams;

const useLocationAutoComplete = (params: UseLocationAutoCompleteParams) => {
  const { handleItemSelect, suggestRef } = useHandleItemSelect(params);

  const handleQueryChange = useHandleQueryChange(
    pick(['setQuery', 'setCompletions', 'setZones', 'mode'], params),
  );

  return { handleQueryChange, handleItemSelect, suggestRef };
};

export { useLocationAutoComplete };
