import { normalize, schema } from 'normalizr';
import camelCase from 'lodash/fp/camelCase';
import compose from 'lodash/fp/compose';
import mapKeys from 'lodash/fp/mapKeys';
import mapValues from 'lodash/fp/mapValues';
import omit from 'lodash/fp/omit';
import pick from 'lodash/fp/pick';
import pickBy from 'lodash/fp/pickBy';
import { formatISOWithOptions, isValid, parseISO } from 'date-fns/fp';

import {
  BikCompletion,
  CompanyOverallExpenses,
  Department,
  DepartmentsExpensesResponse,
  DepartmentsResponse,
  Employee,
  EmployeeResponse,
  EmployeesExpensesResponse,
  EmployeesResponse,
  GetBikCompletionsResponse,
  GetInnCompletionsResponse,
  GetOrderRequestsResponse,
  GetOrdersResponse,
  InnCompletion,
  Invite,
  InviteRequest,
  InviteToCreate,
  Order,
  OrderRequest,
  GetCompanyOverallExpensesResponse,
  DepartmentExpenses,
  RawEmployeeExpenses,
  RawInvite,
  RawInviteToCreate,
  RawUser,
  ServiceOffer,
  User,
  EmployeeRole,
} from '_api/types';
import { Role, ROLES } from '_constants';

const transformRoleToRolesArray = (role: Role): EmployeeRole[] => {
  if (role === ROLES.user) {
    return [];
  }

  return [role as EmployeeRole];
};

const transformRolesToRole = (roles?: EmployeeRole[]): Role => {
  if (roles?.includes('admin')) {
    return ROLES.admin;
  }

  if (roles?.includes('travel_coordinator')) {
    return ROLES.travel_coordinator;
  }

  if (roles?.includes('finance')) {
    return ROLES.finance;
  }

  return ROLES.user;
};

const transformSnakeToCamelCase = <
  T extends Record<string, unknown>,
  R extends Record<string, unknown>,
>(
  object: T,
): R => mapKeys(camelCase, object) as R;

const normalizeCompanyOverallExpenses = (expenses: GetCompanyOverallExpensesResponse) =>
  transformSnakeToCamelCase<GetCompanyOverallExpensesResponse, CompanyOverallExpenses>(expenses);

const formatIso = formatISOWithOptions({ representation: 'date' });
const pickValidDates = pickBy<Record<string, unknown>>(
  (value: unknown): boolean => typeof value === 'string' && isValid(parseISO(value)),
);

const formatIsoDateToDate = compose(formatIso, parseISO);

const transformDateRangeIsoToFormat = <T extends Record<string, unknown>>(dateRange: T): T =>
  compose(mapValues(formatIsoDateToDate), pickValidDates)(dateRange);

const defaultEmployee: Employee = {
  id: '',
  user_id: '',
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  role: ROLES.user,
  status: 'active',
  limit: 0,
  auth_email: '',
  auth_email_verified: false,
};

const employeeSchema = new schema.Entity(
  'employees',
  {},
  {
    processStrategy: value => {
      if (!value.id) {
        return null;
      }

      return {
        ...defaultEmployee,
        ...omit(['admin', 'department_id'], value),
        role: transformRolesToRole(value.roles),
      };
    },
  },
);

const normalizeEmployee = (response: EmployeeResponse) =>
  normalize<Employee>(response.employee, employeeSchema);

export const normalizeSingleEmployee = (response: EmployeeResponse): Employee | null => {
  if (!response.employee.id) {
    return null;
  }

  return {
    ...defaultEmployee,
    ...omit(['admin', 'department_id'], response.employee),
    role: transformRolesToRole(response.employee.roles),
  };
};

const denormalizeEmployee = (employee: Employee): Partial<EmployeeResponse['employee']> => ({
  ...employee,
  ...omit(['role'], employee),
  department_id: employee.department?.id ?? null,
  admin: employee.role === ROLES.admin,
});

const employeesListSchema = new schema.Array(employeeSchema);

const normalizeEmployees = (results: EmployeesResponse['results']) =>
  normalize<Employee>(results, employeesListSchema);

const departmentSchema = new schema.Entity('departments');
const departmentsListSchema = new schema.Array(departmentSchema);

const normalizeDepartments = (results: DepartmentsResponse['results']) =>
  normalize<Department>(results, departmentsListSchema);

const defaultInvite: Partial<Invite> = {
  role: ROLES.user,
  resendable: false,
};

const inviteSchema = new schema.Entity(
  'invites',
  {},
  {
    processStrategy: (value: RawInvite | InviteRequest) => {
      if (!value.id) {
        return null;
      }

      return {
        ...defaultInvite,
        ...pick(['id', 'phone', 'resendable', 'email', 'limit', 'department'], value),
        role: transformRolesToRole(value.roles),
        departmentId: value.department_id,
      };
    },
  },
);

const normalizeInvite = (invite: RawInvite) => normalize<Invite>(invite, inviteSchema);

const invitesListSchema = new schema.Array(inviteSchema);

const normalizeInvites = (invites: Array<RawInvite | InviteRequest>) =>
  normalize<Invite>(invites, invitesListSchema);

const denormalizeInvite = ({
  role,
  departmentId,
  ...invite
}: InviteToCreate): RawInviteToCreate => ({
  ...invite,
  department_id: departmentId,
  roles: transformRoleToRolesArray(role),
});

const denormalizeInvites = (invites: InviteToCreate[]): RawInviteToCreate[] =>
  invites.map(denormalizeInvite);

const normalizeUser = (user: RawUser): User => ({
  ...omit(['roles'], user),
  role: transformRolesToRole(user.company?.roles),
});

const employeeExpensesSchema = new schema.Entity(
  'employeeExpenses',
  {},
  { idAttribute: 'user_id' },
);
const employeesExpensesSchema = new schema.Array(employeeExpensesSchema);

const normalizeEmployeesExpenses = (results: EmployeesExpensesResponse['results']) =>
  normalize<RawEmployeeExpenses>(results, employeesExpensesSchema);

const departmentExpensesSchema = new schema.Entity(
  'departmentExpenses',
  {},
  { idAttribute: 'department_id' },
);
const departmentsExpensesSchema = new schema.Array(departmentExpensesSchema);

const normalizeDepartmentsExpenses = (results: DepartmentsExpensesResponse['results']) =>
  normalize<DepartmentExpenses>(results, departmentsExpensesSchema);
const orderSchema = new schema.Entity('order');
const companyOrdersListSchema = new schema.Array(orderSchema);

const normalizeCompanyOrders = (results: GetOrdersResponse['results']) =>
  normalize<Order>(results, companyOrdersListSchema);

const orderRequestSchema = new schema.Entity('orderRequest');
const companyOrderRequestsListSchema = new schema.Array(orderRequestSchema);
const normalizeCompanyOrderRequests = (results: GetOrderRequestsResponse['results']) =>
  normalize<OrderRequest>(results, companyOrderRequestsListSchema);

const serviceOfferSchema = new schema.Entity<ServiceOffer>(
  'serviceOffers',
  {},
  {
    idAttribute: (value: ServiceOffer) => value?.service?.id,
  },
);
const serviceOffersSchema = new schema.Array(serviceOfferSchema);
const normalizeServiceOffers = (serviceOffers: ServiceOffer[]) =>
  normalize<ServiceOffer>(serviceOffers, serviceOffersSchema);

const bikCompletionsSchema = new schema.Entity('completions', {}, { idAttribute: 'bik' });

const normalizeBikCompletions = (results: GetBikCompletionsResponse) =>
  normalize<BikCompletion>(results, bikCompletionsSchema);

const innCompletionsSchema = new schema.Entity('completions', {}, { idAttribute: 'inn' });

const normalizeInnCompletions = (results: GetInnCompletionsResponse) =>
  normalize<InnCompletion>(results, innCompletionsSchema);

export {
  normalizeCompanyOverallExpenses,
  formatIsoDateToDate,
  transformDateRangeIsoToFormat,
  defaultEmployee,
  employeeSchema,
  normalizeEmployee,
  denormalizeEmployee,
  employeesListSchema,
  normalizeEmployees,
  departmentSchema,
  departmentsListSchema,
  normalizeDepartments,
  inviteSchema,
  normalizeInvite,
  invitesListSchema,
  normalizeInvites,
  denormalizeInvite,
  denormalizeInvites,
  normalizeUser,
  employeeExpensesSchema,
  employeesExpensesSchema,
  normalizeEmployeesExpenses,
  departmentExpensesSchema,
  departmentsExpensesSchema,
  normalizeDepartmentsExpenses,
  orderSchema,
  normalizeCompanyOrders,
  normalizeCompanyOrderRequests,
  serviceOfferSchema,
  serviceOffersSchema,
  normalizeServiceOffers,
  normalizeBikCompletions,
  normalizeInnCompletions,
};
