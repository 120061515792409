import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CompletionsPayload,
  CompletionsResponse,
  CreateOrderPayload,
  CreateOrderRequestPayload,
  ErrorShape,
  Flight,
  Location,
  LocationType,
  NearbyLocationsResponse,
  OrderPassenger,
  RecentReference,
  GetServiceFaresPayload,
} from '_api/types';
import { RootState } from '_store';
import { PreFilledJourney } from '_types/common';
import { getLocationName } from '_utils/getLocationName';
import { splitStopsIntoStopsAndDropoff } from '_utils/splitStopsIntoStopsAndDropoff';
import { notEmpty } from '_utils/nonEmpty';

export type UserCoordsShape = { lat: number; lng: number };

export type NearbyLocations = Partial<
  Pick<Record<LocationType, NearbyLocationsResponse>, 'airport' | 'railway_hub'>
>;

export type NewJourneyState = {
  userCoords: null | UserCoordsShape;
  nearbyLocations: null | NearbyLocations;
  isLoading: boolean;
  error: null | ErrorShape;
  selectedServiceId: null | string;
  route: {
    polyline: null | string;
    isLoading: boolean;
    error: null | ErrorShape;
  };
  pickup: {
    query: string;
    location: null | Location;
    completions: null | CompletionsResponse;
    isLoading: boolean;
    error: null | ErrorShape;
  };
  stops: Array<Location | null | undefined>;
  references: RecentReference[];
  isPrebookMode: boolean;
  pickupAt: string | null;
  flight: Flight | null;
  expectedDuration: number | null;
  preFilledJourney: PreFilledJourney | null;
  preFilledJourneyErrors: null | { employeeNotFound: boolean };
};

export const resetState = createAction('newJourney/resetState');
export const resetServerError = createAction('newJourney/resetServerError');

export const setUserCoords = createAction<UserCoordsShape>('newJourney/setUserCoords');

export const loadNearbyLocations = createAction('newJourney/loadNearbyLocations');
export const loadNearbyLocationsSucceed = createAction<{ nearbyLocations: NearbyLocations }>(
  'newJourney/loadNearbyLocationsSucceed',
);
export const loadNearbyLocationsFailed = createAction<{ error: ErrorShape }>(
  'newJourney/loadNearbyLocationsFailed',
);

export const loadPickupCompletions = createAction<{
  query: string;
  mode?: CompletionsPayload['mode'];
}>('newJourney/loadPickupCompletions');
export const loadPickupCompletionsSucceed = createAction<{
  completions: null | CompletionsResponse;
}>('newJourney/loadPickupCompletionsSucceed');
export const loadPickupCompletionsFailed = createAction<{ error: ErrorShape }>(
  'newJourney/loadPickupCompletionsFailed',
);

export const setPickupLocation = createAction<{ location: Location | null }>(
  'newJourney/setPickupLocation',
);

export const setStops = createAction<{ stops: Array<Location | null | undefined> }>(
  'newJourney/setStops',
);

export const getLocationByReferenceSucceed = createAction<{
  location: Location;
  type: 'pickup';
}>('newJourney/getLocationByReferenceSucceed');
export const getLocationByReferenceFailed = createAction<{
  error: ErrorShape;
  type: 'pickup';
}>('newJourney/getLocationByReferenceFailed');

export const getRoute = createAction<{
  pickup: Location;
  dropoff: Location;
  stops?: Location[];
}>('newJourney/getRoute');
export const getRouteSucceed = createAction<{
  route: string;
}>('newJourney/getRouteSucceed');
export const getRouteFailed = createAction<{
  error: ErrorShape;
}>('newJourney/getRouteFailed');

export const setServiceId = createAction<{ serviceId: string | null }>('newJourney/setServiceId');
export const setPrebookMode = createAction<{ isPrebookMode: boolean }>('newJourney/setPrebookMode');
export const setFlight = createAction<{ flight: Flight | null }>('newJourney/setFlight');

export const createOrder = createAction<CreateOrderPayload>('newJourney/createOrder');
export const createOrderSucceed = createAction('newJourney/createOrderSucceed');
export const createOrderFailed = createAction<{
  error: ErrorShape;
}>('newJourney/createOrderFailed');

export const createOrderRequest = createAction<{
  passenger: Required<OrderPassenger>;
  order: CreateOrderRequestPayload['order'];
}>('newJourney/createOrderRequest');
export const createOrderRequestSucceed = createAction('newJourney/createOrderRequestSucceed');
export const createOrderRequestFailed = createAction<{
  error: ErrorShape;
}>('newJourney/createOrderRequestFailed');

export const setRecentReferences = createAction<{ references: RecentReference[] }>(
  'newJourney/setRecentReferences',
);

const initialState: NewJourneyState = {
  userCoords: null,
  nearbyLocations: null,
  isLoading: false,
  error: null,
  selectedServiceId: null,
  route: {
    polyline: null,
    isLoading: false,
    error: null,
  },
  pickup: {
    query: '',
    location: null,
    completions: null,
    isLoading: false,
    error: null,
  },
  stops: [],
  references: [],
  isPrebookMode: false,
  pickupAt: null,
  flight: null,
  expectedDuration: null,
  preFilledJourney: null,
  preFilledJourneyErrors: null,
};

export const userLocationSelector = (state: RootState): UserCoordsShape | undefined =>
  state.newJourney.userCoords || undefined;

export const nearbyLocationsSelector = (state: RootState) => state.newJourney.nearbyLocations;

export const pickupSelector = (state: RootState) => state.newJourney.pickup;
export const dropoffLocationSelector = (state: RootState) => {
  const { dropoff } = splitStopsIntoStopsAndDropoff(state.newJourney.stops);

  return dropoff;
};

export const dropoffPositionSelector = createSelector(
  dropoffLocationSelector,
  dropoffLocation => dropoffLocation?.position,
);

export const stopsSelector = (state: RootState) => state.newJourney.stops;

export const fulfilledStopsSelector = createSelector(
  stopsSelector,
  stops => stops.filter(stop => Boolean(stop?.position)) as Location[],
);

export const pickupCompletionsSelector = createSelector(
  pickupSelector,
  ({ completions }) => completions?.locations ?? [],
);

export const pickupCompletionsZonesSelector = createSelector(
  pickupSelector,
  ({ completions }) => completions?.zones ?? [],
);

export const pickupLocationSelector = createSelector(pickupSelector, pickup => pickup.location);

const pickupAtSelector = (state: RootState) => state.newJourney.pickupAt;

export const pickupPositionSelector = createSelector(
  pickupLocationSelector,
  pickupLocation => pickupLocation?.position,
);

const extraStopsSelector = createSelector(stopsSelector, stops => stops.slice(0, -1));

export const isPrebookModeSelector = (state: RootState) => state.newJourney.isPrebookMode;

export const expectedDurationSelector = (state: RootState) => state.newJourney.expectedDuration;

export const serviceOffersPayloadSelector = createSelector(
  pickupLocationSelector,
  extraStopsSelector,
  dropoffLocationSelector,
  pickupAtSelector,
  (pickupLocation, extraStops, dropoffLocation, pickupAt) => {
    // TODO: fill up rest fields from the shape `ServicesFarePayload`, at least these:
    // pickup_at: undefined,
    // selected_service_id: undefined,
    // expected_duration: undefined,

    const result: GetServiceFaresPayload = {
      stops: extraStops.filter(notEmpty),
    };

    if (pickupLocation) {
      result.pickup = pickupLocation;
    }

    if (dropoffLocation) {
      result.dropoff = dropoffLocation;
    }

    if (pickupAt) {
      result.pickup_at = pickupAt;
    }

    return result;
  },
);

export const selectedServiceIdSelector = (state: RootState) => state.newJourney.selectedServiceId;
export const flightSelector = (state: RootState) => state.newJourney.flight;
export const railwayHubSelector = (state: RootState) =>
  state.newJourney.pickup.location?.type === 'railway_hub';

export const isOriginPickupSubTypeSelector = (state: RootState) =>
  state.newJourney.pickup.location?.subtype === 'origin';

export const serverErrorSelector = (state: RootState): null | ErrorShape => state.newJourney.error;

export const isLoadingSelector = (state: RootState) => state.newJourney.isLoading;

export const preFilledJourneySelector = (state: RootState) => state.newJourney.preFilledJourney;
export const preFilledJourneyErrorsSelector = (state: RootState) =>
  state.newJourney.preFilledJourneyErrors;

export const newJourneySlice = createSlice({
  name: 'newJourney',
  initialState,
  reducers: {
    setPreFilledJourney(state, action: PayloadAction<PreFilledJourney>) {
      state.preFilledJourney = action.payload;
    },
    setPreFilledJourneyErrors(state, action: PayloadAction<{ employeeNotFound: boolean }>) {
      state.preFilledJourneyErrors = action.payload;
    },
    resetPreFilledJourneyErrors(state) {
      state.preFilledJourneyErrors = null;
    },
    setChauffeurForADayExpectedDuration(
      state,
      action: PayloadAction<{ expectedDuration: number | null }>,
    ) {
      state.expectedDuration = action.payload.expectedDuration;
    },
    setPickupAt(state, action: PayloadAction<{ pickupAt: string | null }>) {
      state.pickupAt = action.payload.pickupAt;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      resetState,
      (): NewJourneyState => ({
        ...initialState,
      }),
    );

    builder.addCase(
      resetServerError,
      (state): NewJourneyState => ({
        ...state,
        error: null,
      }),
    );

    builder.addCase(
      setUserCoords,
      (state, { payload }): NewJourneyState => ({
        ...state,
        userCoords: payload,
      }),
    );

    builder.addCase(
      loadNearbyLocations,
      (state): NewJourneyState => ({
        ...state,
        error: null,
      }),
    );

    builder.addCase(
      loadNearbyLocationsSucceed,
      (state, { payload }): NewJourneyState => ({
        ...state,
        error: null,
        nearbyLocations: payload.nearbyLocations,
      }),
    );

    builder.addCase(
      loadNearbyLocationsFailed,
      (state, { payload }): NewJourneyState => ({
        ...state,
        error: payload.error,
      }),
    );

    builder.addCase(
      loadPickupCompletions,
      (state, { payload: { query } }): NewJourneyState => ({
        ...state,
        pickup: {
          ...state.pickup,
          query,
          completions: null,
          error: null,
          isLoading: Boolean(query.length),
        },
      }),
    );

    builder.addCase(
      loadPickupCompletionsSucceed,
      (state, { payload: { completions } }): NewJourneyState => ({
        ...state,
        pickup: {
          ...state.pickup,
          completions,
          error: null,
          isLoading: false,
        },
      }),
    );

    builder.addCase(
      loadPickupCompletionsFailed,
      (state, { payload: { error } }): NewJourneyState => ({
        ...state,
        pickup: {
          ...state.pickup,
          completions: null,
          error,
          isLoading: false,
        },
      }),
    );

    builder.addCase(
      setPickupLocation,
      (state, { payload: { location } }): NewJourneyState => ({
        ...state,
        pickup: {
          ...state.pickup,
          location,
        },
        route: {
          ...state.route,
          polyline: null,
        },
      }),
    );

    builder.addCase(
      setStops,
      (state, { payload: { stops } }): NewJourneyState => ({
        ...state,
        stops,
        route: {
          ...state.route,
          polyline: null,
        },
      }),
    );

    builder.addCase(
      getLocationByReferenceSucceed,
      (state, { payload: { location, type } }): NewJourneyState => ({
        ...state,
        route: {
          ...state.route,
          polyline: null,
        },
        [type]: {
          ...state[type],
          isLoading: false,
          location,
          query: getLocationName(location),
        },
      }),
    );

    builder.addCase(
      getLocationByReferenceFailed,
      (state, { payload: { error, type } }): NewJourneyState => ({
        ...state,
        [type]: {
          ...state[type],
          isLoading: false,
          error,
        },
      }),
    );

    builder.addCase(
      setServiceId,
      (state, { payload: { serviceId } }): NewJourneyState => ({
        ...state,
        selectedServiceId: serviceId,
      }),
    );

    builder.addCase(
      setPrebookMode,
      (state, { payload: { isPrebookMode } }): NewJourneyState => ({
        ...state,
        isPrebookMode,
      }),
    );

    builder.addCase(getRoute, state => ({
      ...state,
      route: {
        ...state.route,
        isLoading: true,
        error: null,
      },
    }));

    builder.addCase(
      getRouteSucceed,
      (state, { payload }): NewJourneyState => ({
        ...state,
        route: {
          polyline: payload.route,
          isLoading: false,
          error: null,
        },
      }),
    );

    builder.addCase(
      getRouteFailed,
      (state, { payload }): NewJourneyState => ({
        ...state,
        route: {
          polyline: null,
          isLoading: false,
          error: payload.error,
        },
      }),
    );

    builder.addCase(
      createOrder,
      (state): NewJourneyState => ({
        ...state,
        isLoading: true,
        error: null,
      }),
    );

    builder.addCase(
      createOrderSucceed,
      (state): NewJourneyState => ({
        ...state,
        isLoading: false,
        error: null,
      }),
    );

    builder.addCase(
      createOrderFailed,
      (state, { payload: { error } }): NewJourneyState => ({
        ...state,
        isLoading: false,
        error,
      }),
    );

    builder.addCase(
      setRecentReferences,
      (state, { payload }): NewJourneyState => ({
        ...state,
        references: payload.references || [],
      }),
    );

    builder.addCase(
      createOrderRequest,
      (state): NewJourneyState => ({
        ...state,
        isLoading: true,
        error: null,
      }),
    );

    builder.addCase(
      createOrderRequestSucceed,
      (state): NewJourneyState => ({
        ...state,
        isLoading: false,
        error: null,
      }),
    );

    builder.addCase(
      createOrderRequestFailed,
      (state, { payload: { error } }): NewJourneyState => ({
        ...state,
        isLoading: false,
        error,
      }),
    );

    builder.addCase(
      setFlight,
      (state, { payload: { flight } }): NewJourneyState => ({
        ...state,
        flight,
      }),
    );
  },
});
