import React from 'react';
import cn from 'clsx';

import s from './styles.scss';

type Props = React.PropsWithChildren<{
  className?: string;
  dataTestId?: string;
}>;

export const PageContent = ({ className, children, dataTestId }: Props) => (
  <div className={cn(s.pageContent, className)} data-test-id={dataTestId}>
    {children}
  </div>
);
