import React from 'react';
import { Icon, IconProps } from '@wheely/ui-kit';

export const IconTakeOff: Icon = ({
  width,
  height,
  className,
  fill = 'currentColor',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.803 6.988 3.059-.935c.966-.296 2.48.052 2.65.607.17.555-.892 1.69-1.858 1.985l-6.601 2.019c-1.932.59-6.183.692-6.183.692L.714 8.15l1.437-.44 1.923 1.64 3.381-1.034-3.419-3.41L5.83 4.36l5.974 2.63Zm4.697 8.387h-15V16.5h15v-1.125Z"
      fill={fill}
    />
  </svg>
);
