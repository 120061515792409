import { Location, LocationType } from '_api/types';

const PICKUP_LOCATION_VALID_TYPES: LocationType[] = [
  'street_address',
  'point_of_interest',
  'subway',
  'airport',
  'railway_hub',
  'postal_code',
  'landmark',
  'mall',
];

const isPickupLocationInaccurate = (location: Location) =>
  location.type && !PICKUP_LOCATION_VALID_TYPES.includes(location.type);

export { PICKUP_LOCATION_VALID_TYPES, isPickupLocationInaccurate };
