import { defineMessages } from 'react-intl';

import { PAYER_TYPES } from '_constants';

const CORPORATE_PAYER_TYPES = [PAYER_TYPES.company, PAYER_TYPES.user];

const HOSPITALITY_PAYER_TYPES = [
  PAYER_TYPES.company,
  PAYER_TYPES.company_passenger,
  PAYER_TYPES.user,
];

const CORPORATE_ITEMS_MESSAGES = defineMessages({
  [PAYER_TYPES.company]: {
    description: 'Charge company payment method title',
    defaultMessage: 'Charge company',
    id: '+Gg5kt',
  },
  [PAYER_TYPES.user]: {
    description: 'Charge passenger payment method title',
    defaultMessage: 'Charge passenger',
    id: 'Tue9Ye',
  },
});

const HOSPITALITY_ITEMS_MESSAGES = defineMessages({
  [PAYER_TYPES.company]: {
    description: 'Charge hotel (complimentary) payment method title',
    defaultMessage: 'Complimentary journey',
    id: 'sm9NVx',
  },
  [PAYER_TYPES.user]: {
    description: 'Charge passenger payment method title',
    defaultMessage: 'Send payment link',
    id: 'Yd9/fZ',
  },
  [PAYER_TYPES.company_passenger]: {
    description: 'Hospitality charge passenger payment method title',
    defaultMessage: 'Charge at checkout',
    id: 'zy5Ab+',
  },
});

export {
  CORPORATE_PAYER_TYPES,
  HOSPITALITY_PAYER_TYPES,
  CORPORATE_ITEMS_MESSAGES,
  HOSPITALITY_ITEMS_MESSAGES,
};
