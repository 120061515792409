import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { FormGroupProps } from '@wheely/ui-kit';

import { EmployeeSuggest, EmployeeSuggestProps } from '_common/EmployeeSuggest';
import { useCompanyType } from '_hooks';

import { FormGroupTitle } from '../FormGroupTitle';

import s from './styles.scss';

type Props = {
  employeeUserId: null | string;
  onEmployeeChange: (employeeId?: string) => void;
  error?: FormGroupProps['error'];
  disabled?: boolean;
  onBookForAnother?: (query: string) => void;
};

export const EmployeeField = ({
  employeeUserId = '',
  onEmployeeChange,
  error,
  disabled,
  onBookForAnother,
}: Props) => {
  const intl = useIntl();
  const { isHospitalityType } = useCompanyType();
  const handleEmployeeChange = useCallback<EmployeeSuggestProps['onChange']>(
    item => onEmployeeChange(item?.value),
    [onEmployeeChange],
  );

  const title = isHospitalityType
    ? intl.formatMessage({
        description: 'New journey concierge form group title',
        defaultMessage: 'Concierge',
        id: '0FllJO',
      })
    : intl.formatMessage({
        description: 'New journey person form group title',
        defaultMessage: 'Passenger',
        id: 'pnaO2y',
      });

  return (
    <EmployeeSuggest
      title={<FormGroupTitle>{title}</FormGroupTitle>}
      placeholder={intl.formatMessage({
        description: 'Journey person field placeholder',
        defaultMessage: 'Start typing a name',
        id: 'PHr2Dr',
      })}
      value={employeeUserId}
      onChange={handleEmployeeChange}
      error={error}
      errorProps={{ className: s.error }}
      disabled={disabled}
      onBookForAnother={onBookForAnother}
    />
  );
};
