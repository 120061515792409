import { useQuery } from '@tanstack/react-query';

import { apiClient } from '_api';
import { normalizeSingleEmployee } from '_api/normalize';

import { EMPLOYEES } from '../keys';

export const useGetEmployee = (employeeId?: string) =>
  useQuery({
    retry: false,
    queryKey: [EMPLOYEES.LOAD_EMPLOYEE, employeeId],
    queryFn: () => apiClient.getEmployee({ id: employeeId }).then(normalizeSingleEmployee),
    enabled: Boolean(employeeId),
  });
