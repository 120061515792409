import React from 'react';
import cn from 'clsx';
import { FormattedMessage } from 'react-intl';
import { H1 } from '@wheely/ui-kit';

import s from './styles.scss';

export type ErrorStateProps = {
  className?: string;
  title?: React.ReactNode;
  titleClassName?: string;
  description?: React.ReactNode;
  descriptionClassName?: string;
  link?: React.ReactNode;
};

const ErrorState: React.VFC<ErrorStateProps> = ({
  className,
  title,
  titleClassName,
  description,
  descriptionClassName,
  link,
}) => (
  <div className={cn(className, s.errorState)}>
    <div className={s.content}>
      <H1 className={cn(titleClassName, s.title)} capitalize={true}>
        {title || (
          <FormattedMessage
            description="Page error state default title"
            defaultMessage="Error"
            id="bFCIeF"
          />
        )}
      </H1>

      <p className={cn(descriptionClassName, s.description)}>
        {description || (
          <FormattedMessage
            description="Page error state default description"
            defaultMessage="Something went wrong."
            id="4laar3"
          />
        )}
      </p>

      {link}
    </div>
  </div>
);

export { ErrorState };
