import React, { useCallback, useEffect, useState } from 'react';
import { Button, H2, Text } from '@wheely/ui-kit';
import { FormattedMessage } from 'react-intl';
import { isSameMinute } from 'date-fns';

import { isValidDate } from '_utils/isValidDate';
import { MobileDateTimePicker, MobileDateTimePickerProps } from '_common/MobileDateTimePicker';

import s from './styles.scss';

const MOBILE_DATE_PICKER_FORMAT_PATTERN = 'ccc, d MMM';

export type MobileDatePickerProps = {
  value?: Date;
  onSubmit: (value: Date) => void;
  disabled?: boolean;
  todayTitle?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode | ((localValue: Date) => React.ReactNode);
  buttonLabel?: React.ReactNode;
  timezone?: string | null;
} & Pick<MobileDateTimePickerProps, 'disabledDays' | 'fromMonth' | 'isDateOnly'>;

export const MobileDatePicker = ({
  onSubmit,
  value = new Date(),
  disabled = false,
  disabledDays,
  fromMonth,
  todayTitle,
  title,
  subTitle,
  buttonLabel,
  isDateOnly,
  timezone,
}: MobileDatePickerProps) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (value && !isSameMinute(value, localValue)) {
      setLocalValue(value);
    }
    // listen only for external `value` updates
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDateChange = useCallback(
    (date: Date) => {
      if (!isSameMinute(date, localValue)) {
        setLocalValue(date);
      }
    },
    [localValue],
  );

  const handleSubmit = useCallback(() => {
    if (!disabled && isValidDate(localValue)) {
      onSubmit(localValue);
    }
  }, [localValue, disabled, onSubmit]);

  const derivedSubTitle = typeof subTitle === 'function' ? subTitle?.(localValue) : subTitle;

  return (
    <div className={s.mobileDatePickerContainer}>
      {(title || derivedSubTitle) && (
        <div className={s.mobileDatePickerHeader}>
          {title && (
            <H2 capitalize={true} className={s.mobileDatePickerTitle}>
              {title}
            </H2>
          )}
          {derivedSubTitle && (
            <Text className={s.mobileDatePickerSubtitle} muted={true}>
              {derivedSubTitle}
            </Text>
          )}
        </div>
      )}

      <div className={s.mobileDatePickerBody}>
        <MobileDateTimePicker
          value={localValue}
          onChange={handleDateChange}
          disabledDays={disabledDays}
          fromMonth={fromMonth}
          dateFormatPattern={MOBILE_DATE_PICKER_FORMAT_PATTERN}
          disabled={disabled}
          todayTitle={todayTitle}
          isDateOnly={isDateOnly}
          timezone={timezone}
        />
      </div>

      <div className={s.mobileDatePickerFooter}>
        <Button fill={true} type="button" onClick={handleSubmit}>
          {buttonLabel ? (
            buttonLabel
          ) : (
            <FormattedMessage
              description="Mobile date time picker, modal submit button"
              defaultMessage="Select"
              id="VymiF9"
            />
          )}
        </Button>
      </div>
    </div>
  );
};
