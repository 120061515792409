import { call, take } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';

function* waitForRehydration() {
  yield take(REHYDRATE);
}

export function* rootSaga() {
  // this saga should go before any sagas using the store
  // until store rehydration is finished PersistGate shows preloader, so no redirects should happen
  yield call(waitForRehydration);
}
