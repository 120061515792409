import { createSlice, createAction } from '@reduxjs/toolkit';

import { Location } from '_api/types';
import { RootState } from '_store';

export type MapInteractionMode = 'view' | 'edit';

export type FieldToEdit = {
  type: 'pickup' | 'stop';
  position: { lat: number; lng: number };
  index?: number;
};

export type GeocodingState = 'initial' | 'loading' | 'done';

export type MapInteractionState = {
  mode: MapInteractionMode;
  fieldToEdit?: FieldToEdit;
  currentLocation?: Location | null;
  geocodingState: GeocodingState;
  eta?: number | null;
};

const initialState: MapInteractionState = {
  mode: 'view',
  fieldToEdit: undefined,
  currentLocation: undefined,
  geocodingState: 'initial',
  eta: undefined,
};

export const setEditMode = createAction<{ field: FieldToEdit }>('mapInteraction/setEditMode');

export const setCurrentLocation = createAction<{ location: Location | null }>(
  'mapInteraction/setCurrentLocation',
);

export const setGeocodingState = createAction<{ geocodingState: GeocodingState }>(
  'mapInteraction/setGeocodeState',
);

export const setEta = createAction<{ eta: number | null }>('mapInteraction/setEta');

export const restoreViewMode = createAction('mapInteraction/restoreViewMode');

export const mapModeSelector = (state: RootState): MapInteractionMode => state.mapInteraction.mode;

export const fieldToEditSelector = (state: RootState) => state.mapInteraction.fieldToEdit;

export const currentLocationSelector = (state: RootState) => state.mapInteraction.currentLocation;

export const geocodingStateSelector = (state: RootState) => state.mapInteraction.geocodingState;

export const etaSelector = (state: RootState) => state.mapInteraction.eta;

const mapInteractionSlice = createSlice({
  name: 'mapInteraction',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setEditMode, (state, { payload }) => ({
      ...state,
      mode: 'edit',
      fieldToEdit: payload.field,
    }));

    builder.addCase(restoreViewMode, () => initialState);

    builder.addCase(setCurrentLocation, (state, { payload }) => ({
      ...state,
      currentLocation: payload.location,
    }));

    builder.addCase(setGeocodingState, (state, { payload }) => ({
      ...state,
      geocodingState: payload.geocodingState,
    }));

    builder.addCase(setEta, (state, { payload }) => ({
      ...state,
      eta: payload.eta,
    }));
  },
});

export { mapInteractionSlice };
