import { createAction, createSlice } from '@reduxjs/toolkit';

import { CardData, Company, ErrorShape, PhoneShape, User } from '_api/types';
import { RootState } from '_store';

export type RegistrationCompany = Partial<Company>;
export type RegistrationUser = Partial<User>;

export type RegistrationState = {
  isLoading: boolean;
  error: ErrorShape | null;
  registrationAdd3dsCardError: ErrorShape | null;
  registrationUpdate3dsCardError: ErrorShape | null;
  company: RegistrationCompany | null;
  isUserExist: boolean;
  paymentMethod: CardData | null;
  serverVatUrl: string | null; // AE only
  clientVatFile: File | null; // AE only
};

const initialState: RegistrationState = {
  isLoading: false,
  error: null,
  registrationAdd3dsCardError: null,
  registrationUpdate3dsCardError: null,
  company: null,
  isUserExist: false,
  paymentMethod: null,
  serverVatUrl: null,
  clientVatFile: null,
};

export const setIsLoading = createAction<{ isLoading: boolean }>('registration/setIsLoading');
export const resetRegistrationState = createAction('registration/resetRegistrationState');
export const createUser = createAction<{ phone: PhoneShape; captchaToken: string; locale: string }>(
  'registration/createUser',
);
export const createUserSucceed = createAction<{
  isUserExist: boolean;
}>('registration/createUserSucceed');
export const createUserFailed = createAction<{ error: ErrorShape }>(
  'registration/createUserFailed',
);

export const isLoadingSelector = (state: RootState) => state.registration.isLoading;

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(resetRegistrationState, state => ({
      ...state,
      ...initialState,
    }));

    builder.addCase(setIsLoading, (state, { payload: { isLoading } }) => ({
      ...state,
      isLoading,
    }));

    builder.addCase(createUser, state => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(createUserSucceed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      isUserExist: payload.isUserExist,
      error: null,
      pinSentAt: new Date().toISOString(),
    }));
    builder.addCase(createUserFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }));
  },
});

export { registrationSlice };
