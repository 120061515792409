import { call, put } from 'redux-saga/effects';

import { apiClient } from '_api';
import {
  loadEmployees as loadEmployeesAction,
  loadEmployeesSucceed as loadEmployeesSucceedAction,
  loadEmployeesFailed as loadEmployeesFailedAction,
} from '_store/employees';
import { normalizeEmployees } from '_api/normalize';
import { handleApiError } from '_utils/handleApiError';
import { companySelector } from '_queries/company/selectors';

function* loadEmployees(action: ReturnType<typeof loadEmployeesAction>) {
  try {
    const company = companySelector();

    const { results, skip, limit, total_entries } = yield call(apiClient.getEmployees, {
      ...action.payload,
      companyId: company?.id as string,
    });

    const {
      entities: { employees = {} },
      result: order = [],
    } = normalizeEmployees(results);

    yield put(
      loadEmployeesSucceedAction({
        skip,
        limit,
        order,
        appendMode: action.payload.appendMode,
        entities: employees,
        total: total_entries,
      }),
    );
  } catch (error) {
    yield put(loadEmployeesFailedAction({ error: handleApiError(error) }));
  }
}

export { loadEmployees };
