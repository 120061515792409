import React from 'react';
import { useIntl } from 'react-intl';

import { ServiceOptions } from '_api/types';

import { MESSAGES, serviceItemOptionsIcons } from '../../constants';

import s from './styles.scss';

type OptionItemProps = {
  type: 'baggage' | 'passenger';
  min?: number;
  max?: number;
  plain: number | string;
};

const OptionItem = ({ type, min, max, plain }: OptionItemProps) => {
  const formattedText =
    min && max ? (
      <>
        {min} – {max}
      </>
    ) : (
      plain
    );

  const Icon = serviceItemOptionsIcons[type];

  return (
    <>
      <Icon className={s.itemOptionIcon} />
      {formattedText}
    </>
  );
};

type Props = {
  options?: null | ServiceOptions;
};

const ItemOptions = ({ options }: Props) => {
  const intl = useIntl();

  return options && (options.baggage || options.passenger) ? (
    <ul className={s.itemOptions}>
      {options.passenger && (
        <li className={s.itemOption} title={intl.formatMessage(MESSAGES.passenger)}>
          <OptionItem type="passenger" plain={options?.passenger} />
        </li>
      )}

      {options.baggage && (
        <li className={s.itemOption} title={intl.formatMessage(MESSAGES.baggage)}>
          <OptionItem
            type="baggage"
            min={options?.baggage_min}
            max={options?.baggage_max}
            plain={options?.baggage}
          />
        </li>
      )}
    </ul>
  ) : null;
};

export { ItemOptions };
