import { createSlice, createAction, createSelector, PayloadAction } from '@reduxjs/toolkit';
import compose from 'lodash/fp/compose';
import isNil from 'lodash/fp/isNil';
import pick from 'lodash/fp/pick';
import pickBy from 'lodash/fp/pickBy';
import uniq from 'lodash/fp/uniq';

import { setOrdersCommonFilters } from '_store/common/actions';
import {
  ErrorShape,
  CurrencyCode,
  GetOrderRequestPayload,
  OrderRequest,
  OrderRequestStatus,
} from '_api/types';
import { RootState } from '_store';
import { DateRange } from '_store/types';
import { formatAndJoinNames } from '_utils/formatAndJoinNames';
import { formatPassengerName } from '_utils/formatPassengerName';
import { EMPTY_VALUE } from '_constants';
import { companyTypeSelector } from '_queries/company/selectors';
import { getIsHospitalityType } from '_utils/getCompanyType';

type OrderRequestOrder = Array<string>;
type OrderRequestEntities = Record<string, OrderRequest>;
type OrderRequestsTableData = Array<{
  id: string;
  data: {
    number: { value: number | null };
    status: { value: OrderRequest['status'] };
    class: { value: string };
    start: { value: { date: string | null; utcOffset: number | null } };
    passenger: { value: string };
    concierge: { value: string };
    cost: { value: { amount: number | null; currencyCode: CurrencyCode | null } };
  };
}>;

export type OrderRequestsState = {
  error: ErrorShape | null;
  isLoading: boolean;
  total: number;
  dateRange: DateRange | null;
  number: number | null;
  statuses: OrderRequestStatus[];
  creatorId: string | null;
  userPhone: string | null;
  order: OrderRequestOrder;
  entities: OrderRequestEntities;
  filterType: FilterType;
};

export const FILTERS = {
  DATE_RANGE: 'dateRange',
  USER: 'creatorId',
  STATUS: 'statuses',
  QUERY: 'query',
} as const;

type OrderRequestFilter = (typeof FILTERS)[keyof typeof FILTERS];

export type FilterType = Extract<OrderRequestFilter, 'creatorId' | 'statuses'>;

export const filterTypes: FilterType[] = ['creatorId', 'statuses'];

export type Filters = {
  dateRange?: DateRange | null;
  statuses?: OrderRequestStatus[];
  creatorId?: string;
  query?: string;
};

const orderRequestFilters: OrderRequestFilter[] = ['dateRange', 'query', ...filterTypes];

const pickFilters: (orderRequestsState: OrderRequestsState) => Filters = compose(
  pickBy(value => !isNil(value)),
  pick(orderRequestFilters),
);

export const loadOrderRequests = createAction<
  Filters & {
    limit?: number;
    skip?: number;
  }
>('orderRequests/loadOrderRequests');
export const loadOrderRequestsFailed = createAction<{ error: ErrorShape }>(
  'orderRequests/loadOrderRequestsFailed',
);
export const loadOrderRequestsSucceed = createAction<{
  skip: number;
  limit: number;
  total: number;
  order: OrderRequestOrder;
  entities: OrderRequestEntities;
}>('orderRequests/loadOrderRequestsSucceed');

export const loadOrderRequest = createAction<GetOrderRequestPayload>(
  'orderRequests/loadOrderRequest',
);
export const loadOrderRequestFailed = createAction<{ error: ErrorShape }>(
  'orderRequests/loadOrderRequestFailed',
);
export const loadOrderRequestSucceed = createAction<{
  orderRequest: OrderRequest;
}>('orderRequests/loadOrderRequestSucceed');

export const updateOrderRequest = createAction<{ orderRequest: OrderRequest }>(
  'orderRequests/updateOrderRequest',
);

export const orderRequestsOrderSelector = (state: RootState) => state.orderRequests.order;
export const orderRequestsEntitiesSelector = (state: RootState) => state.orderRequests.entities;
export const orderRequestsSelector = createSelector(
  orderRequestsOrderSelector,
  orderRequestsEntitiesSelector,
  (order, entities) => order.map(id => entities[id]),
);
export const orderRequestsCountSelector = (state: RootState) => state.orderRequests.total;
export const filterTypeSelector = (state: RootState) => state.orderRequests.filterType;

export const activeFiltersSelector = (state: RootState): Filters =>
  pickFilters(state.orderRequests);

export const dateRangeSelector = (state: RootState) => state.orderRequests.dateRange;

export const orderRequestByIdSelector = createSelector(
  orderRequestsEntitiesSelector,
  (state: RootState, { id }: { id: string }) => id,
  (orderRequestEntities, id): OrderRequest | null => orderRequestEntities[id] || null,
);

export const isLoadingSelector = (state: RootState) => state.orderRequests.isLoading;
export const canLoadMoreSelector = createSelector(
  orderRequestsOrderSelector,
  orderRequestsCountSelector,
  (order, total) => order.length < total,
);

export const orderRequestErrorSelector = (state: RootState) => state.orderRequests.error;

export const orderRequestsTableDataSelector = createSelector(
  orderRequestsSelector,
  companyTypeSelector,
  (orderRequests, companyType): OrderRequestsTableData =>
    orderRequests.map(orderRequest => {
      const { id, employee, number, user, payment, service, sorted_at, status, city, reference } =
        orderRequest;

      const isHospitalityType = getIsHospitalityType(companyType);
      const userName = user?.name || EMPTY_VALUE;

      return {
        id,
        data: {
          number: { value: number ?? null },
          status: { value: status },
          class: { value: service.title },
          start: {
            value: {
              date: sorted_at || null,
              utcOffset: service.utc_offset ?? null,
            },
          },
          passenger: {
            value: isHospitalityType
              ? formatPassengerName(userName)
              : formatAndJoinNames([employee?.name || '', userName]),
          },
          concierge: {
            value: formatPassengerName(employee?.name || ''),
          },
          cost: {
            value: {
              amount: payment?.total ?? null,
              currencyCode: payment?.currency_code ?? null,
            },
          },
          location: { value: city?.name ?? EMPTY_VALUE },
          passengerPhone: { value: employee?.phone },
          reference: { value: reference ?? EMPTY_VALUE },
        },
      };
    }),
);

const initialState: OrderRequestsState = {
  error: null,
  isLoading: false,
  total: 0,
  dateRange: null,
  number: null,
  statuses: [],
  creatorId: null,
  userPhone: null,
  order: [],
  entities: {},
  filterType: 'creatorId',
};

const orderRequestsSlice = createSlice({
  name: 'orderRequests',
  initialState,
  reducers: {
    setFilterType(state, { payload: { type } }: PayloadAction<{ type: FilterType }>) {
      state.filterType = type;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      loadOrderRequests,
      (state, { payload: { skip, dateRange, statuses, creatorId, query } }) => ({
        ...state,
        order: skip ? state.order : [],
        entities: skip ? state.entities : {},
        dateRange: dateRange || null,
        statuses: statuses || [],
        creatorId: creatorId || null,
        query: query || null,
        isLoading: true,
        error: null,
      }),
    );
    builder.addCase(loadOrderRequestsSucceed, (state, { payload: { total, order, entities } }) => ({
      ...state,
      total,
      order: uniq([...state.order, ...order]),
      entities: { ...state.entities, ...entities },
      isLoading: false,
      error: null,
    }));
    builder.addCase(loadOrderRequestsFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }));

    builder.addCase(loadOrderRequest, state => ({ ...state, isLoading: true, error: null }));
    builder.addCase(loadOrderRequestFailed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }));
    builder.addCase(loadOrderRequestSucceed, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: null,
      entities: { [payload.orderRequest.id]: payload.orderRequest },
    }));
    builder.addCase(updateOrderRequest, (state, { payload }) => ({
      ...state,
      entities: { ...state.entities, [payload.orderRequest.id]: payload.orderRequest },
    }));

    builder.addCase(setOrdersCommonFilters, (state, { payload }) => ({
      ...state,
      dateRange: payload.dateRange || initialState.dateRange,
    }));
  },
});

export { orderRequestsSlice };
