import React, { useMemo } from 'react';
import { Button } from '@wheely/web-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, FormikHelpers } from 'formik';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { getPhoneCountryCode } from '_utils/getPhoneCountryCode';
import { HCaptchaProps } from '_hooks/useCaptcha';
import { FormikPhoneInput } from '_/components/FormikFields/FormikPhoneInput';

import styles from './styles.scss';
import { makeValidationSchema } from './validationSchema';

export type FormValues = Record<'phone', string>;

type PhoneFormProps = {
  hCaptchaProps: HCaptchaProps;
  isLoading?: boolean;
  isPhoneInputDisabled?: boolean;
  phoneCodeOptions: { value: string; localizedName: Record<'en' | 'fr' | 'ru', string> }[];
  serverErrorMessage?: string;
  enteredPhone?: string;
  onSubmit(formValues: FormValues, formikHelpers: FormikHelpers<FormValues>): Promise<void>;
};

export const PhoneForm = ({
  onSubmit,
  isLoading,
  serverErrorMessage,
  enteredPhone,
  hCaptchaProps,
  isPhoneInputDisabled,
  phoneCodeOptions,
}: PhoneFormProps) => {
  const intl = useIntl();
  const initialPhoneNumber = enteredPhone || getPhoneCountryCode(intl.locale);
  const validationSchema = useMemo(() => makeValidationSchema(intl), [intl]);

  return (
    <Formik
      initialValues={{ phone: initialPhoneNumber }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount={true}
    >
      {formProps => (
        <form onSubmit={formProps.handleSubmit} className={styles.form}>
          <FormikPhoneInput
            name="phone"
            inputProps={{
              title: intl.formatMessage({
                defaultMessage: 'Phone Number',
                id: 'global_phone_input_title',
                description: 'Phone Input title',
              }),
              options: phoneCodeOptions,
              disabled: isPhoneInputDisabled,
              'data-test-id': 'login_phone_input',
              errorMessage: serverErrorMessage,
              autoComplete: 'off',
            }}
          />
          <Button
            className={styles.submit}
            type="submit"
            data-test-id="login_phone_submit_button"
            disabled={!formProps.isValid || isLoading}
            isLoading={isLoading}
          >
            <FormattedMessage
              defaultMessage="Continue"
              id="global_continue_button"
              description="Text on submit phone number button"
            />
          </Button>
          <HCaptcha {...hCaptchaProps} />
        </form>
      )}
    </Formik>
  );
};
