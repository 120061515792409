import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient } from '_api';
import { User } from '_api/types';

import { COMPANY, USER } from '../keys';

type PatchCompanyPaymentsPayload = {
  paymentMethodId: string;
  sessionId: string;
};

export const usePutCompanyPayments = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [COMPANY.UPDATE_PAYMENTS],
    mutationFn: ({ paymentMethodId, sessionId }: PatchCompanyPaymentsPayload) =>
      apiClient.update3dsCard({ payment_method_id: paymentMethodId, session_id: sessionId }),
    onSuccess: () => {
      const companyId = queryClient.getQueryData<User>([USER.LOAD])?.company?.id;

      queryClient.invalidateQueries({ queryKey: [COMPANY.LOAD, companyId] });
    },
  });
};
