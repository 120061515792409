import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { FieldArray, FormikErrors, FormikTouched } from 'formik';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import cn from 'clsx';

import { companyCityOrUserLocationSelector } from '_store/common/selectors';
import { useTypedSelector } from '_store';
import { Location } from '_api/types';
import { AddFieldButton } from '_common/AddFieldButton';
import { DropoffAddressField } from '_common/DropoffAddressField';
import { useSyncOnDiff } from '_hooks/useSyncOnDiff';
import { EditLocationOnMapButton } from '_common/EditLocationOnMapButton';
import { setEditMode } from '_store/mapInteraction';

import { MESSAGES } from './constants';
import s from './styles.scss';

const reorder = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export type Stop = {
  _id: string;
  data: Location | null | undefined;
};

export type MultistopGroupProps = {
  className?: string;
  disabled: boolean;
  stops: Stop[];
  name: string;
  touched?: FormikTouched<Stop>[];
  errors?: string[] | string | FormikErrors<Stop>[];
  onSync?: (locations: Array<Location | null | undefined>) => void;
  onChange: ({ value, index }: { value: Location | null; index: number }) => void;
  setTouched: ({ value, index }: { value: boolean; index: number }) => void;
  setFieldValue: (stops: Stop[]) => void;
  clearServerError?: () => void;
};

export const MultistopGroup = ({
  className,
  stops,
  disabled,
  name,
  onChange,
  setTouched,
  touched,
  errors,
  onSync,
  setFieldValue,
  clearServerError,
}: MultistopGroupProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const defaultPosition = useTypedSelector(companyCityOrUserLocationSelector);

  useSyncOnDiff({ data: stops.map(stop => stop.data), syncFn: onSync });

  const handleEditPinOnMap = useCallback(
    (position: Location['position'] | undefined, index: number) => {
      const positionToSet = position ? { lat: position[0], lng: position[1] } : defaultPosition;

      if (positionToSet)
        dispatch(
          setEditMode({
            field: {
              type: 'stop',
              position: positionToSet,
              index,
            },
          }),
        );
    },
    [dispatch, defaultPosition],
  );

  return (
    <FieldArray name={name}>
      {({ remove, push }) => (
        <div className={className}>
          <div>
            <DragDropContext
              onDragEnd={(result: DropResult) => {
                const from = result.source.index;
                let to = result.destination?.index;

                // if dragged out of the list
                if (typeof to !== 'number') {
                  to = from;
                }

                // we cannot use "move" helper by Formik
                // https://github.com/jaredpalmer/formik/issues/2378
                // so
                setFieldValue(reorder(stops, from, to));
              }}
            >
              <Droppable droppableId="droppable">
                {droppableProvided => (
                  <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                    {stops.map(({ _id, data: stop }, index) => {
                      const hasDeleteButton = stops.length !== 1;
                      const isTouched = Array.isArray(touched) && Boolean(touched[index]);
                      const error = errors?.[index];

                      // formik workaround: always cast to string
                      const fieldError = typeof error === 'string' ? error : undefined;

                      return (
                        <Draggable key={_id} draggableId={_id} index={index}>
                          {draggableProvided => (
                            <div
                              className={s.draggableElement}
                              ref={draggableProvided.innerRef}
                              style={draggableProvided.draggableProps.style}
                              {...draggableProvided.draggableProps}
                            >
                              <div
                                className={cn(s.draggableArea, { [s.hidden]: stops.length < 2 })}
                                {...draggableProvided.dragHandleProps}
                              />
                              <div className={s.fieldContainer}>
                                <DropoffAddressField
                                  location={stop}
                                  deleteButtonProps={{
                                    onClick: () => {
                                      if (clearServerError) {
                                        clearServerError();
                                      }

                                      remove(index);
                                    },
                                  }}
                                  hasDeleteButton={hasDeleteButton}
                                  disabled={disabled}
                                  onChange={({ location }) => onChange({ value: location, index })}
                                  setTouched={value => setTouched({ value, index })}
                                  touched={isTouched}
                                  error={fieldError}
                                  formGroupProps={{
                                    className: s.addressFieldFormGroup,
                                  }}
                                />
                                <EditLocationOnMapButton
                                  disabled={disabled}
                                  className={s.mapButton}
                                  onClick={() => {
                                    handleEditPinOnMap(stop?.position, index);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          {stops.length < 4 && (
            <AddFieldButton
              disabled={disabled}
              onClick={() => {
                if (clearServerError) {
                  clearServerError();
                }

                push({ _id: uuid(), data: null });
              }}
              data-test-id={'add-additional-address-button'}
              text={intl.formatMessage(MESSAGES.addAddressTitle)}
            />
          )}
        </div>
      )}
    </FieldArray>
  );
};
