import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'clsx';
import { useIntl } from 'react-intl';
import { Button, ButtonGroup, IconArrowLeft, SelectField, SelectFieldItem } from '@wheely/ui-kit';

import { City, Location } from '_api/types';
import { CitySelect, CitySelectItem } from '_common/CitySelect';
import { useLocalSagas } from '_hooks';
import { loadCityAirports } from '_sagas/newJourney';
import { handleApiError } from '_utils/handleApiError';
import { useGetCitiesQuery } from '_queries/citites';

import dialogStyles from '../../styles.scss';

import s from './styles.scss';

type Props = {
  onConfirm: (location: Location) => void;
  onBack: () => void;
};

const getLocationPositionString = (location: Location | null): string => {
  if (!location || !location.position) {
    return '';
  }

  return location.position.join(',');
};

export const AirportSelect = ({ onConfirm, onBack }: Props) => {
  const intl = useIntl();

  const { runLocalSaga } = useLocalSagas();

  const { data } = useGetCitiesQuery();

  const [selectedCity, setSelectedCity] = useState<City>();
  const [selectedAirport, setSelectedAirport] = useState<Location | null>(null);
  const [selectedTerminal, setSelectedTerminal] = useState<Location | null>(null);
  const [airports, setAirports] = useState<Location[]>([]);
  const [terminals, setTerminals] = useState<Location[]>([]);
  const [isAirportsLoading, setIsAirportsLoading] = useState(false);

  const handleConfirmButtonClick = () => {
    if (selectedTerminal) {
      onConfirm(selectedTerminal);

      return;
    }

    if (selectedAirport) {
      onConfirm(selectedAirport);
    }
  };

  const handleCitySelect = ({ value }: CitySelectItem) => {
    const newSelectedCity = Object.values(data?.cities || {}).find(city => city.id === value);

    setSelectedCity(newSelectedCity as City);
  };

  const updateSelectedAirport = useCallback((newSelectedAirport?: Location) => {
    if (!newSelectedAirport) {
      return;
    }

    setSelectedAirport(newSelectedAirport);

    const airportTerminals = newSelectedAirport.locations || [];

    setTerminals(airportTerminals);
    setSelectedTerminal(airportTerminals[0] || null);
  }, []);

  useEffect(() => {
    setAirports([]);
    setTerminals([]);
    setSelectedAirport(null);
    setSelectedTerminal(null);

    if (!selectedCity) {
      return;
    }

    setIsAirportsLoading(true);

    runLocalSaga(loadCityAirports, { city: selectedCity })
      .then((result: Location[]) => {
        setAirports(result);

        if (result.length > 0) {
          updateSelectedAirport(result[0]);
        }
      })
      .catch(error => {
        handleApiError(error);
      })
      .finally(() => setIsAirportsLoading(false));
  }, [selectedCity, updateSelectedAirport, runLocalSaga]);

  const airportItems = useMemo<SelectFieldItem[]>(
    () =>
      airports.map(airport => ({
        value: getLocationPositionString(airport),
        title: airport.name,
      })),
    [airports],
  );

  const terminalItems = useMemo<SelectFieldItem[]>(
    () =>
      terminals.map(terminal => {
        const subtypePostfix =
          terminal.subtype === 'private'
            ? ` (${intl.formatMessage({
                defaultMessage: 'Private Aviation',
                id: 'rVVapc',
                description: 'Airport private terminal',
              })})`
            : '';

        return {
          value: getLocationPositionString(terminal),
          title: `${terminal.name}${subtypePostfix}`,
        };
      }),
    [intl, terminals],
  );

  const handleAirportItemSelect = useCallback(
    ({ value: positionString }: SelectFieldItem) => {
      const newSelectedAirport = airports.find(
        airport => getLocationPositionString(airport) === positionString,
      );

      updateSelectedAirport(newSelectedAirport);
    },
    [airports, updateSelectedAirport],
  );

  const handleTerminalItemSelect = useCallback(
    ({ value: positionString }: SelectFieldItem) => {
      const newSelectedTerminal = terminals.find(
        terminal => getLocationPositionString(terminal) === positionString,
      );

      setSelectedTerminal(newSelectedTerminal ?? null);
    },
    [terminals],
  );

  return (
    <>
      <div className={dialogStyles.titleWithBackButton}>
        <Button
          className={dialogStyles.backButton}
          minimal={true}
          onClick={onBack}
          icon={<IconArrowLeft />}
        />
        <div>
          {intl.formatMessage({
            defaultMessage: 'Airport',
            id: 'oVjoDZ',
            description: 'Airport modal title',
          })}
        </div>
      </div>

      <CitySelect
        title={intl.formatMessage({
          description: 'City field title',
          defaultMessage: 'City',
          id: 'ADe7qP',
        })}
        placeholder={intl.formatMessage({
          description: 'City field placeholder',
          defaultMessage: 'Select city',
          id: 'i7HlwB',
        })}
        value={selectedCity ? selectedCity.id : null}
        onItemSelect={handleCitySelect}
      />

      <SelectField
        title={intl.formatMessage({
          description: 'Airport field title',
          defaultMessage: 'Airport',
          id: 'MKDKDL',
        })}
        placeholder={intl.formatMessage({
          description: 'Airport field placeholder',
          defaultMessage: 'Select airport',
          id: 'UGuoYJ',
        })}
        items={airportItems}
        onItemSelect={handleAirportItemSelect}
        disabled={isAirportsLoading}
        value={getLocationPositionString(selectedAirport)}
      />

      <SelectField
        formGroupProps={{
          className: cn({
            [s.hidden]: terminals.length === 0,
          }),
        }}
        title={intl.formatMessage({
          description: 'Terminal field title',
          defaultMessage: 'Terminal',
          id: 'yiQnof',
        })}
        placeholder={intl.formatMessage({
          description: 'Terminal field placeholder',
          defaultMessage: 'Select terminal',
          id: '0gBRlS',
        })}
        items={terminalItems}
        onItemSelect={handleTerminalItemSelect}
        disabled={isAirportsLoading || terminalItems.length === 0}
        value={getLocationPositionString(selectedTerminal)}
      />

      <ButtonGroup className={dialogStyles.footer}>
        <Button
          className={cn(dialogStyles.footerButton, s.confirmButton)}
          onClick={handleConfirmButtonClick}
          disabled={isAirportsLoading}
          loading={isAirportsLoading}
          text={intl.formatMessage({
            defaultMessage: 'Confirm',
            id: 'yrTAdQ',
            description: 'Confirm button text',
          })}
        />
      </ButtonGroup>
    </>
  );
};
