import { useCallback, useLayoutEffect, useRef } from 'react';

export const useMethod = <T extends (...args: any[]) => any>(func: T): T => {
  const funcRef = useRef(func);

  useLayoutEffect(() => {
    funcRef.current = func;
  }, [func]);

  return useCallback((...args: any[]) => funcRef.current(...args), []) as T;
};
