import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useGoogleMap } from '@react-google-maps/api';
import { Button, Intent, IconLocation } from '@wheely/ui-kit';
import { useIntl } from 'react-intl';

import { getCurrentPosition } from '_utils/getCurrentPosition';
import { setGeocodingState, geocodingStateSelector } from '_store/mapInteraction';
import { useLocalSagas } from '_hooks';
import { loadReversedGeocodeAndEtaSaga } from '_sagas/map';
import { useTypedSelector } from '_store';

import s from './styles.scss';

export const UserPositionButton = () => {
  const intl = useIntl();
  const map = useGoogleMap();
  const { runLocalSaga } = useLocalSagas();
  const dispatch = useDispatch();
  const geocodingState = useTypedSelector(geocodingStateSelector);

  const handleCurrentLocationButtonClick = useCallback(async () => {
    try {
      dispatch(setGeocodingState({ geocodingState: 'loading' }));

      const { coords } = await getCurrentPosition();

      const updateCenter = { lat: coords.latitude, lng: coords.longitude };

      map?.setCenter(updateCenter);
      runLocalSaga(loadReversedGeocodeAndEtaSaga, updateCenter, {
        goToPinTitle: intl.formatMessage({
          defaultMessage: 'Go to pin',
          id: '7MMBrY',
          description: 'User selects pin on map which has no address',
        }),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("Can't get current position:", error);
    } finally {
      dispatch(setGeocodingState({ geocodingState: 'done' }));
    }
  }, [map, runLocalSaga, dispatch, intl]);

  return (
    <div className={s.container}>
      <Button
        disabled={geocodingState === 'loading'}
        onClick={handleCurrentLocationButtonClick}
        intent={Intent.NONE}
        className={s.button}
        minimal={true}
        icon={<IconLocation width={22} height={22} />}
      />
    </div>
  );
};
