import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'clsx';

import { ChauffeurForADayType } from '_api/types';
import { ChauffeurForADaySign } from '_common/ChauffeurForADaySign';

import s from './styles.scss';

type Props = {
  minDurationHours: number;
  maxDurationHours: number;
  chauffeurForADayType: ChauffeurForADayType;
  isAvailable: boolean;
};

export const ExpectedDurationOption = ({
  minDurationHours,
  maxDurationHours,
  chauffeurForADayType,
  isAvailable,
}: Props) => (
  <div
    className={cn(s.expectedDurationOption, {
      [s.disabled]: !isAvailable,
    })}
  >
    <ChauffeurForADaySign type={chauffeurForADayType} />
    <div className={s.text}>
      {chauffeurForADayType === 'full_day' ? (
        <FormattedMessage
          defaultMessage="Full Day"
          id="e3FoQM"
          description="Chauffeur for a day full day option text"
        />
      ) : (
        <FormattedMessage
          defaultMessage="Half Day"
          id="bHMVVc"
          description="Chauffeur for a day half day option text"
        />
      )}
      {!isAvailable && (
        <span>
          &nbsp;-&nbsp;
          <FormattedMessage
            defaultMessage="Not available"
            id="CSVhL0"
            description="Chauffeur for a day option not available text"
          />
        </span>
      )}
    </div>
    <div className={s.period}>
      {minDurationHours >= 6 ? (
        <FormattedMessage
          defaultMessage="Over 6 hours"
          id="new_journey_cfad_full_day_text"
          description="Chauffeur for a day period text"
        />
      ) : (
        <FormattedMessage
          defaultMessage="{maxDurationHours, plural, one {} other {{minDurationHours}-{maxDurationHours} Hours}}"
          id="C80qW6"
          description="Chauffeur for a day period text"
          values={{ minDurationHours, maxDurationHours }}
        />
      )}
    </div>
  </div>
);
