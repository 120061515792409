import localforage from 'localforage';
import { createTransform } from 'redux-persist';

import { AuthState, initialState as authInitialState } from './auth';

const DEFAULT_PHONE_SHAPE = { number: '', code: '' }; // do not let to store null

const authTransform = createTransform(
  ({ phone = DEFAULT_PHONE_SHAPE, isLoggedIn }: AuthState) => ({
    ...authInitialState,
    phone,
    isLoggedIn,
  }),
  ({ phone = DEFAULT_PHONE_SHAPE, isLoggedIn }: AuthState) => ({
    ...authInitialState,
    phone,
    isLoggedIn,
  }),
  { whitelist: ['auth'] },
);

export const persistConfig = {
  key: 'root',
  storage: localforage.createInstance({
    driver: localforage.INDEXEDDB,
    name: 'w4b-state',
    version: 1.0,
    storeName: 'w4b_state_storage',
  }),
  whitelist: ['auth'],
  blacklist: [],
  transforms: [authTransform],
};
