import { Role } from '_constants';

export const PERMISSION_SEPARATOR = ':';

export type Action = 'read' | 'write';

export type Module =
  | 'newJourney'
  | 'journeys'
  | 'people'
  | 'billing'
  | 'reports'
  | 'policies'
  | 'settings'
  | 'common';

export type Resource =
  | 'page'
  | 'billingTab'
  | 'tabPanel'
  | 'employeeFormMonthlyLimit'
  | 'filterUser'
  | 'filterDeparment'
  | 'sendReportToEmail'
  | 'pickupAddressField'
  | 'mailingAddressField'
  | 'authEmailField'
  | 'companyAttachCardNotifier'
  | 'companyDebtsNotifier'
  | 'privateRoute'
  | 'employeesTab'
  | 'departmentsTab'
  | 'analyticsTab'
  | 'adminRole'
  | 'financeRole';

export type Permission = `${Module}:${Resource}:${Action}`;

export const permissionsByRole: Record<Role, Permission[]> = {
  admin: [
    'newJourney:page:read',
    'policies:page:read',
    'people:page:read',
    'people:employeeFormMonthlyLimit:write',
    'people:adminRole:write',
    'people:financeRole:write',
    'billing:page:read',
    'journeys:page:read',
    'journeys:filterUser:read',
    'journeys:filterDeparment:read',
    'journeys:filterUser:write',
    'journeys:filterDeparment:write',
    'journeys:sendReportToEmail:write',
    'reports:page:read',
    'reports:tabPanel:read',
    'reports:employeesTab:read',
    'reports:departmentsTab:read',
    'reports:analyticsTab:read',
    'settings:pickupAddressField:write',
    'settings:mailingAddressField:write',
    'settings:authEmailField:write',
    'settings:billingTab:read',
    'common:companyAttachCardNotifier:read',
    'common:companyDebtsNotifier:read',
    'common:privateRoute:read',
  ],
  travel_coordinator: [
    'newJourney:page:read',
    'journeys:page:read',
    'journeys:filterUser:read',
    'journeys:filterDeparment:read',
    'journeys:filterUser:write',
    'journeys:filterDeparment:write',
    'journeys:sendReportToEmail:write',
    'settings:authEmailField:write',
  ],
  finance: [
    'billing:page:read',
    'settings:billingTab:read',
    'settings:pickupAddressField:write',
    'settings:mailingAddressField:write',
    'settings:authEmailField:write',
  ],
  user: ['newJourney:page:read', 'journeys:page:read'],
};

export const getPermissionsByRole = (role: Role) => permissionsByRole[role];
