import { defineMessages } from 'react-intl';
import { IconBaggage, IconPassenger } from '@wheely/ui-kit';

export const MESSAGES = defineMessages({
  passenger: {
    id: 'passenger',
    description: 'New ride service offer passenger option',
    defaultMessage: 'Passengers',
  },
  baggage: {
    id: 'baggage',
    description: 'New ride service offer baggage option',
    defaultMessage: 'Baggage',
  },
  aboutService: {
    id: 'aboutService',
    description: 'About service link title',
    defaultMessage: 'About this service',
  },
  fareMinimumPrefix: {
    id: 'fareMinimumPrefix',
    description: 'Fare minimum prefix',
    defaultMessage: 'From',
  },
  serviceEtaSchedule: {
    id: 'serviceEtaSchedule',
    description: 'Service offer menu item schedule mode label',
    defaultMessage: 'Schedule',
  },
});

export const serviceItemOptionsIcons = {
  passenger: IconPassenger,
  baggage: IconBaggage,
};
