import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getEtaDuration } from '_utils/getEtaDuration';

import s from './styles.scss';

type Props = {
  eta: number;
};

export const EtaMarkerContent = ({ eta }: Props) => {
  const etaDuration = getEtaDuration(eta);

  return (
    <div className={s.label}>
      <div className={s.value}>{etaDuration}</div>
      <div className={s.unit}>
        <FormattedMessage description="ETA pin unit name" defaultMessage="min" id="lb7Qla" />
      </div>
    </div>
  );
};
