import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { FormikHelpers } from 'formik';

import {
  EnrichedInvite,
  useGetInviteForEnrichmentQuery,
  useEnrichInviteMutation,
} from '_queries/invites';
import { ErrorShape } from '_api/types';
import { LoadingWrapper } from '_common/LoadingWrapper';
import { NotFoundWrapper } from '_common/NotFoundWrapper';
import { ErrorWrapper } from '_common/ErrorWrapper';
import { PublicLayout } from '_common/PublicLayout';

import { InviteAccepted } from './features/InviteAccepted';
import { FormValues, PhoneForm } from './features/PhoneForm';
import styles from './styles.scss';

export const AcceptInvite = () => {
  const params = useParams<Record<'inviteId', string>>();

  const { data, isFetching, isError, error } = useGetInviteForEnrichmentQuery(params.inviteId);
  const {
    mutateAsync: setPhone,
    isPending: isLoading,
    isError: isEnrichError,
    error: enrichError,
  } = useEnrichInviteMutation(params.inviteId);

  const handleSubmit = useCallback(
    async ({ phone }: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      try {
        await setPhone(phone as string);
      } catch (e) {
        if ((e as ErrorShape).status === 400) {
          formikHelpers.setFieldError('phone', (e as ErrorShape).message);
        }
      }
    },
    [setPhone],
  );

  const showErrorStub = isError || (isEnrichError && (enrichError as ErrorShape)?.status !== 400);

  return (
    <LoadingWrapper isLoading={isFetching}>
      <NotFoundWrapper isError={isError} error={error}>
        <ErrorWrapper className={styles.error} isError={showErrorStub} layout={PublicLayout}>
          <PublicLayout>
            {(data as EnrichedInvite)?.maskedPhone ? (
              <InviteAccepted {...(data as EnrichedInvite)} />
            ) : (
              <PhoneForm
                isLoading={isLoading}
                companyName={(data as EnrichedInvite)?.companyName}
                onSubmit={handleSubmit}
              />
            )}
          </PublicLayout>
        </ErrorWrapper>
      </NotFoundWrapper>
    </LoadingWrapper>
  );
};
