/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { captureException } from '@sentry/react';

import { isDev } from '_constants';
import { LangCode } from '_types/common';
import { lazyRetry } from '_utils/lazyRetry';

import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE_CODE } from './constants';

if (!window.b2bAppConfig.SAVED_LANG_CODE_KEY_NAME) {
  throw new Error('SAVED_LANG_CODE_KEY_NAME is not set');
}

const savedLangCodeKeyName = window.b2bAppConfig.SAVED_LANG_CODE_KEY_NAME;

export const updateUserLanguageCode = (langCode: LangCode): void => {
  try {
    localStorage.setItem(savedLangCodeKeyName, langCode);
  } catch (error) {
    if (isDev) {
      // eslint-disable-next-line no-console
      console.error("Can't save user locale", error);
    }

    captureException(error);
  }
};

export const getUserLanguageCode = (): LangCode => {
  let savedLocale: LangCode | null = null;

  try {
    savedLocale = localStorage.getItem(savedLangCodeKeyName) as LangCode;
  } catch (error) {
    if (isDev) {
      // eslint-disable-next-line no-console
      console.error("Can't get user locale", error);
    }

    captureException(error);
  }

  if (savedLocale) {
    return savedLocale;
  }

  const browserLocale = navigator.language || navigator.languages[0];

  if (browserLocale) {
    const [browserLanguageCode] = browserLocale.split('-') as [LangCode];

    if (AVAILABLE_LANGUAGES.includes(browserLanguageCode)) {
      return browserLanguageCode;
    }
  }

  return DEFAULT_LANGUAGE_CODE;
};

export const getLocaleData = async (languageCode: LangCode) => {
  switch (languageCode) {
    case 'fr':
      return lazyRetry(
        () => import(/* webpackChunkName: "locale-fr" */ './compiled-lang/fr.json'),
        'locale-fr',
      );

    case 'ru':
      return lazyRetry(
        () => import(/* webpackChunkName: "locale-ru" */ './compiled-lang/ru.json'),
        'locale-ru',
      );

    default:
      return lazyRetry(
        () => import(/* webpackChunkName: "locale-en" */ './compiled-lang/en.json'),
        'locale-en',
      );
  }
};
