import { useCallback, useEffect, useRef } from 'react';
import { Task } from 'redux-saga';

import { runSaga } from '_store';

type RunLocalSaga = (...args: Parameters<typeof runSaga>) => Promise<any>;

type UseLocalSagasReturn = {
  runLocalSaga: RunLocalSaga;
};

type UseLocalSagas = () => UseLocalSagasReturn;

/**
 * Expose the runLocalSaga method and cancel all local sagas on unmount component
 */
export const useLocalSagas: UseLocalSagas = () => {
  const sagaTasks = useRef<Task[]>([]);

  // Cancel all local sagas on unmount
  useEffect(
    () => () => {
      sagaTasks.current.forEach(task => {
        task.cancel();
      });
    },
    [],
  );

  const runLocalSaga: RunLocalSaga = useCallback<RunLocalSaga>((saga, ...args) => {
    const sagaTask = runSaga(saga, ...args);

    sagaTasks.current.push(sagaTask);

    return sagaTask.toPromise();
  }, []);

  return { runLocalSaga };
};
