import compact from 'lodash/fp/compact';
import compose from 'lodash/fp/compose';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';
import trim from 'lodash/fp/trim';

import { formatPassengerName } from '_utils/formatPassengerName';

const formatAndJoinNames: (names: Array<string | undefined>) => string = compose(
  join(' / '),
  map(formatPassengerName),
  compact,
  map(trim),
);

export { formatAndJoinNames };
