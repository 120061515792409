import { endOfMonth, startOfMonth } from 'date-fns';

import { ChauffeurForADayType } from '_api/types';
import { DateRange } from '_store/types';

const getCurrentMonthDateRange = (): DateRange => {
  const today = new Date();

  return {
    start: startOfMonth(today).toISOString(),
    end: endOfMonth(today).toISOString(),
  };
};

const FULL_DAY_MINIMUM_DURATION = 21600; // 6 hours

// expected duration param presented only for journeys were booked as chauffeur for a day
const getLongJourneyOptionsKey = (expectedDuration?: number): ChauffeurForADayType | null => {
  if (!expectedDuration) {
    return null;
  }

  if (expectedDuration < FULL_DAY_MINIMUM_DURATION) {
    return 'half_day';
  }

  return 'full_day';
};

export { getCurrentMonthDateRange, getLongJourneyOptionsKey };
