import React from 'react';
import { Button, ButtonProps, IconTrash } from '@wheely/ui-kit';

import s from './styles.scss';

type Props = {
  title: React.ReactNode;
  closeButtonProps?: ButtonProps;
  canClose?: boolean;
};

export const OptionalFieldHeader = ({ title, closeButtonProps, canClose }: Props) => (
  <div className={s.container}>
    <h6 className={s.title}>{title}</h6>
    {canClose && <Button minimal={true} small={true} icon={<IconTrash />} {...closeButtonProps} />}
  </div>
);
