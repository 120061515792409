import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'clsx';
import { isBefore, secondsToHours } from 'date-fns';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  H2,
  ItemListRenderer,
  ItemRenderer,
  SelectedItemPredicate,
  SelectField,
  SelectFieldProps,
  SelectMenu,
} from '@wheely/ui-kit';

import { useTypedSelector } from '_store';
import {
  isChauffeurForADayAvailableForSelectedServiceSelector,
  isChauffeurForADayAvailableSelector,
  selectedServiceOfferSelector,
  serviceOffersSelector,
} from '_store/serviceOffers';
import {
  NewJourneyState,
  expectedDurationSelector,
  newJourneySlice,
  setServiceId,
} from '_store/newJourney';
import { getServiceOfferEta } from '_utils/getServiceOfferNearestReservationOffset';
import { getEarliestReservationDate } from '_utils/getEarliestReservationDate';
import { EMPTY_VALUE } from '_constants';
import { MobileModal } from '_common/MobileModal';
import { MobileTapContainer } from '_common/MobileTapContainer';
import { useBreakpoints } from '_hooks/useBreakpoints';
import { OptionalField } from '_common/OptionalField';
import { ExpectedDurationField } from '_pages/NewJourney/components/ServiceLevels/components/ExpectedDurationField';
import { FormattedCurrency } from '_common/FormattedCurrency';
import { stopPropagation } from '_utils/stopPropagation';
import { ChauffeurForADayType, Service } from '_api/types';

import { FormGroupTitle } from '../FormGroupTitle';
import { FormValues } from '../Form/types';

import { ItemEta } from './components/ItemEta';
import { ItemImage } from './components/ItemImage';
import { ItemInfoLink } from './components/ItemInfoLink';
import { ItemFare } from './components/ItemFare';
import { ItemFormattedEarliestReservationDate } from './components/ItemFormattedEarliestReservationDate';
import { ItemOptions } from './components/ItemOptions';
import { ServiceOfferItem } from './types';
import s from './styles.scss';
import { FormattedEtaInMinutes } from './components/FormattedEtaInMinutes/FormattedEtaInMinutes';

const CFAD_INFO_LINK = 'https://wheely.com/essentials/chauffeur-for-a-day';

const selectedItemPredicate: SelectedItemPredicate<ServiceOfferItem> = (items, value) =>
  items.find(item => item.id === value) || null;

const getIsServiceAvailable = (
  isPrebookMode: boolean,
  earliestReservationDate: Date,
  pickupAt: Date | null,
  eta?: number,
) => (isPrebookMode ? isBefore(earliestReservationDate, pickupAt || new Date()) : Boolean(eta));

const getIsNotAvailableForChauffeurForADay = (
  expectedDuration: NewJourneyState['expectedDuration'],
  longJourneyOptions: Service['long_journey_options'],
) => {
  const hasLongJourneyOptions = Number(longJourneyOptions?.length) > 0;

  return expectedDuration !== null && !hasLongJourneyOptions;
};

interface FareProps {
  isNotAvailableForChauffeurForADay: boolean;
  expectedDuration: NewJourneyState['expectedDuration'];
  service: Service;
  cfadType?: ChauffeurForADayType;
  isAvailable: boolean;
  earliestReservationDate: Date;
  isDropoffExist: boolean;
}

const Fare = ({
  isNotAvailableForChauffeurForADay,
  expectedDuration,
  service,
  isAvailable,
  earliestReservationDate,
  isDropoffExist,
  cfadType,
}: FareProps) => {
  if (isNotAvailableForChauffeurForADay) {
    return (
      <div className={s.itemHint}>
        <FormattedMessage
          defaultMessage="Not available"
          id="SR9ihR"
          description="Chauffeur for a day not available text"
        />
      </div>
    );
  }

  if (expectedDuration !== null) {
    const optionIndex =
      cfadType === 'full_day' && service?.long_journey_options?.length
        ? service?.long_journey_options?.length - 1
        : 0;

    const selectedOption =
      (service.long_journey_options || []).find(
        option => option.duration_min === expectedDuration,
      ) || service?.long_journey_options?.[optionIndex];

    const durationInHours = selectedOption ? secondsToHours(selectedOption.duration_min) : 0;
    const price = (selectedOption?.price_per_hour as number) * durationInHours;

    if (selectedOption && service.fare) {
      return (
        <div className={s.itemHint}>
          <FormattedMessage
            defaultMessage="From"
            id="P8jhgn"
            description="Chauffeur for a day from text"
          />
          &nbsp;
          <FormattedCurrency value={price} currencyCode={service.fare.currency_code} />/
          {durationInHours}
          &nbsp;
          <FormattedMessage defaultMessage="h" id="+407Ep" description="hours unit shorthand" />
        </div>
      );
    }
  }

  if (!isAvailable) {
    return (
      <ItemFormattedEarliestReservationDate
        earliestReservationDate={earliestReservationDate}
        className={s.earliestReservationDate}
      />
    );
  }

  return <ItemFare fare={service.fare} isDropoffExist={isDropoffExist} className={s.fare} />;
};

const itemListRenderer: ItemListRenderer<ServiceOfferItem> = (
  listProps,
  initialContent,
  noResults,
  infiniteScrollProps,
  loadingElement,
  menuProps,
) => {
  const { itemsParentRef, renderItem, filteredItems } = listProps;

  return (
    <SelectMenu
      className={s.list}
      itemsParentRef={itemsParentRef}
      infiniteScrollProps={infiniteScrollProps}
      {...menuProps}
    >
      {filteredItems.map(renderItem).filter(Boolean)}
    </SelectMenu>
  );
};

type ListItemProps = {
  className: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  tabIndex?: number;
};

type ItemProps = ServiceOfferItem & { listItemProps: ListItemProps };

const ListItem = ({ listItemProps, image, infoLink, title, eta, options, fare }: ItemProps) => (
  <li {...listItemProps} data-test-id="service-level-item">
    <div className={s.serviceItemContent}>
      <div className={s.serviceItemImageContainer}>{image}</div>
      <div className={s.serviceItemRowsWrapper}>
        <div className={s.serviceItemRow}>
          <div className={s.serviceItemTitle}>
            <span className={s.serviceItemTitleText}>{title}</span>
            {infoLink}
          </div>
          <div className={s.serviceItemEta}>{eta}</div>
        </div>
        <div className={s.serviceItemRow}>
          {options}
          {fare}
        </div>
      </div>
    </div>
  </li>
);

const itemRenderer: ItemRenderer<ServiceOfferItem> = (item, itemProps, selectedItem) => {
  const { handleClick, modifiers } = itemProps;

  const { disabled, active } = modifiers;

  const isSelected = !disabled && selectedItem && selectedItem.id === item.id;

  const listItemProps: ListItemProps = {
    className: cn(s.serviceItem, {
      [s.disabled]: disabled,
      [s.selected]: isSelected,
      [s.active]: isSelected || active,
    }),
    onClick: disabled ? undefined : handleClick,
    tabIndex: disabled ? -1 : undefined,
  };

  return <ListItem key={item.id} listItemProps={listItemProps} {...item} />;
};

type MobileItemProps = {
  item: ServiceOfferItem;
  isSelected: boolean;
  onClick: (serviceOfferItem: ServiceOfferItem) => void;
};

const MobileItem = ({ item, onClick, isSelected }: MobileItemProps) => {
  const { disabled } = item;

  const listItemProps = {
    onClick: disabled ? undefined : () => onClick(item),
    className: cn(s.serviceItem, {
      [s.disabled]: disabled,
      [s.selected]: isSelected,
    }),
    key: item.id,
    tabIndex: disabled ? -1 : undefined,
  };

  return <ListItem listItemProps={listItemProps} {...item} />;
};

type Props = {
  disabled?: boolean;
  isDropoffExist: boolean;
  isPrebookMode: boolean;
  isWelcomeBoardSelected: boolean;
  pickupAt: null | Date;
  clearServerError?: () => void;
};

export const ServiceLevels = ({
  disabled,
  isDropoffExist,
  isPrebookMode,
  isWelcomeBoardSelected,
  pickupAt,
  clearServerError,
}: Props) => {
  const intl = useIntl();
  const [cfadType, setCfadType] = useState<ChauffeurForADayType>();
  const dispatch = useDispatch();
  const { validateForm } = useFormikContext<FormValues>();
  const { isTablet } = useBreakpoints();
  const [isModalOpened, setModalOpen] = useState(false);

  const serviceOffers = useTypedSelector(serviceOffersSelector);
  const { isLoading, error } = useTypedSelector(state => state.serviceOffers);
  const { isLoading: isFormLoading } = useTypedSelector(state => state.newJourney);
  const selectedService = useTypedSelector(selectedServiceOfferSelector);
  const isChauffeurForADayAvailable = useTypedSelector(isChauffeurForADayAvailableSelector);
  const isChauffeurForADayAvailableForSelectedService = useTypedSelector(
    isChauffeurForADayAvailableForSelectedServiceSelector,
  );

  const expectedDuration = useTypedSelector(expectedDurationSelector);

  const isDisabled = disabled || isLoading || isFormLoading || Boolean(error);

  const setService = useCallback<(item?: ServiceOfferItem) => void>(
    service => {
      if (clearServerError) {
        clearServerError();
      }

      if (!service) {
        return;
      }

      if (!selectedService || service.id !== selectedService.service.id) {
        dispatch(
          setServiceId({
            serviceId: service.id,
          }),
        );
      }
    },
    [clearServerError, dispatch, selectedService],
  );

  const handleOpenMobileSelect = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleCloseMobileSelect = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleItemSelect = useCallback<SelectFieldProps<ServiceOfferItem>['onItemSelect']>(
    item => {
      setService(item);

      const longJourneyOptions = item.long_journey_options || [];

      if (
        expectedDuration !== null &&
        longJourneyOptions.length > 0 &&
        !longJourneyOptions.some(option => option.duration_min === expectedDuration)
      ) {
        dispatch(
          newJourneySlice.actions.setChauffeurForADayExpectedDuration({
            expectedDuration: longJourneyOptions[0].duration_min,
          }),
        );
      }

      // mobile only
      if (isModalOpened) {
        handleCloseMobileSelect();
      }
    },
    [setService, expectedDuration, isModalOpened, dispatch, handleCloseMobileSelect],
  );

  const handleExpectedDurationFieldChange = useCallback(
    (newExpectedDuration: number) => {
      if (clearServerError) {
        clearServerError();
      }

      dispatch(
        newJourneySlice.actions.setChauffeurForADayExpectedDuration({
          expectedDuration: newExpectedDuration,
        }),
      );
    },
    [clearServerError, dispatch],
  );

  const handleExpectedDurationOptionalFieldOpen = useCallback(() => {
    const firstLongJourneyOption = selectedService?.service?.long_journey_options?.[0];

    if (!firstLongJourneyOption) {
      return;
    }

    if (clearServerError) {
      clearServerError();
    }

    dispatch(
      newJourneySlice.actions.setChauffeurForADayExpectedDuration({
        expectedDuration: firstLongJourneyOption.duration_min,
      }),
    );
  }, [dispatch, selectedService, clearServerError]);

  const handleExpectedDurationOptionalFieldClose = useCallback(() => {
    if (clearServerError) {
      clearServerError();
    }

    dispatch(
      newJourneySlice.actions.setChauffeurForADayExpectedDuration({
        expectedDuration: null,
      }),
    );
  }, [clearServerError, dispatch]);

  const items = useMemo<ServiceOfferItem[]>(
    () =>
      serviceOffers.map(serviceOffer => {
        const {
          service: { id, image_url, title, web_url, options, utc_offset, long_journey_options },
          service_availability: { eta },
        } = serviceOffer;

        const serviceOfferEta = getServiceOfferEta(serviceOffer, isWelcomeBoardSelected);

        const earliestReservationDate = getEarliestReservationDate(serviceOfferEta, utc_offset);

        const isAvailable = getIsServiceAvailable(
          isPrebookMode,
          earliestReservationDate,
          pickupAt,
          eta,
        );

        const isNotAvailableForChauffeurForADay = getIsNotAvailableForChauffeurForADay(
          expectedDuration,
          long_journey_options,
        );

        return {
          id,
          title,
          eta: !isNotAvailableForChauffeurForADay && (
            <ItemEta
              isAvailable={isAvailable}
              isPrebookMode={isPrebookMode}
              eta={serviceOfferEta}
            />
          ),
          image: <ItemImage url={image_url} title={title} />,
          infoLink: <ItemInfoLink url={web_url} onClick={stopPropagation} />,
          fare: (
            <Fare
              cfadType={cfadType}
              isNotAvailableForChauffeurForADay={isNotAvailableForChauffeurForADay}
              expectedDuration={expectedDuration}
              service={serviceOffer.service}
              isAvailable={isAvailable}
              earliestReservationDate={earliestReservationDate}
              isDropoffExist={isDropoffExist}
            />
          ),
          options: <ItemOptions options={options} />,
          disabled: isNotAvailableForChauffeurForADay,
          long_journey_options,
        };
      }),
    [
      cfadType,
      isDropoffExist,
      isPrebookMode,
      isWelcomeBoardSelected,
      expectedDuration,
      pickupAt,
      serviceOffers,
    ],
  );

  let errorMessage = null;

  // Trigger validation on error change
  useEffect(() => {
    validateForm();
  }, [error, selectedService, validateForm]);

  const buttonText = useMemo<React.ReactNode>(() => {
    if (!selectedService) {
      return EMPTY_VALUE;
    }

    const serviceOfferEta = getServiceOfferEta(selectedService, isWelcomeBoardSelected);

    const earliestReservationDate = getEarliestReservationDate(
      serviceOfferEta,
      selectedService.service.utc_offset,
    );

    const isAvailable = getIsServiceAvailable(
      isPrebookMode,
      earliestReservationDate,
      pickupAt,
      selectedService.service_availability.eta,
    );

    const isNotAvailableForChauffeurForADay = getIsNotAvailableForChauffeurForADay(
      expectedDuration,
      selectedService.service.long_journey_options,
    );

    return (
      <div className={s.buttonText}>
        <span>{selectedService.service.title}</span>
        {!isPrebookMode && selectedService.service_availability.eta && (
          <span className={s.selectedClassEta}>
            {' – '}
            <FormattedEtaInMinutes etaDuration={selectedService.service_availability.eta} />
          </span>
        )}
        <Fare
          cfadType={cfadType}
          isNotAvailableForChauffeurForADay={isNotAvailableForChauffeurForADay}
          expectedDuration={expectedDuration}
          service={selectedService.service}
          isAvailable={isAvailable}
          earliestReservationDate={earliestReservationDate}
          isDropoffExist={isDropoffExist}
        />
      </div>
    );
  }, [
    cfadType,
    selectedService,
    expectedDuration,
    isDropoffExist,
    isPrebookMode,
    isWelcomeBoardSelected,
    pickupAt,
  ]);

  // used as key for CFAD OptionalField to unmount and mount
  // when services change (e.g. when pickup changed to different city)
  const servicesHash = useMemo(
    () => JSON.stringify(serviceOffers.map(serviceOffer => serviceOffer.service.id)),
    [serviceOffers],
  );

  useEffect(() => {
    if (!selectedService || serviceOffers.length === 0) {
      dispatch(
        setServiceId({
          serviceId: null,
        }),
      );
    }
  }, [dispatch, selectedService, serviceOffers.length]);

  if (!selectedService) {
    return null;
  }

  if (serviceOffers.length === 0 || error?.data?.errors?.no_coverage_without_city) {
    return null;
  }

  if (!isLoading && error && serviceOffers.length === 0) {
    // TODO: handle actual server errors
    // https://wheely.atlassian.net/browse/BUS-540
    errorMessage = intl.formatMessage({
      description: 'Service levels default error message',
      defaultMessage: 'Cannot get services, please choose pickup, check connection and try again',
      id: 'lJn9UU',
    });
  }

  const isChauffeurForADayDisabled = disabled || !isChauffeurForADayAvailableForSelectedService;

  return (
    <>
      <MobileTapContainer onClick={handleOpenMobileSelect} disabled={isDisabled}>
        <div className={s.serviceLevels}>
          <SelectField<ServiceOfferItem>
            items={isTablet ? [] : items}
            value={selectedService.service.id}
            onItemSelect={handleItemSelect}
            selectedItemPredicate={selectedItemPredicate}
            itemListRenderer={itemListRenderer}
            itemRenderer={itemRenderer}
            itemDisabled={'disabled'}
            scrollToActiveItem={false}
            disabled={isDisabled}
            error={errorMessage}
            title={
              <FormGroupTitle>
                {intl.formatMessage({
                  description: 'Service level group class title',
                  defaultMessage: 'Class',
                  id: '38M5rS',
                })}
              </FormGroupTitle>
            }
            description={
              isPrebookMode
                ? intl.formatMessage({
                    description: 'Estimated price alert',
                    defaultMessage:
                      'Estimated price is based on current traffic. The final price may differ depending on the actual duration and route of the journey.',
                    id: '1yOr3L',
                  })
                : ''
            }
            buttonProps={{
              text: buttonText,
              'data-test-id': 'service-level-button',
            }}
            menuProps={{
              'data-test-id': 'service-level-menu',
            }}
          />
        </div>
      </MobileTapContainer>
      {isChauffeurForADayAvailable && selectedService.service.fare && (
        <OptionalField
          key={servicesHash}
          title={intl.formatMessage({
            description: 'New journey chauffeur for a day button title',
            defaultMessage: 'Chauffeur for a Day',
            id: 'iKZHFD',
          })}
          openedTitle={
            <span className={s.cfadTitle}>
              {intl.formatMessage({
                description: 'New journey chauffeur for a day field title',
                defaultMessage: 'Chauffeur for a Day',
                id: '9uQTL4',
              })}{' '}
              <ItemInfoLink
                className={s.infoBlock}
                url={CFAD_INFO_LINK}
                onClick={stopPropagation}
              />
            </span>
          }
          disabled={isChauffeurForADayDisabled}
          initialIsOpen={expectedDuration !== null}
          onOpen={handleExpectedDurationOptionalFieldOpen}
          onClose={handleExpectedDurationOptionalFieldClose}
          buttonProps={{
            'data-test-id': 'add-chauffeur-for-a-day-button',
          }}
        >
          {expectedDuration && (
            <ExpectedDurationField
              availableLongJourneyOptions={selectedService.service.long_journey_options || []}
              currencyCode={selectedService.service.fare.currency_code}
              value={expectedDuration}
              setCfadType={setCfadType}
              onChange={handleExpectedDurationFieldChange}
              serviceDescriptionUrl={selectedService.service.web_url}
              disabled={isChauffeurForADayDisabled}
            />
          )}
        </OptionalField>
      )}
      {isChauffeurForADayAvailable && !isChauffeurForADayAvailableForSelectedService && (
        <div className={s.cfadNotAvailableMessage}>
          <FormattedMessage
            defaultMessage="Not available for this class"
            id="vfjiiB"
            description="Chauffeur for a day not available message"
          />
        </div>
      )}
      {isModalOpened && (
        <MobileModal onClose={handleCloseMobileSelect}>
          <div className={s.mobileServiceContainer}>
            <H2 capitalize={true} className={s.containerTitle}>
              <FormattedMessage
                description="Service class selection, modal title on mobile"
                defaultMessage="Select class"
                id="HqrVMD"
              />
            </H2>
            <ul className={s.serviceListMobile}>
              {items.map(item => (
                <MobileItem
                  isSelected={item.id === selectedService.service.id}
                  onClick={handleItemSelect}
                  key={item.id}
                  item={item}
                />
              ))}
            </ul>
          </div>
        </MobileModal>
      )}
    </>
  );
};
