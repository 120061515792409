import React from 'react';
import cn from 'clsx';

import { Header } from './components/Header';
import { Footer } from './components/Footer';
import s from './styles.scss';

type Props = {
  children: React.ReactNode;
  contentWrapperClassName?: string;
  contentClassName?: string;
  withoutContentPadding?: boolean;
  withoutBackground?: boolean;
  withoutHeader?: boolean;
  withoutLogoutButton?: boolean;
  withoutFooter?: boolean;
  withFixedRoot?: boolean;
};

const GuestLayout: React.FC<Props> = ({
  children,
  contentWrapperClassName,
  contentClassName,
  withoutContentPadding = false,
  withoutBackground = false,
  withoutHeader = false,
  withoutLogoutButton = false,
  withoutFooter = false,
  withFixedRoot = true,
}) => (
  <div className={cn(s.root, { [s.rootFixed]: withFixedRoot })}>
    {!withoutHeader && <Header withoutLogoutButton={withoutLogoutButton} />}
    <div
      className={cn(s.contentWrapper, contentWrapperClassName, {
        [s.withoutBackground]: withoutBackground,
        [s.contentWrapperWithFixedRoot]: withFixedRoot,
      })}
    >
      <main
        className={cn(s.content, contentClassName, {
          [s.withoutContentPadding]: withoutContentPadding,
        })}
      >
        {children}
      </main>
    </div>
    {!withoutFooter && <Footer />}
  </div>
);

export { GuestLayout };
