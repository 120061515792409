import React from 'react';
import { Icon, IconProps } from '@wheely/ui-kit';

export const IconPassengerFilled: Icon = ({
  width,
  height,
  className,
  fill = 'currentColor',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16.5C13.1425 16.5 16.5 13.1422 16.5 9C16.5 4.85781 13.1425 1.5 9 1.5C4.8575 1.5 1.5 4.85781 1.5 9C1.5 13.1422 4.8575 16.5 9 16.5ZM9 15.375C9.23721 15.375 9.47139 15.362 9.70187 15.3368C11.2241 15.088 12.5692 14.3125 13.544 13.2035C13.1184 13.0217 12.6411 12.8877 12.1829 12.759C11.4438 12.5515 10.7543 12.3579 10.4115 12.0001C9.42057 12.8971 7.91762 12.8851 6.94164 12C6.59509 12.3584 5.89361 12.5542 5.14298 12.7636L5.14297 12.7636C4.7962 12.8603 4.43893 12.96 4.10132 13.08C5.27066 14.4825 7.03101 15.375 9 15.375ZM6.76347 9.22901C6.804 9.57203 6.96586 9.95144 7.14027 10.232L8.09021 11.2498C8.57743 11.7719 9.39558 11.8001 9.91761 11.3129C9.93935 11.2926 9.96037 11.2716 9.98066 11.2498L10.9142 10.2496C11.0879 9.96747 11.25 9.57926 11.2916 9.22901C11.4746 9.21549 11.7628 9.05434 11.8476 8.4181C11.8932 8.07652 11.7121 7.8843 11.6021 7.82387C11.8996 7.01525 12.5184 4.51488 10.4582 4.25618C10.2462 3.92007 9.70326 3.75 8.99773 3.75C6.17494 3.79691 5.83445 5.67399 6.45295 7.82387C6.34296 7.8843 6.16187 8.07652 6.20746 8.4181C6.29195 9.05434 6.58047 9.21549 6.76347 9.22901Z"
      fill={fill}
      fillOpacity="0.76"
    />
  </svg>
);
