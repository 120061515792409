import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import uniq from 'lodash/fp/uniq';

import { ErrorShape, Order } from '_api/types';
import { RootState } from '_store';
import { companyTypeSelector } from '_queries/company/selectors';

import { createConvertOrderListToTableData } from './common/selectors';
import { OrdersEntities, OrdersOrder } from './types';

export type FavoriteOrdersState = {
  total: number;
  error: null | ErrorShape;
  isLoading: boolean;
  order: OrdersOrder;
  entities: OrdersEntities;
};

const favoriteOrdersOrderSelector = (state: RootState) => state.favoriteOrders.order;
const favoriteOrdersEntitiesSelector = (state: RootState) => state.favoriteOrders.entities;
const favoriteOrdersSelector = createSelector(
  favoriteOrdersOrderSelector,
  favoriteOrdersEntitiesSelector,
  (order, entities) => order.map(id => entities[id]),
);

export const isFavoriteOrdersLoadingSelector = (state: RootState) => state.favoriteOrders.isLoading;

export const favoriteOrdersTableDataSelector = createSelector(
  favoriteOrdersSelector,
  companyTypeSelector,
  createConvertOrderListToTableData({ isFavorite: true }),
);

export const favoriteOrdersCountSelector = (state: RootState) => state.favoriteOrders.total;

const initialState: FavoriteOrdersState = {
  total: 0,
  error: null,
  isLoading: false,
  order: [],
  entities: {},
};

const favoriteOrdersSlice = createSlice({
  name: 'favoriteOrders',
  initialState,
  reducers: {
    loadFavoriteOrders() {
      return { ...initialState, isLoading: true };
    },
    loadFavoriteOrdersFailed(state, action: PayloadAction<{ error: ErrorShape }>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    },
    loadFavoriteOrdersSucceed(
      state,
      { payload }: PayloadAction<{ order: OrdersOrder; entities: OrdersEntities }>,
    ) {
      return {
        ...state,
        total: payload.order.length,
        order: uniq([...state.order, ...payload.order]),
        entities: { ...state.entities, ...payload.entities },
        isLoading: false,
        error: null,
      };
    },
    addOrderToFavorites(state, { payload: { order } }: PayloadAction<{ order: Order }>) {
      return {
        ...state,
        total: state.total + 1,
        entities: { ...state.entities, [order.id]: order },
        order: [order.id, ...state.order],
      };
    },
    removeOrderFromFavorites(state, { payload: { orderId } }: PayloadAction<{ orderId: string }>) {
      if (!state.entities[orderId]) {
        return state;
      }

      const { [orderId]: _, ...entities } = state.entities;

      return {
        ...state,
        total: state.total - 1,
        entities,
        order: state.order.filter(orderItem => orderItem !== orderId),
      };
    },
  },
});

export { favoriteOrdersSlice };
