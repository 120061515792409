import React from 'react';
import cx from 'clsx';
import { Text } from '@wheely/ui-kit';
import { FormattedMessage } from 'react-intl';

import { IconSupportFilled } from '_common/icons/IconSupportFilled';

import styles from './styles.scss';

type CustomerServiceButtonProps = {
  onClick: VoidFunction;
};

export const CustomerServiceButton = ({ onClick }: CustomerServiceButtonProps) => (
  <button className={cx(styles.root)} onClick={onClick}>
    <IconSupportFilled height={18} />
    <Text withoutPadding={true}>
      <FormattedMessage
        defaultMessage="Customer Service"
        id="QHBsYl"
        description="Sidebar menu Customer service button"
      />
    </Text>
  </button>
);
