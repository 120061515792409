import { CountryCode } from '_api/types';
import { useCompanyCountryCode } from '_hooks/company';
import { getRootDomain } from '_utils/getRootDomain';

export type MenuItems = {
  faqLink: string;
  citiesAndFaresLink: string;
  termsAndConditionsLink: string;
  privacyPolicyLink: string;
  cookiePolicyLink: string;
  supportPhone: string;
  supportEmail: string;
};

const rootDomain = getRootDomain(location.host);

const menuItemsByCountry: Record<Extract<CountryCode, 'RU' | 'GB'>, MenuItems> = {
  RU: {
    citiesAndFaresLink: `https://${rootDomain}/ru/cities-and-classes`,
    faqLink: `https://${rootDomain}/ru/faq-for-b2b/russia`,
    privacyPolicyLink: `https://wheely.ltd/ru/legal/russia/for-business-accounts/privacy`,
    termsAndConditionsLink: `https://wheely.ltd/ru/legal/russia/for-business-accounts/terms`,
    cookiePolicyLink: `https://wheely.ltd/ru/legal/uk/for-users/cookie`,
    supportPhone: `+7 495 414-25-36`,
    supportEmail: `support@wheely.com`,
  },
  GB: {
    citiesAndFaresLink: `https://${rootDomain}/en/cities-and-classes`,
    faqLink: `https://${rootDomain}/en/faq-for-b2b/uk`,
    privacyPolicyLink: `https://${rootDomain}/en/legal/uk/for-business-accounts/privacy`,
    termsAndConditionsLink: `https://${rootDomain}/en/legal/uk/for-business-accounts/terms`,
    cookiePolicyLink: `https://${rootDomain}/en/legal/uk/for-users/cookie`,
    supportPhone: `+44 20 3936 9971`,
    supportEmail: `support@wheely.com`,
  },
};

type UseMenuItems = () => MenuItems;

export const useMenuItems: UseMenuItems = () => {
  const countryCode = useCompanyCountryCode();

  if (countryCode !== 'RU' && countryCode !== 'GB') {
    return menuItemsByCountry.GB;
  }

  return menuItemsByCountry[countryCode];
};
