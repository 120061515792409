import React, { useMemo } from 'react';
import cn from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import intervalToDuration from 'date-fns/intervalToDuration';
import { H6, H7, Loader } from '@wheely/ui-kit';
import { isThisYear } from 'date-fns';

import { CustomMarker, CustomMarkerProps } from '_common/CustomMarker';
import { THIN_SPACE } from '_constants';
import { isValidDate } from '_utils/isValidDate';
import { shiftDateToUtcOffset } from '_utils/shiftDateToUtcOffset';
import { useLocaleDate } from '_i18n/useLocaleDate';
import { WithChildren } from '_types/helpers';

import s from './styles.scss';

type PropsArrival = CustomMarkerProps & {
  createdAt: string;
  utcOffset?: number;
  eta: number;
  className?: string;
  isLoading?: boolean;
  label?: string;
};

const TripDetailsArrival: React.VFC<PropsArrival> = ({
  createdAt,
  utcOffset,
  eta,
  position,
  className,
  isLoading,
  label,
}) => {
  const { format: formatDate } = useLocaleDate();
  const estimatedArrival = useMemo(() => {
    const DATE_FORMAT_PATTERN = `HH:mm, ccc, d LLL`;
    const FULL_DATE_FORMAT_PATTERN = `${DATE_FORMAT_PATTERN}, yyyy`;
    const date = new Date(createdAt);

    if (!isValidDate(date)) {
      return null;
    }

    const shiftedDate = shiftDateToUtcOffset(date, (utcOffset || 0) + eta);
    const formatPattern = isThisYear(shiftedDate) ? DATE_FORMAT_PATTERN : FULL_DATE_FORMAT_PATTERN;

    return formatDate(shiftedDate, formatPattern);
  }, [eta, createdAt, formatDate, utcOffset]);

  return (
    <CustomMarker position={position}>
      <div className={cn(s.tripDetails, className)}>
        {isLoading ? (
          <div className={s.preloaderWrapper}>
            <Loader />
          </div>
        ) : (
          <>
            <H6 capitalize={true}>
              {label || (
                <FormattedMessage
                  defaultMessage="Estimated time of arrival"
                  id="isAiMR"
                  description="Trip duration label for scheduled status"
                />
              )}
            </H6>
            <H7>{estimatedArrival}</H7>
          </>
        )}
      </div>
    </CustomMarker>
  );
};

type Props = CustomMarkerProps & {
  duration: number;
  className?: string;
  isLoading?: boolean;
  label?: string;
};

const DigitsWithExtension: React.FC<WithChildren<{ extension: string }>> = ({
  children,
  extension,
}) => (
  <>
    {children}
    {THIN_SPACE}
    {extension}
  </>
);

const TripDetails: React.FC<Props> = ({ duration, position, className, label, isLoading }) => {
  const { hours, minutes } = intervalToDuration({ start: 0, end: duration * 1000 });
  const intl = useIntl();

  if (typeof minutes !== 'number') {
    return null;
  }

  const isHoursMoreThanZero = typeof hours === 'number' && hours > 0;
  const isMinutesMoreThanZero = minutes > 0;

  return (
    <CustomMarker position={position}>
      <div className={cn(s.tripDetails, className)}>
        {isLoading ? (
          <div className={s.preloaderWrapper}>
            <Loader />
          </div>
        ) : (
          <>
            <H6 capitalize={true}>
              {label || (
                <FormattedMessage
                  defaultMessage="Journey duration"
                  id="FiL2d8"
                  description="Journey duration label"
                />
              )}
            </H6>
            <H7>
              ~
              {isHoursMoreThanZero && (
                <DigitsWithExtension
                  extension={intl.formatMessage({
                    description: 'Hours short label',
                    defaultMessage: 'h',
                    id: 'tU3ygj',
                  })}
                >
                  {hours}
                </DigitsWithExtension>
              )}
              {isHoursMoreThanZero && isMinutesMoreThanZero && ' '}
              {(!isHoursMoreThanZero || isMinutesMoreThanZero) && (
                <DigitsWithExtension
                  extension={intl.formatMessage({
                    description: 'Minutes short label',
                    defaultMessage: 'm',
                    id: '8hX3pk',
                  })}
                >
                  {isMinutesMoreThanZero ? minutes : 1}
                </DigitsWithExtension>
              )}
            </H7>
          </>
        )}
      </div>
    </CustomMarker>
  );
};

export { TripDetails, TripDetailsArrival };
