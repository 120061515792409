import { useHistory } from 'react-router';

import { Company } from '_api/types';
import { useUser } from '_hooks/user';
import { authRoutes } from '_routes';
import { useTypedSelector } from '_store';
import { isLoggedInSelector } from '_store/auth';
import { card3dsVerificationRoute } from '_routes/common';

export const useRedirects = (company?: Company) => {
  const user = useUser();
  const history = useHistory();
  const isLoggedIn = useTypedSelector(isLoggedInSelector);
  const isNotOnRegisterPage = history.location.pathname !== authRoutes.registerRoute;
  const isUserCompanyNotActive = !company || company?.status === 'new';

  if (!isLoggedIn) {
    return {
      shouldRedirectToAddCompany: false,
      shouldRedirectToRegister: false,
    };
  }

  const shouldRedirectToRegister =
    (user?.companies?.length || 0) <= 1 &&
    isUserCompanyNotActive &&
    isNotOnRegisterPage &&
    history.location.pathname !== card3dsVerificationRoute;
  const shouldRedirectToAddCompany =
    (user?.companies?.length || 0) > 1 &&
    isUserCompanyNotActive &&
    ![authRoutes.addCompanyRoute, card3dsVerificationRoute].includes(history.location.pathname);

  return { shouldRedirectToAddCompany, shouldRedirectToRegister };
};
