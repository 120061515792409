import { Route, Switch } from 'react-router';
import React from 'react';

import { PrivateRouter } from './PrivateRouter';
import { PublicRouter } from './PublicRouter';

export const Router = () => (
  <Switch>
    {PublicRouter}
    <Route component={PrivateRouter} path="*" />
  </Switch>
);
