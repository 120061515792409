import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import {
  ConnectedRouterProps,
  ConnectedRouter as LibConnectedRouter,
} from 'connected-react-router';
import { ErrorBoundary } from '@sentry/react';
import { ConfirmProvider, NotificationProvider } from '@wheely/ui-kit';
import { QueryClientProvider } from '@tanstack/react-query';
import { CurrentPageSpanStoreManager } from '@wheely/web-tracing';

import { history, persistor, store } from '_store';
import { WithChildren } from '_types/helpers';
import { ViewportLoader } from '_common/ViewportLoader';
import { queryClient } from '_queries/client';
import { getApiUrl } from '_utils/getApiUrl';

import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';
import { IntlProvider } from './IntlProvider';
import { Router } from './router/Router';
import { TracesRender } from './TracesRender';

const ConnectedRouter = LibConnectedRouter as unknown as React.FC<
  WithChildren<ConnectedRouterProps>
>;

CurrentPageSpanStoreManager.configure({
  tracesEndpoint: '/api/traces',
  tracesResourceName: 'front-b2b',
  tracesCorsUrls: [getApiUrl()],
  tracesConsoleEnabled: Boolean(window.b2bAppConfig.ENABLE_CONSOLE_TRACES),
  tracesResourceVersion: '0.0.3-alpha',
  tracesInstumentationUse: {
    fetch: true,
    documentLoad: true,
    xhr: true,
  },
});

export const AppContainer: React.FC = () => {
  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <IntlProvider>
            <ConfirmProvider>
              <NotificationProvider dark={true}>
                <ConnectedRouter history={history}>
                  <QueryClientProvider client={queryClient}>
                    <TracesRender />
                    <React.Suspense fallback={<ViewportLoader isLoading={true} />}>
                      <Router />
                    </React.Suspense>
                  </QueryClientProvider>
                </ConnectedRouter>
              </NotificationProvider>
            </ConfirmProvider>
          </IntlProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};
