import React from 'react';
import { useParams } from 'react-router';

import { LoadingWrapper } from '_common/LoadingWrapper';
import { NotFoundWrapper } from '_common/NotFoundWrapper';
import { ErrorWrapper } from '_common/ErrorWrapper';
import { usePublicEmployee } from '_queries/invites/usePublicEmployee';
import { PublicLayout } from '_common/PublicLayout';

import {
  GrantedAccess as GrantedAccessFeature,
  InviteGrantedAccessProps,
} from './features/GrantedAccess';
import styles from './styles.scss';

export const GrantedAccess = () => {
  const params = useParams<Record<'employeeId', string>>();

  const { data, isFetching, isError, error } = usePublicEmployee(params.employeeId);

  return (
    <LoadingWrapper isLoading={isFetching}>
      <NotFoundWrapper isError={isError} error={error}>
        <ErrorWrapper className={styles.error} isError={isError} layout={PublicLayout}>
          <PublicLayout>
            <GrantedAccessFeature {...(data as InviteGrantedAccessProps)} />
          </PublicLayout>
        </ErrorWrapper>
      </NotFoundWrapper>
    </LoadingWrapper>
  );
};
