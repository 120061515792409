import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import cn from 'clsx';

import { setOrdersCommonFilters } from '_store/common/actions';
import { BasicLayout } from '_layouts/Basic';
import { useTypedSelector } from '_store';
import { mapModeSelector } from '_store/mapInteraction';
import { useBreakpoints } from '_hooks/useBreakpoints';
import { lazyRetry } from '_utils/lazyRetry';

import s from './styles.scss';

const JourneysContent = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "JourneysContent" */ './JourneysContent'),
    'JourneysContent',
  ),
);

const Journeys = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isTablet } = useBreakpoints();

  const mapMode = useTypedSelector(mapModeSelector);

  // prevent react-beautiful-dnd double scroll warning
  const isJourneyPage = /journeys\/journeys\/.*/i.test(pathname);

  // Flush all common filters when unmount component
  useEffect(
    () => () => {
      dispatch(
        setOrdersCommonFilters({
          dateRange: null,
        }),
      );
    },
    [dispatch],
  );

  return (
    <BasicLayout
      className={cn({ [s.lock]: isTablet && mapMode === 'edit' })}
      contentClassName={cn({ [s.layoutContent]: isJourneyPage })}
    >
      <JourneysContent />
    </BasicLayout>
  );
};

export { Journeys };
