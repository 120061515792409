import React from 'react';
import cn from 'clsx';
import { IconExtraStop } from '@wheely/ui-kit';

import { CustomMarker, CustomMarkerProps } from '../CustomMarker';

import s from './styles.scss';

type Props = CustomMarkerProps & { className?: string; onDoubleClick?: () => void };

const ExtraStopMarker: React.VFC<Props> = ({ className, position, onDoubleClick }) => (
  <CustomMarker position={position} onDoubleClick={onDoubleClick}>
    <div className={cn(s.extraStopMarker, className)}>
      <IconExtraStop />
    </div>
  </CustomMarker>
);

export { ExtraStopMarker };
