import React, { useCallback, useEffect } from 'react';
import { Body } from '@wheely/web-ui';
import { FormattedMessage } from 'react-intl';

import { use3dsResultHandler } from '_hooks/use3dsResultHandler';
import { Update3dsCardParams } from '_modules/Auth/types';
import { usePutCompanyPayments } from '_queries/company/usePutCompanyPayments';

import styles from './Card3dsVerification.module.scss';

export const BROADCAST_CHANNEL_3DS_NAME = '3ds';

const channel = new BroadcastChannel(BROADCAST_CHANNEL_3DS_NAME);

export type Card3dsVerificationData = {
  isSuccess?: boolean;
  isError?: boolean;
  is3dsFailed?: boolean;
  error?: Error;
};

export const Card3dsVerification = () => {
  const { mutateAsync: putPayments, error } = usePutCompanyPayments();

  const handleUpdate3dsCard = useCallback<(params: Update3dsCardParams) => Promise<void>>(
    async ({ paymentMethodId, sessionId }) => {
      try {
        await putPayments({ paymentMethodId, sessionId });
        channel.postMessage({ isSuccess: true });
      } catch (e) {
        channel.postMessage({ isError: true, error: e });
      } finally {
        window.close();
      }
    },
    [putPayments],
  );

  const { is3dsFailed } = use3dsResultHandler({
    onUpdate3dsCard: handleUpdate3dsCard,
    update3dsCardError: error,
  });

  useEffect(() => {
    if (is3dsFailed) {
      channel.postMessage({ is3dsFailed });
      window.close();
    }
  }, [error, is3dsFailed]);

  return (
    <div className={styles.root}>
      <Body>
        <FormattedMessage
          defaultMessage="Redirecting..."
          id="global_redirecting"
          description="Redirect page title"
        />
      </Body>
    </div>
  );
};
