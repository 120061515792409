import React from 'react';
import cn from 'clsx';

import { LogoLink } from '_common/LogoLink';

import s from './styles.scss';

type Props = {
  className?: string;
  isMenuOpened: boolean;
  onToggleOpen: () => void;
};

const Header: React.VFC<Props> = ({ className, isMenuOpened, onToggleOpen }) => (
  <div className={cn(s.header, className)}>
    <LogoLink className={s.logo} />
    <button
      type="button"
      aria-label="Menu"
      className={cn(s.toggleButton, { [s.opened]: isMenuOpened })}
      onClick={onToggleOpen}
    >
      <span className={s.toggleButtonIcon} />
    </button>
  </div>
);

export { Header };
