import { TracePageRenderRQ } from '@wheely/web-tracing';
import React from 'react';
import { useIsFetching } from '@tanstack/react-query';

import { useUser } from '_hooks/user';

export const TracesRender = () => {
  const user = useUser();
  const isFetching = useIsFetching();

  return (
    <TracePageRenderRQ
      isFetching={Boolean(isFetching)}
      commonAttributes={{ 'user.id': user?.id }}
    />
  );
};
