import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  GoogleMap,
  GoogleMapProps,
  LoadScriptNext,
  LoadScriptNextProps,
  useGoogleMap,
} from '@react-google-maps/api';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash/fp';
import cn from 'clsx';
import { useIntl } from 'react-intl';
import { Loader } from '@wheely/ui-kit';

import { GOOGLE_MAPS } from '_constants';
import { useDebouncedMethod, useLocalSagas, usePrevious } from '_hooks';
import {
  currentLocationSelector,
  fieldToEditSelector,
  geocodingStateSelector,
  MapInteractionMode,
  mapModeSelector,
  setGeocodingState,
} from '_store/mapInteraction';
import { useTypedSelector } from '_store';
import { loadReversedGeocodeAndEtaSaga } from '_sagas/map';
import { WithChildren } from '_types/helpers';

import { Address } from './components/Address';
import { EditingControls } from './components/EditingControls';
import { UserPositionButton } from './components/UserPositionButton';
import s from './styles.scss';

const { googleMapsApiKey, DEFAULT_ZOOM } = GOOGLE_MAPS;
const FIT_BOUNDS_DELAY = 20000; // 20 sec
const mapOptions = {
  disableDefaultUI: true, // hides the default controls
  zoomControl: true, // shows + / - zoom buttons
  scrollwheel: false, // fix false target to zoom to by design of google maps, prevent event in general
  disableDoubleClickZoom: true, // prevent confusion with double click to edit
};

const fitBounds = ({
  map,
  zoom,
  mapMode,
  children,
  shouldFit,
}: {
  map?: google.maps.Map | null;
  zoom: number;
  mapMode: MapInteractionMode;
  children?: ReactNode;
  shouldFit?: (nextPositions: google.maps.LatLng[]) => boolean;
}) => {
  if (!map) {
    return;
  }

  const foundPositions: google.maps.LatLng[] = [];

  React.Children.forEach(children, child => {
    if (!React.isValidElement(child)) {
      return;
    }

    if (child.props.position) {
      foundPositions.push(child.props.position);
    }

    if (Array.isArray(child.props.path)) {
      child.props.path.forEach((position: google.maps.LatLng) => {
        foundPositions.push(position);
      });
    }
  });

  if (shouldFit && !shouldFit(foundPositions)) {
    return;
  }

  if (foundPositions.length === 1) {
    map.setCenter(foundPositions[0]);
  }

  if (foundPositions.length < 2) {
    if (mapMode !== 'edit') {
      map.setZoom(zoom);
    }

    return;
  }

  const bounds = new google.maps.LatLngBounds();

  foundPositions.forEach(position => {
    bounds.extend(position);
  });

  map.fitBounds(bounds);
};

const FitBoundsWrapper: React.FC<WithChildren<{ zoom?: number }>> = ({
  children,
  zoom = DEFAULT_ZOOM,
}) => {
  const map = useGoogleMap();
  const mapMode = useTypedSelector(mapModeSelector);
  const lastPositions = useRef<google.maps.LatLng[]>([]);

  useEffect(() => {
    fitBounds({
      map,
      mapMode,
      zoom,
      children,
      shouldFit: nextPositions => {
        if (
          lastPositions.current.length === nextPositions.length &&
          lastPositions.current.every(
            (position, index) =>
              position.lat === nextPositions[index].lat &&
              position.lng === nextPositions[index].lng,
          )
        ) {
          return false;
        }

        lastPositions.current = nextPositions;

        return true;
      },
    });
  }, [zoom, map, children, mapMode]);

  return <>{children}</>;
};

const libraries: LoadScriptNextProps['libraries'] = ['geometry'];

const ZOOM_ON_CITY = 10;
const ZOOM_ON_PIN_WHILE_EDITING = DEFAULT_ZOOM + 1;

type Props = React.PropsWithChildren<{
  center: { lat: number; lng: number };
  className?: string;
  zoom?: number;
  maxZoom?: number;
  onLoad?: GoogleMapProps['onLoad'];
  editModeProps?: {
    onCancel: () => void;
    onConfirm: () => void;
    showEditControls: boolean;
  } | null;
  onDragStart?: () => void;
  onDragEnd?: () => void;
}>;

const AutoZoomEditingMode = ({
  isEditingMode,
  zoom,
  onInitialLoading,
}: {
  isEditingMode: boolean;
  zoom: number;
  onInitialLoading: () => void;
}) => {
  const map = useGoogleMap();
  const prevIsEditingMode = usePrevious(isEditingMode);
  const fieldToEdit = useTypedSelector(fieldToEditSelector);
  const currentLocation = useTypedSelector(currentLocationSelector);

  useEffect(() => {
    if (map) {
      const isTransitionTo = !prevIsEditingMode && isEditingMode;

      if (isTransitionTo) {
        map.setZoom(ZOOM_ON_PIN_WHILE_EDITING);

        if (fieldToEdit) {
          map.setCenter(fieldToEdit.position);
        }

        if (fieldToEdit && currentLocation === undefined) {
          onInitialLoading();
        }
      }
    }
  }, [map, isEditingMode, zoom, prevIsEditingMode, fieldToEdit, currentLocation, onInitialLoading]);

  return null;
};

const Map = ({
  children,
  center,
  className,
  zoom = DEFAULT_ZOOM,
  maxZoom,
  onLoad,
  editModeProps,
  onDragStart,
  onDragEnd,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const mapRef = useRef<google.maps.Map>();
  const { runLocalSaga } = useLocalSagas();

  const geocodingState = useTypedSelector(geocodingStateSelector);

  const mapMode = useTypedSelector(mapModeSelector);
  const currentLocation = useTypedSelector(currentLocationSelector);

  const handleOnLoadMap = useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map;
      onLoad?.(map);
    },
    [onLoad],
  );

  useEffect(() => {
    const handleKeyDownPress = (event: KeyboardEvent) => {
      if (mapMode !== 'edit') {
        return;
      }

      if (event.key === 'Escape') {
        editModeProps?.onCancel();
      }

      if (event?.key === 'Tab') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDownPress);

    return () => {
      document.removeEventListener('keydown', handleKeyDownPress);
    };
  }, [mapMode, editModeProps]);

  const updateLocationFromGeocode = useCallback(() => {
    if (!mapRef.current || mapMode !== 'edit') {
      return;
    }

    const newCenter: { lat: number; lng: number } = {
      lat: mapRef.current?.getCenter()?.lat() || 0,
      lng: mapRef.current?.getCenter()?.lng() || 0,
    };

    runLocalSaga(loadReversedGeocodeAndEtaSaga, newCenter, {
      goToPinTitle: intl.formatMessage({
        defaultMessage: 'Go to pin',
        id: '7MMBrY',
        description: 'User selects pin on map which has no address',
      }),
    });
  }, [runLocalSaga, mapMode, intl]);

  const handleDragStartMap = useCallback(() => {
    if (!mapRef.current) {
      return;
    }

    if (mapMode === 'view' && onDragStart) {
      onDragStart();
    }

    if (mapMode !== 'edit' || geocodingState === 'loading') {
      return;
    }

    dispatch(setGeocodingState({ geocodingState: 'loading' }));
  }, [dispatch, geocodingState, mapMode, onDragStart]);

  const handleDragEndMap = useCallback(() => {
    if (mapMode === 'view' && onDragEnd) {
      onDragEnd();
    }

    updateLocationFromGeocode();
  }, [updateLocationFromGeocode, mapMode, onDragEnd]);

  const handleAutoZoomInitialLoading = useCallback(() => {
    updateLocationFromGeocode();
  }, [updateLocationFromGeocode]);

  const debouncedHandleDragEndMap = debounce(1200, handleDragEndMap);
  const options = useMemo(
    () => ({
      ...mapOptions,
      ...(maxZoom ? { maxZoom } : {}),
    }),
    [maxZoom],
  );
  const debouncedFitBounds = useDebouncedMethod(
    () => fitBounds({ map: mapRef.current, mapMode, children, zoom }),
    FIT_BOUNDS_DELAY,
  );
  const memoizedCenter = useMemo(
    () => ({
      lat: center.lat,
      lng: center.lng,
    }),
    [center.lat, center.lng],
  );

  return (
    <>
      <LoadScriptNext
        libraries={libraries}
        loadingElement={<Loader />}
        googleMapsApiKey={googleMapsApiKey}
      >
        <GoogleMap
          onLoad={handleOnLoadMap}
          clickableIcons={false}
          options={options}
          mapContainerClassName={cn(className, s.basicMap)}
          center={memoizedCenter}
          zoom={children ? zoom : ZOOM_ON_CITY}
          onDragStart={handleDragStartMap}
          onDragEnd={debouncedHandleDragEndMap}
          onBoundsChanged={debouncedFitBounds}
          onZoomChanged={debouncedFitBounds}
        >
          {children && <FitBoundsWrapper zoom={zoom}>{children}</FitBoundsWrapper>}
          <AutoZoomEditingMode
            zoom={zoom}
            isEditingMode={Boolean(editModeProps)}
            onInitialLoading={handleAutoZoomInitialLoading}
          />

          {editModeProps && (
            <>
              <Address location={currentLocation} geocodingState={geocodingState} />
              <UserPositionButton />
            </>
          )}

          {editModeProps?.showEditControls && (
            <EditingControls isConfirmDisabled={!currentLocation} {...editModeProps} />
          )}
        </GoogleMap>
      </LoadScriptNext>
      {mapMode === 'edit' && (
        <div
          className={cn(s.tint, { [s.pointer]: Boolean(editModeProps?.onCancel) })}
          onClick={editModeProps?.onCancel}
        />
      )}
    </>
  );
};

export { Map };
