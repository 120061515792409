import React from 'react';
import { isValid } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import { formatNearestReservationTime } from '_utils/formatNearestReservationTime';

import s from './styles.scss';

type Props = {
  earliestReservationDate: null | Date;
  className?: string;
};

const ItemFormattedEarliestReservationDate = ({ earliestReservationDate, className }: Props) => {
  if (!earliestReservationDate || !isValid(earliestReservationDate)) {
    return null;
  }

  const formattedNearestReservationTime = formatNearestReservationTime(earliestReservationDate);

  return (
    <div className={clsx(s.earliestReservationDate, className)}>
      <FormattedMessage
        description="Service level item earliest reservation date"
        defaultMessage="Available from {time}"
        id="KTCiNK"
        values={{
          time: formattedNearestReservationTime,
        }}
      />
    </div>
  );
};

export { ItemFormattedEarliestReservationDate };
