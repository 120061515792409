import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getPhoneCountryCode } from '_utils/getPhoneCountryCode';

// keep locales between mounts
let persistedLocale: string | undefined;

const usePhoneCodeUpdateFn = (callback: (phoneCode: string) => void) => {
  const { locale } = useIntl();

  if (!persistedLocale) {
    persistedLocale = locale;
  }

  useEffect(() => {
    const phoneCode = getPhoneCountryCode(locale);

    if (persistedLocale !== locale && phoneCode) {
      callback(phoneCode);
      persistedLocale = locale;
    }
  }, [callback, locale]);
};

export { usePhoneCodeUpdateFn };
