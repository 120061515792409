import React from 'react';

import { useTypedSelector } from '_store';
import { globalErrorSelector } from '_store/ui';
import { ErrorState } from '_common/ErrorState';
import { GuestLayout } from '_layouts/Guest';
import { WithChildren } from '_types/helpers';

const GlobalErrorGate: React.FC<WithChildren> = ({ children }) => {
  const globalError = useTypedSelector(globalErrorSelector);

  if (globalError) {
    return (
      <GuestLayout withoutBackground={true}>
        <ErrorState />
      </GuestLayout>
    );
  }

  return <>{children}</>;
};

export { GlobalErrorGate };
