import { createAction } from '@reduxjs/toolkit';

import { DateRange } from '_store/types';

const setOrdersCommonFilters = createAction<{
  dateRange: DateRange | null;
}>('common/setOrdersCommonFilters');

const sendKeepalive = createAction('common/sendKeepalive');

export { setOrdersCommonFilters, sendKeepalive };
