import cookie from 'cookie_js';
import { IRawSession, ISession, transformRawSession } from '@wheely/web-auth-lib';
import { captureException } from '@sentry/react';
import { Dispatch } from 'redux';

import { authAPI } from '_api/auth';
import { setIsLoggedIn } from '_store/auth';
import { setAllowUserLoad, setIsAppReadyToBeShown } from '_store/ui';
import { createDelayPromise } from '_utils/createDelayPromise';

const WEBSITE_AUTH_COOKIE_NAME = 'wheely';

const COOKIE_DOMAIN_RE = /\.wheely(-dev)?\..*$/;

const getParsedSession = (cookieName: string): any => {
  const rawSession = cookie.get(cookieName);

  if (!rawSession) {
    return null;
  }

  const decodedSession = decodeURIComponent(rawSession);

  try {
    return JSON.parse(decodedSession);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Could not parse cookie data', error);
    captureException(error);

    return null;
  }
};

const getWebsiteTokenFromCookie = (): IRawSession | null =>
  getParsedSession(WEBSITE_AUTH_COOKIE_NAME);

export function getAuthStateFromCookie() {
  const tokenFromCookie = getWebsiteTokenFromCookie();

  // force delete all possible auth cookies to avoid any auth issues
  cookie.remove(WEBSITE_AUTH_COOKIE_NAME);

  const [cookieDomain] = window.location.hostname.match(COOKIE_DOMAIN_RE) || [];

  if (cookieDomain) {
    cookie.removeSpecific(WEBSITE_AUTH_COOKIE_NAME, { domain: cookieDomain });
  }

  if (!tokenFromCookie) {
    return;
  }

  const session = transformRawSession(tokenFromCookie);

  if (!session) {
    // eslint-disable-next-line no-console
    console.warn('Could not parse cookie data');

    return;
  }

  return session;
}

export async function restoreSession(dispatch: Dispatch) {
  let session = getAuthStateFromCookie();

  if (session) {
    await authAPI.setCurrentSession(session as ISession);
  } else {
    session = await authAPI.restoreSession();
  }

  const isLoggedIn = Boolean(session?.activated);

  dispatch(setIsLoggedIn({ isLoggedIn }));
  dispatch(setAllowUserLoad(isLoggedIn));

  const delayPromise = createDelayPromise(500);

  if (!isLoggedIn) {
    await delayPromise;
    dispatch(setIsAppReadyToBeShown({ isAppReadyToBeShown: true }));
  }
}
