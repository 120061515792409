import React from 'react';
import { Body, Button, Heading } from '@wheely/web-ui';
import { FormattedMessage } from 'react-intl';
import { Formik, FormikHelpers } from 'formik';

import { FormikPhoneInput } from '_/components/FormikFields/FormikPhoneInput';

import styles from './styles.scss';
import { validationSchema } from './validationSchema';

export type FormValues = Partial<Record<'phone', string>>;

type PhoneFormProps = {
  onSubmit(formValues: FormValues, formikHelpers: FormikHelpers<FormValues>): void;
  companyName: string;
  isLoading: boolean;
};

export const PhoneForm = ({ onSubmit, companyName, isLoading }: PhoneFormProps) => (
  <div className={styles.root}>
    <Heading size="M" className={styles.heading}>
      <FormattedMessage
        defaultMessage="Link Your Phone Number"
        id="zW66lL"
        description="Accept invite form title"
      />
    </Heading>
    <Body className={styles.description}>
      <FormattedMessage
        defaultMessage="{companyName} granted you access to manage the Corporate Dashboard and invited you to use your corporate payment method for Wheely business journeys. Enter your phone number to activate your account."
        id="wiBV5y"
        description="Accept invite form description"
        values={{ companyName }}
      />
    </Body>
    <Formik
      initialValues={{}}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount={true}
    >
      {formProps => (
        <form onSubmit={formProps.handleSubmit}>
          <FormikPhoneInput
            name="phone"
            inputProps={{ title: 'Phone Number', 'data-test-id': 'invite_accept_form_phone_input' }}
          />
          <Button
            className={styles.submit}
            type="submit"
            disabled={!formProps.isValid || isLoading}
            isLoading={isLoading}
          >
            <FormattedMessage
              defaultMessage="Activate"
              id="hZtGID"
              description="Text on submit phone number button"
            />
          </Button>
        </form>
      )}
    </Formik>
  </div>
);
