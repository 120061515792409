import React from 'react';
import { Loader } from '@wheely/ui-kit';

import styles from './styles.scss';

type Props = {
  isLoading: boolean;
  children?: React.ReactNode | React.ReactNode[];
};

export const ViewportLoader = ({ isLoading, children }: Props) => (
  <div>
    {isLoading && (
      <div className={styles.loaderRoot}>
        <Loader className={styles.loader} />
      </div>
    )}
    {children}
  </div>
);
