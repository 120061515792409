import React from 'react';
import cn from 'clsx';

import { Breakpoint, BREAKPOINTS, useBreakpoints } from '_hooks/useBreakpoints';

import s from './styles.scss';

type Props = React.HTMLProps<HTMLDivElement> & {
  onClick: () => void;
  children: React.ReactElement;
  disabled?: boolean;
  breakpoint?: Breakpoint;
  fill?: boolean;
};

const MobileTapContainer = ({
  children,
  onClick,
  disabled,
  breakpoint: breakpointProp = BREAKPOINTS.isTablet,
  fill = false,
  ...HTMLProps
}: Props) => {
  const { [breakpointProp]: breakpoint } = useBreakpoints();

  if (!breakpoint) {
    return children;
  }

  return (
    <div
      {...HTMLProps}
      onClick={disabled ? undefined : onClick}
      className={cn(s.container, {
        [s.disabled]: disabled,
        [s.fill]: fill,
      })}
    >
      <div className={s.eventBreaker}>{children}</div>
    </div>
  );
};

export { MobileTapContainer };
