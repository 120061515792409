import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IconCheckmark, MenuDivider } from '@wheely/ui-kit';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';

import { updateUserCompany } from '_store/auth';
import { IconBag } from '_common/icons/IconBag';
import { useClickOutside } from '_hooks/useClickOutside';
import { useUserCompanies } from '_hooks/user';
import { personalSettingsRoutes, authRoutes } from '_routes';
import { useCompany } from '_hooks/company';
import { useDimmer } from '_layouts/Basic/components/Menu/DimmerContext';

import { UserButton } from '../UserButton/UserButton';

import styles from './styles.scss';
import { InfoButton } from './InfoButton';

type Props = {
  profileName: string | JSX.Element;
  handleSignOutClick: VoidFunction;
  onMenuItemClick?: VoidFunction;
};

export const CompanySwitcherMenu: React.FC<Props> = ({
  profileName,
  handleSignOutClick,
  onMenuItemClick,
}: Props) => {
  const history = useHistory();

  const company = useCompany();
  const { setIsShowDimmer } = useDimmer();

  const companiesList = useUserCompanies();
  const [isOpened, setIsOpened] = useState(false);

  const dispatch = useDispatch();

  const hasMultipleCompanies = useMemo(
    () => Number(companiesList?.length) > 1,
    [companiesList?.length],
  );

  const onCompanyActivate = useCallback(
    (companyId: string) => () => {
      if (hasMultipleCompanies) {
        dispatch(updateUserCompany({ companyId }));
      }

      setIsOpened(false);
    },
    [dispatch, hasMultipleCompanies],
  );

  const handleClose = useCallback(() => {
    setIsOpened(false);
  }, []);

  const handleToggle = useCallback(() => {
    setIsOpened(state => !state);
  }, []);

  const handleOpenPersonalSettings = useCallback(() => {
    history.push(personalSettingsRoutes.personalSettingsBase);
    onMenuItemClick?.();
  }, [history, onMenuItemClick]);

  const handleAddCompany = useCallback(() => {
    history.push(authRoutes.addCompanyRoute);
  }, [history]);

  useEffect(() => {
    setIsShowDimmer(isOpened);
  }, [isOpened, setIsShowDimmer]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const companiesListRef = useRef<HTMLDivElement>(null);

  const [isShowScrollGradient, setIsShowScrollGradient] = useState<boolean | null>();

  useEffect(() => {
    if (isOpened) {
      const isScrollable =
        companiesListRef.current &&
        companiesListRef.current.scrollHeight > companiesListRef.current.clientHeight;

      setIsShowScrollGradient(isScrollable);
    }
  }, [isOpened]);

  const onCompaniesListScroll = useCallback(() => {
    const companiesListNode = companiesListRef.current;

    if (!companiesListNode) {
      return;
    }

    const { scrollHeight, clientHeight, scrollTop } = companiesListNode;

    setIsShowScrollGradient(scrollTop !== scrollHeight - clientHeight);
  }, []);

  const menuRef = useRef<HTMLDivElement>(null);

  const [menuHeight, setMenuHeight] = useState<number>();

  useEffect(() => {
    const boundingClientRect = menuRef.current?.getBoundingClientRect();

    if (isOpened && boundingClientRect) {
      setMenuHeight(boundingClientRect?.height + boundingClientRect?.top);
    }
  }, [isOpened]);

  useClickOutside(dropdownRef, handleClose);

  return (
    <div className={styles.root} ref={dropdownRef}>
      {isOpened && (
        <div
          className={styles.menu}
          ref={menuRef}
          style={{ '--menu-height': `${menuHeight}px` } as React.CSSProperties}
        >
          <UserButton userName={profileName} onClick={handleOpenPersonalSettings} />
          <MenuDivider className={styles.divider} />
          <div
            ref={companiesListRef}
            className={cx(styles.companiesList, { [styles.gradient]: isShowScrollGradient })}
            onScroll={onCompaniesListScroll}
          >
            {companiesList?.map(({ id, name }) => (
              <button onClick={onCompanyActivate(id)} key={id} className={styles.menuButton}>
                <div className={styles.menuButtonInfo}>
                  <IconBag width={18} height={18} className={styles.menuButtonIcon} />
                  <span className={styles.menuButtonName}>{name}</span>
                </div>
                {id === company?.id && (
                  <IconCheckmark width={18} className={styles.menuCheckedIcon} />
                )}
              </button>
            ))}
          </div>
          <MenuDivider className={styles.divider} />
          <button className={cx(styles.menuButton, styles.addCompany)} onClick={handleAddCompany}>
            <FormattedMessage
              description="Add company menu item text"
              id="FYjTzQ"
              defaultMessage="Add Company"
            />
          </button>
          <button
            className={cx(styles.menuButton, styles.signOutButton)}
            onClick={handleSignOutClick}
          >
            <FormattedMessage
              description="Sign Out menu item text"
              defaultMessage="Sign Out"
              id="IiZa/v"
            />
          </button>
        </div>
      )}
      <InfoButton
        profileName={profileName}
        handleToggle={handleToggle}
        companyName={company?.name}
        isOpened={isOpened}
      />
    </div>
  );
};
