import { debounce, fork, put } from 'redux-saga/effects';

import { loadCompletions } from '_sagas/common/loadCompletions';
import {
  loadPickupCompletions as loadPickupCompletionsAction,
  loadPickupCompletionsFailed as loadPickupCompletionsFailedAction,
  loadPickupCompletionsSucceed as loadPickupCompletionsSucceedAction,
} from '_store/newJourney';
import { handleApiError } from '_utils/handleApiError';
import { CompletionsResponse } from '_api/types';

type LoadCompletionsResult = { completions: null | CompletionsResponse };

function* loadPickupSelectCompletions(action: ReturnType<typeof loadPickupCompletionsAction>) {
  const { query, mode } = action.payload;

  try {
    const result: LoadCompletionsResult = yield loadCompletions({ query, mode });

    yield put(loadPickupCompletionsSucceedAction(result));
  } catch (error) {
    yield put(loadPickupCompletionsFailedAction({ error: handleApiError(error) }));
  }
}

function* watchCompletionsLoad(timeout: number) {
  yield debounce(timeout, loadPickupCompletionsAction.type, loadPickupSelectCompletions);
}

function* completionsFlow() {
  yield fork(watchCompletionsLoad, 500);
}

export { completionsFlow };
