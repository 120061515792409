import React from 'react';
import { TextField } from '@wheely/ui-kit';
import { useField } from 'formik';
import { useIntl } from 'react-intl';

import { getFieldErrorMessage } from '_pages/NewJourney/components/Form/utils/getFieldErrorMessage';

interface MeetAndGreetByOriginProps {
  disabled?: boolean;
}

export const MeetAndGreetByOrigin = ({ disabled }: MeetAndGreetByOriginProps) => {
  const intl = useIntl();
  const [originTextField, originTextMeta] = useField('origin');
  const [arrivalTimeTextField, arrivalTimeTextMeta] = useField('arrivalTime');

  return (
    <>
      <TextField
        {...originTextField}
        disabled={disabled}
        placeholder={intl.formatMessage({
          description: 'Meet & Greet origin input placeholder',
          defaultMessage: 'Origin',
          id: 'ya//Bp',
        })}
        error={getFieldErrorMessage(originTextMeta)}
        data-test-id="origin-field"
        errorProps={{
          'data-test-id': 'origin-error',
        }}
      />
      <TextField
        {...arrivalTimeTextField}
        placeholder={intl.formatMessage({
          description: 'Meet and Greet train arrival time',
          defaultMessage: 'Arrival time',
          id: 'o3SlfQ',
        })}
        error={getFieldErrorMessage(arrivalTimeTextMeta)}
        disabled={disabled}
        data-test-id="arrival-time-field"
        errorProps={{
          'data-test-id': 'arriva-time-error',
        }}
      />
    </>
  );
};
