import { ServiceOffer } from '_api/types';

export const getServiceOfferEta = (serviceOffer: ServiceOffer, isWelcomeBoardSelected: boolean) => {
  const { eta, earliest_reservation, welcome_board_eta } = serviceOffer.service_availability;

  if (isWelcomeBoardSelected && welcome_board_eta) {
    return welcome_board_eta;
  }

  return (eta || earliest_reservation) ?? null;
};
