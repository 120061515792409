import React from 'react';
import cn from 'clsx';
import { Menu, MenuProps } from '@wheely/ui-kit';

import s from './styles.scss';

export * from './NavMenuItem';

export const NavMenu: React.FC<MenuProps> = ({ className, ...restProps }) => (
  <Menu {...restProps} className={cn(s.navMenu, className)} />
);
