import React from 'react';
import { H1, IconLogo } from '@wheely/ui-kit';

import s from './styles.scss';

const ErrorBoundaryFallback = () => (
  <div className={s.root}>
    <div className={s.header}>
      <div className={s.logoContainer}>
        <a className={s.logoLink} href="/">
          <IconLogo className={s.logoIcon} />
        </a>
      </div>
    </div>

    <div className={s.contentWrapper}>
      <main className={s.content}>
        <div className={s.errorState}>
          <div className={s.errorStateContent}>
            <H1 className={s.errorStateTitle} capitalize={true}>
              Error
            </H1>

            <p className={s.errorStateDescription}>Something went wrong.</p>
          </div>
        </div>
      </main>
    </div>
  </div>
);

export { ErrorBoundaryFallback };
