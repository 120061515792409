import React, { useCallback } from 'react';
import cn from 'clsx';

import { Header } from '../Header';
import { Menu } from '../Menu';
import { Footer } from '../Footer';
import { DimmerProvider } from '../Menu/DimmerContext';

import s from './styles.scss';

type Props = {
  headerClassName?: string;
  className?: string;
  isMenuOpened: boolean;
  disabled?: boolean;
  onToggleOpen: () => void;
};

const Navigation: React.FC<Props> = ({
  headerClassName,
  className,
  disabled,
  isMenuOpened,
  onToggleOpen,
}) => {
  const handleMenuItemClick = useCallback(() => {
    if (isMenuOpened) {
      onToggleOpen();
    }
  }, [isMenuOpened, onToggleOpen]);

  return (
    <div className={cn(s.navigation, className)}>
      {isMenuOpened && <div className={cn(s.shade)} onClick={onToggleOpen} />}

      <Header className={headerClassName} onToggleOpen={onToggleOpen} isMenuOpened={isMenuOpened} />
      <div className={cn(s.contentHolder, { [s.opened]: isMenuOpened })}>
        <div className={s.content}>
          <DimmerProvider disabled={disabled}>
            <Menu onMenuItemClick={handleMenuItemClick} disabled={disabled} />
            <Footer onMenuItemClick={handleMenuItemClick} />
          </DimmerProvider>
        </div>
      </div>
    </div>
  );
};

export { Navigation };
