import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, IntlFormatters, useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { Divider } from '@wheely/ui-kit';

import { Location } from '_api/types';
import { MultistopGroup } from '_common/MultistopGroup';
import { ReferenceField } from '_common/ReferenceField';
import { SpecialRequestField } from '_common/SpecialRequestField';
import { PAYER_TYPES } from '_constants';
import { usePrevious } from '_hooks';
import { useBreakpoints } from '_hooks/useBreakpoints';
import { useTypedSelector } from '_store';
import {
  employeesSelectItemsSelector,
  isOnlyPrebookAvailableForSelectedServiceOfferSelector,
  selectedServiceOfferTimezoneSelector,
  selectedServiceOfferUtcOffsetSelector,
} from '_store/common/selectors';
import { mapModeSelector, restoreViewMode } from '_store/mapInteraction';
import {
  flightSelector,
  railwayHubSelector,
  newJourneySlice,
  preFilledJourneyErrorsSelector,
  preFilledJourneySelector,
  setPrebookMode,
  setStops as setStopsAction,
  isOriginPickupSubTypeSelector,
  loadNearbyLocations,
} from '_store/newJourney';
import { selectedServiceOfferSelector } from '_store/serviceOffers';
import { featureFlags } from '_utils/featureFlags';
import { getEarliestReservationDate } from '_utils/getEarliestReservationDate';
import { getFlightArrivalDateTime } from '_utils/getFlightArrivalDateTime';
import { getServiceOfferEta } from '_utils/getServiceOfferNearestReservationOffset';
import { isValidDate } from '_utils/isValidDate';
import { getUTCDateISO } from '_utils/timezone/timezone';
import { PayerField } from '_pages/NewJourney/components/PayerField';
import { splitCodeAndPhoneNumber } from '_utils/splitPhoneNumberAndCode';
import { usePhoneCountryCode } from '_hooks/usePhoneCountryCode';
import { AddFieldButton } from '_common/AddFieldButton';
import { useUser } from '_hooks/user';
import { useCompany, useCompanyDefaultPayer } from '_hooks/company';

import { EmployeeField } from '../EmployeeField';
import { FormFooter } from '../FormFooter';
import { Map } from '../Map';
import { MeetAndGreetField } from '../MeetAndGreetField';
import { PassengerField } from '../PassengerField';
import { PickupAddressField } from '../PickupAddressField';
import { PickupTimeField } from '../PickupTimeField';
import { ServiceLevels } from '../ServiceLevels';
import { MESSAGES } from '../../constants';

import { getErrorMessageByFieldName } from './utils/getErrorMessageByFieldName';
import { getFormError } from './utils/getFormError';
import { FormErrors, FormContentProps, FormTouched } from './types';
import s from './styles.scss';

const getEmployeeFieldErrorMessage = (
  errors: FormErrors,
  touched: FormTouched,
  hasPreFilledEmployeeError: boolean | undefined,
  messageFormatter: IntlFormatters['formatMessage'],
  submitCount: number,
) => {
  const validationError = getErrorMessageByFieldName(
    'employeeUserId',
    errors,
    touched,
    submitCount,
  );

  if (!validationError && hasPreFilledEmployeeError) {
    return messageFormatter(MESSAGES.rebookNoEmployeeError);
  }

  return validationError;
};

export const FormContent = ({
  setFieldValue,
  setFieldTouched,
  values,
  errors,
  touched,
  validateForm,
  submitCount,
  handleSubmit,
  pickupLocation,
  serverError,
  clearServerError,
  isPrebookMode,
  isHospitalityType,
  isSubmitting,
  isValidating,
  isLoading,
}: FormContentProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { isTablet } = useBreakpoints();

  const mapMode = useTypedSelector(mapModeSelector);
  const company = useCompany();

  const preFilledJourney = useTypedSelector(preFilledJourneySelector);
  const preFilledJourneyErrors = useTypedSelector(preFilledJourneyErrorsSelector);
  const pickupError = getErrorMessageByFieldName('pickup', errors, touched, submitCount);
  const pickupAtError = getErrorMessageByFieldName('pickupAt', errors, touched, submitCount);
  const employeeError = getEmployeeFieldErrorMessage(
    errors,
    touched,
    preFilledJourneyErrors?.employeeNotFound,
    intl.formatMessage,
    submitCount,
  );

  const referenceError = serverError?.data?.errors?.reference?.join('\n');

  const isPickupExist = Boolean(pickupLocation) && !pickupError;
  const isDropoffExist =
    Boolean(values.stops.filter(Boolean).length) && Boolean(values.stops[0].data);
  const isDisabled = isSubmitting || isLoading;

  useEffect(() => {
    if (isPickupExist || isDropoffExist) {
      dispatch(loadNearbyLocations());
    }
  }, [dispatch, isDropoffExist, isPickupExist]);

  const selectedServiceOffer = useTypedSelector(selectedServiceOfferSelector);
  const selectedServiceOfferUtcOffset = useTypedSelector(selectedServiceOfferUtcOffsetSelector);
  const timezone = useTypedSelector(selectedServiceOfferTimezoneSelector);

  const selectedServiceOfferEta = selectedServiceOffer
    ? getServiceOfferEta(selectedServiceOffer, values.welcomeBoard)
    : null;

  const earliestReservationDate = useMemo(
    () => getEarliestReservationDate(selectedServiceOfferEta, selectedServiceOfferUtcOffset),
    [selectedServiceOfferEta, selectedServiceOfferUtcOffset],
  );

  const isPrebookOnly = useTypedSelector(isOnlyPrebookAvailableForSelectedServiceOfferSelector);

  const employees = useTypedSelector(employeesSelectItemsSelector);

  const employeeName = useMemo(() => {
    const employee = employees.find(({ value }) => value === values.employeeUserId);

    if (!employee) {
      return '';
    }

    return employee?.title;
  }, [employees, values.employeeUserId]);

  const currentUser = useUser();
  const phoneCode = usePhoneCountryCode();

  useEffect(() => {
    if (preFilledJourney?.specialRequest) {
      setFieldValue('specialRequest', preFilledJourney.specialRequest, true);
    }

    if (preFilledJourney?.reference) {
      setFieldValue('reference', preFilledJourney.reference, true);
    }

    if (preFilledJourney?.stops?.length) {
      setFieldValue(
        'stops',
        preFilledJourney.stops.map(data => ({ data, _id: uuid() })),
      );
    }

    const isBookForAnother =
      preFilledJourney?.passengerName || preFilledJourney?.passengerPhoneNumber;

    if (isBookForAnother || isHospitalityType) {
      setFieldValue('passengerName', preFilledJourney?.passengerName || '');

      const [code, phoneNumber] = preFilledJourney?.passengerPhoneNumber
        ? splitCodeAndPhoneNumber(preFilledJourney?.passengerPhoneNumber) || []
        : [];

      setFieldValue('passengerCountryCode', code || phoneCode);
      setFieldValue('passengerPhoneNumber', phoneNumber || '');
    }

    if (currentUser?.id) {
      setFieldValue('employeeUserId', currentUser?.id, true);
    }

    if (
      preFilledJourney?.employeeUserId &&
      !preFilledJourneyErrors?.employeeNotFound &&
      (!isBookForAnother || isHospitalityType)
    ) {
      setFieldValue('employeeUserId', preFilledJourney?.employeeUserId, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check and set correct initial company default payer
  const companyDefaultPayer = useCompanyDefaultPayer();

  const flight = useTypedSelector(flightSelector);
  const railwayHub = useTypedSelector(railwayHubSelector);
  const isOriginPickupSubType = useTypedSelector(isOriginPickupSubTypeSelector);

  const prevFlight = usePrevious(flight);
  const prevWelcomeBoardValue = usePrevious(values.welcomeBoard);

  useEffect(() => {
    // Check for
    // - feature flag is enabled
    // - value is not falsy
    // - 'company_passenger' is not being applied if company is type of corporate
    if (
      featureFlags.isB2CPaymentEnabled(company) &&
      companyDefaultPayer &&
      (isHospitalityType || companyDefaultPayer !== 'company_passenger')
    ) {
      const payerValue = preFilledJourney?.payer || companyDefaultPayer;

      setFieldValue('payer', payerValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateWelcomeBoardText = useCallback(() => {
    setFieldValue('welcomeBoardText', values.passengerName || employeeName);
  }, [employeeName, values.passengerName, setFieldValue]);
  const handlePickupTimeFieldChange = useCallback(
    (date: null | Date) => {
      clearServerError();
      setFieldTouched('pickupAt');
      setFieldValue('pickupAt', date);

      const nextIsPrebookMode = isValidDate(date);

      dispatch(
        newJourneySlice.actions.setPickupAt({
          pickupAt: nextIsPrebookMode ? (getUTCDateISO(date, timezone) as string) : null,
        }),
      );

      if (nextIsPrebookMode !== isPrebookMode) {
        dispatch(setPrebookMode({ isPrebookMode: nextIsPrebookMode }));
      }
    },
    [clearServerError, setFieldTouched, setFieldValue, timezone, dispatch, isPrebookMode],
  );

  useEffect(() => {
    if (!flight) {
      setFieldValue('welcomeBoard', false);
      setFieldValue('welcomeBoardText', '');

      return;
    }

    if (!prevFlight) {
      // adding welcome board only when flight is changed from null to not null
      setFieldValue('welcomeBoard', true);

      const arrivalDateTime = getFlightArrivalDateTime(flight);

      handlePickupTimeFieldChange(arrivalDateTime);
    }
  }, [dispatch, flight, handlePickupTimeFieldChange, prevFlight, setFieldTouched, setFieldValue]);

  // FIXME: move to welcomeBoard field change handler
  useEffect(() => {
    if (!prevWelcomeBoardValue) {
      updateWelcomeBoardText();
    }
  }, [prevWelcomeBoardValue, values.welcomeBoard, updateWelcomeBoardText]);

  // FIXME: move to employee field and guest passenger name field change handlers
  useEffect(() => {
    if (values.welcomeBoard) {
      updateWelcomeBoardText();
    }
  }, [values.welcomeBoard, updateWelcomeBoardText]);

  const handleBookForAnother = useCallback(
    (query?: string) => {
      setFieldValue('passengerName', query || '');
      setFieldValue('passengerPhoneNumber', '');
      setFieldValue('passengerCountryCode', phoneCode);
      setFieldTouched('passengerName', false);
      setFieldTouched('passengerPhoneNumber', false);
      setFieldTouched('passengerCountryCode', false);
    },
    [phoneCode, setFieldTouched, setFieldValue],
  );

  const handleAsapRequest = useCallback(() => {
    setFieldValue('pickupAt', null);
  }, [setFieldValue]);

  const handleSync = useCallback(
    (stops: Array<Location | null | undefined>) => {
      dispatch(setStopsAction({ stops }));
      dispatch(restoreViewMode());
    },
    [dispatch],
  );

  const handleEmployeeChange = useCallback(
    (employeeUserId: string | undefined) => {
      dispatch(newJourneySlice.actions.resetPreFilledJourneyErrors());
      setFieldTouched('employeeUserId', Boolean(employeeUserId));
      setFieldValue('employeeUserId', employeeUserId);
      clearServerError();
    },
    [setFieldTouched, setFieldValue, clearServerError, dispatch],
  );
  const isMeetAndGreetVisible = Boolean(flight) || (railwayHub && isPrebookMode);
  const meetAndGreetType = isMeetAndGreetVisible ? (flight ? 'airport' : 'railway_hub') : null;

  const isGuestPassengerOrder = values.passengerName !== null;
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;

      return;
    }

    if (isHospitalityType) {
      return;
    }

    clearServerError();

    if (isGuestPassengerOrder) {
      if (currentUser?.id) {
        setFieldValue('employeeUserId', currentUser.id, true);
      }

      return;
    }

    setFieldValue('payer', PAYER_TYPES.company);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearServerError, isGuestPassengerOrder, isHospitalityType, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      {(!isGuestPassengerOrder || isHospitalityType) && (
        <EmployeeField
          employeeUserId={values.employeeUserId}
          onEmployeeChange={handleEmployeeChange}
          error={employeeError}
          disabled={isDisabled}
          onBookForAnother={isHospitalityType ? undefined : handleBookForAnother}
        />
      )}

      {!isGuestPassengerOrder && (
        <AddFieldButton
          onClick={() => handleBookForAnother('')}
          text={
            <FormattedMessage
              description="Book for another person button"
              defaultMessage="Book For Another Person"
              id="zBVZKV"
            />
          }
        />
      )}

      {isGuestPassengerOrder && <PassengerField disabled={isDisabled} />}

      <PickupAddressField
        disabled={isDisabled}
        onAsapRequest={handleAsapRequest}
        formPickupError={pickupError}
        clearServerError={clearServerError}
      />

      <MultistopGroup
        name="stops"
        stops={values.stops}
        disabled={isDisabled}
        onChange={({ value, index }) => {
          clearServerError();
          setFieldValue(`stops.${index}.data`, value);
        }}
        setTouched={({ value, index }) => setFieldTouched(`stops.${index}`, value)}
        errors={errors.stops}
        onSync={handleSync}
        touched={touched.stops}
        clearServerError={clearServerError}
        setFieldValue={value => {
          clearServerError();
          setFieldValue('stops', value);
        }}
      />

      {isTablet && mapMode === 'view' && <Map isViewOnly={true} className={s.mobileMap} />}

      {isMeetAndGreetVisible && (
        <MeetAndGreetField
          isOriginPickupSubType={isOriginPickupSubType}
          type={meetAndGreetType}
          disabled={isDisabled}
        />
      )}

      {!flight && (
        <PickupTimeField
          value={values.pickupAt}
          onChange={handlePickupTimeFieldChange}
          earliestReservationDate={earliestReservationDate}
          eta={selectedServiceOfferEta}
          error={pickupAtError}
          disabled={isDisabled}
          isPrebookOnly={isPrebookOnly}
        />
      )}

      {isPickupExist && (
        <ServiceLevels
          pickupAt={values.pickupAt}
          isPrebookMode={isPrebookMode}
          isDropoffExist={isDropoffExist}
          isWelcomeBoardSelected={values.welcomeBoard}
          disabled={isDisabled}
          clearServerError={clearServerError}
        />
      )}

      {featureFlags.isB2CPaymentEnabled(company) && (
        <PayerField
          disabled={isDisabled || !isGuestPassengerOrder}
          isHidden={!isGuestPassengerOrder}
          isHospitalityType={isHospitalityType}
          clearServerError={clearServerError}
        />
      )}

      {(isPickupExist || isGuestPassengerOrder) && <Divider className={s.divider} />}

      <ReferenceField
        value={values.reference}
        onValueChange={reference => {
          clearServerError();
          setFieldValue('reference', reference, false);
        }}
        error={referenceError}
        disabled={isDisabled}
        initialIsOpen={Boolean(preFilledJourney?.reference)}
      />
      <SpecialRequestField
        value={values.specialRequest}
        onValueChange={specialRequest => {
          setFieldValue('specialRequest', specialRequest, false);
        }}
        disabled={isDisabled}
        initialIsOpen={Boolean(preFilledJourney?.specialRequest)}
      />

      <FormFooter
        loading={isSubmitting || isValidating || isLoading}
        formError={getFormError(errors, serverError, intl.formatMessage)}
        serverError={serverError}
        validateForm={validateForm}
        submitCount={submitCount}
      />
    </form>
  );
};
