import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { apiClient } from '_api';
import { normalizeServiceOffers } from '_api/normalize';
import { GetServiceFaresResponse } from '_api/types';
import { MapInteractionMode, mapModeSelector } from '_store/mapInteraction';
import {
  selectedServiceIdSelector,
  setServiceId,
  pickupLocationSelector,
  newJourneySlice,
} from '_store/newJourney';
import {
  loadServiceOffers as loadServiceOffersAction,
  loadServiceOffersFailed as loadServiceOffersFailedAction,
  loadServiceOffersSucceed as loadServiceOffersSucceedAction,
} from '_store/serviceOffers';
import { handleApiError } from '_utils/handleApiError';

const { setChauffeurForADayExpectedDuration } = newJourneySlice.actions;

// FIXME: remove the deep coherency between this common saga and app sections (or move to the `new journey` part at all)
export function* loadServiceOffersSaga(
  action: ReturnType<typeof loadServiceOffersAction>,
): SagaIterator {
  const { isSilent = false } = action.payload;

  const mapMode: MapInteractionMode = yield select(mapModeSelector);

  if (mapMode === 'edit') {
    return;
  }

  // https://wheely.atlassian.net/browse/BUS-531
  if (isSilent && document.hidden) {
    return;
  }

  try {
    const result: GetServiceFaresResponse = yield call(apiClient.getServiceFares, action.payload);

    const {
      entities: { serviceOffers = {} },
      result: order = [],
    } = normalizeServiceOffers(result);

    yield put(
      loadServiceOffersSucceedAction({
        order,
        entities: serviceOffers,
      }),
    );

    const pickupLocation = pickupLocationSelector(yield select());
    const selectedServiceId = selectedServiceIdSelector(yield select());
    const serviceIdToSelect = order[0] || null;

    const shouldUpdateSelectedServiceId = Boolean(
      (!selectedServiceId || !serviceOffers.hasOwnProperty(selectedServiceId)) &&
        pickupLocation &&
        serviceIdToSelect,
    );

    if (shouldUpdateSelectedServiceId) {
      yield put(setServiceId({ serviceId: serviceIdToSelect }));
      yield put(setChauffeurForADayExpectedDuration({ expectedDuration: null }));
    }
  } catch (error) {
    const serializedError = handleApiError(error);

    if (isSilent) {
      // eslint-disable-next-line no-console
      console.error('Cannot get fares', serializedError);

      return;
    }

    yield put(loadServiceOffersFailedAction({ error: serializedError }));
  }
}
