import { useQuery } from '@tanstack/react-query';

import { apiClient } from '_api';

import { INVOICES } from '../keys';

export const useGetInvoiceDebts = () =>
  useQuery({
    queryKey: [INVOICES.LOAD_DEBTS],
    queryFn: () => apiClient.getInvoiceDebts(),
  });
