import React from 'react';
import { Switch } from 'react-router';

import { PrivateRoute } from '_common/PrivateRoute';
import { personalSettingsRoutes } from '_routes';
import { lazyRetry } from '_utils/lazyRetry';

const PersonalPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "PersonalPage" */ './PersonalPage/PersonalPage'),
    'PersonalPage',
  ),
);

export const PersonalSettingsRouter = () => {
  return (
    <Switch>
      <PrivateRoute
        path={personalSettingsRoutes.personalSettingsBase}
        exact={true}
        component={PersonalPage}
      />
    </Switch>
  );
};
