import { IntlFormatters } from 'react-intl';

import { Location, LocationType } from '_api/types';
import { NearbyLocations } from '_store/newJourney';

import { ITEM_TYPE_BACK, META_ITEM_MESSAGES } from './constants';

type MetaLocationSuggestItemType = 'airport_by_flight_number_pickup';

export type MetaLocationSuggestItem = {
  name: string;
  type: LocationType;
  isMeta: boolean;
  metaType: MetaLocationSuggestItemType;
};

// TEMP
const markLocationsAsInitial = (locations: any[]) =>
  locations.map(location => {
    const updatedLocation = {
      ...location,
      isInitial: true,
    };

    if (location.locations) {
      updatedLocation.locations = markLocationsAsInitial(location.locations);
    }

    return updatedLocation;
  });

type MetaItemMessagesKeys = keyof typeof META_ITEM_MESSAGES;
type NearbyLocationEntries = [
  [
    key: LocationType extends MetaItemMessagesKeys ? MetaItemMessagesKeys : never,
    value: {
      locations: Location[];
    },
  ],
];

const transformNearbyLocationsToAddressItems = (
  messageFormatter: IntlFormatters['formatMessage'],
  nearbyLocations: NearbyLocations | null,
): Record<string, any>[] => {
  if (!nearbyLocations) {
    return [];
  }

  return (Object.entries(nearbyLocations) as NearbyLocationEntries)
    .map(([locationType, { locations }]) => {
      const metaItemsMessage = META_ITEM_MESSAGES[locationType];

      if (!metaItemsMessage || !locations?.length) {
        return null;
      }

      return {
        name: messageFormatter(metaItemsMessage),
        locations: markLocationsAsInitial(locations),
        type: locationType,
        isMeta: true,
      };
    })
    .filter(Boolean) as Record<string, any>[];
};

const getNearbyLocationsWithoutAirports = (nearbyLocations: NearbyLocations | null) => {
  if (!nearbyLocations) {
    return null;
  }

  const { airport, ...nearbyLocationsWithoutAirports } = nearbyLocations;

  return nearbyLocationsWithoutAirports;
};

type ListOfLocations = (MetaLocationSuggestItem | Location)[];

const toCorrectOrder = (items: ListOfLocations): ListOfLocations => {
  const head: ListOfLocations = [];
  const body: ListOfLocations = [];
  const tail: ListOfLocations = [];

  items.forEach(item => {
    if (item.type === 'airport') {
      head.push(item);

      return;
    }

    if (item.type === 'railway_hub') {
      body.push(item);

      return;
    }

    tail.push(item);
  });

  return [...head, ...body, ...tail];
};

export const createInitialItems = (
  messageFormatter: IntlFormatters['formatMessage'],
  allNearbyLocations: NearbyLocations | null,
  hideAirports: boolean,
  extraLocations?: (MetaLocationSuggestItem | Location)[] | null,
): Record<string, any>[] => {
  const extraAddressItems = extraLocations ? markLocationsAsInitial(extraLocations) : [];

  const nearbyLocations = hideAirports
    ? getNearbyLocationsWithoutAirports(allNearbyLocations)
    : allNearbyLocations;

  const nearbyAddressItems = transformNearbyLocationsToAddressItems(
    messageFormatter,
    nearbyLocations,
  );

  return toCorrectOrder([...extraAddressItems, ...nearbyAddressItems]);
};

export const getBackItem = (
  messageFormatter: IntlFormatters['formatMessage'],
  locations: Record<string, any>[],
): Record<string, any> => ({
  name: messageFormatter(META_ITEM_MESSAGES[ITEM_TYPE_BACK]),
  locations,
  type: ITEM_TYPE_BACK,
  isMeta: true,
});

export const isReferenceLocation = <T>(
  location: T extends Location ? Location : Record<string, any>,
) => Boolean(location?.place_id);

export const isAddressFieldItems = (items: unknown): items is Record<string, any>[] =>
  Array.isArray(items) && items.length > 0;
