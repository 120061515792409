import { IntlFormatters } from 'react-intl';

import { ErrorShape } from '_api/types';
import { formatNearestReservationTime } from '_utils/formatNearestReservationTime';
import { shiftDateToUtcOffset } from '_utils/shiftDateToUtcOffset';

import { MESSAGES } from '../../../constants';

const getPickupAtErrorFromServerResponse = (
  serverError: null | ErrorShape,
  messageFormatter: IntlFormatters['formatMessage'],
): string => {
  const newPickupAt = serverError?.data?.new_pickup_at;

  if (newPickupAt) {
    const formattedTime = formatNearestReservationTime(
      shiftDateToUtcOffset(new Date(newPickupAt), serverError?.data?.utc_offset),
    );

    return messageFormatter(MESSAGES.nearestAvailableTime, {
      formattedTime,
    });
  }

  return messageFormatter(MESSAGES.pickupTimeTooEarly);
};

export { getPickupAtErrorFromServerResponse };
