import React from 'react';
import cn from 'clsx';

import { GuestFooter } from '_common/GuestFooter';

import s from './styles.scss';

type Props = {
  className?: string;
};

const Footer: React.VFC<Props> = ({ className }) => (
  <div className={cn(s.root, className)}>
    <div className={s.content}>
      <GuestFooter dark={true} />
    </div>
  </div>
);

export { Footer };
