import React from 'react';
import { Route, Switch } from 'react-router';

import { NotFound } from '_pages/NotFound';
import { commonRoutes, policiesRoutes } from '_routes';
import { lazyRetry } from '_utils/lazyRetry';

const PoliciesPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "PoliciesPage" */ './PoliciesPage/PoliciesPage'),
    'PoliciesPage',
  ),
);

export const PoliciesRouter = () => (
  <Switch>
    <Route path={policiesRoutes.policiesBaseRoute} component={PoliciesPage} exact={true} />
    <Route component={NotFound} path={[commonRoutes.notFound, '*']} />
  </Switch>
);
