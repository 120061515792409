import { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { createTransport } from '@wheely/web-auth-lib';
import isNil from 'lodash/fp/isNil';
import pickBy from 'lodash/fp/pickBy';
import { v4 as uuid } from 'uuid';
import { callCbWithPageSpanContext } from '@wheely/web-tracing';

import { LangCode } from '_types/common';
import { getApiUrl } from '_utils/getApiUrl';

import { serializeError } from './utils';
import { sessionManager } from './auth';
import {
  CancelOrderPayload,
  CancelOrderResponse,
  GetCompanyOverallExpensesPayload,
  CompletionsPayload,
  CompletionsResponse,
  CreateCompanyPayload,
  CreateCompanyResponse,
  CreateInvitesPayload,
  CreateInvitesResponse,
  CreateOrderPayload,
  CreateOrderResponse,
  CreateUserPayload,
  CreateUserResponse,
  DeleteInvitesPayload,
  DeleteInvitesResponse,
  Department,
  DepartmentsExpensesPayload,
  DepartmentsExpensesResponse,
  DepartmentsResponse,
  EmployeeResponse,
  EmployeesExpensesPayload,
  EmployeesExpensesResponse,
  EmployeesResponse,
  GetCitiesResponse,
  GetBikCompletionsPayload,
  GetBikCompletionsResponse,
  GetCompanyResponse,
  GetEmployeesPayload,
  GetInvitesPayload,
  GetInvitesResponse,
  GetInvoicesPayload,
  GetInvoicesResponse,
  GetCreditsPayload,
  GetCreditsResponse,
  GetOrderPayload,
  GetOrderResponse,
  GetOrdersPayload,
  GetOrdersReportPayload,
  GetOrdersReportResponse,
  GetOrdersResponse,
  GetRecentReferencesResponse,
  GetRoutePayload,
  GetRouteResponse,
  GetUpcomingInvoiceResponse,
  GetUserResponse,
  LocationByReferencePayload,
  LocationByReferenceResponse,
  NearbyLocationsPayload,
  NearbyLocationsResponse,
  PayInvoicePayload,
  PayInvoiceResponse,
  GetCompanyOverallExpensesResponse,
  RawEmployee,
  ResendInvitePayload,
  ResendInviteResponse,
  UpdateCompanyPayload,
  UpdateCompanyResponse,
  GetServiceFaresPayload,
  GetServiceFaresResponse,
  UpdateUserPayload,
  UpdateUserResponse,
  GetInnCompletionsPayload,
  GetInnCompletionsResponse,
  GetOrderRequestPayload,
  GetOrderRequestResponse,
  FindOrCreateUserByPhonePayload,
  FindOrCreateUserByPhoneResponse,
  CreateOrderRequestPayload,
  CreateOrderRequestResponse,
  GetOrderRequestsPayload,
  GetOrderRequestsResponse,
  CancelOrderRequestPayload,
  CancelOrderRequestResponse,
  UpdateOrderPayload,
  UpdateOrderResponse,
  GetCompanyInvoiceDebtsResponse,
  Add3dsCardPayload,
  Add3dsCardResponse,
  Update3dsCardPayload,
  Update3dsCardResponse,
  GetFlightsPayload,
  GetFlightsResponse,
  GetUploadsPayload,
  GetUploadsResponse,
  UploadDocumentPayload,
  UploadDocumentResponse,
  FavoriteOrdersPayload,
  PayCreditInvoicePayload,
  PayCreditInvoiceResponse,
  GetReverseGeocodePayload,
  GetReverseGeocodeResponse,
  ErrorData,
  NewServiceErrorData,
  GetTravelPoliciesResponse,
  GetRatesResponse,
  CreateTravelPolicyData,
  UpdateTravelPolicyPayload,
  UpdateUserCompanyPayload,
  EnrichInvitePayload,
  GetInviteForEnrichmentPayload,
  EnrichedInvite,
  PublicEmployeeResponse,
  AddUserCardPayload,
  GetCompanyLastDraftResponse,
  PostCompanyDraftPayload,
  PatchCompanyDraftPayload,
  UpdateAuthEmailPayload,
  CompanyConfig,
} from './types';

if (!window.b2bAppConfig.API_URL && window.b2bAppConfig.USE_API_URL) {
  throw new Error('API_URL is not set');
}

if (!window.b2bAppConfig.APP_ID) {
  throw new Error('APP_ID is not set');
}

const appId = window.b2bAppConfig.APP_ID;
const baseURL = getApiUrl();

const pickExisting = pickBy(value => !isNil(value) && value !== '');

export const DEFAULT_LIMIT = 20;

const axiosConfig = {
  baseURL,
};

class ApiClient {
  private appId = appId;
  private transport: AxiosInstance;

  private _langCode: LangCode | null = null;

  private _lastFinishedRequestDatetime = new Date();

  private geosearchSessionId: null | string = null;
  private lastCompletionMode: undefined | string | 'dropoff';

  constructor() {
    this.transport = createTransport({
      config: axiosConfig,
      sessionManager,
      onFulfilledRequest: this.interceptRequest,
      onFulfilledResponse: this.interceptResponse,
      onRejectedResponse: this.interceptResponseError,
    });

    this.transport.request = callCbWithPageSpanContext(this.transport.request);
  }

  private interceptResponse = ({ data }: AxiosResponse) => data;

  private interceptResponseError = (error: AxiosError) => {
    const serializedError = serializeError(error as AxiosError<ErrorData | NewServiceErrorData>);

    return Promise.reject(serializedError);
  };

  private interceptRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (this._langCode) {
      config.headers['Accept-Language'] = this._langCode;
    }

    if (config.params) {
      config.params = pickExisting(config.params);
    }

    return config;
  };

  // TODO: Generic
  public request({
    url,
    method,
    data,
    params,
    headers,
  }: {
    url: string;
    method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
    data?: { [key: string]: any };
    params?: { [key: string]: any };
    headers?: { [key: string]: any };
  }): Promise<any> {
    return this.transport
      .request({
        url,
        method,
        data,
        params,
        headers,
      })
      .finally(() => {
        this._lastFinishedRequestDatetime = new Date();
      });
  }

  public getLastFinishedRequestDatetime = () => this._lastFinishedRequestDatetime;

  public set langCode(langCode: LangCode) {
    this._langCode = langCode;
  }

  public getEmployees = ({
    limit = DEFAULT_LIMIT,
    skip = 0,
    query,
    sort,
    departmentId,
    excludeDepartmentId,
    name,
    companyId,
  }: GetEmployeesPayload): Promise<EmployeesResponse> =>
    this.request({
      url: `b2b/v1/companies/${companyId}/employees`,
      method: 'GET',
      params: {
        skip,
        limit,
        query,
        sort,
        department_id: departmentId,
        exclude_department_id: excludeDepartmentId,
        name,
      },
    });

  public getEmployee = ({
    id,
  }: {
    id?: string;
  } = {}): Promise<EmployeeResponse> =>
    this.request({ url: `v6/company/employees/${id}`, method: 'GET' });

  public saveEmployee = ({
    id,
    employee,
  }: {
    id: string;
    employee: Partial<RawEmployee>;
  }): Promise<Partial<RawEmployee>> =>
    this.request({ url: `b2b/v1/company/employees/${id}`, method: 'PATCH', data: employee });

  public deleteEmployees = ({ ids }: { ids: string[] }): Promise<string[]> =>
    this.request({ url: `b2b/v1/company/employees`, method: 'DELETE', data: { ids } }).then(
      () => ids,
    );

  public getPublicEmployee = (id: string): Promise<PublicEmployeeResponse> =>
    this.request({
      url: `/b2b/pub/v1/e/${id}`,
      method: 'GET',
    });

  public getCompany = (companyId: string): Promise<GetCompanyResponse> =>
    this.request({ url: `b2b/v1/company/${companyId}`, method: 'GET' });

  public getCompanyLastDraft = (): Promise<GetCompanyLastDraftResponse> =>
    this.request({ url: `b2b/v1/company/draft/last`, method: 'GET' });

  public updateCompanyDraft = ({
    id,
    ...data
  }: PatchCompanyDraftPayload): Promise<GetCompanyLastDraftResponse> =>
    this.request({ url: `b2b/v1/company/draft/${id}`, method: 'PATCH', data });

  public createCompanyDraft = (
    data: PostCompanyDraftPayload,
  ): Promise<GetCompanyLastDraftResponse> =>
    this.request({ url: 'b2b/v1/company/draft', method: 'POST', data });

  public updateCompany = ({
    company: { id, ...rest },
  }: UpdateCompanyPayload): Promise<UpdateCompanyResponse> =>
    this.request({ url: `b2b/v1/company/${id}`, method: 'PATCH', data: rest });

  public createCompany = ({ company }: CreateCompanyPayload): Promise<CreateCompanyResponse> =>
    this.request({ url: 'b2b/v1/company', method: 'POST', data: { company } });

  public getCities = (): Promise<GetCitiesResponse> =>
    this.request({ url: 'v6/cities', method: 'GET' });

  public getCompanyOverallExpenses = ({
    start,
    end,
  }: GetCompanyOverallExpensesPayload): Promise<GetCompanyOverallExpensesResponse> =>
    this.request({ url: 'b2b/v1/expenses/overall', method: 'GET', params: { start, end } });

  public getDepartments = ({
    limit = DEFAULT_LIMIT,
    skip = 0,
    query,
    sort,
    companyId,
  }: {
    limit?: number;
    skip?: number;
    query?: string;
    sort?: string;
    companyId: string;
  }): Promise<DepartmentsResponse> =>
    this.request({
      url: `b2b/v1/companies/${companyId}/departments`,
      method: 'GET',
      params: { skip, limit, query, sort },
    });

  public getDepartment = ({ id }: { id: string }): Promise<{ department: Department }> =>
    this.request({ url: `v6/company/departments/${id}`, method: 'GET' });

  public createDepartment = ({ title }: { title: string }): Promise<{ department: Department }> =>
    this.request({ url: 'v6/company/departments', method: 'POST', data: { title } });

  public editDepartment = (
    id: string,
    departmentUpdate: Partial<Department>,
  ): Promise<{ department: Department }> =>
    this.request({ url: `v6/company/departments/${id}`, method: 'PATCH', data: departmentUpdate });

  public deleteDepartment = ({ id }: { id: string }): Promise<{ id: string }> =>
    this.request({ url: `v6/company/departments/${id}`, method: 'DELETE' }).then(() => ({ id }));

  public getInvites = ({
    query,
    limit = DEFAULT_LIMIT,
    skip = 0,
  }: GetInvitesPayload): Promise<GetInvitesResponse> =>
    this.request({
      url: 'b2b/v1/invites',
      method: 'GET',
      params: {
        query,
        limit,
        skip,
      },
    });

  public createInvites = ({ invites }: CreateInvitesPayload): Promise<CreateInvitesResponse> =>
    this.request({ url: 'b2b/v1/invites', method: 'POST', data: invites });

  public resendInvite = ({ id }: ResendInvitePayload): Promise<ResendInviteResponse> =>
    this.request({
      url: `b2b/v1/invites/${id}/resend`,
      method: 'POST',
    });

  public deleteInvites = ({ ids }: DeleteInvitesPayload): Promise<DeleteInvitesResponse> =>
    this.request({
      url: `b2b/v1/invites`,
      method: 'DELETE',
      data: {
        ids,
      },
    });

  public getInviteForEnrichment = ({
    invite_id,
  }: GetInviteForEnrichmentPayload): Promise<EnrichedInvite> =>
    this.request({
      url: `/b2b/v1/invites/${invite_id}/enrich`,
      method: 'GET',
    });

  public enrichInvite = ({ invite_id, phone }: EnrichInvitePayload): Promise<EnrichedInvite> =>
    this.request({
      url: `/b2b/v1/invites/${invite_id}/enrich`,
      method: 'PATCH',
      data: {
        phone,
      },
    });

  public createUser = ({ user }: CreateUserPayload): Promise<CreateUserResponse> =>
    this.request({
      url: 'v6/users',
      method: 'POST',
      data: { app_id: this.appId, user },
    });

  public getUser = (): Promise<GetUserResponse> =>
    this.request({
      url: 'b2b/v1/user',
      method: 'GET',
    });

  public updateUser = ({ user }: UpdateUserPayload): Promise<UpdateUserResponse> =>
    this.request({
      url: 'v6/user',
      method: 'PATCH',
      data: { app_id: this.appId, user },
    });

  public updateAuthEmail = ({ auth_email }: UpdateAuthEmailPayload): Promise<GetUserResponse> =>
    this.request({
      url: 'b2b/v1/user/auth_email',
      method: 'PATCH',
      data: { auth_email },
    });

  public updateUserCompany = ({
    company_id,
  }: UpdateUserCompanyPayload): Promise<UpdateUserResponse> =>
    this.request({
      url: 'b2b/v1/user',
      method: 'PATCH',
      data: { company_id },
    });

  public getEmployeesExpenses = ({
    start,
    end,
    limit = DEFAULT_LIMIT,
    skip = 0,
  }: EmployeesExpensesPayload): Promise<EmployeesExpensesResponse> =>
    this.request({
      url: 'b2b/v1/expenses/employees',
      method: 'GET',
      params: { start, end, limit, skip },
    });

  public getDepartmentsExpenses = ({
    start,
    end,
    limit = DEFAULT_LIMIT,
    skip = 0,
    companyId,
  }: DepartmentsExpensesPayload): Promise<DepartmentsExpensesResponse> =>
    this.request({
      url: `b2b/v1/companies/${companyId}/expenses/departments`,
      method: 'GET',
      params: { start, end, limit, skip },
    });

  public getNearbyLocations = ({
    position,
    type,
  }: NearbyLocationsPayload): Promise<NearbyLocationsResponse> =>
    this.request({
      url: 'geosearch/v6/nearby',
      method: 'GET',
      params: { position, type },
    });

  public getCompletions = ({
    input,
    position,
    mode,
  }: CompletionsPayload): Promise<CompletionsResponse> => {
    if (!this.geosearchSessionId || this.lastCompletionMode !== mode) {
      this.geosearchSessionId = uuid();
    }

    this.lastCompletionMode = mode;

    const params: CompletionsPayload = {
      include: 'zones',
      input,
      position,
      session_id: this.geosearchSessionId,
    };

    if (mode === 'dropoff') {
      params.mode = 'dropoff';
    } else {
      params.mode = 'pickup';
    }

    return this.request({
      url: 'geosearch/v6/complete',
      method: 'GET',
      params,
    });
  };

  public getLocationByReference = ({
    place_id,
  }: LocationByReferencePayload): Promise<LocationByReferenceResponse> => {
    const session_id = this.geosearchSessionId ?? uuid();

    return this.request({
      url: 'geosearch/v6/details',
      method: 'GET',
      params: { place_id, session_id },
    }).then(result => {
      this.geosearchSessionId = null;

      return result;
    });
  };

  public getServiceFares = ({
    route,
    points,
    selected_service_id,
    pickup_at,
    pickup,
    stops,
    dropoff,
    expected_duration,
  }: GetServiceFaresPayload): Promise<GetServiceFaresResponse> =>
    this.request({
      url: 'v6/services/fare',
      method: 'GET',
      params: {
        route,
        points,
        selected_service_id,
        pickup_at,
        pickup: JSON.stringify(pickup),
        stops: JSON.stringify(stops),
        dropoff: JSON.stringify(dropoff),
        expected_duration,
      },
    });

  public getRoute = ({ pickup, stops, dropoff }: GetRoutePayload): Promise<GetRouteResponse> =>
    this.request({
      url: 'b2b/v1/route',
      method: 'GET',
      params: {
        pickup: JSON.stringify(pickup),
        stops: JSON.stringify(stops),
        dropoff: JSON.stringify(dropoff),
      },
    });

  public getOrders = ({
    from,
    to,
    limit = DEFAULT_LIMIT,
    skip = 0,
    user_id,
    department_id,
    statuses,
    favorite,
    query,
  }: GetOrdersPayload): Promise<GetOrdersResponse> =>
    this.request({
      url: 'b2b/v1/orders',
      method: 'GET',
      params: {
        from,
        to,
        limit,
        skip,
        user_id,
        department_id,
        statuses,
        favorite,
        query,
      },
    });

  public getOrder = ({ id }: GetOrderPayload): Promise<GetOrderResponse> =>
    this.request({ url: `b2b/v1/orders/${id}`, method: 'GET' });

  public createOrder = ({
    user_id,
    passenger,
    pickup,
    pickup_details,
    stops,
    dropoff,
    service_id,
    pickup_at,
    pickup_at_local,
    payment_method_id,
    comment,
    reference,
    payer,
    expected_duration,
    app = {
      version: 'w4b',
    },
    external_id,
  }: CreateOrderPayload): Promise<CreateOrderResponse> =>
    this.request({
      url: 'v6/company/orders',
      method: 'POST',
      data: {
        user_id,
        passenger,
        pickup,
        pickup_details,
        stops,
        dropoff,
        service_id,
        pickup_at,
        pickup_at_local,
        payment_method_id,
        comment,
        reference,
        payer,
        expected_duration,
        app,
        external_id,
      },
    });

  public updateOrder = ({ id, ...order }: UpdateOrderPayload): Promise<UpdateOrderResponse> =>
    this.request({
      url: `v6/company/orders/${id}`,
      method: 'PATCH',
      data: { order },
    });

  public cancelOrder = ({ id }: CancelOrderPayload): Promise<CancelOrderResponse> =>
    this.request({ url: `v6/company/orders/${id}/cancel`, method: 'PUT' });

  public addOrdersToFavorites = ({ orderIds }: FavoriteOrdersPayload): Promise<void> =>
    this.request({
      url: 'b2b/v1/employee/favorite_orders',
      method: 'POST',
      data: { favorite_order_ids: orderIds },
    });

  public deleteOrdersFromFavorites = ({ orderIds }: FavoriteOrdersPayload): Promise<void> =>
    this.request({
      url: 'b2b/v1/employee/favorite_orders',
      method: 'DELETE',
      data: { favorite_order_ids: orderIds },
    });

  public getRecentReferences = (): Promise<GetRecentReferencesResponse> =>
    this.request({
      url: 'v6/company/recent_references',
      method: 'GET',
    });

  public getReceiptUrl = ({ orderId, token }: { orderId: string; token: string }): string =>
    `${baseURL}${`v6/company/orders/${orderId}/pdf?short_token=${token}`}`;

  public getOrdersReport = ({
    token,
    from,
    to,
    user_id,
    locale,
  }: GetOrdersReportPayload): Promise<GetOrdersReportResponse> =>
    this.request({
      url: 'b2b/v1/orders/report',
      method: 'GET',
      params: { short_token: token, from, to, user_id, locale },
    });

  public getUpcomingInvoice = (): Promise<GetUpcomingInvoiceResponse> =>
    this.request({
      method: 'GET',
      url: 'v6/company/invoices/upcoming',
    });

  public getInvoices = ({ limit, skip }: GetInvoicesPayload): Promise<GetInvoicesResponse> =>
    this.request({
      method: 'GET',
      url: 'v6/company/invoices',
      params: {
        limit,
        skip,
      },
    });

  public payInvoice = ({ invoiceId }: PayInvoicePayload): Promise<PayInvoiceResponse> =>
    this.request({
      method: 'POST',
      url: `v6/company/invoices/${invoiceId}/pay`,
    });

  public getInvoiceDebts = (): Promise<GetCompanyInvoiceDebtsResponse> =>
    this.request({
      method: 'GET',
      url: 'b2b/v1/invoices/debts',
    });

  public getCredits = ({
    limit = DEFAULT_LIMIT,
    skip = 0,
  }: GetCreditsPayload): Promise<GetCreditsResponse> =>
    this.request({
      method: 'GET',
      url: 'v6/company/credits',
      params: {
        limit,
        skip,
      },
    });

  public payCreditInvoice = ({
    invoiceId,
  }: PayCreditInvoicePayload): Promise<PayCreditInvoiceResponse> =>
    this.request({
      method: 'POST',
      url: `v6/company/credits/${invoiceId}/pay`,
    });

  public add3dsCard = (data: Add3dsCardPayload): Promise<Add3dsCardResponse> =>
    this.request({
      method: 'POST',
      url: 'v6/company/payments',
      data,
    });

  public addUserCard = ({ company_id, user_card_id }: AddUserCardPayload): Promise<void> =>
    this.request({
      method: 'POST',
      url: `/b2b/v1/companies/${company_id}/payments/personal`,
      data: { user_card_id },
    });

  public update3dsCard = (data: Update3dsCardPayload): Promise<Update3dsCardResponse> =>
    this.request({
      method: 'PUT',
      url: 'v6/company/payments',
      data,
    });

  public getBikCompletions = ({
    bik,
  }: GetBikCompletionsPayload): Promise<GetBikCompletionsResponse> =>
    this.request({
      method: 'GET',
      url: `v6/search/bik/${bik}`,
    });

  public getInnCompletions = ({
    inn,
  }: GetInnCompletionsPayload): Promise<GetInnCompletionsResponse> =>
    this.request({
      method: 'GET',
      url: `v6/search/inn/${inn}`,
    });

  public getOrderRequests = ({
    limit = DEFAULT_LIMIT,
    skip = 0,
    sorted_at_from,
    sorted_at_to,
    query,
    statuses,
    creator_id,
  }: GetOrderRequestsPayload): Promise<GetOrderRequestsResponse> =>
    this.request({
      method: 'GET',
      url: 'b2b/v1/order_requests',
      params: {
        limit,
        skip,
        sorted_at_from,
        sorted_at_to,
        query,
        statuses,
        creator_id,
      },
    });

  public createOrderRequest = ({
    user,
    order,
  }: CreateOrderRequestPayload): Promise<CreateOrderRequestResponse> =>
    this.request({
      method: 'POST',
      url: 'b2b/v1/order_requests',
      data: {
        user,
        order,
      },
    });

  public findOrCreateUserByPhone = ({
    phone,
  }: FindOrCreateUserByPhonePayload): Promise<FindOrCreateUserByPhoneResponse> =>
    this.request({
      method: 'POST',
      url: 'b2b/v1/order_requests/users',
      data: { phone },
    });

  public getOrderRequest = ({ id }: GetOrderRequestPayload): Promise<GetOrderRequestResponse> =>
    this.request({ url: `b2b/v1/order_requests/${id}`, method: 'GET' });

  public cancelOrderRequest = ({
    id,
  }: CancelOrderRequestPayload): Promise<CancelOrderRequestResponse> =>
    this.request({ url: `b2b/v1/order_requests/${id}/cancel`, method: 'POST' });

  public getFlights = ({
    flight_number,
    departure_date,
  }: GetFlightsPayload): Promise<GetFlightsResponse> =>
    this.request({
      method: 'GET',
      url: 'flights/v2/by_number',
      params: { flight_number, departure_date },
    });

  public getUploads = ({ companyId }: GetUploadsPayload): Promise<GetUploadsResponse> =>
    this.request({
      method: 'GET',
      url: `v6/uploads/b2b/companies/${companyId}`,
      params: {
        prefix: 'vat_certificate',
        limit: '1',
      },
    });

  public uploadCompanyDocument = ({
    companyId,
    file,
    prefix,
    countryCode,
  }: UploadDocumentPayload): Promise<UploadDocumentResponse> => {
    // must for multipart/form-data
    const formData = new FormData();

    formData.append('file', file);
    formData.append('prefix', prefix);
    formData.append('country_code', countryCode);

    return this.request({
      method: 'POST',
      url: `v6/uploads/b2b/companies/${companyId}`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'multipart/form-data' },
    });
  };

  public getReverseGeocode = ({
    lat,
    lng,
  }: GetReverseGeocodePayload): Promise<GetReverseGeocodeResponse> =>
    this.request({
      method: 'GET',
      url: `geosearch/v6/reverse?position=${lat},${lng}`,
    });

  public getTravelPolicies = (): Promise<GetTravelPoliciesResponse> =>
    this.request({
      method: 'GET',
      url: 'b2b/v1/travel_policies',
    });

  public getRates = (): Promise<GetRatesResponse> =>
    this.request({
      method: 'GET',
      url: 'v6/rates',
    });

  public createTravelPolicy = ({
    name,
    departmentsIds,
    vendorIds,
    monthlyLimit,
    referenceRequired,
  }: CreateTravelPolicyData) =>
    this.request({
      method: 'POST',
      url: 'b2b/v1/travel_policies',
      data: {
        name,
        department_ids: departmentsIds,
        vendor_ids: vendorIds,
        monthly_limit: monthlyLimit,
        reference_required: referenceRequired,
      },
    });

  public updateTravelPolicy = ({
    name,
    departmentsIds,
    vendorIds,
    monthlyLimit,
    id,
    referenceRequired,
  }: UpdateTravelPolicyPayload) =>
    this.request({
      method: 'PUT',
      url: `b2b/v1/travel_policies/${id}`,
      data: {
        name,
        department_ids: departmentsIds,
        vendor_ids: vendorIds,
        monthly_limit: monthlyLimit,
        reference_required: referenceRequired,
      },
    });

  public deleteTravelPolicy = ({ id }: { id: string }) =>
    this.request({
      method: 'DELETE',
      url: `b2b/v1/travel_policies/${id}`,
    });

  public getAnalyticsDashboardUrl = ({ companyId }: { companyId: string }) =>
    this.request({
      method: 'GET',
      url: `b2b/v1/companies/${companyId}/analytics_dashboard_url`,
    });

  public getCompanyConfig = (): Promise<CompanyConfig> =>
    this.request({
      method: 'GET',
      url: 'b2b/pub/v1/company/config',
    });

  public postCompanyPaymentsInvoice = ({
    companyId,
  }: {
    companyId: string;
  }): Promise<CompanyConfig> =>
    this.request({
      method: 'POST',
      url: `b2b/v1/companies/${companyId}/payments/invoice`,
    });

  public postCompanyPaymentsDirectDebit = ({
    companyId,
  }: {
    companyId: string;
  }): Promise<CompanyConfig> =>
    this.request({
      method: 'POST',
      url: `b2b/v1/companies/${companyId}/payments/direct_debit`,
    });
}

const apiClient = new ApiClient();

export { apiClient };
