import React from 'react';
import { useIntl } from 'react-intl';
import { useField } from 'formik';
import { CheckboxField } from '@wheely/ui-kit';

import styles from './styles.scss';
import { MeetAndGreetOnRailwayHub } from './components/MeetAndGreetRailwayHub';
import { MeetAndGreetAirport } from './components/MeetAndGreetAirport';

type Props = {
  disabled?: boolean;
  isOriginPickupSubType?: boolean;
  type: 'airport' | 'railway_hub' | null;
};

const MeetAndGreetField = ({ disabled, type, isOriginPickupSubType }: Props) => {
  const intl = useIntl();
  const [welcomeBoardField] = useField('welcomeBoard');

  return (
    <div className={styles.root} data-test-id={'meet-and-greet-section'}>
      <div className={styles.checkbox}>
        <CheckboxField
          {...welcomeBoardField}
          disabled={disabled}
          checked={welcomeBoardField.value}
          data-test-id="meet-and-greet-checkbox"
        >
          {intl.formatMessage({
            description: 'Meet and Greet checkbox label',
            defaultMessage: 'Meet and Greet',
            id: 'Eht/kV',
          })}
        </CheckboxField>
      </div>
      {type === 'airport' && (
        <MeetAndGreetAirport disabled={disabled} welcomeBoardField={welcomeBoardField} />
      )}
      {type === 'railway_hub' && (
        <MeetAndGreetOnRailwayHub
          isOriginPickupSubType={isOriginPickupSubType}
          disabled={disabled}
          welcomeBoardField={welcomeBoardField}
        />
      )}
    </div>
  );
};

export { MeetAndGreetField };
