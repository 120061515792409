import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { H3, H5, useNotification } from '@wheely/ui-kit';

import { BasicLayout } from '_layouts/Basic';
import { useGetCompany } from '_queries/company/useGetCompany';
import { useIsUserHasMoreThenOneCompany, useUser } from '_hooks/user';
import { useDevice } from '_hooks/useDevice';
import { sendSnowplowEvent } from '_hooks/useSnowplow';
import { useCompany } from '_hooks/company';

import { ADD_COMPANY_SOURCE } from '../AddCompanyPage/components/StepsProvider/analytics';
import { REGISTRATION_SOURCE } from '../RegisterPage/components/StepsProvider/analytics';

import styles from './styles.scss';

const NEW_REGISTRATION_VERSION = '2.0';

export const PendingCompanyPage = () => {
  const user = useUser();
  const company = useCompany();
  const { isPhone } = useDevice();
  const useIsUserHasMoreThanOneCompany = useIsUserHasMoreThenOneCompany();
  const { showNotification } = useNotification();
  const intl = useIntl();

  useGetCompany(user?.company?.id, true, true);

  useEffect(() => {
    sendSnowplowEvent({
      event: 'Signup - Success',
      properties: {
        city: company?.address?.city,
        version: NEW_REGISTRATION_VERSION,
        source: useIsUserHasMoreThanOneCompany ? ADD_COMPANY_SOURCE : REGISTRATION_SOURCE,
      },
    });
  }, [company?.address?.city, isPhone, useIsUserHasMoreThanOneCompany]);

  const handleEmailClick = useCallback(() => {
    window.navigator.clipboard
      .writeText('support@wheely.com')
      .then(() => {
        showNotification({
          message: intl.formatMessage({
            description: 'Support email copied notification in menu text',
            defaultMessage: 'Email address copied',
            id: 'global_copied',
          }),
        });
      })
      .catch(() => {});
  }, [intl, showNotification]);

  return (
    <BasicLayout>
      <div className={styles.contentWrapper}>
        <div className={styles.contentRoot}>
          <H3 className={styles.title}>
            <FormattedMessage
              defaultMessage="Company is being verified"
              id="nPC0zH"
              description="Pending company stub title"
            />
          </H3>
          <H5 className={styles.description}>
            <FormattedMessage
              defaultMessage="Our team is reviewing your information. This usually takes 1{hyphen}3{space}business days. You will receive an email notification when your account becomes activated."
              id="jaWiPq"
              description="Pending company stub description"
              values={{
                space: ' ',
                hyphen: '‑',
              }}
            />
            <br />
            <br />
            <FormattedMessage
              defaultMessage="If you have any questions, contact us anytime at"
              id="I+eFWN"
              description="Pending company email description"
            />
            <br />
            <button onClick={handleEmailClick} className={styles.link}>
              support@wheely.com
            </button>
          </H5>
        </div>
      </div>
    </BasicLayout>
  );
};
