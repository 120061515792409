import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { differenceInDays, isPast, isToday, isValid, startOfToday } from 'date-fns';

import { useLocaleDate } from '_i18n/useLocaleDate';
import { PageNotifier } from '_common/PageNotifier';
import { useHasPermission } from '_app/PermissionsProvider';
import { useGetInvoiceDebts } from '_queries/invoices/useGetInvoiceDebts';
import { handleApiError } from '_utils/handleApiError';
import { useCompany } from '_hooks/company';

import { DATE_FORMAT_PATTERN, MESSAGES } from './constants';

type Props = { className?: string };

export const CompanyDebtsNotifier = ({ className }: Props) => {
  const intl = useIntl();
  const { format, formatDistanceToNowStrict } = useLocaleDate();

  const canReadCompanyDebtsNotifier = useHasPermission('common:companyDebtsNotifier:read');
  const companyStatus = useCompany()?.status || null;
  const { data, error } = useGetInvoiceDebts();

  useEffect(() => {
    if (error) {
      handleApiError(error);
    }
  }, [error]);

  const companyInvoiceDebtsDueDate = useMemo(() => {
    if (!data?.grace_due_date) {
      return null;
    }

    const date = new Date(data?.grace_due_date);

    if (!isValid(date)) {
      return null;
    }

    return date;
  }, [data]);

  if (
    !canReadCompanyDebtsNotifier ||
    companyStatus !== 'has_debts' ||
    !companyInvoiceDebtsDueDate
  ) {
    return null;
  }

  let description: React.ReactNode = null;

  const isBlocked = isPast(companyInvoiceDebtsDueDate) || isToday(companyInvoiceDebtsDueDate);

  if (isBlocked) {
    description = intl.formatMessage(MESSAGES.blockedText);
  } else {
    const formattedDueDate = format(companyInvoiceDebtsDueDate, DATE_FORMAT_PATTERN);

    const distanceToDueDateInDays = differenceInDays(companyInvoiceDebtsDueDate, startOfToday());

    if (distanceToDueDateInDays > 10) {
      description = intl.formatMessage(MESSAGES.statementText, {
        period: format(new Date(), 'LLLL yyyy'),
        dueDate: formattedDueDate,
      });
    } else {
      const formatterDaysLeft = formatDistanceToNowStrict(companyInvoiceDebtsDueDate, {
        unit: 'day',
        roundingMethod: 'ceil',
      });

      const message =
        distanceToDueDateInDays > 3 ? MESSAGES.daysLeftText : MESSAGES.lessThan3DaysLeftText;

      description = intl.formatMessage(message, {
        daysLeft: formatterDaysLeft,
        dueDate: formattedDueDate,
      });
    }
  }

  return (
    <PageNotifier
      className={className}
      anchorButtonProps={{
        text: intl.formatMessage(MESSAGES.linkLabel),
        href: '/billing',
      }}
    >
      {description}
    </PageNotifier>
  );
};
