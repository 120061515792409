import { useQuery } from '@tanstack/react-query';

import { apiClient } from '_api';

import { COMPANY } from '../keys';

export const useGetCompanyLastDraft = (enabled = true) =>
  useQuery({
    enabled,
    retry: 0,
    queryKey: [COMPANY.LOAD_LAST_DRAFT],
    queryFn: () => apiClient.getCompanyLastDraft().then(({ company_draft }) => company_draft),
  });
