import React from 'react';
import { Redirect, useLocation } from 'react-router';

import { useTypedSelector } from '_store';
import { isLoggedInSelector } from '_store/auth';
import { commonRoutes } from '_routes';
import { WithChildren } from '_types/helpers';

type LocationState = {
  from?: {
    pathname?: string;
  };
};

export const WithNotLoggedIn = ({
  children,
  customCondition = true,
  redirectTo,
}: WithChildren<{ redirectTo?: string; customCondition?: boolean }>) => {
  const isLoggedIn = useTypedSelector(isLoggedInSelector);
  const location = useLocation<LocationState>();
  const redirectPathname = redirectTo || location?.state?.from?.pathname || commonRoutes.root;

  const redirectLocation = {
    ...location,
    pathname: redirectPathname,
  };

  if (isLoggedIn && customCondition) {
    return <Redirect to={redirectLocation} />;
  }

  return <>{children}</>;
};
