import React from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import clsx from 'clsx';

import { Company, Fare } from '_api/types';
import { FormattedCurrency } from '_common/FormattedCurrency';
import { FormattedCurrencyRange } from '_common/FormattedCurrencyRange';
import { featureFlags, INCREASED_ESTIMATED_PRICE_RATIOS } from '_utils/featureFlags';
import { useCompany } from '_hooks/company';

import { MESSAGES } from '../../constants';

import { getAmountWithFallback, isValidAmount } from './utils';
import s from './styles.scss';

const getFormattedFareValue = (
  company: Company | null | undefined,
  fare: Fare,
  isDropoffExist: boolean,
  messageFormatter: IntlFormatters['formatMessage'],
) => {
  const {
    currency_code: currencyCode = null,
    value_discounted,
    value,
    estimate,
    estimate_discounted,
    estimate_max,
    estimate_discounted_max,
    minimum,
  } = fare;

  if (isValidAmount(value)) {
    return (
      <FormattedCurrency
        currencyCode={currencyCode}
        value={getAmountWithFallback(value_discounted, value)}
      />
    );
  }

  if (isDropoffExist && isValidAmount(estimate)) {
    const estimateMinValue = getAmountWithFallback(estimate_discounted, estimate);

    if (isValidAmount(estimate_max)) {
      let estimateMaxValue = getAmountWithFallback(estimate_discounted_max, estimate_max);

      if (company?.id && featureFlags.isEstimatedPriceChanged(company.id)) {
        estimateMaxValue = Math.round(
          estimateMaxValue * INCREASED_ESTIMATED_PRICE_RATIOS[company.id],
        );
      }

      return (
        <FormattedCurrencyRange
          currencyCode={currencyCode}
          from={estimateMinValue}
          to={estimateMaxValue}
        />
      );
    }

    return <FormattedCurrency currencyCode={currencyCode} value={estimateMinValue} />;
  }

  if (isValidAmount(minimum)) {
    return (
      <>
        {messageFormatter(MESSAGES.fareMinimumPrefix)}
        &nbsp;
        <FormattedCurrency currencyCode={currencyCode} value={minimum} />
      </>
    );
  }

  return null;
};

const ItemFare = ({
  fare,
  isDropoffExist,
  className,
}: {
  fare?: Fare | null;
  isDropoffExist: boolean;
  className?: string;
}) => {
  const intl = useIntl();
  const company = useCompany();

  if (!fare) {
    return null;
  }

  const formattedFare = getFormattedFareValue(company, fare, isDropoffExist, intl.formatMessage);

  return formattedFare ? <div className={clsx(s.fare, className)}>{formattedFare}</div> : null;
};

export { ItemFare };
