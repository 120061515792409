import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import noop from 'lodash/fp/noop';
import omit from 'lodash/fp/omit';
import { useField } from 'formik';
import { CustomBaseSuggest, IconClear, Intent } from '@wheely/ui-kit';

import { Flight, Location } from '_api/types';
import { AddFieldButton } from '_common/AddFieldButton';
import { EditLocationOnMapButton } from '_common/EditLocationOnMapButton';
import { FlightCard } from '_common/FlightCard';
import { LocationField, LocationFieldProps } from '_common/LocationField';
import { WrappedIconPickup } from '_common/LocationField/common';
import { isReferenceLocation, MetaLocationSuggestItem } from '_common/LocationField/helpers';
import { withLocalSagas } from '_hocs/withLocalSagas';
import { usePrevious } from '_hooks';
import { RefinedLocation } from '_sagas/common/getLocationByReference';
import { getLocationByReference } from '_sagas/newJourney/getLocationByReference';
import { useTypedSelector } from '_store';
import { companyCityOrUserLocationSelector } from '_store/common/selectors';
import { FieldToEdit, mapModeSelector, setEditMode } from '_store/mapInteraction';
import {
  flightSelector,
  loadPickupCompletions,
  pickupCompletionsSelector,
  pickupCompletionsZonesSelector,
  pickupLocationSelector,
  pickupSelector,
  preFilledJourneySelector,
  setFlight as setFlightAction,
  setPickupLocation as setPickupLocationAction,
  setServiceId,
} from '_store/newJourney';
import { getLocationName } from '_utils/getLocationName';
import { handleApiError } from '_utils/handleApiError';
import { isAirport } from '_utils/isAirport';
import { isPickupLocationInaccurate } from '_utils/isPickupLocationInaccurate';
import { useHospitalityFavouriteAddress } from '_hooks/company';

import { getFieldErrorMessage } from '../Form/utils/getFieldErrorMessage';
import { FormGroupTitle } from '../FormGroupTitle';

import { AirportPickupDialog } from './components/AirportPickupDialog';
import s from './styles.scss';

type Props = {
  disabled?: boolean;
  onAsapRequest: () => void;
  formPickupError?: string;
  clearServerError?: () => void;
};

const PickupAddressField = withLocalSagas<Props>(
  ({ runSaga, disabled, onAsapRequest, formPickupError, clearServerError }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [, pickupMeta, helpers] = useField('pickup');
    const mapMode = useTypedSelector(mapModeSelector);

    const previousMapMode = usePrevious(mapMode);

    const [isFlightSearchDialogOpen, setIsFlightSearchDialogOpen] = useState(false);

    const { query, isLoading } = useTypedSelector(pickupSelector);
    const location = useTypedSelector(pickupLocationSelector);

    const completions = useTypedSelector(pickupCompletionsSelector);
    const zones = useTypedSelector(pickupCompletionsZonesSelector);
    const flight = useTypedSelector(flightSelector);

    const isAirportLocation = isAirport(location?.type);

    const defaultPosition = useTypedSelector(companyCityOrUserLocationSelector);

    const lastUsedLocation = useRef(location);
    const suggestRef = useRef<CustomBaseSuggest<Record<string, any>>>(null);

    const handleQueryChange = useCallback(
      (pickupQuery: string) => {
        dispatch(loadPickupCompletions({ query: pickupQuery }));
      },
      [dispatch],
    );

    // update suggestions when map pin updated
    useEffect(() => {
      const shouldUpdateSuggestions =
        mapMode === 'view' &&
        previousMapMode === 'edit' &&
        lastUsedLocation.current !== location &&
        location;

      if (shouldUpdateSuggestions) {
        lastUsedLocation.current = location;

        if (location.line1) {
          handleQueryChange(location.line1);
        }
      }
    }, [location, mapMode, previousMapMode, handleQueryChange]);

    const setPickupLocation = useCallback(
      (pickupLocation: Location | null) => {
        if (clearServerError) {
          clearServerError();
        }

        dispatch(setPickupLocationAction({ location: pickupLocation }));

        if (!pickupLocation) {
          dispatch(setServiceId({ serviceId: null }));
        }
      },
      [clearServerError, dispatch],
    );

    const handleAirportPickupDialogLocationChange = useCallback(
      (pickupLocation: Location | null) => {
        // to prevent flashing of validation error
        helpers.setTouched(false);
        setPickupLocation(pickupLocation);
      },
      [setPickupLocation, helpers],
    );

    const handleItemSelect = useCallback(
      async (item: Record<string, any> | null) => {
        if (!item) {
          setPickupLocation(null);

          return;
        }

        if (item.type === 'airport') {
          setIsFlightSearchDialogOpen(true);
        }

        if (item.metaType === 'airport_by_flight_number_pickup') {
          if (suggestRef.current) {
            suggestRef.current.setOpenState(false);
          }

          setIsFlightSearchDialogOpen(true);

          return;
        }

        const isMetaOrInitialItemType = item.isMeta || item.isInitial;
        const derivedLocation = omit(['isMeta', 'isInitial'], item) as Location;

        if (!isMetaOrInitialItemType) {
          handleQueryChange(getLocationName(derivedLocation));
        }

        // Try to get detailed exact location from the given reference
        if (!isMetaOrInitialItemType && isReferenceLocation<Location>(derivedLocation)) {
          try {
            const getLocationByReferenceResult: RefinedLocation | undefined = await runSaga(
              getLocationByReference,
              { locationReference: derivedLocation, type: 'pickup' },
            );

            if (getLocationByReferenceResult?.location) {
              // Refined location is fine, let's set it as the next location
              const { location: refinedLocation } = getLocationByReferenceResult;

              handleQueryChange(getLocationName(refinedLocation));

              // In case of refined location _is_ exact location already,
              // we need to close suggest manually by calling method
              // of the Suggest instance we're holding in the ref
              if (!isPickupLocationInaccurate(refinedLocation) && suggestRef.current) {
                suggestRef.current.setOpenState(false);
              }
            }
          } catch (error) {
            handleApiError(error);
          }
        } else {
          setPickupLocation(derivedLocation);
        }
      },
      [handleQueryChange, runSaga, setPickupLocation],
    );

    const handleClosing = useCallback(() => {
      helpers.setTouched(true);
    }, [helpers]);

    const handleAddFlightNumberButtonClick = useCallback(() => {
      setIsFlightSearchDialogOpen(true);
    }, []);

    useEffect(() => {
      if (lastUsedLocation.current !== location) {
        if (!location) {
          // Trigger validation on render tick when selected item gets removed
          helpers.setTouched(true);
        }

        lastUsedLocation.current = location;
      }
    }, [location, lastUsedLocation.current]); // eslint-disable-line react-hooks/exhaustive-deps

    const pickupError = isFlightSearchDialogOpen
      ? undefined
      : formPickupError || getFieldErrorMessage(pickupMeta);

    const inputProps: LocationFieldProps['inputProps'] = {
      leftIcon: WrappedIconPickup,
      placeholder: intl.formatMessage({
        description: 'Pickup address field suggest placeholder',
        defaultMessage: 'Pickup',
        id: 'AHBFFR',
      }),
      'data-test-id': 'pickup-select-address-input',
    };

    const hospitalityFavouriteAddress = useHospitalityFavouriteAddress();
    const preFilledJourney = useTypedSelector(preFilledJourneySelector);

    useEffect(() => {
      if (preFilledJourney && location) {
        return;
      }

      // hospitality favourite address selector will return value only in case company type is hospitality
      // so it's unnecessary to check company type here
      if (hospitalityFavouriteAddress) {
        setPickupLocation(hospitalityFavouriteAddress);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const extraInitialLocations = useMemo(() => {
      const result: (MetaLocationSuggestItem | Location)[] = [
        {
          name: intl.formatMessage({
            defaultMessage: 'Airport Pickup',
            id: 'CYS7H4',
            description: 'Airport Pickup suggest item text',
          }),
          type: 'airport',
          metaType: 'airport_by_flight_number_pickup',
          isMeta: true,
        },
      ];

      if (hospitalityFavouriteAddress) {
        result.push(hospitalityFavouriteAddress);
      }

      return result;
    }, [hospitalityFavouriteAddress, intl]);

    const handleFlightSearchDialogClose = useCallback(() => setIsFlightSearchDialogOpen(false), []);

    const handleFlightSearchDialogFlightSelect = useCallback(
      (selectedFlight: Flight) => {
        setPickupLocation(selectedFlight.pickup);
        dispatch(setFlightAction({ flight: selectedFlight }));
      },
      [setPickupLocation, dispatch],
    );

    const handleClearFlightButtonClick = useCallback(() => {
      setPickupLocation(null);
      dispatch(setFlightAction({ flight: null }));
    }, [setPickupLocation, dispatch]);

    const handleEditPickupOnMapButton = useCallback(() => {
      let positionToSet = defaultPosition;

      if (location) {
        const [lat, lng] = location.position;

        positionToSet = { lat, lng };
      }

      if (positionToSet) {
        const field: FieldToEdit = { type: 'pickup', position: positionToSet };

        dispatch(setEditMode({ field }));
      }
    }, [location, dispatch, defaultPosition]);

    const renderContent = () => {
      if (flight) {
        return (
          <div className={s.flightContainer}>
            <FormGroupTitle className={s.flightContainerFormGroupTitle}>
              <span>
                {intl.formatMessage({
                  description: 'Route form group title',
                  defaultMessage: 'Route',
                  id: '3M8NII',
                })}
              </span>
              <button
                disabled={disabled}
                type={'button'}
                className={s.clearFlightButton}
                onClick={handleClearFlightButtonClick}
                data-test-id={'clear-flight-button'}
              >
                <IconClear className={s.clearFlightButtonIcon} />
              </button>
            </FormGroupTitle>
            <FlightCard flight={flight} />
          </div>
        );
      }

      return (
        <div className={s.locationFieldContainer}>
          <LocationField
            completions={completions}
            zones={zones}
            query={query}
            onQueryChange={handleQueryChange}
            selectedItem={location}
            onItemSelect={handleItemSelect}
            inputProps={inputProps}
            error={pickupError}
            hideAirports={true}
            intent={pickupError ? Intent.DANGER : Intent.NONE}
            title={
              <FormGroupTitle>
                {intl.formatMessage({
                  description: 'Route form group title',
                  defaultMessage: 'Route',
                  id: '3M8NII',
                })}
              </FormGroupTitle>
            }
            infiniteScrollProps={{
              loadMore: noop,
              canLoadMore: false,
              isLoading,
            }}
            disabled={disabled}
            popoverProps={{
              onClosing: handleClosing,
            }}
            suggestRef={suggestRef}
            extraInitialLocations={extraInitialLocations}
            menuProps={{
              'data-test-id': 'pickup-location-select-menu',
            }}
            errorProps={{
              'data-test-id': 'pickup-error',
            }}
          />
          <EditLocationOnMapButton onClick={handleEditPickupOnMapButton} className={s.mapButton} />
        </div>
      );
    };

    return (
      <>
        <AirportPickupDialog
          isOpen={isFlightSearchDialogOpen}
          hideBookWithoutFlightNumber={Boolean(location)}
          onFlightSelect={handleFlightSearchDialogFlightSelect}
          onClose={handleFlightSearchDialogClose}
          onLocationChange={handleAirportPickupDialogLocationChange}
          onAsapRequest={onAsapRequest}
        />
        <div className={s.contentWrapper}>{renderContent()}</div>
        {isAirportLocation && !flight && (
          <AddFieldButton
            disabled={disabled}
            className={s.addFlightNumberButton}
            onClick={handleAddFlightNumberButtonClick}
            text={intl.formatMessage({
              defaultMessage: 'Add flight number',
              id: 'Ewi4Cm',
              description: 'Add flight number button text',
            })}
          />
        )}
      </>
    );
  },
);

export { PickupAddressField };
