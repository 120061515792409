import { defineMessages } from 'react-intl';

import { LocationType } from '_api/types';

const LOCATIONS_WITH_MEET_AND_GREET: LocationType[] = ['airport'];

const MESSAGES = defineMessages({
  employeeBlocked: {
    description: 'Selected person is blocked error message',
    defaultMessage: 'Person blocked',
    id: '6pezBT',
  },
  employeeEmpty: {
    description: 'Missing person error message',
    defaultMessage: 'Please select a person',
    id: 'kPbnlx',
  },
  formEmployeeAndPickupEmpty: {
    description: 'Missing person and pickup form footer error message',
    defaultMessage: 'Person and pickup are required to request a chauffeur',
    id: 'ZfYCAW',
  },
  formEmployeeBlocked: {
    description: 'Selected person is blocked form footer error message',
    defaultMessage: 'Selected person blocked',
    id: 'KuN+Vc',
  },
  formEmployeeEmpty: {
    description: 'Missing person form footer error message',
    defaultMessage: 'Please specify a person',
    id: 'SIiuAN',
  },
  formPassengerEmpty: {
    description: 'Missing passenger form footer error message',
    defaultMessage: 'Please specify a passenger',
    id: 'J+IG5A',
  },
  formPickupEmpty: {
    description: 'Missing pickup form footer error message',
    defaultMessage: 'Please specify pickup address',
    id: 'd8GHjU',
  },
  formPickupTimeTooEarly: {
    description: 'Pickup time is too early form footer error message',
    defaultMessage: 'Pickup time is too early',
    id: 'Ke0sOb',
  },
  formUnknownError: {
    description: 'Unknown error form footer error message',
    defaultMessage: 'Something went wrong, please try again',
    id: 'aUbPNw',
  },
  locationInaccurate: {
    description: 'Missing house number error message',
    defaultMessage: 'Specify a house number and choose an option from the list',
    id: 'F7TnEs',
  },
  nearestAvailableTime: {
    description: 'Nearest available time error message',
    defaultMessage: 'Nearest available booking time is {formattedTime}',
    id: 'O5fq9E',
  },
  passengerNameEmpty: {
    description: 'Missing passenger name error message',
    defaultMessage: 'Passenger name is required',
    id: 'PvBz5f',
  },
  passengerPhoneEmpty: {
    description: 'Missing passenger phone error message',
    defaultMessage: 'Passenger phone is required',
    id: 'Y3c2p0',
  },
  passengerPhoneInvalid: {
    description: 'Invalid passenger phone format error message',
    defaultMessage: 'Invalid passenger phone number',
    id: 'S+Ue8g',
  },
  pickupEmpty: {
    description: 'Missing pickup error message',
    defaultMessage: 'Specify pickup location',
    id: 'wr16z8',
  },
  pickupTimeInvalid: {
    description: 'Pickup time picker invalid time',
    defaultMessage: 'Invalid time',
    id: '0dLoXo',
  },
  pickupTimeTooEarly: {
    description: 'Pickup time is too early error message',
    defaultMessage: 'Pickup time is too early',
    id: 'JSYRLu',
  },
  pickupUnavailableArea: {
    description: 'Unavailable pickup area error message',
    defaultMessage: 'No service coverage in selected area',
    id: 'rRSXNG',
  },
  welcomeBoardText: {
    description: 'Welcome board text error message',
    defaultMessage: 'Specify welcome board text',
    id: 'AoAzdC',
  },
  rebookNoEmployeeError: {
    description: 'Rebook a journey. No such person error message',
    defaultMessage: 'Person no longer exists. Please select another person.',
    id: 'zMIdck',
  },
  trainNumber: {
    description: 'Welcome board train number error message',
    defaultMessage: 'Specify train number',
    id: 'BlsxGz',
  },
  carriageNumber: {
    description: 'Welcome board carriage number error message',
    defaultMessage: 'Specify carriage',
    id: 'Aqr08q',
  },
  origin: {
    description: 'Welcome board origin error message',
    defaultMessage: 'Specify origin',
    id: 'OXjoDY',
  },
  arrivalTime: {
    description: 'Welcome board arrival time error message',
    defaultMessage: 'Specify arrival time',
    id: 'y2pEWR',
  },
  noCoverageWithoutCity: {
    description: 'Replace server error of unsupported area',
    defaultMessage:
      'This location requires further assistance. Please contact Customer Service via the Help Centre or your Account Manager.',
    id: 'U7UaoG',
  },
});

export { MESSAGES, LOCATIONS_WITH_MEET_AND_GREET };
