import { defineMessage } from 'react-intl';

const MESSAGES = {
  addAddressTitle: defineMessage({
    defaultMessage: 'Add additional address',
    id: 'tg5j+o',
    description: 'Add additional address button title',
  }),
};

export { MESSAGES };
