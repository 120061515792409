import React from 'react';

const WheelyLogoIcon = ({ className, ...rest }: { className?: string }) => (
  <svg
    className={className}
    width="202"
    height="24"
    viewBox="0 0 202 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M61.9977 0V3.22206H67.2606V10.3914H51.4673V3.22206H56.735V0H32.0747V3.22206H37.1381L31.1944 19.7169L24.9782 3.22206H29.4385V0H17.157V3.22206H21.8361L15.62 19.7169L9.67625 3.22206H14.5159V0H0.478516V3.22206H5.79968L13.6987 24H17.3029L23.4072 7.86049L29.5114 24H33.1156L41.0098 3.22206H47.9604V20.7779H42.6976V24H56.735V20.7779H51.4673V13.6086H67.2606V20.7779H61.9977V24H76.0351V20.7779H70.7723V3.22206H76.0351V0H61.9977Z"
      fill="black"
    />
    <path
      d="M78.6811 0V3.22206H83.9488V20.7779H78.6811V24H106.761V13.7546H103.249V20.7779H87.4557V13.6086H94.4743V17.2687H97.9861V6.7313H94.4743V10.3914H87.4557V3.22206H103.249V10.2454H106.761V0H78.6811Z"
      fill="black"
    />
    <path
      d="M110.268 0V3.22206H115.535V20.7779H110.268V24H138.347V13.7546H134.835V20.7779H119.042V13.6086H126.061V17.2687H129.573V6.7313H126.061V10.3914H119.042V3.22206H134.835V10.2454H138.347V0H110.268Z"
      fill="black"
    />
    <path
      d="M166.422 20.7779H150.629V3.22206H159.403V0H141.854V3.22206H147.122V20.7779H141.854V24H169.934V13.7546H166.422V20.7779Z"
      fill="black"
    />
    <path
      d="M183.971 0V3.22206H190.781L182.215 12.4502L173.65 3.22206H180.459V0H162.91V3.22206H169.053L180.459 15.5117V20.7779H173.441V24H190.99V20.7779H183.971V15.5117L195.377 3.22206H201.515V0H183.971Z"
      fill="black"
    />
  </svg>
);

export { WheelyLogoIcon };
