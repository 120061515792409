import React, { Ref, forwardRef, useCallback } from 'react';
import { NumberInput, NumberInputProps } from '@wheely/web-ui';
import { FieldAttributes, useField } from 'formik';

type FormikInputProps<T> = {
  inputProps?: NumberInputProps;
  ref: Ref<HTMLInputElement>;
  hideErrors?: boolean;
  onValueChange?: (value?: string) => void;
} & FieldAttributes<T>;

export const FormikNumberInput = forwardRef<
  HTMLInputElement,
  FormikInputProps<Record<string, unknown>>
>(({ inputProps = {}, name, hideErrors, onValueChange }, ref) => {
  const [numberField, numberFieldMeta, helpers] = useField(name);

  const handleChange = useCallback(
    (value?: string) => {
      helpers.setValue(value);
      onValueChange?.(value);
    },
    [helpers, onValueChange],
  );

  const handleClear = useCallback(() => {
    helpers.setValue('');
  }, [helpers]);

  const showErrors = hideErrors
    ? numberFieldMeta.touched && Boolean(numberField.value)
    : numberFieldMeta.touched;

  return (
    <NumberInput
      ref={ref}
      {...numberField}
      {...inputProps}
      {...(showErrors && {
        errorMessage: numberFieldMeta.error || inputProps?.errorMessage,
      })}
      onClear={handleClear}
      onChange={handleChange}
    />
  );
});
