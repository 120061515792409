import React from 'react';
import { IconLoadingIndicator, IconPickupBig, IconPickupPrebookingBig } from '@wheely/ui-kit';

import { useTypedSelector } from '_store';
import { selectedServiceOfferSelector } from '_store/serviceOffers';
import { isPrebookModeSelector } from '_store/newJourney';
import { isFiniteNumber } from '_utils/isFiniteNumber';
import { CustomMarker, CustomMarkerProps } from '_common/CustomMarker';
import { EtaMarkerContent } from '_common/EtaMarkerContent';

import s from './styles.scss';

type Props = CustomMarkerProps & {
  className: string;
  derivedEta?: number | null;
};

const PickupMarkerContent = ({
  isLoading,
  isAsapMode,
  eta,
}: {
  isLoading: boolean;
  isAsapMode: boolean;
  eta?: number | null;
}) => {
  if (isLoading) {
    return <IconLoadingIndicator className={s.preloader} />;
  }

  if (isAsapMode) {
    if (!eta) {
      return null;
    }

    return <EtaMarkerContent eta={eta} />;
  }

  return null;
};

export const PickupMarker = ({ className, position, onDoubleClick, derivedEta }: Props) => {
  const isLoading = useTypedSelector(state => state.serviceOffers.isLoading);
  const isPrebookModeSelected = useTypedSelector(isPrebookModeSelector);
  const selectedServiceOffer = useTypedSelector(selectedServiceOfferSelector);
  const eta = selectedServiceOffer?.service_availability.eta;
  const isAsapMode = isFiniteNumber(eta) && eta <= 1800 && !isPrebookModeSelected;

  return (
    <CustomMarker position={position} onDoubleClick={onDoubleClick}>
      <div className={className}>
        {isAsapMode || isLoading ? <IconPickupBig /> : <IconPickupPrebookingBig />}
        <PickupMarkerContent
          isLoading={isLoading}
          isAsapMode={isAsapMode}
          eta={derivedEta || eta}
        />
      </div>
    </CustomMarker>
  );
};
