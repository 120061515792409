import { useMediaQuery } from '_hooks/useMediaQuery';
import { ValueOf } from '_types/helpers';

export const BREAKPOINTS = {
  isMobile: 'isMobile',
  isDesktop: 'isDesktop',
  isTablet: 'isTablet',
} as const;

export type Breakpoint = ValueOf<typeof BREAKPOINTS>;

export const useBreakpoints = (): Record<Breakpoint, boolean> => {
  const isMobile = useMediaQuery(`(max-width: 768px)`);
  const isTablet = useMediaQuery(`(max-width: 1024px)`);
  const isDesktop = useMediaQuery(`(min-width: 1025px)`);

  return {
    isMobile,
    isDesktop,
    isTablet,
  };
};
