import React from 'react';
import { Route, Switch } from 'react-router';

import { NotFound } from '_pages/NotFound';
import { billingRoutes, commonRoutes } from '_routes';
import { lazyRetry } from '_utils/lazyRetry';
import { useIsCreditEnabled } from '_hooks/company';

const BillingPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "BillingPage" */ './BillingPage/BillingPage'),
    'BillingPage',
  ),
);

const CreditsPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "CreditsPage" */ './CreditsPage/CreditsPage'),
    'CreditsPage',
  ),
);

export const BillingRouter = () => {
  const isCreditEnabled = useIsCreditEnabled();

  return (
    <Switch>
      <Route path={billingRoutes.billingBase} component={BillingPage} exact={true} />
      {isCreditEnabled && (
        <Route path={billingRoutes.billingCredits} component={CreditsPage} exact={true} />
      )}
      <Route component={NotFound} path={[commonRoutes.notFound, '*']} />
    </Switch>
  );
};
