import { useRef, useEffect } from 'react';
import isEqual from 'lodash/fp/isEqual';

const removeEmpty = <T>(array: T[]): T[] => array.filter(value => Boolean(value));
const useSyncOnDiff = <T>({ data, syncFn }: { data: T[]; syncFn?: (value: T[]) => void }) => {
  const compareData = useRef<T[]>(removeEmpty(data));

  useEffect(() => {
    // e.g. sync with store for maps route
    // if map is out of a component hierarchy
    // and could not access formik values
    if (syncFn) {
      const fulfilledList = removeEmpty(data);

      if (!isEqual(fulfilledList, compareData.current)) {
        compareData.current = fulfilledList;
        syncFn(data);
      }
    }
  }, [data, syncFn]);
};

export { useSyncOnDiff };
