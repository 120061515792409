import { isErrorShape as isAuthErrorShape } from '@wheely/web-auth-lib';

import { ErrorShape } from '_api/types';

const isErrorShape = (value: unknown): value is ErrorShape =>
  typeof value === 'object' && value !== null && !(value instanceof Error) && 'message' in value;

const extractErrorShape = (error: unknown): ErrorShape => {
  if (isAuthErrorShape(error)) {
    return {
      message: error.statusText,
      status: error.status,
    };
  }

  if (isErrorShape(error)) {
    return error;
  }

  if (error instanceof Error) {
    return { message: error.message };
  }

  return { message: 'Unknown error' };
};

export { extractErrorShape };
