import React, { useEffect, useState } from 'react';
import { Task } from 'redux-saga';
import { replace } from 'connected-react-router';
import { useDispatch, useStore } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { runSaga } from '_store';

const PREDICATE_ERROR = 'PREDICATE';

const withSagaFlow =
  <P extends Record<string, any>>({
    onLoad,
    predicate,
    onUnload,
    redirectTo,
  }: {
    onLoad: any;
    predicate?: any;
    onUnload?: any;
    redirectTo?: string;
  }): ((WrappedComponent: React.ComponentType<P>) => React.FC<P>) =>
  WrappedComponent =>
  props => {
    const [isInitialized, setIsInitialized] = useState(false);
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const store = useStore();

    useEffect(() => {
      let loadingTask: Task;

      Promise.resolve()
        .then(() => {
          if (typeof predicate === 'function') {
            const predicateTask = runSaga(predicate);

            return predicateTask.toPromise().then(isPredicatePassed => {
              if (isPredicatePassed === false) {
                throw new Error(PREDICATE_ERROR);
              }
            });
          }
        })
        .then(
          () => {
            if (onLoad) {
              loadingTask = runSaga(onLoad, { match });
            }

            setIsInitialized(true);
          },
          error => {
            if (error.message === PREDICATE_ERROR) {
              const redirectPathname =
                redirectTo || store.getState().router?.location?.state?.from?.pathname || '/';

              dispatch(replace(redirectPathname));

              return;
            }

            throw error;
          },
        );

      return () => {
        Promise.resolve()
          .then(() => {
            if (typeof onUnload === 'function') {
              const unloadTask = runSaga(onUnload);

              return unloadTask.toPromise();
            }
          })
          .finally(() => {
            if (loadingTask) {
              loadingTask.cancel();
            }
          });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isInitialized) {
      return null;
    }

    // Casting to generic is important because of TS issue
    // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
    return <WrappedComponent {...(props as P)} />;
  };

export { withSagaFlow };
