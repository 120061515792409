import React from 'react';
import { FootnoteButton } from '@wheely/web-ui';
import { FormattedMessage } from 'react-intl';

import styles from './styles.scss';

type Props = {
  timeUntilResend: number;
  onClick(): void;
};

export const ResendButton = ({ timeUntilResend, onClick }: Props) =>
  timeUntilResend > 0 ? (
    <>
      <FormattedMessage
        defaultMessage="Resend"
        id="sign_in_otp_resend"
        description="Resend verification code button"
      />{' '}
      <FormattedMessage
        defaultMessage="in {time}s"
        id="sign_in_otp_timer"
        description="Send verification code button"
        values={{
          time: timeUntilResend,
        }}
      />
    </>
  ) : (
    <FootnoteButton as="button" onClick={onClick} type="button" className={styles.resendButton}>
      <FormattedMessage
        defaultMessage="Resend"
        id="sign_in_otp_resend"
        description="Resend verification code button"
      />
    </FootnoteButton>
  );
