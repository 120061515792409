import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import cn from 'clsx';
import { isSameDay } from 'date-fns';
import { Text, IconAirport, IconWarning } from '@wheely/ui-kit';

import { Flight } from '_api/types';
import { shiftDateToUtcOffset } from '_utils/shiftDateToUtcOffset';
import { formatDateToTimeValue } from '_utils/formatDateToTimeValue';
import { useLocaleDate } from '_i18n/useLocaleDate';

import s from './styles.scss';

const DEPARTURE_DATE_FORMAT_PATTERN = 'LLLL dd'; // e.g. October 6
const ARRIVAL_NEXT_DAY_HINT_FORMAT_PATTERN = 'LLL dd'; // e.g. Oct 6

type Props = {
  flight: Flight;
  isSelected?: boolean;
  isSelectable?: boolean;
  onSelect?: (flight: Flight) => void;
  className?: string;
};

export const FlightCard = ({
  flight,
  onSelect,
  isSelected = false,
  isSelectable = false,
  className,
}: Props) => {
  const intl = useIntl();
  const { format } = useLocaleDate();

  const departureDateTime = useMemo(
    () => shiftDateToUtcOffset(new Date(flight.departure_time), flight.departure_utc_offset),
    [flight.departure_time, flight.departure_utc_offset],
  );

  const scheduledArrivalDateTime = useMemo(
    () => shiftDateToUtcOffset(new Date(flight.scheduled_arrival), flight.arrival_utc_offset || 0),
    [flight.scheduled_arrival, flight.arrival_utc_offset],
  );

  const estimatedArrivalDateTime = useMemo(() => {
    if (!flight.estimated_arrival) {
      return null;
    }

    return shiftDateToUtcOffset(new Date(flight.estimated_arrival), flight.arrival_utc_offset || 0);
  }, [flight.estimated_arrival, flight.arrival_utc_offset]);

  const formattedEstimatedArrivalTime = useMemo(() => {
    if (!estimatedArrivalDateTime) {
      return null;
    }

    return formatDateToTimeValue(estimatedArrivalDateTime);
  }, [estimatedArrivalDateTime]);

  const isArrivalOnNextDate = useMemo(
    () => !isSameDay(departureDateTime, scheduledArrivalDateTime),
    [departureDateTime, scheduledArrivalDateTime],
  );

  const formattedDepartureDate = useMemo(
    () => format(departureDateTime, DEPARTURE_DATE_FORMAT_PATTERN),
    [departureDateTime, format],
  );

  const departureTime = formatDateToTimeValue(departureDateTime);

  const arrivalOnNextDayHint = format(
    scheduledArrivalDateTime,
    ARRIVAL_NEXT_DAY_HINT_FORMAT_PATTERN,
  );

  const handleClick = () => {
    if (isSelectable && onSelect) {
      onSelect(flight);
    }
  };

  return (
    <div
      className={cn(className, s.container, {
        [s.selectable]: isSelectable && !isSelected,
        [s.selected]: isSelected,
      })}
      onClick={handleClick}
      data-test-id={'flight-card-container'}
    >
      <div className={s.header}>
        <div className={s.departureTime}>{formattedDepartureDate}</div>
      </div>
      <div className={s.info}>
        <div className={s.departureItem}>
          <div>
            <div>{departureTime}</div>
            <span>
              <Text tagName="span" capitalize={true}>
                {flight.departure_city}
                {', '}
              </Text>

              <Text tagName="span" muted={true} capitalize={true}>
                {flight.departure_airport_code}
              </Text>
            </span>
          </div>
          <IconAirport className={s.iconAirport} />
        </div>
        <div className={s.arrivalItem}>
          <div>
            <span className={s.arrivalTime}>
              {formatDateToTimeValue(scheduledArrivalDateTime)}
              {isArrivalOnNextDate && <span className={s.arrivalTimePlusOneSign}>+1</span>}
            </span>
          </div>
          <span>
            <Text tagName="span" capitalize={true}>
              {flight.arrival_city}
              {', '}
            </Text>
            <Text tagName="span" muted={true} capitalize={true}>
              {flight.arrival_airport_code}
            </Text>
            {isArrivalOnNextDate && (
              <Text className={s.arrivalNextDayWarning}>
                <IconWarning className={s.iconWarning} />
                {intl.formatMessage(
                  {
                    description: 'Flight card arrival date notification',
                    defaultMessage: 'Arrives {hint}',
                    id: 'VOEl4W',
                  },
                  { hint: arrivalOnNextDayHint },
                )}
              </Text>
            )}

            {Boolean(formattedEstimatedArrivalTime) && (
              <div className={s.estimatedArrival}>
                {intl.formatMessage(
                  {
                    description: 'Expected time prefix',
                    defaultMessage: 'Expected {arrivalTime}',
                    id: 'PKYI4k',
                  },
                  { arrivalTime: formattedEstimatedArrivalTime },
                )}
              </div>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
