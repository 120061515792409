import React from 'react';
import { NumberFormatOptions } from '@formatjs/ecma402-abstract';
import { CustomFormatConfig, FormattedNumberParts } from 'react-intl';

import { CurrencyCode } from '_api/types';

type Props = { currencyCode: null | CurrencyCode; from: number; to: number } & NumberFormatOptions &
  CustomFormatConfig;

const delimiterSymbol = '–';

export const FormattedCurrencyRange: React.FC<Props> = ({
  currencyCode,
  from,
  to,
  ...restProps
}) => {
  const formatterProps = {
    style: currencyCode ? 'currency' : 'decimal',
    currency: currencyCode as string,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    ...restProps,
  } as NumberFormatOptions;

  if (!isFinite(from) || !isFinite(to)) {
    return <>{delimiterSymbol}</>;
  }

  let isFormatBeginsWithSign = false;

  const formattedFromValue = (
    <FormattedNumberParts value={from} {...formatterProps}>
      {parts => {
        // Get currency sign (code, name, sign) position
        // to prevent its duplication in both formatted values;
        // Note: we assume that the currency sign could be
        // at the beginning or the end of the formatted string only
        isFormatBeginsWithSign =
          parts?.[0]?.type === 'currency' || parts?.[parts.length - 1]?.type !== 'currency';

        return (
          <>
            {parts
              // Omit currency sign from first formatted value if the format ends with sign,
              // since if should be at the end of the second formatted value
              .map(({ type, value }) =>
                !isFormatBeginsWithSign && type === 'currency' ? '' : value,
              )
              .join('')
              .trim()}
          </>
        );
      }}
    </FormattedNumberParts>
  );

  const formattedToValue = (
    <FormattedNumberParts value={to} {...formatterProps}>
      {parts => (
        <>
          {parts
            // Omit currency sign from second formatted value if the format begins with sign,
            // since the sign should already exist at start of the first formatted value
            .map(({ type, value }) => (isFormatBeginsWithSign && type === 'currency' ? '' : value))
            .join('')
            .trim()}
        </>
      )}
    </FormattedNumberParts>
  );

  return (
    <>
      {formattedFromValue}
      {delimiterSymbol}
      {formattedToValue}
    </>
  );
};
