import React from 'react';
import type { Icon, IconProps } from '@wheely/ui-kit';

export const ArrowIcon: Icon = ({ width = 18, height = 18, className }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.08 11.2909L9.70502 4.95334L9 4.25247L8.29498 4.95334L1.91998 11.2909L3.33002 12.7092L9 7.07259L14.67 12.7092L16.08 11.2909Z"
      fill="white"
      fillOpacity="0.38"
    />
  </svg>
);
