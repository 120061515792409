import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { format } from 'date-fns';

import { apiClient } from '_api';
import { Flight, GetFlightsResponse } from '_api/types';

const DATE_FORMAT_PATTERN = 'y-MM-dd';

function* loadFlights({
  flightNumber,
  departureDate,
}: {
  flightNumber: string;
  departureDate: Date;
}): SagaIterator<Flight[]> {
  const formattedDepartureDate = format(departureDate, DATE_FORMAT_PATTERN);

  const response: GetFlightsResponse = yield call(apiClient.getFlights, {
    flight_number: flightNumber,
    departure_date: formattedDepartureDate,
  });

  if ('errors' in response) {
    throw response.errors;
  }

  if (!response?.flights) {
    return [];
  }

  return response.flights;
}

export { loadFlights };
