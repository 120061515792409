import React from 'react';
import { Route, Redirect, RouteProps, RedirectProps, RouteComponentProps } from 'react-router';

import { useTypedSelector } from '_store';
import { isLoggedInSelector } from '_store/auth';
import { WithChildren } from '_types/helpers';
import { usePermissions } from '_app/PermissionsProvider';
import { Permission } from '_app/PermissionsProvider/permissions';
import { authRoutes } from '_routes';

type PrivateRouteProps = RouteProps &
  WithChildren<{
    redirectTo?: string;
    redirectProps?: RedirectProps;
    permission?: Permission;
  }>;

const PrivateRoute = ({
  component: Component,
  redirectProps,
  permission,
  children,
  redirectTo = '/',
  ...restProps
}: PrivateRouteProps) => {
  const isLoggedIn = useTypedSelector(isLoggedInSelector);
  const { hasPermission } = usePermissions();

  if (!Component && !children) {
    // eslint-disable-next-line no-console
    console.warn('PrivateRoute: cannot render route: no Component or children passed');

    return null;
  }

  return (
    <Route
      {...restProps}
      render={(routeProps: RouteComponentProps) => {
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{ pathname: authRoutes.loginRoute, state: { from: routeProps.location } }}
              {...redirectProps}
            />
          );
        }

        if (permission && !hasPermission(permission)) {
          return <Redirect to={redirectTo} {...redirectProps} />;
        }

        return Component ? <Component {...routeProps} /> : children;
      }}
    />
  );
};

export { PrivateRoute };
