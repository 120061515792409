import { Location, LocationType } from '_api/types';
import { PICKUP_LOCATION_VALID_TYPES } from '_utils/isPickupLocationInaccurate';

const DROPOFF_LOCATION_VALID_TYPES: LocationType[] = [
  ...PICKUP_LOCATION_VALID_TYPES,
  'general',
  'city',
];

const isDropoffLocationInaccurate = (location: Location) =>
  location.type && !DROPOFF_LOCATION_VALID_TYPES.includes(location.type);

export { isDropoffLocationInaccurate };
