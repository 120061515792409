import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FormattedEtaInMinutes } from '../FormattedEtaInMinutes/FormattedEtaInMinutes';
import { MESSAGES } from '../../constants';

const ItemEta: React.VFC<{
  eta: null | number;
  isPrebookMode: boolean;
  isAvailable: boolean;
}> = ({ eta, isAvailable, isPrebookMode }) => {
  if (!isAvailable || isPrebookMode) {
    return <FormattedMessage {...MESSAGES.serviceEtaSchedule} />;
  }

  return <FormattedEtaInMinutes etaDuration={eta} />;
};

export { ItemEta };
