import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader } from '@wheely/ui-kit';

import s from './styles.scss';

export const AppPreloader: React.FC = () => (
  <div className={s.preloaderContainer}>
    <Loader className={s.loader} />
    <div className={s.label}>
      <FormattedMessage
        defaultMessage="Loading"
        id="U3sozx"
        description="App preloader loading label"
      />
    </div>
  </div>
);
