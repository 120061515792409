import React from 'react';
import { Polyline } from '@react-google-maps/api';

import { GOOGLE_MAPS } from '_constants';

const { ROUTE_OPACITY, ROUTE_WEIGHT } = GOOGLE_MAPS;

type Props = { path: google.maps.LatLng[] | null };

const Track = ({ path }: Props) => (
  <Polyline
    path={path || []}
    options={{ strokeOpacity: ROUTE_OPACITY, strokeWeight: ROUTE_WEIGHT }}
  />
);

export { Track };
