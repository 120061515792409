import React, { ReactNode, useCallback, useContext, useMemo } from 'react';

import { useUserRole } from '_hooks/user';

import { Permission, getPermissionsByRole } from './permissions';

export type PermissionsContextValue = {
  hasPermission: (permission: Permission) => boolean | undefined;
};

export const PermissionsContext = React.createContext<PermissionsContextValue>({
  hasPermission: () => false,
});

type Props = {
  children: ReactNode;
};

export const PermissionsProvider = ({ children }: Props) => {
  const role = useUserRole();
  const permissions = useMemo(() => (role ? getPermissionsByRole(role) : []), [role]);

  const hasPermission = useCallback(
    (permission: Permission) => permissions?.includes(permission),
    [permissions],
  );

  const value = useMemo(() => ({ hasPermission }), [hasPermission]);

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};

export const usePermissions = () => useContext(PermissionsContext);

export const useHasPermission = (permission: Permission) => {
  const { hasPermission } = usePermissions();

  return hasPermission(permission);
};
