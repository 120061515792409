import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AnchorButton, IconArrowRightCircled } from '@wheely/ui-kit';

import { GuestLayout } from '_layouts/Guest';
import { ErrorState } from '_common/ErrorState';

import s from './styles.scss';

const NotFound: React.VFC = () => (
  <GuestLayout withoutBackground={true}>
    <ErrorState
      title={
        <FormattedMessage
          defaultMessage="404. Page not found"
          id="P44Hhp"
          description="404 error title"
        />
      }
      description={
        <FormattedMessage
          defaultMessage="Error 404. This page doesn't exist."
          id="CyO1Ll"
          description="404 error description"
        />
      }
      link={
        <AnchorButton
          className={s.link}
          component={Link}
          href={'/'}
          minimal={true}
          large={true}
          rightIcon={<IconArrowRightCircled />}
          text={
            <FormattedMessage
              defaultMessage="Go to the main page"
              id="amWwg+"
              description="404 error back link"
            />
          }
        />
      }
    />
  </GuestLayout>
);

export { NotFound };
