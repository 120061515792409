import { Button, ButtonGroup, IconArrowLeft } from '@wheely/ui-kit';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Flight, Location } from '_api/types';
import { useBreakpoints } from '_hooks/useBreakpoints';
import { FlightCard } from '_common/FlightCard';

import dialogStyles from '../../styles.scss';

import s from './styles.scss';

type Props = {
  flight: Flight;
  onRequestOnDemand: (location: Location) => void;
  onBookWithoutFlightNumber: () => void;
  onBack: () => void;
};

export const FlightLanded = ({
  flight,
  onBookWithoutFlightNumber,
  onRequestOnDemand,
  onBack,
}: Props) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoints();

  const handleRequestOnDemandButtonClick = useCallback(() => {
    onRequestOnDemand(flight.pickup);
  }, [flight, onRequestOnDemand]);

  return (
    <>
      <div className={dialogStyles.titleWithBackButton}>
        <Button
          className={dialogStyles.backButton}
          minimal={true}
          onClick={onBack}
          icon={<IconArrowLeft />}
        />
        <div>
          {intl.formatMessage({
            defaultMessage: 'The flight has already landed',
            id: 'c9JC8V',
            description: 'The flight has already landed modal title',
          })}
        </div>
      </div>
      <FlightCard flight={flight} className={s.flightCard} />
      <ButtonGroup className={dialogStyles.footer} vertical={isMobile}>
        <Button
          className={dialogStyles.footerButton}
          onClick={handleRequestOnDemandButtonClick}
          text={intl.formatMessage({
            defaultMessage: 'Request On-Demand',
            id: '5FLgd9',
            description: 'Request On-Demand button text',
          })}
        />
        <Button
          className={dialogStyles.footerButton}
          minimal={true}
          onClick={onBookWithoutFlightNumber}
          text={intl.formatMessage({
            defaultMessage: 'Book without flight number',
            id: '9WL0nx',
            description: 'Book without flight number button text',
          })}
        />
      </ButtonGroup>
    </>
  );
};
