import { Location } from '_api/types';

const isNotNull = <T>(value: T | null | undefined): value is T => value !== null;

type ReturnType = {
  dropoff: Location;
  stops: Location[];
};

const splitStopsIntoStopsAndDropoff = (stops: Array<Location | null | undefined>): ReturnType => {
  const fulfilled: Location[] = stops.filter(isNotNull);
  const dropoff: Location = fulfilled[fulfilled.length - 1];
  const extraStops: Location[] = fulfilled.slice(0, -1);

  return {
    dropoff,
    stops: extraStops.filter(extraStop => Boolean(extraStop)),
  };
};

export { splitStopsIntoStopsAndDropoff };
