import { call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { captureMessage } from '@sentry/react';

import { apiClient } from '_api';
import { Location } from '_api/types';
import { isDev } from '_constants';

export type GetLocationByReferenceParams = {
  locationReference: Location;
  type: 'pickup';
};

export type RefinedLocation = {
  location: Location;
  type: 'pickup';
};

export type GetLocationByReferenceReturnType = SagaIterator<RefinedLocation>;

function* getLocationByReference({
  locationReference,
  type,
}: GetLocationByReferenceParams): GetLocationByReferenceReturnType {
  if (!locationReference) {
    const errorMessage = `Invalid location reference passed to "getLocationByReference" saga. Got ${JSON.stringify(
      locationReference,
    )}`;

    if (isDev) {
      console.error(errorMessage); // eslint-disable-line no-console
    }

    captureMessage(errorMessage);
  }

  const { place_id } = locationReference;

  // Seems passed location os already ok;
  // but anyway, we can't refine location without its `place_id`
  if (!place_id) {
    return {
      location: locationReference,
      type,
    };
  }

  try {
    const { location } = yield call(apiClient.getLocationByReference, { place_id });

    return {
      location,
      type,
    };
  } catch (error) {
    if (isDev) {
      // eslint-disable-next-line no-console
      console.error('Cannot get location by reference:', { error, type });
    }

    throw error;
  }
}

export { getLocationByReference };
