import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Intent } from '@wheely/ui-kit';

import s from './styles.scss';

type Props = {
  isConfirmDisabled: boolean;
  onCancel: () => void;
  onConfirm?: () => void;
};

export const EditingControls = ({ isConfirmDisabled, onCancel, onConfirm }: Props) => (
  <div className={s.container}>
    <Button onClick={onConfirm} disabled={isConfirmDisabled} className={s.button}>
      <FormattedMessage description="Confirm button text" defaultMessage="Confirm" id="yrTAdQ" />
    </Button>
    <Button intent={Intent.NONE} onClick={onCancel} className={s.button}>
      <FormattedMessage description="Cancel button title" defaultMessage="Cancel" id="D4IuIb" />
    </Button>
  </div>
);
