import { ExcludeArray } from '_types/helpers';

export const castAsCompactArray = <T>(
  maybeArray: T | T[],
  predicate: (element: T) => boolean = Boolean,
): ExcludeArray<T>[] => {
  if (!maybeArray) {
    return [];
  }

  const casted = Array.isArray(maybeArray) ? maybeArray : [maybeArray];

  return casted.filter((element): element is ExcludeArray<T> => predicate(element));
};
