export const INVITE_ACCEPT = ['invite', 'accept'];
export const INVITES = {
  LOAD: 'invites/load',
  DELETE: 'invites/delete',
  RESEND: 'invites/resend',
  CREATE: 'invites/create',
};

export const PUBLIC_EMPLOYEE = ['public', 'employee'];

export const POLICIES = {
  LOAD: 'policies/load',
  CREATE: 'policies/create',
  UPDATE: 'policies/update',
  DELETE: 'policies/delete',
  LOAD_RATES: 'policies/rates/load',
};

export const SEARCH = {
  INN_COMPLETIONS: 'search/inn_completions',
  BIK_COMPLETIONS: 'search/bik_completions',
};

export const CITIES = 'cities';

export const METABASE_DASHBOARD_URL = ['metabase', 'dashboard', 'url'];

export const INVOICES = {
  LOAD_UPCOMING: 'invoices/load_upcoming',
  LOAD_DEBTS: 'invoices/load_debts',
  LOAD: 'invoices/load',
  PAY: 'invoices/pay',
};

export const USER = {
  LOAD: 'user/load',
  UPDATE: 'user/update',
  UPDATE_COMPANY: 'user/update_company',
  UPDATE_AUTH_EMAIL: 'user/update_auth_email',
  CREATE: 'user/create',
};

export const COMPANY = {
  LOAD: 'company/load',
  LOAD_LAST_DRAFT: 'company/load_last_draft',
  LOAD_CONFIG: 'company/load_config',
  CREATE_DRAFT: 'company/create_draft',
  UPDATE_DRAFT: 'company/update_draft',
  UPLOADED_FILES: 'company/uploaded_files',
  UPLOAD_FILE: 'company/upload_file',
  UPDATE: 'company/update',
  CREATE: 'company/create',
  UPDATE_PAYMENTS: 'company/update_payments',
  POST_PAYMENTS: 'company/post_payments',
  B2B_POST_PAYMENTS: 'company/b2b_post_payments',
  POST_PAYMENTS_INVOICE: 'company/post_payments/invoice',
  POST_PAYMENTS_DIRECT_DEBIT: 'company/post_payments/direct_debit',
};

export const GEOSEARCH = {
  COMPLETIONS: 'geosearch/completions',
  REFERENCE: 'geosearch/reference',
};

export const CREDITS = {
  LOAD: 'credits/load',
  PAY: 'credits/pay',
};

export const REPORTS = {
  LOAD_EMPLOYEES_EXPENSES: 'reports/expenses_employees/load',
  LOAD_DEPARTMENTS_EXPENSES: 'reports/expenses_departments/load',
  LOAD_OVERALL_EXPENSES: 'reports/expenses_overall/load',
};

export const EMPLOYEES = {
  LOAD_EMPLOYEES: 'employees/load',
  LOAD_EMPLOYEE: 'employees/employee',
  UPDATE_EMPLOYEE: 'employees/update_employee',
  UPDATE_EMPLOYEE_B2B: 'employees/update_employee_b2b',
  DELETE_EMPLOYEES: 'employees/delete',
};

export const DEPARTMENTS = {
  LOAD: 'departments/load',
  LOAD_DEPARTMENT: 'departments/load_department',
  LOAD_INFINITE: 'departments/load_infinite',
  CREATE: 'departments/create',
  DELETE: 'departments/delete',
  EDIT: 'departments/edit',
};
