import React, { ChangeEventHandler, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TextAreaField } from '@wheely/ui-kit';

import { OptionalField } from '_common/OptionalField';

import s from './styles.scss';

const maxCharsCount = 500;

const SpecialRequestField: React.VFC<{
  value: string;
  onValueChange: (value: string) => void;
  disabled?: boolean;
  initialIsOpen: boolean;
  onClose?: () => void;
  error?: string;
}> = ({ value, onValueChange, disabled, initialIsOpen, onClose, error }) => {
  const intl = useIntl();

  const handleFieldClose = useCallback(() => {
    if (onClose) {
      onClose();
    }

    onValueChange('');
  }, [onValueChange, onClose]);

  const handleValueChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    event => {
      onValueChange(event.target.value);
    },
    [onValueChange],
  );

  const currentCharsCount = Math.max(maxCharsCount - value.length, 0);

  return (
    <OptionalField
      title={intl.formatMessage({
        description: 'New journey add special request button title',
        defaultMessage: 'Add Special request',
        id: 'OD7cfN',
      })}
      openedTitle={intl.formatMessage({
        description: 'New journey special request field title',
        defaultMessage: 'Special request',
        id: 'eqGZV7',
      })}
      onClose={handleFieldClose}
      className={s.optionalField}
      disabled={disabled}
      initialIsOpen={initialIsOpen}
      buttonProps={{
        'data-test-id': 'add-special-request-button',
      }}
    >
      <TextAreaField
        className={s.textarea}
        description={intl.formatMessage(
          {
            description: 'New journey special request field description',
            defaultMessage: 'Characters left: {currentCharsCount} of {maxCharsCount}',
            id: 'zEq58+',
          },
          {
            br: <br />,
            maxCharsCount,
            currentCharsCount,
          },
        )}
        descriptionProps={{
          'data-test-id': 'special-request-field-description',
        }}
        fill={true}
        placeholder={intl.formatMessage({
          description: 'New journey special request placeholder',
          defaultMessage: 'Need a child seat',
          id: 'S/DzzX',
        })}
        maxLength={maxCharsCount}
        value={value}
        onChange={handleValueChange}
        disabled={disabled}
        error={error}
        data-test-id="special-request-textarea"
      />
    </OptionalField>
  );
};

export { SpecialRequestField };
