import React from 'react';
import { FieldInputProps } from 'formik';

import { MeetAndGreetRailwayHubWithBoardText } from './components/MeetAndGreetRailwayHubWithBoardText';
import { MeetAndGreetRailwayHubWithoutBoard } from './components/MeetAndGreetRailwayHubWithoutBoard';

interface MeetAndGreetByOriginProps {
  disabled?: boolean;
  isOriginPickupSubType?: boolean;
  welcomeBoardField: FieldInputProps<unknown>;
}

export const MeetAndGreetOnRailwayHub = ({
  disabled,
  welcomeBoardField,
  isOriginPickupSubType,
}: MeetAndGreetByOriginProps) =>
  welcomeBoardField.value ? (
    <MeetAndGreetRailwayHubWithBoardText
      isOriginPickupSubType={isOriginPickupSubType}
      disabled={disabled}
    />
  ) : (
    <MeetAndGreetRailwayHubWithoutBoard />
  );
