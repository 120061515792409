import { defineMessage } from 'react-intl';

export const MONTHS = [
  defineMessage({
    id: 'January',
    description: 'Month names January',
    defaultMessage: 'January',
  }),
  defineMessage({
    id: 'February',
    description: 'Month names February',
    defaultMessage: 'February',
  }),
  defineMessage({
    id: 'March',
    description: 'Month names March',
    defaultMessage: 'March',
  }),
  defineMessage({
    id: 'April',
    description: 'Month names April',
    defaultMessage: 'April',
  }),
  defineMessage({
    id: 'May',
    description: 'Month names May',
    defaultMessage: 'May',
  }),
  defineMessage({
    id: 'June',
    description: 'Month names June',
    defaultMessage: 'June',
  }),
  defineMessage({
    id: 'July',
    description: 'Month names July',
    defaultMessage: 'July',
  }),
  defineMessage({
    id: 'August',
    description: 'Month names August',
    defaultMessage: 'August',
  }),
  defineMessage({
    id: 'September',
    description: 'Month names September',
    defaultMessage: 'September',
  }),
  defineMessage({
    id: 'October',
    description: 'Month names October',
    defaultMessage: 'October',
  }),
  defineMessage({
    id: 'November',
    description: 'Month names November',
    defaultMessage: 'November',
  }),
  defineMessage({
    id: 'December',
    description: 'Month names December',
    defaultMessage: 'December',
  }),
];

export const WEEKDAYS_LONG = [
  defineMessage({
    id: 'Sunday',
    description: 'Weekday names Sunday',
    defaultMessage: 'Sunday',
  }),
  defineMessage({
    id: 'Monday',
    description: 'Weekday names Monday',
    defaultMessage: 'Monday',
  }),
  defineMessage({
    id: 'Tuesday',
    description: 'Weekday names Tuesday',
    defaultMessage: 'Tuesday',
  }),
  defineMessage({
    id: 'Wednesday',
    description: 'Weekday names Wednesday',
    defaultMessage: 'Wednesday',
  }),
  defineMessage({
    id: 'Thursday',
    description: 'Weekday names Thursday',
    defaultMessage: 'Thursday',
  }),
  defineMessage({
    id: 'Friday',
    description: 'Weekday names Friday',
    defaultMessage: 'Friday',
  }),
  defineMessage({
    id: 'Saturday',
    description: 'Weekday names Saturday',
    defaultMessage: 'Saturday',
  }),
];

export const WEEKDAYS_SHORT = [
  defineMessage({
    id: 'Su',
    description: 'Weekday short names Sunday',
    defaultMessage: 'Su',
  }),
  defineMessage({
    id: 'Mo',
    description: 'Weekday short names Monday',
    defaultMessage: 'Mo',
  }),
  defineMessage({
    id: 'Tu',
    description: 'Weekday short names Tuesday',
    defaultMessage: 'Tu',
  }),
  defineMessage({
    id: 'We',
    description: 'Weekday short names Wednesday',
    defaultMessage: 'We',
  }),
  defineMessage({
    id: 'Th',
    description: 'Weekday short names Thursday',
    defaultMessage: 'Th',
  }),
  defineMessage({
    id: 'Fr',
    description: 'Weekday short names Friday',
    defaultMessage: 'Fr',
  }),
  defineMessage({
    id: 'Sa',
    description: 'Weekday short names Saturday',
    defaultMessage: 'Sa',
  }),
];
