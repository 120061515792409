import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Body,
  Button,
  Footnote,
  FootnoteButton,
  PhoneNumberInput,
  SelectOption,
} from '@wheely/web-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, FormikHelpers } from 'formik';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { HCaptchaProps } from '_hooks/useCaptcha';
import { FormikNumberInput } from '_/components/FormikFields/FormikNumberInput';

import styles from './styles.scss';
import { makeValidationSchema } from './validationSchema';
import { CallMeButton } from './CallMeButton';
import { ResendButton } from './ResendButton';

export type FormValues = Record<'pin', string>;

type PhoneFormProps = {
  hCaptchaProps: HCaptchaProps;
  phoneCodeOptions: { value: string; localizedName: Record<'en' | 'fr' | 'ru', string> }[];
  isLoading?: boolean;
  enteredPhone: string;
  pinSentAt?: Date;
  callRequestedAt?: Date;
  serverErrorMessage?: string;
  onChangeLogin(): void;
  onPinResend(): void;
  onCallMe(): void;
  onSubmit(formValues: FormValues, formikHelpers: FormikHelpers<FormValues>): Promise<void>;
};

const SIXTY_SECONDS = 60 * 1000;
const ONE_SECOND = 1000;

const renderSelectedPhoneCode = ({ value: phoneCodeValue }: SelectOption) => (
  <Body className={styles.selectedValue}>{phoneCodeValue}</Body>
);

const startCountdown = (countDownStartTime: Date | undefined, setState: (date: number) => void) => {
  const updateCountdown = () => {
    const millisecondsSincePinSent = Number(new Date()) - Number(countDownStartTime);
    const secondsLeft = Math.ceil((SIXTY_SECONDS - millisecondsSincePinSent) / 1000);

    setState(secondsLeft);

    if (secondsLeft > 0) {
      return setTimeout(updateCountdown, ONE_SECOND);
    }
  };

  return updateCountdown();
};

export const PinForm = ({
  onSubmit,
  isLoading,
  serverErrorMessage,
  enteredPhone,
  hCaptchaProps,
  onChangeLogin,
  onPinResend,
  callRequestedAt,
  onCallMe,
  pinSentAt,
  phoneCodeOptions,
}: PhoneFormProps) => {
  const intl = useIntl();
  const validationSchema = useMemo(() => makeValidationSchema(intl), [intl]);
  const [timeUntilResend, setTimeUntilResend] = useState(0);
  const [timeUntilRecall, setTimeUntilRecall] = useState(0);

  useEffect(() => {
    const timerId = startCountdown(pinSentAt, setTimeUntilResend);

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [pinSentAt]);

  useEffect(() => {
    if (!callRequestedAt) {
      return;
    }

    const timerId = startCountdown(callRequestedAt, setTimeUntilRecall);

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [callRequestedAt]);

  const pinInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    pinInputRef.current?.focus();
  }, []);

  return (
    <Formik
      initialValues={{ pin: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount={true}
    >
      {formProps => {
        const handlePinChange = (newValue?: string) => {
          if (newValue?.length === 4) {
            formProps.handleSubmit();
          }
        };

        const isSubmitButtonDisabled =
          !formProps.isValid ||
          isLoading ||
          formProps.isSubmitting ||
          formProps.values.pin.length !== 4;

        return (
          <form onSubmit={formProps.handleSubmit} className={styles.form}>
            <div className={styles.phoneInputWrapper}>
              <PhoneNumberInput
                data-test-id="login_phone_input"
                title={intl.formatMessage({
                  defaultMessage: 'Phone Number',
                  id: 'global_phone_input_title',
                  description: 'Phone Input title',
                })}
                options={phoneCodeOptions}
                renderSelectedValue={renderSelectedPhoneCode}
                value={enteredPhone}
                disabled={true}
                autoComplete="off"
              />
              <FootnoteButton
                className={styles.changePhoneButton}
                data-test-id="login_change_phone_button"
                as="button"
                type="button"
                onClick={onChangeLogin}
              >
                <FormattedMessage
                  defaultMessage="Change Phone"
                  id="global_phone_input_change"
                  description="Change Phone button"
                />
              </FootnoteButton>
            </div>
            <FormikNumberInput
              name="pin"
              ref={pinInputRef}
              inputProps={{
                clearable: true,
                digits: 4,
                maxLength: 4,
                title: intl.formatMessage({
                  defaultMessage: 'Verification Code',
                  id: 'sign_in_otp_input_title',
                  description: 'Verification Code input title',
                }),
                'data-test-id': 'login_form_pin_input',
                errorMessage: serverErrorMessage,
                autoComplete: 'one-time-code',
              }}
              onValueChange={handlePinChange}
              hideErrors={true}
            />
            <Footnote className={styles.resendWrapper}>
              <FormattedMessage
                defaultMessage="Haven’t received the code?"
                id="sign_in_otp_helper"
                description="Resend verification code text"
              />{' '}
              <CallMeButton timeUntilRecall={timeUntilRecall} onClick={onCallMe} />{' '}
              <FormattedMessage
                defaultMessage="or"
                id="sign_in_otp_or"
                description="request pin description"
              />{' '}
              <ResendButton timeUntilResend={timeUntilResend} onClick={onPinResend} />
            </Footnote>
            <Button
              className={styles.submit}
              type="submit"
              data-test-id="login_pin_submit_button"
              disabled={isSubmitButtonDisabled}
              isLoading={isLoading}
            >
              <FormattedMessage
                defaultMessage="Sign in"
                id="global_sign_in_button"
                description="Sign in button"
              />
            </Button>
            <HCaptcha {...hCaptchaProps} />
          </form>
        );
      }}
    </Formik>
  );
};
