import { defineMessages } from 'react-intl';

const DATE_FORMAT_PATTERN = 'PPP';

const MESSAGES = defineMessages({
  statementText: {
    description: 'Company debts notifier statement text',
    defaultMessage:
      'You can find your statement for {period} in the Billing section. The due date is {dueDate}.',
    id: 'yIADDZ',
  },
  daysLeftText: {
    description: 'Company debts notifier days left text',
    defaultMessage: '{daysLeft} left to settle your statement due {dueDate}.',
    id: 'L7JXv4',
  },
  lessThan3DaysLeftText: {
    description: 'Company debts notifier less than 3 days left text',
    defaultMessage: 'You have just {daysLeft} left to settle your statement due {dueDate}.',
    id: 'pgDP6G',
  },
  blockedText: {
    description: 'Company debts notifier blocked text',
    defaultMessage:
      'Unfortunately, your company has been blocked. To unblock your account, please settle your statement.',
    id: 'ftWoTA',
  },
  linkLabel: {
    description: 'Company debts notifier Go to billing link label',
    defaultMessage: 'Go to billing',
    id: 'mNG9ON',
  },
});

export { MESSAGES, DATE_FORMAT_PATTERN };
