import { Redirect, Route, Switch, useHistory } from 'react-router';
import React, { useMemo } from 'react';

import { PrivateRoute } from '_common/PrivateRoute';
import { NewJourney } from '_pages/NewJourney';
import { Journeys } from '_pages/Journeys';
import { BillingRouter } from '_modules/Billing/router';
import { NotFound } from '_pages/NotFound';
import { useHasPermission } from '_app/PermissionsProvider';
import {
  billingRoutes,
  employeesRoutes,
  authRoutes,
  personalSettingsRoutes,
  policiesRoutes,
  reportsRoutes,
  settingsRoutes,
  commonRoutes,
} from '_routes';
import { PoliciesRouter } from '_modules/Policies/router';
import { RerportsRouter } from '_modules/Reports/router';
import { SettingsRouter } from '_modules/Settings/router';
import { useCompany } from '_hooks/company';
import { PersonalSettingsRouter } from '_modules/PersonalSettings/router';
import { employeesRedirects } from '_modules/Employees/redirects';
import { lazyRetry } from '_utils/lazyRetry';
import { AuthRouter } from '_modules/Auth/Routes';
import { personalSettingsBase } from '_routes/personalSettings';
import { card3dsVerificationRoute } from '_routes/common';
import { Card3dsVerification } from '_/components/Card3dsVerification/Card3dsVerification';

import { PrivateRouterWrapper } from './PrivateRouterWrapper';
import { useRedirects } from './hooks';

const EmployeesRoutes = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "EmployeesContent" */ '../../modules/Employees/routes'),
    'EmployeesRoutes',
  ),
);

export const PurePrivateRouter = () => {
  const canBeRedirectedToBilling = useHasPermission('billing:page:read');
  const canBeRedirectedToNewJourney = useHasPermission('newJourney:page:read');
  const company = useCompany();
  const history = useHistory();

  const companyStatus = company?.status || null;
  const { shouldRedirectToAddCompany, shouldRedirectToRegister } = useRedirects(company);
  const shouldBeRedirectedToBilling = companyStatus === 'has_debts';

  const rootRedirect = useMemo(() => {
    if (shouldBeRedirectedToBilling && canBeRedirectedToBilling) {
      return billingRoutes.billingBase;
    }

    if (canBeRedirectedToNewJourney) {
      return '/new-journey';
    }

    if (canBeRedirectedToBilling) {
      return billingRoutes.billingBase;
    }

    return personalSettingsBase;
  }, [canBeRedirectedToBilling, canBeRedirectedToNewJourney, shouldBeRedirectedToBilling]);

  if (shouldRedirectToRegister) {
    return (
      <Redirect
        to={{
          ...history.location,
          pathname: authRoutes.registerRoute,
        }}
      />
    );
  }

  if (shouldRedirectToAddCompany) {
    return (
      <Redirect
        to={{
          ...history.location,
          pathname: authRoutes.addCompanyRoute,
        }}
      />
    );
  }

  return (
    <Switch>
      <PrivateRoute exact={true} path="/">
        <Redirect to={rootRedirect} />
      </PrivateRoute>
      <Redirect exact={true} from="/signin" to={authRoutes.loginRoute} />
      <Redirect exact={true} from="/invoices" to={billingRoutes.billingBase} />
      <PrivateRoute
        permission="newJourney:page:read"
        exact={true}
        path="/new-journey"
        component={NewJourney}
      />
      <PrivateRoute permission="journeys:page:read" path="/journeys" component={Journeys} />

      {employeesRedirects}
      <PrivateRoute
        path={employeesRoutes.employeesBase}
        permission="people:page:read"
        component={EmployeesRoutes}
      />
      <PrivateRoute
        exact={true}
        path={policiesRoutes.policiesBaseRoute}
        permission="policies:page:read"
        component={PoliciesRouter}
      />
      <PrivateRoute
        path={reportsRoutes.reportsBase}
        permission="reports:page:read"
        component={RerportsRouter}
      />

      <PrivateRoute
        path={billingRoutes.billingBase}
        permission="billing:page:read"
        component={BillingRouter}
      />

      <PrivateRoute path={settingsRoutes.settingsBase} component={SettingsRouter} />

      <PrivateRoute
        path={personalSettingsRoutes.personalSettingsBase}
        component={PersonalSettingsRouter}
      />
      <PrivateRoute path={card3dsVerificationRoute} component={Card3dsVerification} />

      <Route
        path={[
          authRoutes.loginRoute,
          authRoutes.registerRoute,
          authRoutes.pendingRoute,
          authRoutes.addCompanyRoute,
          authRoutes.welcomeRoute,
        ]}
        component={AuthRouter}
      />

      <Route component={NotFound} path={[commonRoutes.notFound, '*']} />
    </Switch>
  );
};

export const PrivateRouter = () => (
  <PrivateRouterWrapper>
    <PurePrivateRouter />
  </PrivateRouterWrapper>
);
