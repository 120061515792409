import React, { useCallback } from 'react';
import cn from 'clsx';
import { Button, ButtonProps, Select, IconGlobe } from '@wheely/ui-kit';

import { useLocale } from '_i18n/useLocale';
import { isFunction } from '_utils/isFunction';
import { LangCode } from '_types/common';

import { LANGUAGE_SELECT_ITEMS } from './constants';
import s from './styles.scss';

type LanguageItem = { title: string; value: LangCode };

type HandleItemSelect = (item: LanguageItem) => void;

type ChildrenProps = {
  items: LanguageItem[];
  selectedItem?: LanguageItem;
  handleItemSelect: HandleItemSelect;
};

type Props = {
  children?: ((props: ChildrenProps) => React.ReactElement) | React.ReactElement;
  className?: string;
  buttonProps?: ButtonProps;
  dark?: boolean;
};

const LanguageSwitcher: React.FC<Props> = ({
  buttonProps: { className: buttonClassname, ...restButtonProps } = {},
  children,
  dark,
  ...restProps
}) => {
  const { switchLocale, langCode: currentLanguage } = useLocale();

  const handleItemSelect = useCallback<HandleItemSelect>(
    item => {
      switchLocale(item.value);
    },
    [switchLocale],
  );

  const selectedItem = LANGUAGE_SELECT_ITEMS.find(({ value }) => value === currentLanguage);

  if (isFunction(children)) {
    return children({
      items: LANGUAGE_SELECT_ITEMS,
      selectedItem: selectedItem,
      handleItemSelect: handleItemSelect,
    });
  }

  return (
    <Select<LanguageItem>
      selectedItem={selectedItem}
      items={LANGUAGE_SELECT_ITEMS}
      onItemSelect={handleItemSelect}
      popoverProps={{
        minimal: true,
        targetTagName: 'div',
        wrapperTagName: 'div',
        targetClassName: s.popoverTarget,
      }}
      dark={dark}
      {...restProps}
    >
      <Button
        minimal={true}
        icon={<IconGlobe />}
        text={selectedItem && selectedItem.title}
        className={cn(s.button, buttonClassname, { [s.dark]: dark })}
        data-test-id="language-switcher-button"
        {...restButtonProps}
      />
    </Select>
  );
};

export { LanguageSwitcher };
