import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, IntlFormatters, useIntl } from 'react-intl';
import cn from 'clsx';
import {
  H2,
  ItemListRenderer,
  ItemRenderer,
  SelectedItemPredicate,
  SelectField,
  SelectFieldProps,
  SelectMenu,
} from '@wheely/ui-kit';

import { ChauffeurForADayType, CurrencyCode, LongJourneyOption } from '_api/types';
import { useBreakpoints } from '_hooks/useBreakpoints';
import { FormattedCurrency } from '_common/FormattedCurrency';
import { MobileTapContainer } from '_common/MobileTapContainer';
import { MobileModal } from '_common/MobileModal';
import { ExpectedDurationOption } from '_pages/NewJourney/components/ServiceLevels/components/ExpectedDurationField/components/ExpectedDurationOption';
import { ItemInfoLink } from '_pages/NewJourney/components/ServiceLevels/components/ItemInfoLink';
import { stopPropagation } from '_utils/stopPropagation';

import { secondsToHours } from '../../utils';

import s from './styles.scss';

type ExpectedDurationItem = {
  minExpectedDurationHours: number;
  maxExpectedDurationHours: number;
  chauffeurForADayType: ChauffeurForADayType;
  expectedDuration?: number;
  distanceLimit?: number;
  distanceUnit?: LongJourneyOption['distance_unit'];
  pricePerHour?: number;
  isAvailable: boolean;
};

const selectedItemPredicate: SelectedItemPredicate<ExpectedDurationItem> = (items, value) =>
  items.find(item => item.expectedDuration === value) || null;

const defaultExpectedDurationItems: Record<ChauffeurForADayType, ExpectedDurationItem> = {
  half_day: {
    minExpectedDurationHours: 4,
    maxExpectedDurationHours: 6,
    chauffeurForADayType: 'half_day',
    isAvailable: false,
  },
  full_day: {
    minExpectedDurationHours: 6,
    maxExpectedDurationHours: 12,
    chauffeurForADayType: 'full_day',
    isAvailable: false,
  },
};

const convertLongJourneyOptionToExpectedDurationItem = (
  longJourneyOption: LongJourneyOption,
): ExpectedDurationItem => ({
  minExpectedDurationHours: secondsToHours(longJourneyOption.duration_min),
  maxExpectedDurationHours: secondsToHours(longJourneyOption.duration_max),
  expectedDuration: longJourneyOption.duration_min,
  chauffeurForADayType: longJourneyOption.key,
  distanceLimit: longJourneyOption.distance_limit,
  distanceUnit: longJourneyOption.distance_unit,
  pricePerHour: longJourneyOption.price_per_hour,
  isAvailable: true,
});

const getDistanceUnitShort = (
  distanceUnit: ExpectedDurationItem['distanceUnit'],
  intl: IntlFormatters,
): string => {
  if (!distanceUnit) {
    return '';
  }

  switch (distanceUnit) {
    case 'km': {
      return intl.formatMessage({
        description: 'Kilometer unit shorthand',
        defaultMessage: 'km',
        id: 'bhyRwZ',
      });
    }
    case 'mile': {
      return intl.formatMessage({
        description: 'Mile unit shorthand',
        defaultMessage: 'mi',
        id: 'Mdeu/H',
      });
    }
  }
};

type Props = {
  value?: number;
  availableLongJourneyOptions: LongJourneyOption[];
  currencyCode: CurrencyCode;
  onChange: (expectedDuration: number) => void;
  setCfadType: (cfadType: ChauffeurForADayType) => void;
  serviceDescriptionUrl?: string;
  disabled?: boolean;
};

type MobileItemProps = {
  item: ExpectedDurationItem;
  isSelected: boolean;
  onSelect: (item: ExpectedDurationItem) => void;
};

const MobileItem = ({ item, isSelected, onSelect }: MobileItemProps) => {
  const handleClick = useCallback(() => {
    onSelect(item);
  }, [item, onSelect]);

  return (
    <li
      className={cn(s.mobileItem, {
        [s.selected]: isSelected,
      })}
      key={item.chauffeurForADayType}
      onClick={handleClick}
    >
      <ExpectedDurationOption
        minDurationHours={item.minExpectedDurationHours}
        maxDurationHours={item.maxExpectedDurationHours}
        chauffeurForADayType={item.chauffeurForADayType}
        isAvailable={item.isAvailable}
      />
    </li>
  );
};

export const ExpectedDurationField = ({
  value,
  availableLongJourneyOptions,
  currencyCode,
  serviceDescriptionUrl,
  onChange,
  setCfadType,
  disabled = false,
}: Props) => {
  const intl = useIntl();

  const { isTablet } = useBreakpoints();
  const [isModalOpened, setModalOpen] = useState(false);

  const items = useMemo<ExpectedDurationItem[]>(() => {
    const chauffeurForADayTypes: ChauffeurForADayType[] = ['half_day', 'full_day'];

    return chauffeurForADayTypes.map(chauffeurForADayType => {
      const availableLongJourneyOption = availableLongJourneyOptions.find(
        option => option.key === chauffeurForADayType,
      );

      if (!availableLongJourneyOption) {
        return defaultExpectedDurationItems[chauffeurForADayType];
      }

      return convertLongJourneyOptionToExpectedDurationItem(availableLongJourneyOption);
    });
  }, [availableLongJourneyOptions]);

  const handleOpenMobileSelect = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleCloseMobileSelect = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleItemSelect = useCallback<SelectFieldProps<ExpectedDurationItem>['onItemSelect']>(
    item => {
      if (!item?.expectedDuration) {
        return;
      }

      setCfadType(item.chauffeurForADayType);
      onChange(item.expectedDuration);

      if (isModalOpened) {
        handleCloseMobileSelect();
      }
    },
    [handleCloseMobileSelect, isModalOpened, onChange, setCfadType],
  );

  const selectedItem = items.find(item => item.expectedDuration === value);

  const itemListRenderer: ItemListRenderer<ExpectedDurationItem> = (
    listProps,
    initialContent,
    noResults,
    infiniteScrollProps,
    loadingElement,
    menuProps,
  ) => {
    const { itemsParentRef, renderItem, filteredItems } = listProps;

    return (
      <SelectMenu
        className={s.list}
        itemsParentRef={itemsParentRef}
        infiniteScrollProps={infiniteScrollProps}
        {...menuProps}
      >
        {filteredItems.map(renderItem)}
      </SelectMenu>
    );
  };

  const itemRenderer: ItemRenderer<ExpectedDurationItem> = (item, itemProps) => {
    const { handleClick } = itemProps;

    const isSelected =
      typeof item.expectedDuration === 'number' &&
      selectedItem?.expectedDuration === item.expectedDuration;

    return (
      <li
        className={cn(s.item, {
          [s.selected]: isSelected,
        })}
        key={item.chauffeurForADayType}
        onClick={handleClick}
      >
        <ExpectedDurationOption
          minDurationHours={item.minExpectedDurationHours}
          maxDurationHours={item.maxExpectedDurationHours}
          chauffeurForADayType={item.chauffeurForADayType}
          isAvailable={item.isAvailable}
        />
      </li>
    );
  };

  const description = useMemo(() => {
    if (!selectedItem?.pricePerHour) {
      return null;
    }

    return (
      <div className={s.description}>
        <FormattedCurrency value={selectedItem.pricePerHour} currencyCode={currencyCode} />
        /
        <FormattedMessage description="Hour unit" defaultMessage="hour" id="BveI0f" /> (
        <FormattedMessage description="Included short" defaultMessage="incl." id="KUIJCL" />
        &nbsp;
        {selectedItem.distanceLimit}&nbsp;{getDistanceUnitShort(selectedItem.distanceUnit, intl)})
        {serviceDescriptionUrl && (
          <>
            {' '}
            <ItemInfoLink
              className={s.infoLink}
              url={serviceDescriptionUrl}
              onClick={stopPropagation}
            />
          </>
        )}
      </div>
    );
  }, [selectedItem, currencyCode, intl, serviceDescriptionUrl]);

  const buttonText = useMemo<React.ReactNode>(() => {
    if (!selectedItem) {
      return null;
    }

    return (
      <ExpectedDurationOption
        minDurationHours={selectedItem.minExpectedDurationHours}
        maxDurationHours={selectedItem.maxExpectedDurationHours}
        chauffeurForADayType={selectedItem.chauffeurForADayType}
        isAvailable={true}
      />
    );
  }, [selectedItem]);

  return (
    <>
      <MobileTapContainer onClick={handleOpenMobileSelect}>
        <SelectField<ExpectedDurationItem>
          items={isTablet ? [] : items}
          value={value}
          onItemSelect={handleItemSelect}
          selectedItemPredicate={selectedItemPredicate}
          itemListRenderer={itemListRenderer}
          itemRenderer={itemRenderer}
          itemDisabled={item => !item.isAvailable}
          description={description}
          disabled={disabled}
          buttonProps={{
            text: buttonText,
            'data-test-id': 'expected-duration-button',
          }}
          menuProps={{
            'data-test-id': 'expected-duration-menu',
          }}
        />
      </MobileTapContainer>
      {isModalOpened && (
        <MobileModal onClose={handleCloseMobileSelect}>
          <div className={s.mobileModalContent}>
            <H2 capitalize={true} className={s.containerTitle}>
              <FormattedMessage
                description="Chauffeur for a day expected duration, modal title on mobile"
                defaultMessage="Select duration"
                id="rczJ75"
              />
            </H2>
            <ul className={s.mobileList}>
              {items.map(item => (
                <MobileItem
                  key={item.chauffeurForADayType}
                  isSelected={item === selectedItem}
                  item={item}
                  onSelect={handleItemSelect}
                />
              ))}
            </ul>
          </div>
        </MobileModal>
      )}
    </>
  );
};
