import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const makeValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    pin: Yup.string().required(
      intl.formatMessage({
        description: 'Empty pin error message',
        defaultMessage: 'Pin is required',
        id: '5JbwQa',
      }),
    ),
  });
