import React, { useEffect } from 'react';

export const useClickOutside = <T extends HTMLElement, R>(
  ref: React.RefObject<T>,
  onClick: () => R,
) => {
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref?.current && !ref.current.contains(event.target as Node)) {
        onClick();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, onClick]);
};
