import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { WithChildren } from '_types/helpers';
import { AppPreloader } from '_app/PreLoader/PreLoader';

import { useSessionUpdate } from './hooks';
import { restoreSession } from './utils';

export const WithSession = ({ children }: WithChildren) => {
  const dispatch = useDispatch();
  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    restoreSession(dispatch).then(() => setShowPage(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSessionUpdate(showPage);

  if (!showPage) {
    return <AppPreloader />;
  }

  return <>{children}</>;
};
