import React from 'react';
import { useIntl } from 'react-intl';

import { THIN_SPACE } from '_constants';
import { getEtaDuration } from '_utils/getEtaDuration';

export const FormattedEtaInMinutes = ({ etaDuration }: { etaDuration: number | null }) => {
  const intl = useIntl();

  if (!etaDuration) {
    return null;
  }

  const duration = `${getEtaDuration(etaDuration)}${THIN_SPACE}${intl.formatMessage({
    id: 'minutes',
    description: 'minutes unit shorthand',
    defaultMessage: 'min',
  })}`;

  return <>{duration}</>;
};
