import React from 'react';
import { Icon, IconProps } from '@wheely/ui-kit';

export const IconMap: Icon = ({ width, height, className, fill = 'currentColor' }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 3.6777C1.5 3.43693 1.5 3.31655 1.5438 3.21891C1.58241 3.13284 1.64458 3.05945 1.72313 3.00721C1.81224 2.94796 1.93099 2.92817 2.16848 2.88858L5.06848 2.40525C5.38897 2.35184 5.54921 2.32513 5.67409 2.37151C5.78368 2.41222 5.87555 2.49004 5.93371 2.59145C6 2.70701 6 2.86946 6 3.19437V13.5723C6 13.8131 6 13.9334 5.9562 14.0311C5.91759 14.1172 5.85542 14.1905 5.77687 14.2428C5.68776 14.302 5.56901 14.3218 5.33152 14.3614L2.43152 14.8447C2.11103 14.8982 1.95079 14.9249 1.82591 14.8785C1.71632 14.8378 1.62445 14.76 1.56629 14.6585C1.5 14.543 1.5 14.3805 1.5 14.0556V3.6777ZM12 4.42773C12 4.18696 12 4.06658 12.0438 3.96894C12.0824 3.88287 12.1446 3.80948 12.2231 3.75725C12.3122 3.69799 12.431 3.6782 12.6685 3.63861L15.5685 3.15526C15.889 3.10184 16.0492 3.07513 16.1741 3.12152C16.2837 3.16222 16.3755 3.24004 16.4337 3.34145C16.5 3.45701 16.5 3.61946 16.5 3.94437V14.3223C16.5 14.5631 16.5 14.6835 16.4562 14.7811C16.4176 14.8672 16.3554 14.9406 16.2769 14.9928C16.1878 15.0521 16.069 15.0719 15.8315 15.1114L12.9315 15.5948C12.611 15.6482 12.4508 15.6749 12.3259 15.6285C12.2163 15.5878 12.1245 15.51 12.0663 15.4086C12 15.293 12 15.1306 12 14.8057V4.42773ZM10.875 4.29162C10.875 4.09606 10.875 3.99828 10.8435 3.91374C10.8156 3.83904 10.7703 3.77209 10.7113 3.71847C10.6445 3.65779 10.5537 3.62147 10.3721 3.54884L8.22211 2.68884C7.85457 2.54182 7.6708 2.46832 7.52327 2.49897C7.39427 2.52578 7.28105 2.60243 7.20825 2.71225C7.125 2.83784 7.125 3.03577 7.125 3.43162V13.7084C7.125 13.9039 7.125 14.0017 7.15654 14.0863C7.18441 14.161 7.22974 14.2279 7.28875 14.2815C7.35553 14.3422 7.44631 14.3785 7.62789 14.4512L9.77789 15.3112C10.1454 15.4582 10.3292 15.5317 10.4767 15.501C10.6057 15.4742 10.7189 15.3976 10.7917 15.2877C10.875 15.1622 10.875 14.9642 10.875 14.5684V4.29162Z"
      fill={fill}
    />
  </svg>
);
