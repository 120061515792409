import { replace } from 'connected-react-router';
import {
  call,
  cancel,
  debounce,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { SagaIterator, Task } from 'redux-saga';

import { apiClient } from '_api';
import { normalizeServiceOffers } from '_api/normalize';
import {
  CreateOrderRequestResponse,
  FindOrCreateUserByPhonePayload,
  FindOrCreateUserByPhoneResponse,
  Location,
  NearbyLocationsResponse,
  GetServiceFaresResponse,
  City,
} from '_api/types';
import { RootState } from '_store';
import { companyCityOrUserLocationSelector } from '_store/common/selectors';
import {
  createOrder as createOrderAction,
  createOrderFailed as createOrderFailedAction,
  createOrderRequest as createOrderRequestAction,
  createOrderRequestFailed as createOrderRequestFailedAction,
  createOrderRequestSucceed as createOrderRequestSucceedAction,
  createOrderSucceed as createOrderSucceedAction,
  getLocationByReferenceSucceed as getLocationByReferenceSucceedAction,
  getRoute as getRouteAction,
  getRouteFailed as getRouteFailedAction,
  getRouteSucceed as getRouteSucceedAction,
  loadNearbyLocations as loadNearbyLocationsAction,
  loadNearbyLocationsFailed as loadNearbyLocationsFailedAction,
  loadNearbyLocationsSucceed as loadNearbyLocationsSucceedAction,
  NearbyLocations,
  newJourneySlice,
  pickupLocationSelector,
  preFilledJourneySelector,
  serviceOffersPayloadSelector,
  setPickupLocation as setPickupLocationAction,
  setRecentReferences as setRecentReferencesAction,
  setStops as setStopsAction,
  setUserCoords as setUserCoordsAction,
  stopsSelector,
  UserCoordsShape,
} from '_store/newJourney';
import {
  loadServiceOffers as loadServiceOffersAction,
  ServiceOffersEntities,
  setServiceOffersLoadingState as setServiceOffersLoadingStateAction,
} from '_store/serviceOffers';
import {
  loadEmployees as loadEmployeesAction,
  resetNameState as resetEmployeesNameStateAction,
} from '_store/employees';
import { splitStopsIntoStopsAndDropoff } from '_utils/splitStopsIntoStopsAndDropoff';
import { getGeoPointQueryFromCoords } from '_utils/getGeoPointQueryFromCoords';
import { handleApiError } from '_utils/handleApiError';
import { getCurrentPosition } from '_utils/getCurrentPosition';
import { companyTypeSelector } from '_queries/company/selectors';

import { watchServiceOffersLoad } from '../common/watchServiceOffersLoad';
import { watchEmployeesLoad } from '../common/watchEmployeesLoad';

import { completionsFlow } from './completionsFlow';

function* getUserLocation() {
  try {
    const { coords }: GeolocationPosition = yield call(getCurrentPosition, {
      timeout: 10000,
    });

    yield put(setUserCoordsAction({ lat: coords.latitude, lng: coords.longitude }));
    yield put(loadNearbyLocationsAction());
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Can't get current position:", error);
  }
}

function* loadRoute(action: ReturnType<typeof getRouteAction>) {
  try {
    const { route } = yield call(apiClient.getRoute, action.payload);

    yield put(getRouteSucceedAction({ route }));
  } catch (error) {
    yield put(getRouteFailedAction({ error: handleApiError(error) }));
  }
}

function* getRoute() {
  const pickupLocation: Location | null =
    yield select<(state: RootState) => Location | null>(pickupLocationSelector);

  const extraStops: Array<Location | null | undefined> =
    yield select<(state: RootState) => Array<Location | null | undefined>>(stopsSelector);

  const { dropoff: dropoffLocation, stops } = splitStopsIntoStopsAndDropoff(extraStops);

  if (pickupLocation && dropoffLocation) {
    yield put(
      getRouteAction({
        pickup: pickupLocation,
        stops,
        dropoff: dropoffLocation,
      }),
    );
  }
}

function* loadNearbyLocations() {
  try {
    const coords: UserCoordsShape = yield select<(state: RootState) => UserCoordsShape | undefined>(
      companyCityOrUserLocationSelector,
    );

    const pickupLocation: Location | null =
      yield select<(state: RootState) => Location | null>(pickupLocationSelector);

    const extraStops: Array<Location | null | undefined> =
      yield select<(state: RootState) => Array<Location | null | undefined>>(stopsSelector);
    const { dropoff: dropoffLocation } = splitStopsIntoStopsAndDropoff(extraStops);

    const pickupCoords = pickupLocation?.position[0] && {
      lat: pickupLocation?.position[0],
      lng: pickupLocation?.position[1],
    };

    const dropoffCoords = dropoffLocation?.position[0] && {
      lat: dropoffLocation?.position[0],
      lng: dropoffLocation?.position[1],
    };

    const position = getGeoPointQueryFromCoords(pickupCoords || dropoffCoords || coords);

    const nearbyAirports: NearbyLocationsResponse = yield call(apiClient.getNearbyLocations, {
      position,
      type: 'airport',
    });
    const nearbyRailwayHubs: NearbyLocationsResponse = yield call(apiClient.getNearbyLocations, {
      position,
      type: 'railway_hub',
    });

    const nearbyLocations: NearbyLocations = {
      airport: nearbyAirports,
      railway_hub: nearbyRailwayHubs,
    };

    yield put(loadNearbyLocationsSucceedAction({ nearbyLocations }));
  } catch (error) {
    yield put(loadNearbyLocationsFailedAction({ error: handleApiError(error) }));
  }
}

export function* loadCityAirports({ city }: { city: City }): SagaIterator<Location[]> {
  const position = city.center.join(',');

  let result = null;

  while (!result) {
    try {
      result = yield call(apiClient.getNearbyLocations, {
        position,
        type: 'airport',
      });
    } catch {
      yield delay(3000);
    }
  }

  return result.locations;
}

export function* loadServiceOffersForPickupLocation({
  pickup,
  selectedServiceId,
  stops,
}: {
  pickup: Location;
  selectedServiceId: string;
  stops: Location[];
}): SagaIterator<ServiceOffersEntities> {
  const result: GetServiceFaresResponse = yield call(apiClient.getServiceFares, {
    pickup,
    selected_service_id: selectedServiceId,
    stops,
  });

  const {
    entities: { serviceOffers = {} },
  } = normalizeServiceOffers(result);

  return serviceOffers;
}

function* watchNearbyLocationsLoad() {
  yield debounce(200, loadNearbyLocationsAction.type, loadNearbyLocations);
}

function* requestServiceOffers({ isSilent }: { isSilent: boolean }) {
  const payload = serviceOffersPayloadSelector(yield select());

  if (payload.pickup) {
    yield put(loadServiceOffersAction({ ...payload, isSilent }));
  }
}

function* loadServiceOffersFlow(): SagaIterator {
  const pattern = [
    loadNearbyLocationsSucceedAction.type,
    setPickupLocationAction.type,
    setStopsAction.type,
    getLocationByReferenceSucceedAction.type,
    newJourneySlice.actions.setPickupAt.type,
  ];

  let lastDelayTask: Task | null = null;

  while (true) {
    const [, delayResult] = yield race([take(pattern), delay(10 * 1000)]);

    if (lastDelayTask) {
      yield cancel(lastDelayTask);
    }

    const forkedTask = yield fork(requestServiceOffers, {
      isSilent: Boolean(delayResult),
    });

    if (delayResult) {
      lastDelayTask = forkedTask;
    }
  }
}

function* loadRecentReferences() {
  try {
    const { references } = yield call(apiClient.getRecentReferences);

    yield put(setRecentReferencesAction({ references }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Can't get recent references:", error);
    yield put(setRecentReferencesAction({ references: [] }));
  }
}

export function* resetEmployeesQueryState() {
  yield put(resetEmployeesNameStateAction());
}

function* findOrCreateUserByPhone({
  phone,
}: FindOrCreateUserByPhonePayload): SagaIterator<FindOrCreateUserByPhoneResponse['user_uuid']> {
  try {
    const { user_uuid }: FindOrCreateUserByPhoneResponse = yield call(
      apiClient.findOrCreateUserByPhone,
      { phone },
    );

    return user_uuid;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Can't find or create order request user by phone:", error);
    throw error;
  }
}

function* createOrderRequest(action: ReturnType<typeof createOrderRequestAction>) {
  const { passenger, order } = action.payload;

  try {
    const user_id: string = yield call(findOrCreateUserByPhone, { phone: passenger.phone });

    const { id }: CreateOrderRequestResponse = yield call(apiClient.createOrderRequest, {
      user: { uuid: user_id, name: passenger.name },
      order,
    });

    yield put(createOrderRequestSucceedAction());
    yield put(replace(`/journeys/order-requests/${id}`));
  } catch (error) {
    yield put(createOrderRequestFailedAction({ error: handleApiError(error) }));
  }
}

function* createOrder(action: ReturnType<typeof createOrderAction>) {
  const { payload } = action;

  try {
    const { id } = yield call(apiClient.createOrder, payload);

    yield put(createOrderSucceedAction());
    yield put(replace(`/journeys/journeys/${id}`));
  } catch (error) {
    yield put(createOrderFailedAction({ error: handleApiError(error) }));
  }
}

export function* newJourneyFlowSaga() {
  yield put(setServiceOffersLoadingStateAction({ isLoading: true }));

  yield fork(watchServiceOffersLoad);
  yield fork(loadServiceOffersFlow);
  yield fork(watchNearbyLocationsLoad);
  yield fork(completionsFlow);
  yield fork(watchEmployeesLoad);
  yield takeLatest(
    [setPickupLocationAction.type, getLocationByReferenceSucceedAction.type, setStopsAction.type],
    getRoute,
  );
  yield takeLatest(getRouteAction.type, loadRoute);

  const companyType = companyTypeSelector();
  const preFilledJourney = preFilledJourneySelector(yield select());

  yield put(
    loadEmployeesAction({
      appendMode: companyType === 'hospitality' || Boolean(preFilledJourney?.employeeUserId),
    }),
  );
  yield put(loadNearbyLocationsAction());

  yield takeLatest(createOrderAction.type, createOrder);
  yield takeLatest(createOrderRequestAction.type, createOrderRequest);
  yield call(loadRecentReferences);
  yield call(getUserLocation);
}
