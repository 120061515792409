import React, { useCallback } from 'react';
import { Button, IconArrowLeft } from '@wheely/ui-kit';
import { useIntl } from 'react-intl';

import { Flight, Location, ServiceOffer } from '_api/types';
import { useLocaleDate } from '_i18n/useLocaleDate';
import { getServiceOfferEta } from '_utils/getServiceOfferNearestReservationOffset';
import { getEarliestReservationDate } from '_utils/getEarliestReservationDate';

import dialogStyles from '../../styles.scss';

import s from './styles.scss';

type Props = {
  flight: Flight;
  serviceOffer: ServiceOffer | null;
  earliestPickupDateTime: Date;
  onRequestOnDemand: (location: Location) => void;
  onBack: () => void;
};

export const PickupUnavailable = ({
  flight,
  serviceOffer,
  earliestPickupDateTime,
  onRequestOnDemand,
  onBack,
}: Props) => {
  const intl = useIntl();
  const { format: formatDate } = useLocaleDate();

  const handleRequestOnDemandButtonClick = useCallback(() => {
    onRequestOnDemand(flight.pickup);
  }, [flight, onRequestOnDemand]);

  const renderContent = () => {
    if (!serviceOffer) {
      return intl.formatMessage(
        {
          defaultMessage: 'Pickup is only available from {time}. Your flight lands earlier.',
          id: '1b7RJS',
          description: 'Flight not description',
        },
        {
          time: formatDate(earliestPickupDateTime, 'p'),
        },
      );
    }

    const serviceOfferEta = getServiceOfferEta(serviceOffer, true);
    const earliestServicePickupDateTime = getEarliestReservationDate(
      serviceOfferEta,
      serviceOffer.service.utc_offset,
    );

    return intl.formatMessage(
      {
        defaultMessage:
          '{serviceClassName} class is only available from {time}. Your flight lands earlier. Please select a different class or turn off scheduling and adjust the pickup time.',
        id: 'STwt0W',
        description: 'Flight not description',
      },
      {
        serviceClassName: serviceOffer.service.title,
        time: formatDate(earliestServicePickupDateTime, 'p'),
      },
    );
  };

  return (
    <>
      <div className={dialogStyles.titleWithBackButton}>
        <Button
          className={dialogStyles.backButton}
          minimal={true}
          onClick={onBack}
          icon={<IconArrowLeft />}
        />
        <div>
          {intl.formatMessage({
            defaultMessage: 'Unavailable at this time',
            id: 'yr5bye',
            description: 'Unavailable at this time modal title',
          })}
        </div>
      </div>
      <div className={s.text}>{renderContent()}</div>
      <Button
        onClick={handleRequestOnDemandButtonClick}
        text={intl.formatMessage({
          defaultMessage: 'Request On-Demand',
          id: '5FLgd9',
          description: 'Request On-Demand button text',
        })}
      />
    </>
  );
};
