import React from 'react';
import { TextField } from '@wheely/ui-kit';
import { useField } from 'formik';
import { useIntl } from 'react-intl';

import { getFieldErrorMessage } from '_pages/NewJourney/components/Form/utils/getFieldErrorMessage';

interface MeetAndGreetByTrainNumberProps {
  disabled?: boolean;
}

export const MeetAndGreetByTrainNumber = ({ disabled }: MeetAndGreetByTrainNumberProps) => {
  const intl = useIntl();
  const [trainNumberTextField, trainNumberTextMeta] = useField('trainNumber');
  const [carriageNumberTextField, carriageNumberTextMeta] = useField('carriageNumber');

  return (
    <>
      <TextField
        {...trainNumberTextField}
        disabled={disabled}
        placeholder={intl.formatMessage({
          description: 'Meet & Greet train number input placeholder',
          defaultMessage: 'Train number',
          id: 'gA6sJU',
        })}
        error={getFieldErrorMessage(trainNumberTextMeta)}
        data-test-id="train-number-field"
        errorProps={{
          'data-test-id': 'train-number-error',
        }}
      />
      <TextField
        {...carriageNumberTextField}
        disabled={disabled}
        placeholder={intl.formatMessage({
          description: 'Meet & Greet carriage number input placeholder',
          defaultMessage: 'Carriage',
          id: 'UYXdVV',
        })}
        error={getFieldErrorMessage(carriageNumberTextMeta)}
        data-test-id="carriage-field"
        errorProps={{
          'data-test-id': 'carriage-error',
        }}
      />
    </>
  );
};
