import { IntlFormatters } from 'react-intl';

import { Location } from '_api/types';
import { isDropoffLocationInaccurate } from '_utils/isDropoffLocationInaccurate';

import { MESSAGES } from '../../../constants';
import { FormErrors } from '../types';

const getDropoffError = (
  location: Location | null | undefined,
  messageFormatter: IntlFormatters['formatMessage'],
): FormErrors['dropoff'] => {
  let error;

  if (location && isDropoffLocationInaccurate(location)) {
    error = messageFormatter(MESSAGES.locationInaccurate);
  }

  return error;
};

export { getDropoffError };
