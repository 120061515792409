import { useQuery } from '@tanstack/react-query';

import { apiClient } from '_api';

import { COMPANY } from '../keys';

export const useGetCompany = (
  companyId: string | undefined,
  enabled: boolean = true,
  isPolling?: boolean,
) =>
  useQuery({
    refetchIntervalInBackground: false,
    refetchInterval: isPolling ? 10 * 1000 : false,
    staleTime: 10 * 1000,
    gcTime: 10 * 1000,
    enabled: enabled && Boolean(companyId),
    queryKey: [COMPANY.LOAD, companyId],
    queryFn: () => apiClient.getCompany(companyId as string).then(({ company }) => company),
  });
