import { useHistory } from 'react-router';

import { useGetCompanyLastDraft } from '_queries/company/useGetCompanyLastDraft';
import { authRoutes } from '_routes';

export const useLastDraft = () => {
  const { data } = useGetCompanyLastDraft(false);

  return data;
};

export const useIsRegisterPage = () => {
  const history = useHistory();

  return history.location.pathname === authRoutes.registerRoute;
};
