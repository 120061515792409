import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SelectMenuItem } from '@wheely/ui-kit';

const DefaultSelectLoadingItem: React.VFC<{ className?: string }> = ({ className }) => (
  <SelectMenuItem
    className={className}
    text={
      <FormattedMessage
        defaultMessage="Loading..."
        id="cy/Tru"
        description="Default select loading item text"
      />
    }
    disabled={true}
  />
);

export { DefaultSelectLoadingItem };
