import { NewServiceErrorPayload } from '_api/types';

// Matches leading spaces, "!", "?" and "." characters (in any sequence too),
// as well as trailing spaces (for trimRight)
export const MESSAGE_CLEANING_REGEXP = /(^[\s!?.]*)|(\s*$)/g;

// Matches trailing punctuation marks ("!", "?", ".")
export const TRAILING_PUNCTUATION_CHARS_REGEXP = /([!?.])$/g;

const isString = (text: unknown) => typeof text === 'string';
const getJoiningCharacter = (text: string): string =>
  text.match(TRAILING_PUNCTUATION_CHARS_REGEXP) ? ' ' : '. ';

// Returns a single error message combined from the errors array
export const joinErrorMessages = (
  errors?: null | NewServiceErrorPayload[] | Record<string, string[]>,
) => {
  if (!errors) {
    return '';
  }

  if (Array.isArray(errors)) {
    return errors.reduce((acc, error) => {
      const message = isString(error?.message)
        ? error.message.replace(MESSAGE_CLEANING_REGEXP, '')
        : null;

      if (!message) {
        return acc;
      }

      if (!acc) {
        return message;
      }

      const joiningCharacter = getJoiningCharacter(acc);

      acc = `${acc}${joiningCharacter}${message}`;

      return acc;
    }, '');
  }

  if (typeof errors === 'object') {
    return Object.values(errors).reduce((acc, error) => {
      const joiningCharacter = getJoiningCharacter(acc);
      const trimmedMessages = error
        .filter(isString)
        .map(message => message.replace(MESSAGE_CLEANING_REGEXP, ''));
      const joinedError = trimmedMessages.join(joiningCharacter);

      if (!acc) {
        return joinedError;
      }

      acc = `${acc}${joiningCharacter}${joinedError}`;

      return acc;
    }, '');
  }

  return '';
};
