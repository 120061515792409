import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SelectMenuItem } from '@wheely/ui-kit';

const DefaultSelectNoResults: React.VFC<{ className?: string }> = ({ className }) => (
  <SelectMenuItem
    className={className}
    text={
      <FormattedMessage
        defaultMessage="No results"
        id="QHwfQm"
        description="Default select no results item text"
      />
    }
    disabled={true}
  />
);

export { DefaultSelectNoResults };
