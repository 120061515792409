import { Location } from '_api/types';

type Position = {
  lat: number;
  lng: number;
};

// Using for storing location been set on map
// with hint line1 address prevents UI from breaking
// and boolean field "is go to pin" helps us determine if this is location from map
export const transformToGoToPinLocation = (
  position: Position | [number, number],
  line1: string,
): Location => ({
  line1,
  line2: '',
  position: Array.isArray(position) ? position : [position.lat, position.lng],
  source: 'wheely',
  direction: 'position',
  go_to_pin: true,
});
