import React from 'react';
import cn from 'clsx';
import { H2, H3 } from '@wheely/ui-kit';

import { useBreakpoints } from '_hooks/useBreakpoints';

import s from './styles.scss';

export type SectionHeaderProps = React.PropsWithChildren<{
  className?: string;
  headerClassName?: string;
}>;

export const SectionHeader = ({
  className,
  headerClassName,
  children,
  ...restProps
}: SectionHeaderProps) => {
  const { isTablet } = useBreakpoints();
  const HeaderComponent = isTablet ? H2 : H3;

  return (
    <header className={cn(s.sectionHeader, className)} {...restProps}>
      <HeaderComponent capitalize={true} className={headerClassName}>
        {children}
      </HeaderComponent>
    </header>
  );
};
