import { DEFAULT_PHONE_COUNTRY_CODE } from '_constants';

import { useCompanyCountryCode } from './company';

const usePhoneCountryCode = () => {
  const countryCode: string | null = useCompanyCountryCode();

  switch (countryCode) {
    case 'AE':
      return '+971';
    case 'GB':
      return '+44';
    case 'RU':
      return '+7';
    case 'FR':
      return '+33';
    default:
      return DEFAULT_PHONE_COUNTRY_CODE;
  }
};

export { usePhoneCountryCode };
