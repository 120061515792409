import { useQuery } from '@tanstack/react-query';

import { apiClient } from '_api';

import { INVITE_ACCEPT } from '../keys';

export const useGetInviteForEnrichmentQuery = (inviteId: string) =>
  useQuery({
    refetchOnWindowFocus: false,
    retry: false,
    gcTime: Infinity,
    staleTime: Infinity,
    queryKey: [...INVITE_ACCEPT, inviteId],
    queryFn: () =>
      apiClient
        .getInviteForEnrichment({ invite_id: inviteId })
        .then(({ company_name, masked_phone }) => ({
          companyName: company_name,
          maskedPhone: masked_phone,
        })),
  });
