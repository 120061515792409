import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { PhoneField as BasePhoneField, PhoneFieldProps } from '@wheely/ui-kit';

import { PHONE_COUNTRY_CODES } from '_constants';

const CountryCodeMessages = defineMessages({
  fr: {
    description: 'France country code title',
    defaultMessage: 'France ({code})',
    id: 'KA3tDi',
  },
  ru: {
    description: 'Russia country code title',
    defaultMessage: 'Russia ({code})',
    id: 'SqkMvf',
  },
  uk: {
    description: 'UK country code title',
    defaultMessage: 'United Kingdom ({code})',
    id: 'pK5gar',
  },
  ae: {
    description: 'AE country code title',
    defaultMessage: 'United Arab Emirates ({code})',
    id: 'ZOgYmj',
  },
  other: {
    description: 'Other country code title',
    defaultMessage: 'Other country',
    id: '5GRTsq',
  },
});

const PhoneField: React.VFC<Omit<PhoneFieldProps, 'items'>> = props => {
  const intl = useIntl();
  const countryCodeSelectItems = useMemo(
    () =>
      PHONE_COUNTRY_CODES.map(({ id, value }) => ({
        value,
        title: intl.formatMessage(CountryCodeMessages[id], { code: value }),
      })),
    [intl],
  );

  return <BasePhoneField {...props} items={countryCodeSelectItems} />;
};

export { PhoneField };
