import { useQuery } from '@tanstack/react-query';

import { apiClient } from '_api';

import { PUBLIC_EMPLOYEE } from '../keys';

export const usePublicEmployee = (employeeId: string) =>
  useQuery({
    refetchOnWindowFocus: false,
    retry: false,
    gcTime: Infinity,
    staleTime: Infinity,
    queryKey: [...PUBLIC_EMPLOYEE, employeeId],
    queryFn: () =>
      apiClient.getPublicEmployee(employeeId).then(({ company_name, masked_phone, app_url }) => ({
        companyName: company_name,
        maskedPhone: masked_phone,
        appUrl: app_url,
      })),
  });
