import React from 'react';
import { Redirect, Route, generatePath } from 'react-router';

import { employeesRoutes } from '_routes';

export const employeesRedirects = employeesRoutes.redirects.map(({ from, to }, i) => (
  <Route
    key={i}
    path={from}
    exact={true}
    render={({ match }) => <Redirect to={generatePath(to, { id: match.params.id })} />}
  />
));
