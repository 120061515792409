import { PHONE_COUNTRY_CODES } from '_constants';

export const splitCodeAndPhoneNumber = (phoneNumber: string): string[] | null => {
  const code = PHONE_COUNTRY_CODES.find(({ value: countryCode }) =>
    phoneNumber.startsWith(countryCode),
  )?.value;

  if (code) {
    return phoneNumber.match(new RegExp(`(${code.replace('+', '\\+')})(\\d+)`))?.slice(1) || null;
  }

  return null;
};
