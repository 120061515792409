import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useTypedSelector } from '_store';
import {
  allowUserLoadSelector,
  isAppReadyToBeShownSelector,
  isPageLoadingSelector,
} from '_store/ui';
import { ViewportLoader } from '_common/ViewportLoader';
import { WithChildren } from '_types/helpers';
import { useGetUser } from '_queries/user/useGetUser';
import { useGetEmployee } from '_queries/employees';
import { ErrorWrapper } from '_common/ErrorWrapper';
import { GuestLayout } from '_layouts/Guest';
import { usePrevious } from '_hooks';
import { useCompanySwitched } from '_hooks/useCompanySwitched';
import { isLoggedInSelector } from '_store/auth';
import { useGetCompany } from '_queries/company/useGetCompany';
import { handleApiError } from '_utils/handleApiError';
import { logout } from '_utils/logout';

import { AppPreloader } from '../PreLoader/PreLoader';

import { useRegistrationFlow } from './hooks';

export const AppReadyGate: React.FC<WithChildren> = ({ children }) => {
  const isAppReady = useTypedSelector(isAppReadyToBeShownSelector);
  const isPageLoading = useTypedSelector(isPageLoadingSelector);

  const allowUserLoad = useTypedSelector(allowUserLoadSelector);
  const isLoggedIn = useTypedSelector(isLoggedInSelector);
  const dispatch = useDispatch();

  const {
    isLoading: isUserLoading,
    data: user,
    isError: isUserError,
    error: userError,
  } = useGetUser(allowUserLoad && isLoggedIn);

  const {
    isError: isCompanyError,
    error: companyError,
    isLoading: isCompanyLoading,
    data: company,
  } = useGetCompany(user?.company?.id);

  const { error: employeeError } = useGetEmployee(user?.employee_id);
  const prevCompany = usePrevious(company);
  const isCompanyInitialLoading = !company && !prevCompany && isCompanyLoading;

  useRegistrationFlow(allowUserLoad, user, company);

  const error = userError || employeeError || companyError;
  const isError = (!user && isUserError) || (!company && isCompanyError);

  useEffect(() => {
    if (!error) {
      return;
    }

    const serializedError = handleApiError(error);

    if (serializedError?.status === 401) {
      logout(dispatch);

      return;
    }
  }, [error, dispatch]);

  const isLoading = isUserLoading || (!isAppReady && !isError) || isCompanyInitialLoading;

  useCompanySwitched(prevCompany, company, user?.company);

  if (isLoading) {
    return <AppPreloader />;
  }

  return (
    <ErrorWrapper isError={isError} layout={GuestLayout} layoutProps={{ withoutBackground: true }}>
      <ViewportLoader isLoading={isPageLoading}>{children}</ViewportLoader>
    </ErrorWrapper>
  );
};
