import React from 'react';

import { PublicLayout } from '_common/PublicLayout';
import { WithNotLoggedIn } from '_common/WithNotLoggedIn';

import { AuthForm } from '../components/AuthForm/AuthForm';

import styles from './styles.scss';

export const LoginPage = () => (
  <WithNotLoggedIn>
    <PublicLayout>
      <div className={styles.root}>
        <AuthForm />
      </div>
    </PublicLayout>
  </WithNotLoggedIn>
);
