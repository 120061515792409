import React from 'react';
import { Redirect, Switch } from 'react-router';

import { PrivateRoute } from '_common/PrivateRoute';
import { settingsRoutes } from '_routes';
import { lazyRetry } from '_utils/lazyRetry';

const CompanyPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "CompanyPage" */ './CompanyPage/CompanyPage'),
    'CompanyPage',
  ),
);

const BillingPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "BillingPage" */ './BillingPage/BillingPage'),
    'BillingPage',
  ),
);

export const SettingsRouter = () => {
  return (
    <Switch>
      <PrivateRoute path={settingsRoutes.settingsCompany} exact={true} component={CompanyPage} />
      <PrivateRoute
        permission="settings:billingTab:read"
        path={settingsRoutes.settingsBilling}
        exact={true}
        component={BillingPage}
      />
      <Redirect to={settingsRoutes.settingsCompany} />
    </Switch>
  );
};
