import { CreateOrderPayload, Flight, Location } from '_api/types';
import { API_APP_VERSION } from '_constants';
import { pickNonEmpty } from '_utils/pickNonEmpty';
import { splitStopsIntoStopsAndDropoff } from '_utils/splitStopsIntoStopsAndDropoff';
import { getUTCDateISO, getLocalDateISO } from '_utils/timezone/timezone';

import { FormValues } from '../types';

import { getPickupDetails } from './getPickupDetails';
import { getPassenger } from './getPassenger';

const getCreateOrderPayload = ({
  formValues,
  pickupLocation,
  selectedServiceId,
  companyId,
  flight,
  expectedDuration,
  timezone,
}: {
  formValues: FormValues;
  pickupLocation: Location;
  selectedServiceId: string;
  companyId?: string | null;
  flight: Flight | null;
  expectedDuration: number | null;
  timezone?: string | null;
}): CreateOrderPayload => {
  const {
    employeeUserId,
    passengerCountryCode,
    passengerName,
    passengerPhoneNumber,
    pickupAt,
    reference,
    specialRequest,
    welcomeBoard,
    welcomeBoardText,
    trainNumber,
    carriageNumber,
    origin,
    arrivalTime,
    payer,
    stops,
    externalId,
  } = formValues;

  const pickup = pickupLocation.go_to_pin ? { ...pickupLocation, line1: '' } : pickupLocation;

  return {
    payment_method_id: companyId || undefined,
    pickup,
    service_id: selectedServiceId,
    app: {
      version: API_APP_VERSION,
    },
    ...pickNonEmpty<Partial<CreateOrderPayload>>({
      user_id: employeeUserId || undefined,
      passenger: getPassenger(passengerName, passengerCountryCode, passengerPhoneNumber),
      pickup_details: getPickupDetails({
        locationType: pickupLocation.type,
        locationSubType: pickupLocation.subtype,
        welcomeBoard,
        welcomeBoardText,
        flightNumber: flight ? `${flight.carrier} ${flight.number}` : undefined,
        flightTrackingId: flight?.id,
        trainNumber,
        carriageNumber,
        origin,
        arrivalTime,
      }),
      pickup_at: flight
        ? flight.estimated_arrival || flight.scheduled_arrival
        : getUTCDateISO(pickupAt, timezone),
      pickup_at_local: getLocalDateISO(pickupAt),
      ...splitStopsIntoStopsAndDropoff(
        stops.map(stop => (stop.data?.go_to_pin ? { ...stop.data, line1: '' } : stop.data)),
      ),
      comment: specialRequest.trim(),
      reference: reference.trim(),
      // 'user' type is not allowed here, see CreateOrderPayload['payer']
      payer: payer === 'user' ? undefined : payer,
      expected_duration: expectedDuration || undefined,
    }),
    external_id: externalId,
  };
};

export { getCreateOrderPayload };
