import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const makeValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    phone: Yup.string().required(
      intl.formatMessage({
        description: 'Empty phone error message',
        defaultMessage: 'Phone is required',
        id: 'YPJFzF',
      }),
    ),
  });
