import React from 'react';
import cn from 'clsx';

import s from './styles.scss';

type Props = React.PropsWithChildren<{ className?: string; dataTestId?: string }>;

export const FormGroupTitle = ({ children, className, dataTestId }: Props) => (
  <div className={cn(className, s.formGroupTitle)} data-test-id={dataTestId}>
    {children}
  </div>
);
