import React from 'react';
import cn from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Divider, AnchorButton, ButtonGroup } from '@wheely/ui-kit';

import { LanguageSwitcher } from '_common/LanguageSwitcher';
import { useLegalLinks } from '_hooks/useLegalLinks';

import s from './styles.scss';

const GuestFooter: React.VFC<{ dark?: boolean }> = ({ dark = false }) => {
  const links = useLegalLinks();

  return (
    <div className={cn(s.footer, { [s.dark]: dark })}>
      <LanguageSwitcher className={s.languageSwitcher} dark={dark} />
      <Divider dark={dark} />

      <ButtonGroup minimal={true} square={true} className={s.linksGroup}>
        <AnchorButton href={links.termsOfUse} small={true} className={s.link}>
          <FormattedMessage
            description="Terms & Conditions link text"
            defaultMessage="Terms & Conditions"
            id="l3pPMG"
          />
        </AnchorButton>
        <AnchorButton href={links.privacyPolicy} small={true} className={s.link}>
          <FormattedMessage
            description="Privacy Policy link text"
            defaultMessage="Privacy Policy"
            id="C3ZF7Z"
          />
        </AnchorButton>
        <AnchorButton href={links.cookiePolicy} small={true} className={s.link}>
          <FormattedMessage
            description="Cookie Policy link text"
            defaultMessage="Cookie Policy"
            id="euhaNY"
          />
        </AnchorButton>
      </ButtonGroup>
    </div>
  );
};

export { GuestFooter };
