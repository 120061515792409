import React, { useCallback, useState } from 'react';
import { FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { isThisYear, isToday, subDays } from 'date-fns';
import { Button, ButtonGroup, FormGroup, TextField } from '@wheely/ui-kit';

import { BREAKPOINTS, useBreakpoints } from '_hooks/useBreakpoints';
import { useLocaleDate } from '_i18n/useLocaleDate';
import { DatePicker } from '_common/DatePicker';
import { IconTakeOff } from '_common/icons/IconTakeOff';
import { MobileModal } from '_common/MobileModal';
import { MobileDatePicker } from '_common/DatePicker/MobileDatePicker';
import { MobileTapContainer } from '_common/MobileTapContainer';
import { isValidDate } from '_utils/isValidDate';

import dialogStyles from '../../styles.scss';

import { FORM_FIELDS, FormErrors, FormValues } from './types';
import s from './styles.scss';

// XXX-XXXX(X)
const FLIGHT_NUMBER_MAX_LENGTH = 11;

const NOT_ALLOWED_FLIGHT_NUMBER_CHARACTERS_REGEX = /[^\s\dA-Z-()]/gi;

const flightNumberFieldFormGroupProps = {
  className: s.flightNumberFormGroup,
};

const flightNumberFieldLeftIcon = <IconTakeOff />;

const DATE_FORMAT_PATTERN = 'd MMMM';
const FULL_DATE_FORMAT_PATTERN = `${DATE_FORMAT_PATTERN}, yyyy`;

const getSubtitleFormatPattern = (value?: Date) => {
  if (isValidDate(value) && isThisYear(value)) {
    return DATE_FORMAT_PATTERN;
  }

  return FULL_DATE_FORMAT_PATTERN;
};

type Props = FormikProps<FormValues> & {
  isServerErrorOccurred: boolean;
  serverValidationErrors: FormErrors;
  onBookWithoutFlightNumber: () => void;
  onResetServerValidationError: (fieldName: keyof FormErrors) => void;
  isLoading: boolean;
  className?: string;
};

export const FormContent = ({
  handleSubmit,
  handleBlur,
  isLoading,
  errors,
  values,
  touched,
  setFieldValue,
  isValid,
  isServerErrorOccurred,
  serverValidationErrors,
  onBookWithoutFlightNumber,
  onResetServerValidationError,
  className,
}: Props) => {
  const intl = useIntl();
  const { format: formatDate } = useLocaleDate();
  const { isMobile, isDesktop } = useBreakpoints();
  const [isDatePickerModalOpened, setIsDatePickerModalOpened] = useState(false);

  const handleOpenMobileDatePicker = useCallback(() => {
    setIsDatePickerModalOpened(true);
  }, []);

  const handleCloseMobileDatePicker = useCallback(() => {
    setIsDatePickerModalOpened(false);
  }, []);

  const handleFlightNumberChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    event => {
      onResetServerValidationError(FORM_FIELDS.flightNumber);

      const newValue = event.target.value.replace(NOT_ALLOWED_FLIGHT_NUMBER_CHARACTERS_REGEX, '');

      setFieldValue(FORM_FIELDS.flightNumber, newValue, true);
    },
    [setFieldValue, onResetServerValidationError],
  );

  const handleDepartureDateChange = useCallback(
    (date: Date) => {
      setFieldValue(FORM_FIELDS.departureDate, date);

      if (isDatePickerModalOpened) {
        setIsDatePickerModalOpened(false);
      }
    },
    [isDatePickerModalOpened, setFieldValue],
  );

  // departure date can be yesterday
  const minDate = subDays(new Date(), 1);

  const getMobileDatePickerSubtitle = useCallback(
    (localValue: Date) =>
      intl.formatMessage(
        {
          description: 'Flight search form mobile date picker',
          defaultMessage: 'Schedule for {date}',
          id: 'R9Gg8H',
        },
        {
          date: formatDate(localValue, getSubtitleFormatPattern(localValue)),
        },
      ),
    [formatDate, intl],
  );

  return (
    <form onSubmit={handleSubmit} className={className} data-test-id="flight-search-form">
      <TextField
        formGroupProps={flightNumberFieldFormGroupProps}
        disabled={isLoading}
        title={intl.formatMessage({
          description: 'Flight number field title',
          defaultMessage: 'Flight number',
          id: 'ldwfed',
        })}
        name={FORM_FIELDS.flightNumber}
        error={serverValidationErrors.flightNumber || (touched.flightNumber && errors.flightNumber)}
        onChange={handleFlightNumberChange}
        value={values.flightNumber}
        maxLength={FLIGHT_NUMBER_MAX_LENGTH}
        autoFocus={isDesktop}
        leftIcon={flightNumberFieldLeftIcon}
        withClearButton={true}
        onBlur={handleBlur}
        data-test-id="flight-number-input"
      />
      <FormGroup
        title={intl.formatMessage({
          description: 'Departure date field title',
          defaultMessage: 'Departure date',
          id: 'nE0eVc',
        })}
        description={intl.formatMessage({
          description: 'Departure date field description',
          defaultMessage: 'Arrivals are tracked automatically so your chauffeur will be on time.',
          id: 'zAHpjA',
        })}
      >
        <MobileTapContainer
          disabled={isLoading}
          onClick={handleOpenMobileDatePicker}
          breakpoint={BREAKPOINTS.isMobile}
        >
          <DatePicker
            value={values.departureDate}
            onChange={handleDepartureDateChange}
            isToday={isToday(values.departureDate)}
            todayTitle={intl.formatMessage(
              {
                description: 'Dater picker full today title',
                defaultMessage: 'Today, {formattedDate}',
                id: 'asQ4PO',
              },
              {
                formattedDate: formatDate(new Date(), 'PPP'),
              },
            )}
            disabledDays={{ before: minDate }}
            disabled={isLoading}
            usePortalForPopover={false}
            popoverTargetTagName={'div'}
          />
        </MobileTapContainer>
      </FormGroup>

      <div className={s.serverErrorMessageContainer}>
        {isServerErrorOccurred &&
          intl.formatMessage({
            description: 'Server error message',
            defaultMessage: 'Server error, please try again later.',
            id: 'PigG+0',
          })}
      </div>

      <ButtonGroup className={dialogStyles.footer} vertical={isMobile}>
        <Button
          className={dialogStyles.footerButton}
          type="submit"
          disabled={isLoading || !isValid}
          loading={isLoading}
          text={intl.formatMessage({
            defaultMessage: 'Find flight',
            id: 't+hJjv',
            description: 'Find flight button caption',
          })}
          data-test-id={'flight-search-form-submit'}
        />
        <Button
          className={dialogStyles.footerButton}
          minimal={true}
          onClick={onBookWithoutFlightNumber}
          text={intl.formatMessage({
            defaultMessage: 'Book without flight number',
            id: '9WL0nx',
            description: 'Book without flight number button text',
          })}
        />
      </ButtonGroup>

      {isDatePickerModalOpened && (
        <MobileModal onClose={handleCloseMobileDatePicker} breakpoint={BREAKPOINTS.isMobile}>
          <MobileDatePicker
            value={values.departureDate}
            onSubmit={handleDepartureDateChange}
            todayTitle={intl.formatMessage({
              description: 'Airport pickup departure today title',
              defaultMessage: 'Today',
              id: 'WE22IQ',
            })}
            disabledDays={{ before: minDate }}
            fromMonth={minDate}
            title={intl.formatMessage({
              description: 'Airport pickup departure date picker, modal title',
              defaultMessage: 'Departure date',
              id: 'cGF2LW',
            })}
            subTitle={getMobileDatePickerSubtitle}
            isDateOnly={true}
          />
        </MobileModal>
      )}
    </form>
  );
};
