import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import {
  getLocationByReference as baseGetLocationByReference,
  GetLocationByReferenceParams,
  RefinedLocation,
} from '_sagas/common/getLocationByReference';
import {
  getLocationByReferenceFailed as getLocationByReferenceFailedAction,
  getLocationByReferenceSucceed as getLocationByReferenceSucceedAction,
} from '_store/newJourney';
import { handleApiError } from '_utils/handleApiError';

function* getLocationByReference({
  locationReference,
  type,
}: GetLocationByReferenceParams): SagaIterator<RefinedLocation | undefined> {
  try {
    const result: RefinedLocation = yield call(baseGetLocationByReference, {
      locationReference,
      type,
    });

    if (!result?.location) {
      throw new Error(
        'An error occurred trying to refine location: got empty result. Please, try again.',
      );
    }

    yield put(getLocationByReferenceSucceedAction(result));

    return result;
  } catch (error) {
    const serializedError = handleApiError(error);

    yield put(getLocationByReferenceFailedAction({ error: serializedError, type }));
  }
}

export { getLocationByReference };
