import React from 'react';
import { Route, Switch } from 'react-router';

import { NotFound } from '_pages/NotFound';
import { authRoutes, commonRoutes } from '_routes';
import { lazyRetry } from '_utils/lazyRetry';
import { PrivateRoute } from '_common/PrivateRoute';

import { LoginPage } from './LoginPage/LoginPage';
import { PendingCompanyPage } from './PendingCompanyPage/PendingCompanyPage';
import { WelcomeCompanyPage } from './WelcomeCompanyPage/WelcomeCompanyPage';

const RegisterPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "RegisterPage" */ './RegisterPage/RegisterPage'),
    'RegisterPage',
  ),
);

const AddCompanyPage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "AddCompanyPage" */ './AddCompanyPage/AddCompanyPage'),
    'AddCompanyPage',
  ),
);

export const AuthRouter = () => {
  return (
    <Switch>
      <Route path={authRoutes.loginRoute} component={LoginPage} exact={true} />
      <Route path={authRoutes.registerRoute} component={RegisterPage} exact={true} />
      <PrivateRoute path={authRoutes.pendingRoute} component={PendingCompanyPage} exact={true} />
      <PrivateRoute path={authRoutes.welcomeRoute} component={WelcomeCompanyPage} exact={true} />
      <PrivateRoute path={authRoutes.addCompanyRoute} component={AddCompanyPage} exact={true} />
      <Route component={NotFound} path={[commonRoutes.notFound, '*']} />
    </Switch>
  );
};
