import React, { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button, Divider, FormGroup } from '@wheely/ui-kit';

import { JourneyDateTimePicker } from '_common/JourneyDateTimePicker';
import { PrebookOnlyNote } from '_common/JourneyDateTimePicker/PrebookOnlyNote';
import { useTypedSelector } from '_store';
import {
  selectedServiceOfferCitySelector,
  selectedServiceOfferTimezoneSelector,
  selectedServiceOfferUtcOffsetSelector,
} from '_store/common/selectors';
import { isValidDate } from '_utils/isValidDate';
import { roundDateUpTo } from '_utils/roundDateUpTo';

import { FormGroupTitle } from '../FormGroupTitle';

import { MESSAGES } from './constants';
import s from './styles.scss';

type Props = {
  value: null | Date;
  onChange: (datetime: null | Date) => void;
  earliestReservationDate: Date;
  eta: number | null;
  error?: string;
  disabled?: boolean;
  isPrebookOnly: boolean;
};

export const PickupTimeField = ({
  value,
  onChange,
  earliestReservationDate,
  eta,
  error,
  disabled,
  isPrebookOnly,
}: Props) => {
  const intl = useIntl();
  const utcOffset = useTypedSelector(selectedServiceOfferUtcOffsetSelector);
  const timezone = useTypedSelector(selectedServiceOfferTimezoneSelector);
  const city = useTypedSelector(selectedServiceOfferCitySelector);
  const lastDateValue = useRef(value);

  const isValueValid = isValidDate(value);
  const isPrebookMode = isPrebookOnly || isValueValid;

  const handleIsPrebookChange = useCallback<(event: React.MouseEvent<HTMLButtonElement>) => void>(
    event => {
      if (event.currentTarget.value === 'prebook') {
        if (isValueValid && isPrebookMode) {
          return;
        }

        const nextDateValue = lastDateValue.current || roundDateUpTo(5, earliestReservationDate);

        onChange(nextDateValue);

        return;
      }

      if (isPrebookMode) {
        lastDateValue.current = value;
        onChange(null);
      }
    },
    [isPrebookMode, isValueValid, earliestReservationDate, onChange, value],
  );

  return (
    <FormGroup
      formGroupProps={{ className: s.formGroup }}
      title={
        <FormGroupTitle dataTestId={'pickup-time-field-title'}>
          {intl.formatMessage(MESSAGES.pickupTimeFieldTitle)}
        </FormGroupTitle>
      }
      error={error}
    >
      <div className={s.pickupAtModeSwitch}>
        <Button
          value={'asap'}
          className={s.pickupAtModeSwitchButton}
          minimal={true}
          active={!isPrebookMode}
          onClick={handleIsPrebookChange as any}
          disabled={disabled || isPrebookOnly}
          text={intl.formatMessage({
            defaultMessage: 'Now',
            id: '+Er/ld',
            description: 'Pickup at mode switcher now button label',
          })}
          data-test-id="pickup-mode-asap-button"
        />
        <Button
          value={'prebook'}
          className={s.pickupAtModeSwitchButton}
          minimal={true}
          active={isPrebookMode}
          onClick={handleIsPrebookChange as any}
          disabled={disabled}
          text={intl.formatMessage({
            defaultMessage: 'Schedule',
            id: '/9jt8B',
            description: 'Pickup at mode switcher schedule button label',
          })}
          data-test-id="pickup-mode-prebook-button"
        />
      </div>
      <Divider />

      {isPrebookMode && (
        <>
          {isPrebookOnly && <PrebookOnlyNote eta={eta} timezone={timezone} />}
          <JourneyDateTimePicker
            value={value}
            onChange={onChange}
            error={error}
            disabled={disabled}
            earliestReservationDate={earliestReservationDate}
            utcOffset={utcOffset}
            timezone={timezone}
            city={city}
            datePickerProps={{
              disabledDays: { before: earliestReservationDate },
              fromMonth: earliestReservationDate,
            }}
          />
        </>
      )}
    </FormGroup>
  );
};
