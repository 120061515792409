import React from 'react';
import { FootnoteButton } from '@wheely/web-ui';
import { FormattedMessage } from 'react-intl';

import styles from './styles.scss';

type Props = {
  timeUntilRecall: number;
  onClick(): void;
};

export const CallMeButton = ({ timeUntilRecall, onClick }: Props) =>
  timeUntilRecall > 0 ? (
    <>
      <FormattedMessage
        defaultMessage="Call me"
        id="sign_in_otp_call"
        description="Call for verification code button"
      />{' '}
      <FormattedMessage
        defaultMessage="in {time}s"
        id="sign_in_otp_timer"
        description="Call verification code button"
        values={{
          time: timeUntilRecall,
        }}
      />
    </>
  ) : (
    <FootnoteButton as="button" onClick={onClick} type="button" className={styles.resendButton}>
      <FormattedMessage
        defaultMessage="Call me"
        id="sign_in_otp_call"
        description="Call for verification code button"
      />
    </FootnoteButton>
  );
