import { enGB, fr, ru } from 'date-fns/locale';

import { LangCode } from '_types/common';

export const AVAILABLE_LANGUAGES: LangCode[] = ['en', 'ru', 'fr'];
export const DEFAULT_LANGUAGE_CODE: LangCode = 'en';

export const DATE_LOCALES: Record<LangCode, Locale> = {
  en: enGB,
  fr: fr,
  ru: ru,
};
