import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react';

export type DimmerContextValue = {
  isShowDimmer: boolean;
  setIsShowDimmer: (isShowDimmer: boolean) => void;
};

export const DimmerContext = createContext<DimmerContextValue>({
  isShowDimmer: false,
  setIsShowDimmer: () => {},
});

export type Props = {
  children: ReactNode;
  disabled?: boolean;
};

export const DimmerProvider = ({ children, disabled = false }: Props) => {
  const [isShowDimmer, setIsShowDimmer] = useState(false);

  const value = useMemo(
    () => ({
      isShowDimmer: isShowDimmer && !disabled,
      setIsShowDimmer,
    }),
    [isShowDimmer, disabled],
  );

  return <DimmerContext.Provider value={value}>{children}</DimmerContext.Provider>;
};

export const useDimmer = () => useContext(DimmerContext);
