import { AxiosError } from 'axios';

import { joinErrorMessages } from '_utils/joinErrorMessages';

import { ErrorData, ErrorShape, NewServiceErrorData } from './types';

const serializeError = (error: AxiosError<ErrorData | NewServiceErrorData>): ErrorShape => {
  const errorShape: ErrorShape = {
    message: error.message,
  };

  if (error.response) {
    errorShape.status = error.response.status;

    const data = error.response.data;

    if (typeof data === 'object') {
      errorShape.message = joinErrorMessages(data.errors);

      if (Array.isArray(data.errors)) {
        errorShape.data = Object.fromEntries(
          data.errors.map(({ code, message }) => [code, message]),
        );

        errorShape.errorsByField = data.errors.map(({ field, message }) => ({ field, message }));
      } else {
        errorShape.data = data;
      }
    }
  }

  return errorShape;
};

export { serializeError };
