import { useGetUser } from '_queries/user/useGetUser';

export const useUser = () => {
  const { data } = useGetUser(false);

  return data;
};

export const useUserName = () => {
  const user = useUser();

  return user ? `${user.first_name || ''} ${user.last_name || ''}`.trim() : null;
};

export const useIsUserHasMoreThenOneCompany = () => {
  const user = useUser();

  return Number(user?.companies?.length) > 1;
};

export const useUserRole = () => {
  const user = useUser();

  return user?.role;
};

export const useUserId = () => {
  const user = useUser();

  return user?.id || null;
};

export const useIsUserHasLinkedCompany = () => {
  const user = useUser();

  return Boolean(user?.company);
};

export const useUserLinkedCompany = () => {
  const user = useUser();

  return user?.company;
};

export const useUserEmployeeId = () => {
  const user = useUser();

  return user?.employee_id;
};

export const useUserCompanies = () => {
  const user = useUser();

  return user?.companies;
};
