import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import pickBy from 'lodash/fp/pickBy';
import { captureMessage } from '@sentry/react';
import { Formik } from 'formik';
import cn from 'clsx';
import { FORBIDDEN_PHONE_NUMBER_CHARACTERS_REGEXP } from '@wheely/ui-kit';

import { Location } from '_api/types';
import { useTypedSelector } from '_store';
import { withSagaFlow } from '_hocs/withSagaFlow';
import { newJourneyFlowSaga, resetEmployeesQueryState } from '_sagas/newJourney';
import { BasicLayout } from '_layouts/Basic';
import { SectionHeader } from '_common/SectionHeader';
import { PageContent } from '_common/PageContent';
import { CompanyDebtsNotifier } from '_common/CompanyDebtsNotifier';
import { CompanyAttachCardNotifier } from '_common/CompanyAttachCardNotifier';
import { useBreakpoints } from '_hooks/useBreakpoints';
import {
  selectedServiceOfferTimezoneSelector,
  selectedServiceOfferUtcOffsetSelector,
} from '_store/common/selectors';
import {
  expectedDurationSelector,
  createOrder,
  createOrderRequest,
  flightSelector,
  pickupLocationSelector,
  selectedServiceIdSelector,
  serverErrorSelector as newJourneyServerErrorSelector,
  setPickupLocation as setPickupLocationAction,
  railwayHubSelector,
  isOriginPickupSubTypeSelector,
} from '_store/newJourney';
import {
  isLoadingSelector as isServiceOffersLoadingSelector,
  selectedServiceOfferSelector,
  serverErrorSelector as serviceOffersErrorSelector,
} from '_store/serviceOffers';
import { PAYER_TYPES } from '_constants';
import { pickNonEmpty } from '_utils/pickNonEmpty';
import { featureFlags } from '_utils/featureFlags';
import { getEarliestReservationDate } from '_utils/getEarliestReservationDate';
import { getServiceOfferEta } from '_utils/getServiceOfferNearestReservationOffset';
import { mapModeSelector } from '_store/mapInteraction';
import { useCleanupMapState } from '_hooks/useCleanupMapState';
import { MobileModalControls } from '_common/Map/components/MobileModalControls';
import { useCompany } from '_hooks/company';

import { Map } from './components/Map';
import { getPickupAtErrorFromServerResponse } from './components/Form/utils/getPickupAtErrorFromServerResponse';
import { getCreateOrderPayload } from './components/Form/utils/getCreateOrderPayload';
import { getCreateOrderRequestPayload } from './components/Form/utils/getCreateOrderRequestPayload';
import { getPickupAtError } from './components/Form/utils/getPickupAtError';
import { isPayloadHasPassenger } from './components/Form/utils/isPayloadHasPassenger';
import { getDropoffError } from './components/Form/utils/getDropoffError';
import { getPickupError } from './components/Form/utils/getPickupError';
import { Form } from './components/Form';
import { MESSAGES } from './constants';
import { FormErrors, FormValues } from './components/Form/types';
import s from './styles.scss';

const emptyStop = {
  _id: uuid(),
  data: null,
};

export const NewJourney = withSagaFlow({
  onLoad: newJourneyFlowSaga,
  onUnload: resetEmployeesQueryState,
})(() => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isTablet } = useBreakpoints();
  const mapMode = useTypedSelector(mapModeSelector);

  const initialValues: FormValues = useMemo(
    () => ({
      employeeUserId: null,
      passengerName: null,
      passengerCountryCode: null,
      passengerPhoneNumber: null,
      reference: '',
      specialRequest: '',
      pickupAt: null,
      welcomeBoard: false,
      welcomeBoardText: '',
      trainNumber: '',
      carriageNumber: '',
      origin: '',
      arrivalTime: '',
      flightNumber: '',
      payer: PAYER_TYPES.company,
      stops: [emptyStop],
      externalId: uuid(),
    }),
    [],
  );

  const isServiceOffersLoading = useTypedSelector(isServiceOffersLoadingSelector);
  const serverError = useTypedSelector(newJourneyServerErrorSelector);
  const pickupLocation = useTypedSelector(pickupLocationSelector);
  const serviceOffersServerError = useTypedSelector(serviceOffersErrorSelector);

  const company = useCompany();
  const selectedServiceId = useTypedSelector(selectedServiceIdSelector);
  const selectedServiceOffer = useTypedSelector(selectedServiceOfferSelector);
  const selectedServiceUtcOffset = useTypedSelector(selectedServiceOfferUtcOffsetSelector);
  const timezone = useTypedSelector(selectedServiceOfferTimezoneSelector);
  const flight = useTypedSelector(flightSelector);
  const railwayHub = useTypedSelector(railwayHubSelector);
  const isOriginPickupSubType = useTypedSelector(isOriginPickupSubTypeSelector);
  const expectedDuration = useTypedSelector(expectedDurationSelector);

  const validateForm = useCallback(
    (formValues: FormValues) => {
      const errors: FormErrors = {};

      if (!formValues.employeeUserId) {
        errors.employeeUserId = intl.formatMessage(MESSAGES.employeeEmpty);
      }

      if (formValues.passengerName !== null && !formValues.passengerName.trim().length) {
        errors.passengerName = intl.formatMessage(MESSAGES.passengerNameEmpty);
      }

      if (formValues.passengerPhoneNumber !== null) {
        if (!formValues.passengerPhoneNumber.trim().length) {
          errors.passengerPhoneNumber = intl.formatMessage(MESSAGES.passengerPhoneEmpty);
        } else if (FORBIDDEN_PHONE_NUMBER_CHARACTERS_REGEXP.test(formValues.passengerPhoneNumber)) {
          errors.passengerPhoneNumber = intl.formatMessage(MESSAGES.passengerPhoneInvalid);
        }
      }

      errors.pickup = getPickupError(
        pickupLocation,
        Boolean(!isServiceOffersLoading && (!selectedServiceId || serviceOffersServerError)),
        intl.formatMessage,
      );

      if ((flight || railwayHub) && formValues.welcomeBoard && !formValues.welcomeBoardText) {
        errors.welcomeBoardText = intl.formatMessage(MESSAGES.welcomeBoardText);
      }

      if (
        railwayHub &&
        !isOriginPickupSubType &&
        formValues.welcomeBoard &&
        !formValues.trainNumber
      ) {
        errors.trainNumber = intl.formatMessage(MESSAGES.trainNumber);
      }

      if (
        railwayHub &&
        !isOriginPickupSubType &&
        formValues.welcomeBoard &&
        !formValues.carriageNumber
      ) {
        errors.carriageNumber = intl.formatMessage(MESSAGES.carriageNumber);
      }

      if (railwayHub && isOriginPickupSubType && formValues.welcomeBoard && !formValues.origin) {
        errors.origin = intl.formatMessage(MESSAGES.origin);
      }

      if (
        railwayHub &&
        isOriginPickupSubType &&
        formValues.welcomeBoard &&
        !formValues.arrivalTime
      ) {
        errors.arrivalTime = intl.formatMessage(MESSAGES.arrivalTime);
      }

      if (formValues.stops.length) {
        formValues.stops.forEach((stop, index) => {
          const location = stop.data;
          const stopError = getDropoffError(location, intl.formatMessage);

          if (stopError && !errors.stops) {
            errors.stops = [];
          }

          if (stopError && Array.isArray(errors.stops)) {
            errors.stops[index] = stopError;
          }
        });
      }

      if (selectedServiceOffer) {
        const selectedServiceOfferEta = getServiceOfferEta(
          selectedServiceOffer,
          formValues.welcomeBoard,
        );

        const earliestReservationDate = getEarliestReservationDate(
          selectedServiceOfferEta,
          selectedServiceUtcOffset,
        );

        errors.pickupAt = getPickupAtError(
          formValues.pickupAt,
          earliestReservationDate,
          intl.formatMessage,
        );
      }

      // Server errors handling
      const serverErrorMessages = serverError?.data?.errors;

      if (serverErrorMessages) {
        if (!errors.employeeUserId && serverErrorMessages.user_blocked) {
          errors.employeeUserId = intl.formatMessage(MESSAGES.employeeBlocked);
        } else if (!errors.employeeUserId && serverErrorMessages.payment_type) {
          errors.employeeUserId = serverErrorMessages.payment_type.join('\n');
        } else if (!errors.pickupAt && serverErrorMessages.wrong_pickup_at) {
          errors.pickupAt = getPickupAtErrorFromServerResponse(serverError, intl.formatMessage);
        }
      }

      return pickNonEmpty(errors);
    },
    [
      pickupLocation,
      isServiceOffersLoading,
      selectedServiceId,
      selectedServiceOffer,
      serviceOffersServerError,
      intl,
      flight,
      isOriginPickupSubType,
      railwayHub,
      selectedServiceUtcOffset,
      serverError,
    ],
  );

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      // This is just a type guard and case when pickupLocation and selectedServiceId
      // are equal to nulls not possible because they should be validated.
      // Otherwise, log this case in Sentry as emergency.
      if (!pickupLocation || !selectedServiceId || !company) {
        captureMessage(
          `Invalid values during form submitting, empty values: ${JSON.stringify(
            pickBy(value => !value, {
              pickupLocation,
              selectedServiceId,
              company,
            }),
          )}`,
        );

        return;
      }

      const createOrderPayload = getCreateOrderPayload({
        formValues,
        pickupLocation,
        selectedServiceId,
        companyId: company.id,
        flight,
        expectedDuration,
        timezone,
      });

      if (featureFlags.isB2CPaymentEnabled(company) && formValues.payer === 'user') {
        if (!isPayloadHasPassenger(createOrderPayload)) {
          const errorMessage = 'Invalid passenger field value during order request creation';

          // eslint-disable-next-line no-console
          console.warn(errorMessage, {
            passenger: createOrderPayload.passenger,
          });

          captureMessage(errorMessage);

          return;
        }

        dispatch(createOrderRequest(getCreateOrderRequestPayload(createOrderPayload)));

        return;
      }

      dispatch(createOrder(createOrderPayload));
    },
    [timezone, company, dispatch, expectedDuration, flight, pickupLocation, selectedServiceId],
  );

  const handleSetPickupLocation = useCallback(
    (location: Location) => {
      dispatch(setPickupLocationAction({ location }));
    },
    [dispatch],
  );

  useCleanupMapState();

  const screenToRender = mapMode === 'edit' && isTablet ? 'map' : 'form';

  return (
    <BasicLayout
      className={cn({ [s.lock]: screenToRender === 'map' })}
      contentClassName={cn(s.layoutContent, { [s.layoutContentForMap]: screenToRender === 'map' })}
    >
      <Formik<FormValues>
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {formikProps => (
          <>
            <PageContent
              className={cn(s.pageContent, { [s.hidden]: screenToRender === 'map' })}
              dataTestId={'new-journey-section'}
            >
              <SectionHeader data-test-id="new-journey-header">
                <FormattedMessage
                  description="New Journey page title"
                  defaultMessage="New Journey"
                  id="fusZT3"
                />
              </SectionHeader>
              <div className={s.notifier}>
                <CompanyDebtsNotifier />
                <CompanyAttachCardNotifier />
              </div>
              <Form {...formikProps} />
            </PageContent>

            {!isTablet && <Map handleSetPickupLocation={handleSetPickupLocation} />}

            {screenToRender === 'map' && (
              <MobileModalControls handleSetPickupLocation={handleSetPickupLocation}>
                <Map className={s.map} showEditControls={false} />
              </MobileModalControls>
            )}
          </>
        )}
      </Formik>
    </BasicLayout>
  );
});
