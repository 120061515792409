import uniq from 'lodash/fp/uniq';
import join from 'lodash/fp/join';
import pick from 'lodash/fp/pick';
import compose from 'lodash/fp/compose';
import values from 'lodash/fp/values';
import compact from 'lodash/fp/compact';

import { Location } from '_api/types';

const formatAddress: (location: Location) => string = compose(
  join(', '),
  compact,
  uniq,
  values,
  pick(['line1', 'line2']),
);

export { formatAddress };
